import React, { useEffect, useState } from "react";
import MainPage from "../Layout/MainPage";
import SitesDropdown from "../Util/SitesDropdown";
import axios from "axios";
import LeadText from "@material-tailwind/react/LeadText";
import { ChartGeneration } from "./LatestChart";
import {useGetReportsBySite} from "../../hooks/queries/useGetReportsBySite";

export const LatestReport = React.memo(() => {
  const [siteSelected, setSiteSelected] = useState(null);

  const {data: report = [] } = useGetReportsBySite(siteSelected?._id, {days: 1});
  
  const handleClick = (site) => {
    setSiteSelected(site);
  };

  return (
    <MainPage name="Latest Report" color="red">
        <SitesDropdown onShowClick={handleClick}></SitesDropdown>
        {siteSelected ? (
            <>
              {report.length > 0 ? (
                <>
                <ChartGeneration site={siteSelected} siteReport={report}/>
                </>
              ) : (
                <LeadText color="teal">No Data</LeadText>
              )}
            </>
          ) : (
            <>
              <LeadText color="teal">Select a Site</LeadText>
            </>
          )}
    </MainPage>
  );
});
