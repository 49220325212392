import React, { useEffect } from "react";
import Button from "@material-tailwind/react/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  button: {
    marginLeft: "20px"
  }
});
const ButtonLink = props => {
    const {name,  disabled, color, onClick, style} = props;
    const classes = useStyles();

  return (
    <Button
      className={classes.button}
      disabled={disabled}
      color={color}
      onClick={onClick}
      style={style}
    >
      {name}
    </Button>
  );
};

export default ButtonLink;

//    < Button className = { classes.button }
//color = { props.color }
//onClick = { props.onClick } >
//    { props.name }
//            </Button >

//const useStyles = makeStyles({
//    button: {
//        marginLeft: "20px"
//    }
//});
