import { Face, Fingerprint } from '@material-ui/icons'
import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from '@material-ui/core';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import Button from "@material-tailwind/react/Button";
import { makeStyles } from '@material-ui/core/styles';
import "@material-tailwind/react/tailwind.css";
import Input from "@material-tailwind/react/Input";
import { Link } from 'react-router-dom';
import axios from "axios";



export default function ResetPassword({ match, history }) {

    const useStyles = makeStyles(theme => ({

        padding: {
            padding: theme.spacing(5),
            width: '600px',
            height: '300px',
            minWidth: '200px'

        },
        box: {
            background: ' linear-gradient(-45deg, #ee7752, #23a6d5, #23d5ab);',
            height: '100vh',

        },
        margin: {
            marginTop: 'auto',
            flex: 0.8,
            alignItems: 'center',
        },
        icon: {
            fontSize: '2rem',
            color: 'grey',
        },
        card: {
            width: '600px'
        }

    }))
    const classes = useStyles();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const resetPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            header: {
                "Content-Type": "application/json",
            },
        };

        if (password !== confirmPassword) {
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                setError("");
            }, 5000);
            return setError("Passwords don't match");
        }

        try {

            const { data } = await axios.put(process.env.REACT_APP_BACKEND_LINK +
                `api/auth/passwordReset/${match.params.resetToken}`,
                {
                    password,
                },
                config
            );

            if (data?.success) {
                localStorage.setItem("authToken", data.token);
                setSuccess(data?.success);
                history.push('/login');
            }
            
        } catch (error) {
            setError(error.response.data.error);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    useEffect(() => { }, [success])

    return (
        <form
            onSubmit={resetPasswordHandler}
            className="resetpassword-screen__form"
        >
            <Box className={classes.box} display="flex" flexGrow={1} justifyContent="center" alignItems='center' p={2}>
                <Card className={classes.card}>
                    <CardHeader color="blueGray" size="lg">
                        <Typography>
                            Reset Password
                        </Typography>
                    </CardHeader>
                    {error && <span className="error-message">{error} </span>}
                    {success && (
                        <span className="success-message">
                            {success} <Link to="/login">Login</Link>
                        </span>
                    )}

                    <CardBody>

                        <Box >
                            <Grid container spacing={8} alignItems="flex-end">
                                <Grid item>
                                    <Fingerprint className={classes.icon} />
                                </Grid>
                                <Grid item md={true} sm={true} xs={true}>
                                    <Input
                                        type="password"
                                        color="teal"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box >
                            <Grid container spacing={8} alignItems="flex-end">
                                <Grid item>
                                    <Fingerprint className={classes.icon} />
                                </Grid>
                                <Grid item md={true} sm={true} xs={true}>
                                    <Input
                                        type="password"
                                        color="teal"
                                        placeholder="Confirm new password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardBody>

                    <CardFooter>
                        <div className="flex justify-center">
                            <Button
                                color="teal"
                                size="lg"
                                ripple="dark"
                                type="submit"
                            >
                                Reset password
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </Box >
        </form>
    );
}