import React, { useState, useEffect, useContext, memo } from "react";
import { useHistory } from "react-router";
import MainPage from "../../Layout/MainPage";
import { Box, TableContainer } from "@material-ui/core";
import { fetchAllSites, getSitesForUser } from "../../../shared/api";
import { SiteContext } from "../../../shared/context/site/site-context";
import { authContext } from "../../../shared/context/auth/auth-context";
import Grid from "@material-ui/core/Grid";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import ButtonLink from "../../Util/ButtonLink";
import SiteData from "../../Sites/SiteData";

const useStyles = makeStyles({
  pageContent: {
    marginTop: 40
  },
  table: {
    minWidth: 650,

    transition: "0.5s ease"
  },
  searchBar: {
    marginBottom: "30px"
  },
  tableRow: {
    transition: "0.3s ease",
    "&:hover": {
      backgroundColor: "#b8eaff !important"
    }
  },
  link: {
    transition: "0.5s ease",
    "&:hover": {
      fontWeight: 500
    }
  }
});

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.disabled
    }
  }
}))(TableRow);

const Inventory = React.memo(props => {
  const { updateSiteContext } = useContext(SiteContext);
  const { user } = useContext(authContext);
  const history = useHistory();
  const [locations, setLocations] = useState([]); // array of all the locations
  const [site, setSite] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // search bar hooks and methods
  const [searched, setSearched] = useState("");
  const [rows, setRows] = useState(SiteData.originalRows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredRows = rows.filter(row => {
    return row?.siteName?.toLowerCase().includes(searched.toLowerCase());
  });
  const cancelSearch = () => {
    setSearched("");
  };

  useEffect(async () => {
    if (user) {
      let sites = await getSitesForUser(user.id);
      sites.sites = sites.sites.sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1);
      if (sites.success) {
        setRows(sites.sites);
      } else {
        setRows([]);
      }
    }
  }, []);

  return (
    <MainPage name={"Inventory"} color="indigo">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {SiteData.columnsAdminInventory.map(column => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <StyledTableRow className={classes.tableRow} key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.siteName}
                      </TableCell>
                      <TableCell>
                        <ButtonLink
                          name="View"
                          color="indigo"
                          onClick={() => {
                            updateSiteContext(row._id);
                            history.push({
                              pathname: "/inventories",
                              state: {
                                site_id: row._id,
                                siteName: row.siteName
                              }
                            });
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <ButtonLink
                          name="View"
                          color="indigo"
                          onClick={() => {
                            updateSiteContext(row._id);
                            history.push({
                              pathname: "/reorders",
                              state: {
                                _id: row._id,
                                siteName: row.siteName
                              }
                            });
                          }}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </MainPage>
  );
});

export default Inventory;
