import { Route, Switch, HashRouter } from "react-router-dom";
import "@material-tailwind/react/tailwind.css";
import Site from "../Sites/Site/Site";
import Bays from "../Sites/Bays/Bays";
import Packages from "../Sites/Packages/Packages";
import Sites from "../Sites/Sites";
import Controllers from "../Sites/Controllers/Controllers";
import SideNavigation from "../Navigation/SideNavigation";
import { makeStyles } from "@material-ui/core/styles";
import Dashboard from "../Dashboard/operator/Dashboard";
import Alerts from "../Alerts/Alerts";
import React from "react";
import Inventory from "../Inventory/coordinator/Inventory";
import Inventories from "../Inventory/coordinator/Inventories";
import Reorder from "../Inventory/coordinator/Reorder";
import TestSensor from '../TestSensor/TestSensor'
import { MultiDayReport } from "../Reports/MultidayReport";
import { LatestReport } from '../Reports/LatestReport'
import { ProductUsage } from "../Reports/ProductUsageReport";
import Tanks from "../Sites/Tanks/Tanks";
import TanksLevel from "../Sites/Tanks/operator/Tanks";
import Support from "../support/Support";
import AlertPreferences from "../Alerts/AlertPreferences";

const useStyles = makeStyles({
  container: {
    display: "flex"
  },
  content: {
    margin: "auto",
    width: "78%"
  }
});

function Distributor({ logoutHandler }) {
  const classes = useStyles();

  return (
    <>
      <HashRouter>
        <div className={classes.container}>
          <SideNavigation logoutHandler={logoutHandler} />
          <div className={classes.content}>
            <Switch>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route path="/inventories">
                <Inventories />
              </Route>
              <Route path="/sites">
                <Sites />
              </Route>
              <Route path="/site">
                <Site />
              </Route>
              <Route path="/tanks">
                <Tanks />
              </Route>
              <Route path="/tanksLevel">
                <TanksLevel />
              </Route>
              <Route path="/testSensor">
                <TestSensor />
              </Route>
              <Route path="/controllers">
                <Controllers />
              </Route>
              <Route path="/bays">
                <Bays />
              </Route>
              <Route path="/packages">
                <Packages />
              </Route>
              <Route path="/alerts">
                <Alerts />
              </Route>
              <Route path="/alertPreferences">
                <AlertPreferences />
              </Route>
              <Route path="/inventory">
                <Inventory />
              </Route>
              <Route path="/inventories">
                <Inventories />
              </Route>
              <Route path="/reorders">
                <Reorder />
              </Route>
              <Route path="/latestReport">
                <LatestReport />
              </Route>
              <Route path="/productUsage">
                <ProductUsage />
              </Route>
              <Route path="/multidayReport">
                <MultiDayReport />
              </Route>
              <Route path="/support">
                <Support />
              </Route>
            </Switch>
          </div>
        </div>
      </HashRouter>
    </>
  );
}

export default Distributor;
