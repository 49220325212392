import React, { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";

const Cellinput = props => {
    const { low, stock, autoFill, setOrders, productId} = props;
    const [amount, setAmount] = useState(low ? +autoFill : 0);
    const handleChange = (pId, OrderAmn) => (
        setOrders((state) => ({
            ...state,
            [pId]: { ...state[pId], order: +OrderAmn }
        })
        ));

 
    useEffect(() => {
        if (amount > 0) {
            handleChange(productId,autoFill);
        }
    }, []);

    return (
        <TextField
        type="number"
        defaultValue={amount}
        style={{ maxWidth: 75 }}
        id="outlined-basic"
        variant="outlined"
        size="small"       
        onChange={event => { 
            if (event.target.value >= 0) {
                setAmount(event.target.value);
                handleChange(productId, event.target.value);
            } else {
                event.target.value = 0;
            }
        }}
        />
    );
};

export default Cellinput;