import React, { useEffect, useState,useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { TableContainer, Link } from '@material-ui/core';
import SearchBar from "material-ui-search-bar";
import { useHistory } from 'react-router';
import MainPage from '../Layout/MainPage';
import { authContext } from "../../shared/context/auth/auth-context";
import ConfirmDialog from '../Util/ConfirmDialog/ConfirmDialog';
import axios from 'axios'


import DistributorData from './DistributorData';
import ButtonLink from '../Util/ButtonLink';

const useStyles = makeStyles({
    table: {
        minWidth: 650,

        transition: '0.5s ease'
    },
    searchBar: {
        marginBottom: '30px'
    },
    tableRow: {
        transition: '0.3s ease',
        "&:hover": {
            backgroundColor: "#b8eaff !important"
        }
    },
    link: {
        transition: '0.5s ease',
        "&:hover": {
            fontWeight: 500
        }
    }

});
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.disabled,
        },
    },
}))(TableRow);
const Distributors = () => {
    const { user } = useContext(authContext);
    const [disName, setdistributor] = useState()
    const history = useHistory();
    const classes = useStyles();
    const [isSubmited, setIsSubmited] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [confirmOpen, setConfirmOpen] = useState("");
    const [message, setMessage] = useState();

    // search bar hooks and methods
    const [searched, setSearched] = useState("");
    const [rows, setRows] = useState([]);
    const [DeleteRowId, setDeleteRowId] = useState("");


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filteredRows = rows.filter((row) => {
        return (row?.distributorName ? row.distributorName.toLowerCase().includes(searched.toLowerCase()):true);
    });
    const cancelSearch = () => {
        setSearched("");
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_LINK + 'api/distributor/read',
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`
                }
            }
        )
            .then(res => {
                return res.json();
            }).then((data) => {
                //console.log('data==', data);
                data = data.sort((a,b) => (a.distributorName?.toLowerCase() > b.distributorName?.toLowerCase()) ? 1 : -1);
                setRows(data);
            })
    }, []);

  
     

    async function DeleteDistributor() {
        //console.log(DeleteRowId);
        let body = {
            distributorId: DeleteRowId
        };
        fetch(process.env.REACT_APP_BACKEND_LINK + 'api/distributor/delete'
            , {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`
                },
                mode: "cors"
            }
        );
        // setMessage("Delete Successfully!");
        // window.location.reload()
        try {
            await axios.post(
              process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Distributor ${disName} Deleted`, category: "Delete Distributor" }
            )
          }
          catch (error) {
            console.log(error)
          }
        setIsSubmited((state) => !state);
       
    };
    useEffect(()=>{
        const fetchData = async () => {
            const readURL1 = process.env.REACT_APP_BACKEND_LINK + "api/distributor/read/"
            const result = await axios.get(readURL1);
            result.data = result.data.sort((a,b) => (a.distributorName?.toLowerCase() > b.distributorName?.toLowerCase()) ? 1 : -1);
            setRows(result.data);
        }
        fetchData();
    },[isSubmited])


    return (
        <MainPage name={'Distributors List'} color='green'>
            <SearchBar className={classes.searchBar} onChange={(searchVal) => setSearched(searchVal)} onCancelSearch={() => cancelSearch()} />
            {/* Table */}
            {message && <div className="message" style={{ color: 'red', marginLeft: '22px' }}> {message} </div>}
            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead >
                        <TableRow>
                            {DistributorData.columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >

                                    {column.label}

                                </StyledTableCell>
                            ))}


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                        (

                            
                            <StyledTableRow className={classes.tableRow} key={row._id}>
                                <TableCell component="th" scope="row">
                                    <Link style={{ cursor: "pointer" }} className={classes.link} href={row._id} onClick={
                                        (e) => {
                                            //console.log(row._id)
                                            e.preventDefault();
                                            history.push({
                                                pathname: '/updateDistributor',
                                                state: {
                                                    distributorId: row._id
                                                }
                                            })
                                        }
                                    } color="inherit" underline="none">
                                        {row.distributorName}
                                    </Link>
                                </TableCell>

                                <TableCell  >
                                    {row.distributorPhone}
                                </TableCell>

                                <TableCell  >
                                    {row.distributorContact}
                                </TableCell>

                                <TableCell  >
                                    {row.distributorEmail}
                                </TableCell>

                                <TableCell  >
                                    <ButtonLink
                                        name="Edit"
                                        color="green"
                                        onClick={() => {
                                            history.push({
                                                pathname: '/updateDistributor',
                                                state: {
                                                    distributorId: row._id
                                                }
                                            })
                                        }} />
                                </TableCell>
                                <TableCell  >
                                    <ButtonLink
                                        name="Delete"
                                        color="gray"
                                        onClick={() => {
                                            setConfirmOpen(true);
                                            setDeleteRowId(row._id);
                                            setdistributor(row.distributorName)
                                        }} />
                                    <ConfirmDialog
                                        title="Delete Distributor"
                                        open={confirmOpen}
                                        setOpen={setConfirmOpen}
                                        onConfirm={DeleteDistributor}
                                    >
                                        Are you sure you want to delete this distributor?
                                    </ConfirmDialog>

                                </TableCell>

                            </StyledTableRow>
                        )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ButtonLink
                onClick={() => { history.push('/createDistributor') }}
                color="pink"
                name="Create New Distributor" />
        </MainPage>

    )
}

export default Distributors
