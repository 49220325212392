import React, { useState, useEffect, useContext } from "react";
import TableCell from '@material-ui/core/TableCell';
import StyleTableRow from '../Util/StyleTableRow';
import { fetchUserRelatedSites, updateUserSites } from '../../shared/api'
import ButtonLink from '../Util/ButtonLink'
import { Typography } from '@material-ui/core'
import DialogComponent from '../Util/DialogComponent';
import SearchBar from "material-ui-search-bar";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReactLoading from 'react-loading';
import swal from 'sweetalert'


const UserRow = props => {
    const { user, isSubmited, setIsSubmited, classes } = props;

    const [userSites, setUserSites] = useState([]);
    const [open, setOpen] = useState(false);
    const [searched, setSearched] = useState("");
    

    useEffect(() => {
        (async () => {
            const userFetchedSitesData = await fetchUserRelatedSites(user?._id);
            if (!userFetchedSitesData) return;
            const assignedSites = userFetchedSitesData?.userAssignedSites.map(site => { return { ...site, assigned: true } }).sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1);
            const availableSites = userFetchedSitesData?.availableSites.map(site => { return { ...site, assigned: false } }).sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1);
            const userSitesData = [...assignedSites, ...availableSites];
            setUserSites(userSitesData);
        })();
        
    }, [isSubmited]);

    const handleCheckBox = (e) => {
        setUserSites(state => {
            const newState = state.map(site => {
                if (site?._id === e.target.value) return { ...site, assigned: e.target.checked}
                return site;
            });
            return newState;
        });
    }

    const cancelSearch = () => {
       setSearched("");
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {

        const newAssignedSitesIds = userSites.map(site => site?.assigned ? site?._id : '').filter(siteId => siteId);

        let alerts = user?.alerts?.filter(alert => newAssignedSitesIds.includes(alert.siteId));

        let body = {
            sites: newAssignedSitesIds,
            alerts: alerts
        }

        try {
            const res = await updateUserSites(user?._id, body);
            
            if (res.success == true) {
                swal({
                    title: "Success!",
                    text: res.msg,
                    icon: "success",
                    button: "OK!"
                })
                .then((result) => {
                    handleClose();
                    setIsSubmited(state => !state);
                });
            } else {
                swal({
                    title: "Error!",
                    text: res.msg,
                    icon: "error",
                    button: "OK!"
                })
            }
        }
        catch (e) {

            if (e.message?.includes("404")) {
                swal({
                    title: "Error!",
                    text: e.response.data.error,
                    icon: "error",
                    button: "OK!"
                })
            }
            if (e.message?.includes("Network"))
                swal({
                    title: "Error!",
                    text: e.message,
                    icon: "error",
                    button: "OK!"
                })
            if (e.response?.data?.error?.includes("cast"))
                swal({
                    title: "Error!",
                    text: "Select a site",
                    icon: "error",
                    button: "OK!"
                })
        }

    }

    const filteredSites = userSites.filter(row => {
        return row?.siteName?.toLowerCase().includes(searched.toLowerCase());
    });
 
    return (
        (userSites?.length >= 0) ?
            (
                <>
                    <DialogComponent
                        dialogText={classes.dialogText}
                        dialog={classes.dialog}
                        open={open}
                        handleClose={handleClose}
                        handleSubmit={handleSubmit}
                        onClose={handleClose}
                        title="List of Sites"
                        data={[]}

                    >
                        <SearchBar
                            onChange={searchVal => setSearched(searchVal)}
                            onCancelSearch={() => cancelSearch()}
                        />
                        {filteredSites.map((site, i) => {
                            if (site?.assigned) {
                                return <FormControlLabel key={i + 'as'} control={<Checkbox value={site?._id} defaultChecked onChange={handleCheckBox} />} label={site?.siteName} />
                            } else{
                                return <FormControlLabel key={i + 'aa'} control={<Checkbox value={site?._id} onChange={handleCheckBox} />} label={site?.siteName} />
                            }                            
                        })}
                    </DialogComponent >
                    <StyleTableRow >
                        <TableCell >{user?.name}<br /><span style={{ color: 'red', fontStyle: 'italic', fontSize:'0.8em' }}>{user?.role=="owner"?"company":user?.role}</span></TableCell>
                        <TableCell ><ButtonLink style={{ marginLeft: 0 }} onClick={() => setOpen(true)} name='edit' color='gray' /></TableCell>
                        <TableCell style={{ display: 'flex' }} >
                            {userSites.map((sim, j) => {
                                if (sim?.assigned) return <Typography key={j + "some"}>{sim?.siteName}&nbsp; &nbsp; &nbsp; &nbsp; </Typography>
                            })}
                        </TableCell>
                    </StyleTableRow >
                </>
            ) : (<><ReactLoading type='bubbles' color='gray' height={70} width={70} /></>)
    );
};

export default UserRow;
