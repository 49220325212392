import React, { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import LightenDarkenColor from "../../../shared/uitils/colorOpacity";
import Typography from '@material-ui/core/Typography';

const lightenColorValue = 37;

const InvUpdateInput = props => {
    const { inventory, handleStockChange, classes, product, stockUpdates } = props;

    const currentChangedInv = stockUpdates.filter(inv => inv._id == inventory._id);
    const [changedInv, setChangedInv] = useState((currentChangedInv?.length > 0) ? currentChangedInv[0] : inventory); //add inventory here just so up doesn't break

    return (
        <>
            <TableRow>
                <TableCell
                    align="left"
                    style={{
                        backgroundColor: `${LightenDarkenColor(
                            product.productColor,
                            lightenColorValue
                        )}`
                    }}
                    className={classes.tableCellLeft}
                >
                    Current Stock
                </TableCell>
                <TableCell
                    align="left"
                    className={classes.tableCellRight}
                    style={{ fontSize: "1.5em" }}
                >
                    {inventory.amount}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    align="left"
                    style={{
                        backgroundColor: `${LightenDarkenColor(
                            product.productColor,
                            lightenColorValue
                        )}`
                    }}
                    className={classes.tableCellLeft}
                >
                    Updated Stock Level
                </TableCell>
                <TableCell
                    align="left"
                    className={classes.tableCellRight}
                    style={{ fontSize: "1.5em" }}
                >
                    <Grid
                        container
                        spacing={1}
                        style={{ fontSize: "1.5em"}}>

                        <Grid
                            item
                            sm={12}
                            key={inventory._id + inventory.productId + 'textField'}
                        >
                            <TextField
                                type="number"
                                value={changedInv.amount}
                                style={{ maxWidth: 75 }}
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                onChange={event => {
                                    if (event.target.value < 0) {
                                        event.target.value = 0;
                                        let stockValues = {
                                            inventoryId: inventory._id,
                                            amount: +event.target.value
                                        };
                                        handleStockChange(stockValues);
                                    } else {
                                        //event.target.value = 0;
                                        let stockValues = {
                                            inventoryId: inventory._id,
                                            amount: +event.target.value
                                        };
                                        handleStockChange(stockValues);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
};

export default InvUpdateInput;