import axios from "axios";

export const fetchSiteControllers = async id => {
    const readURL0 =
    process.env.REACT_APP_BACKEND_LINK + "api/site/readone/" + id;
  const site = await axios.get(readURL0);
  const siteId = site.data.siteId;

  const readURL =
//   process.env.REACT_APP_BACKEND_LINK + "api/bay/read/" + id;
  process.env.REACT_APP_BACKEND_LINK +"api/controller/read/" + siteId;
  const siteController = await axios.get(readURL);
  return siteController.data;
};

export const fetchSiteControllerBySiteId = async id => {
    const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/controller/read/" + id;
    const siteController = await axios.get(readURL);
    return siteController.data;
};

export const fetchSiteControllerLiveData = async mac => {
    const readURL =
        process.env.REACT_APP_LIVE_DATA_LINK + mac;
    const controllerData = await axios.post(readURL, {
        key: process.env.REACT_APP_LIVE_DATA_KEY
    });
    return controllerData?.data;
};