import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import SiteDetails from './SiteDetails'
import SitePackages from './SitePackages'
import SiteCycles from './SiteCycles'
import MobileLayout from '../../../Layout/MobileLayout'
import { useLocation } from "react-router";
import { SiteContext } from "../../../../shared/context/site/site-context";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import Typography from '@mui/material/Typography';
import { Grid } from '@material-ui/core';

const Site = () => {
  const { site, updateSiteContext } = useContext(SiteContext);
  //UI is based on a 12-column grid layout
  const fixedGridCols = 12;
  const location = useLocation();
  const useStyles = makeStyles((theme) => ({
    pageContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
        background: 'linear-gradient(to right, #abbfcd, #cccccc);'
    },
    card: {
        padding: '5px',
        width: 'fit-content',
    },
    header: {
        padding: '10px',
        height: '50px'
    },
    head: {

        margin: 0,
        height: '20px'
    },
}));
const classes = useStyles();


  let _id,
    siteId,
    CarWashName,
    CarWashOwner = "";
  if (!location.state?._id && !site?._id) {
    _id = localStorage.getItem("site_id");
    siteId = localStorage.getItem("siteId");
    CarWashName = localStorage.getItem("CarWashName");
    CarWashOwner = localStorage.getItem("CarWashOwner");
  } else {
    _id = location.state?._id || site._id;
    siteId = location.state?.siteId || site?.siteId;
    CarWashName = location.state?.siteName || site?.siteName;
    CarWashOwner = location.state?.siteOwner || site?.siteOwner;
    localStorage.setItem("site_id", _id);
    localStorage.setItem("siteId", siteId);
    localStorage.setItem("CarWashName", CarWashName);
    localStorage.setItem("CarWashOwner", CarWashOwner);
  }

  useEffect(() => {
    return () => {
      updateSiteContext(_id);
    };
  }, []);

  return (
    <>
     <MobileLayout style={{ display: 'flex', justifyContent: 'center' }} name={CarWashName} color='deepPurple'>
            <Grid item xs={12} >
                <Card className={classes.pageContent} elevation={24}>
                    <div className={classes.card}>
                        <CardHeader className={classes.header} color="orange">
                            <Typography className={classes.head} >{"Owner -  " + CarWashOwner}</Typography>
                        </CardHeader>
                    </div>
                </Card>
            </Grid>

    <SiteDetails site_id={_id} siteId={siteId} siteName={CarWashName} siteOwner={CarWashOwner} fixedGridCols={fixedGridCols}/>
    <SitePackages site_id={_id} siteId={siteId} siteName={CarWashName} siteOwner={CarWashOwner} fixedGridCols={fixedGridCols}/>
    <SiteCycles site_id={_id} siteId={siteId} siteName={CarWashName} siteOwner={CarWashOwner} fixedGridCols={fixedGridCols}/>
    </MobileLayout>
    </>
  )
}

export default Site

// import React, { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom'
// import { makeStyles } from '@material-ui/core/styles';
// import { useHistory, useLocation } from 'react-router';
// import H6 from "@material-tailwind/react/Heading6";

// import Paragraph from "@material-tailwind/react/Paragraph";
// import Button from "@material-tailwind/react/Button";
// import ButtonLink from '../../../Util/ButtonLink';
// import axios from 'axios'
// import MobileLayout from '../../../Layout/MobileLayout';
// import { SiteContext } from "../../../../shared/context/site/site-context";
// import { Grid } from '@material-ui/core'
// import {
//     fetchOneSite,
//     fetchAllCycles,
//     fetchAllPackages
// } from "../../../../shared/api";
// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& .MuiTextField-root': {
//             margin: theme.spacing(1),
//             width: '25ch',
//         },
//     },
//     formControl: {
//         margin: theme.spacing(1),
//         minWidth: '25ch',
//     },
//     pageContent: {
//         margin: theme.spacing(5),
//         padding: theme.spacing(2)
//     },
//     grid: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//     },
//     alignment: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center'
//     },
//     heading: {
//         marginTop: "40px"
//     }
// }));

// const Site = function SetSite() {
//     const { site, packages, cycles, updateSiteContext } = useContext(SiteContext);

//     const classes = useStyles();
//     const history = useHistory();
//     const location = useLocation();
//     const _id = location.state?._id || site?._id;
//     const siteId = location.state?.siteId || site?.siteId;
//     const CarWashName = location.state?.siteName || site?.siteName;
//     const CarWashOwner = location.state?.siteOwner || site?.siteOwner;
//     const [Packages, setPackages] = useState([]);
//     const [Cycles, setCycles] = useState([]);
//     const [AddressId, setAddressId] = React.useState(site?.addressId);

//     useEffect(() => {
//         return () => {
//             const _id = location.state?._id || site?._id;
//             updateSiteContext(_id);
//         };
//     }, []);

//     useEffect(() => {
//         const fetchData = async () => {

//             try {
//                 const site = await fetchOneSite(_id);
//                 if (site) {
//                     setAddressId(site.addressId);
//                 }
//                 const sitePackage = await fetchAllPackages(_id);
//                 if (sitePackage) {
//                     setPackages(sitePackage);
//                 }
//                 const siteCycle = await fetchAllCycles(_id);
//                 if (siteCycle) {
//                     setCycles(siteCycle);
//                 }
//             } catch (e) {
//                 console.log(e);
//             }
//         }
//         fetchData();
//     }, []);

//     useEffect(() => {
//         // process.env.API_URL +
//         const readURL = process.env.REACT_APP_BACKEND_LINK + "api/package/read/" + _id
//         fetch(readURL,
//             {
//                 headers: {
//                     "Content-Type": "application/json",
//                     'Authorization': `Bearer ${localStorage.getItem("authToken")}`
//                 }
//             }
//         )
//             .then(res => res.json())
//             .then(
//                 (result) => {
//                     setPackages(result);
//                 })
//     }, [])

//     useEffect(() => {
//         const fetchData = async () => {
//             const readURL = process.env.REACT_APP_BACKEND_LINK + "api/cycle/read/" + _id
//             const result = await axios.get(readURL);
//             setCycles(result.data);
//         }
//         fetchData();
//     }, []);


//     return (
//         <div style={{ marginTop: '10vh' }}>
//             <MobileLayout style={{ display: 'flex', justifyContent: 'center' }} name={CarWashName} color='deepPurple'>
//                 <div className={classes.grid}>
//                     <H6 color="indigo">Site Details</H6>
//                     <Paragraph color='brown' >Owner: {CarWashOwner}</Paragraph >
//                     <Grid container className={classes.alignment}>
//                         <Grid className={classes.alignment} xs={12} sm={6} md={4}  >
//                             <Button color="blue"
//                                 buttonType="link"
//                                 ripple="dark">
//                                 <Link style={{ cursor: "pointer" }} className={classes.link} to={_id} onClick={
//                                     (e) => {
//                                         //console.log(_id)
//                                         e.preventDefault();
//                                         history.push({
//                                             pathname: '/bays/',
//                                             state: {
//                                                 _id: _id,
//                                                 siteId: siteId,
//                                                 siteName: CarWashName,
//                                                 siteOwner: CarWashOwner
//                                             }
//                                         });
//                                     }
//                                 } color="inherit" underline="none">
//                                     Bays Information
//                                 </Link>
//                             </Button >
//                         </Grid>
//                         <Grid className={classes.alignment} xs={12} sm={6} md={4} >

//                             <Button color="blue"
//                                 buttonType="link"
//                                 ripple="dark">
//                                 <Link style={{ cursor: "pointer" }} className={classes.link} href={_id} onClick={
//                                     (e) => {
//                                         //console.log(_id)
//                                         e.preventDefault();
//                                         history.push({
//                                             pathname: '/tanks/',
//                                             state: {
//                                                 _id: _id,
//                                                 siteId: siteId,
//                                                 siteName: CarWashName,
//                                                 siteOwner: CarWashOwner
//                                             }
//                                         });
//                                     }
//                                 } color="inherit" underline="none">
//                                     Tanks Information
//                                 </Link>
//                             </Button >
//                         </Grid>
//                         <Grid className={classes.alignment} xs={12} sm={6} md={4}  >
//                             <Button color="blue"
//                                 buttonType="link"
//                                 ripple="dark">
//                                 <Link style={{ cursor: "pointer" }} className={classes.link} href={_id} onClick={
//                                     (e) => {
//                                         //console.log(_id)
//                                         e.preventDefault();
//                                         history.push({
//                                             pathname: '/controllers/',
//                                             state: {
//                                                 _id: _id,
//                                                 siteId: siteId,
//                                                 siteName: CarWashName,
//                                                 siteOwner: CarWashOwner
//                                             }
//                                         });
//                                     }
//                                 } color="inherit" underline="none">
//                                     Controllers Information
//                                 </Link>
//                             </Button >
//                         </Grid>
//                     </Grid>
//                     <div >
//                         <H6 style={{ marginTop: 20 }} color="indigo">Packages</H6>
//                     </div>

//                     <Grid container className={classes.alignment}>

//                         {Packages.map((row) =>
//                         (
//                             <Grid className={classes.alignment} xs={12} sm={6} md={4}  >

//                                 <Button color="blue"
//                                     buttonType="link"
//                                     ripple="dark">
//                                     <Link style={{ cursor: "pointer" }} className={classes.link} href={_id} onClick={
//                                         (e) => {

//                                             e.preventDefault();
//                                             history.push({
//                                                 pathname: '/packages/',
//                                                 state: {
//                                                     _id: _id,
//                                                     siteName: CarWashName,
//                                                     siteOwner: CarWashOwner,
//                                                     packageId: row.packageId,
//                                                     siteId: siteId,
//                                                     packageName: row.packageName
//                                                 }
//                                             });
//                                         }
//                                     } color="inherit" underline="none">
//                                         {row.packageName}<br></br>
//                                     </Link>
//                                 </Button>
//                             </Grid>
//                         )
//                         )}
//                     </Grid>

//                     <H6 style={{ marginTop: 20 }} align='center' color="indigo">Cycles</H6>
//                     <div style={{ marginTop: 20 }}>
//                         <Grid container className={classes.alignment}>

//                             {Cycles.map((row) =>
//                             (
//                                 <Grid className={classes.alignment} xs={12} sm={6} md={4}  >

//                                     <Button color="blue"
//                                         buttonType="link"
//                                         ripple="dark">
//                                         <Link style={{ cursor: "pointer" }} className={classes.link} href={_id} onClick={
//                                             (e) => {

//                                                 e.preventDefault();
//                                                 history.push({
//                                                     pathname: '/updateCycle/',
//                                                     state: {
//                                                         _id: _id,
//                                                         cycleId: row.cycleId,
//                                                         siteId: siteId,
//                                                         siteName: CarWashName,
//                                                         siteOwner: CarWashOwner,
//                                                     }
//                                                 });
//                                             }
//                                         } color="inherit" underline="none">
//                                             {row.cycleName}<br></br>
//                                         </Link>
//                                     </Button>
//                                 </Grid>
//                             )
//                             )}
//                         </Grid>
//                     </div>


//                     <ButtonLink color='pink' name='Create NEW Package'
//                         onClick={() => {
//                             history.push({
//                                 pathname: '/createPackage',
//                                 state: {
//                                     _id: _id,
//                                     siteId: siteId,
//                                     siteName: CarWashName,
//                                     siteOwner: CarWashOwner
//                                 }
//                             })
//                         }} />
//                 </div>
//             </MobileLayout>


//         </div>
//     )
// }

// export default Site