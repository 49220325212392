import React, { useState, useEffect, useContext } from "react";
import { Link, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import SiteLayout from "./SiteLayout";
import Button from "@material-tailwind/react/Button";
import ButtonLink from "../../Util/ButtonLink";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SiteContext } from "../../../shared/context/site/site-context";
import useMediaQuery from '@mui/material/useMediaQuery';
import { authContext } from "../../../shared/context/auth/auth-context";
import {
  fetchAllPackages
} from "../../../shared/api";
import UserRow from "../../Management/UserRow";

const SitePackages = function SetSite(props) {
  const { packages } = useContext(SiteContext);
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    emptyPackages: {
      minHeight: '100px',
  },
}));
const classes = useStyles();
const theme = useTheme();
const { user } = useContext(authContext);
  let _id = props.site_id,
  siteId = props.siteId,
  CarWashName = props.siteName,
  CarWashOwner = props.siteOwner,
  fixedGridCols = props.fixedGridCols;
 
  const filteredPackages = packages.filter(row => (row?.packageName != ""));
  const [Packages, setPackages] = useState(filteredPackages);

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px

  const maxRowElements={xs:2, sm:3, md:4, lg:6, xl:7};
  const spacing = {
  mobile: fixedGridCols/maxRowElements.xs, 
  ipad: fixedGridCols/maxRowElements.sm,
  nesthub: fixedGridCols/maxRowElements.md,
  laptop: fixedGridCols/maxRowElements.lg,
  desktop: fixedGridCols/maxRowElements.xl,
  }


  const ButtonDetails = {
    create: {label:"Create", color:"pink", path:"/createPackage"}
  };
  const numberOfButtons = Object.keys(ButtonDetails).length;
  const buttonStartSpacing = {
    mobile: maxRowElements.xs - numberOfButtons, 
    ipad: maxRowElements.sm - numberOfButtons,
    nesthub: maxRowElements.md - numberOfButtons,
    laptop: maxRowElements.lg - numberOfButtons,
    desktop: maxRowElements.xl - numberOfButtons
  }
  const matches = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    const fetchData = async () => {

      try {
        const sitePackage = await fetchAllPackages(_id);
        if (sitePackage) {
          let filteredPackage = sitePackage.filter(row => (row?.packageName != ""));
          filteredPackage = filteredPackage.sort((a,b) => (a.packageName?.toLowerCase() > b.packageName?.toLowerCase()) ? 1 : -1);
          setPackages(filteredPackage);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <SiteLayout name={"Packages"} color="cyan">
        <Grid container className = {classes.emptyPackages} spacing={2}>
          {Packages.map(row => (
             <Grid item 
             xs={spacing.mobile} 
             sm={spacing.ipad} 
             md={spacing.nesthub} 
             lg={spacing.laptop} 
             xl={spacing.desktop}>

            <Button color="purple" buttonType="link" ripple="dark">
              <Link
                style={{ cursor: "pointer" }}
                href={_id}
                onClick={e => {
                  e.preventDefault();
                  history.push({
                    pathname: "/packages/",
                    state: {
                      _id: _id,
                      siteName: CarWashName,
                      siteOwner: CarWashOwner,
                      packageId: row.packageId,
                      siteId: siteId,
                      packageName: row.packageName
                    }
                  });
                }}
                color="inherit"
                underline="none"
              >
               {
                // console.log("matches=", (matches?10:15)) &&
                row.packageName.length <= (matches?10:15) ? row.packageName: row.packageName.substr(0, (matches?10:15)) + "..."}

                <br />
              </Link>
            </Button>
            </Grid>
          ))}
      </Grid>

      {
        user?.role === 'admin' && 
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item

                >
                <ButtonLink
                    color={ButtonDetails.create?.color}
                    name={ButtonDetails.create?.label}
                    onClick={() => {
                        history.push({
                            pathname: ButtonDetails.create?.path,
                            state: {
                                _id: _id,
                                siteId: siteId,
                                siteName: CarWashName,
                                siteOwner: CarWashOwner
                            }
                        });
                    }}
                />
            </Grid>
          </Grid>
      }
      </SiteLayout>
    </>
  );
};

export default SitePackages;
