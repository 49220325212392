import React, { useState, useEffect, useContext } from "react";
import { Link, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import SiteLayout from "./SiteLayout";
import Button from "@material-tailwind/react/Button";
import ButtonLink from "../../../Util/ButtonLink";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SiteContext } from "../../../../shared/context/site/site-context";
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  fetchAllCycles
} from "../../../../shared/api";

const SiteCycles = function SetSite(props) {
  const { cycles } = useContext(SiteContext);
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    emptyCycles: {
      minHeight: '100px',
  },
}));
const classes = useStyles();
const theme = useTheme();

  let _id = props.site_id,
  siteId = props.siteId,
  CarWashName = props.siteName,
  CarWashOwner = props.siteOwner,
  fixedGridCols = props.fixedGridCols;

  const [Cycles, setCycles] = useState(cycles);

  const maxRowElements={xs:2, sm:3, md:4, lg:6, xl:7};
  const spacing = {
  mobile: fixedGridCols/maxRowElements.xs, 
  ipad: fixedGridCols/maxRowElements.sm,
  nesthub: fixedGridCols/maxRowElements.md,
  laptop: fixedGridCols/maxRowElements.lg,
  desktop: fixedGridCols/maxRowElements.xl,
  }

  const ButtonDetails = {
    create: {label:"Create", color:"pink", path:"/createCycle"}
  };
  const numberOfButtons = Object.keys(ButtonDetails).length;
  const buttonStartSpacing = {
    mobile: maxRowElements.xs - numberOfButtons, 
    ipad: maxRowElements.sm - numberOfButtons,
    nesthub: maxRowElements.md - numberOfButtons,
    laptop: maxRowElements.lg - numberOfButtons,
    desktop: maxRowElements.xl - numberOfButtons
  }

  const matches = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    const fetchData = async () => {

      try {
        const siteCycle = await fetchAllCycles(_id);
        if (siteCycle) {
          setCycles(siteCycle);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <SiteLayout name={"Cycles"} color="cyan">
        <Grid container className = {classes.emptyCycles} spacing={2}>
          {Cycles.map(row => (
            <Grid item   
            xs={spacing.mobile} 
            sm={spacing.ipad} 
            md={spacing.nesthub} 
            lg={spacing.laptop} 
            xl={spacing.desktop}>
            <Button color="purple" buttonType="link" ripple="dark">
              <Link
                style={{ cursor: "pointer" }}
                href={_id}
                onClick={e => {
                  e.preventDefault();
                  history.push({
                    pathname: "/updateCycle/",
                    state: {
                      _id: _id,
                      cycleId: row.cycleId,
                      siteId: siteId,
                      siteName: CarWashName,
                      siteOwner: CarWashOwner
                    }
                  });
                }}
                color="inherit"
                underline="none"
              >
                 {row.cycleName.length <= (matches?10:15) ? row.cycleName: row.cycleName.substr(0, (matches?10:15)) + "..."}
                <br />
              </Link>
            </Button>
          </Grid>
          ))}
         </Grid>

       <Grid container spacing={2} justifyContent="flex-end">
        {/* <Grid item */}
        {/*  xs={buttonStartSpacing.mobile * spacing.mobile} */}
        {/*  sm={buttonStartSpacing.ipad * spacing.ipad} */}
        {/*  md={buttonStartSpacing.nesthub * spacing.nesthub}*/}
        {/*  lg={buttonStartSpacing.laptop * spacing.laptop} */}
        {/*  xl={buttonStartSpacing.desktop * spacing.desktop}>*/}
        {/*</Grid>*/}
        
        {/*<Grid item */}
        {/* xs={spacing.mobile} */}
        {/* sm={spacing.ipad} */}
        {/* md={spacing.nesthub} */}
        {/* lg={spacing.laptop} */}
        {/* xl={spacing.desktop}>*/}
        {/*  <ButtonLink*/}
        {/*    color={ButtonDetails.create?.color}*/}
        {/*    name={ButtonDetails.create?.label}*/}
        {/*    onClick={() => {*/}
        {/*      history.push({*/}
        {/*        pathname: ButtonDetails.create?.path,*/}
        {/*        state: {*/}
        {/*          _id: _id,*/}
        {/*          siteId: siteId,*/}
        {/*          siteName: CarWashName,*/}
        {/*          siteOwner: CarWashOwner*/}
        {/*        }*/}
        {/*      });*/}
        {/*    }}*/}
        {/*  />*/}
                  {/*</Grid>*/}
                  <Grid item>
                      <ButtonLink
                          color={ButtonDetails.create?.color}
                          name={ButtonDetails.create?.label}
                          onClick={() => {
                              history.push({
                                  pathname: ButtonDetails.create?.path,
                                  state: {
                                      _id: _id,
                                      siteId: siteId,
                                      siteName: CarWashName,
                                      siteOwner: CarWashOwner
                                  }
                              });
                          }}
                      />
                  </Grid>
          </Grid>
       
      </SiteLayout>
    </>
  );
};

export default SiteCycles;
