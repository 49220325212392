import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MainPage from "../../Layout/MainPage";
import { Heading4 } from "@material-tailwind/react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import { SiteContext } from "../../../shared/context/site/site-context";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { getProductById, getInventoriesBySiteId } from "../../../shared/api";
import LightenDarkenColor from "../../../shared/uitils/colorOpacity";
import ButtonLink from "../../Util/ButtonLink";
import { Box } from "@material-ui/core";
import { authContext } from "../../../shared/context/auth/auth-context";

const useStyles = makeStyles({
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 280,
    margin: "0 5px",
    minWidth: 278
  },
  table: {
    maxWidth: 276,
    minWidth: 274
  },
  tableCellLeft: {
    padding: "4px",
    fontSize: "1.1em",
    fontWeight: 600,
    minWidth: 90,
    textAlign: "center",
    minHeight: 30
  },
  tableCellRight: {
    padding: "4px",
    fontSize: "1.1em",
    fontWeight: 600,
    minWidth: 180,
    textAlign: "center",
    minHeight: 30
  }
});

const lightenColorValue = 37;

const Inventories = props => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(authContext);
  const { site } = useContext(SiteContext);
  const [inventories, setInventories] = useState(null);
  const [products, setProducts] = useState(null);
  const [isLoaded, setIsloaded] = useState(false);
  const [isInventoriesReceived, setIsInventoriesReceived] = useState(false);

  let id,
    siteName = "";
  if (!history.location.state?.site_id && !site?._id) {
    id = localStorage.getItem("site_id");
    siteName = localStorage.getItem("site_name", siteName);
  } else {
    id = history.location.state?.site_id || site._id;
    siteName = history.location.state?.siteName || site.siteName;
    localStorage.setItem("site_id", id);
    localStorage.setItem("site_name", siteName);
  }

  useEffect(() => {
    let inventories = [];
    //get invetories related to the site
    async function fetchInventories() {
      inventories = await getInventoriesBySiteId(id);
      setIsInventoriesReceived(inventories.success);
      if (inventories.success) {
        setInventories(inventories.inventories);
      }
    }
    fetchInventories();

    //setIsloaded(true);

    return () => {
      //localStorage.removeItem("site_id");
    };
  }, []);

  useEffect(
    async () => {
      // get products related to the site inventories
      let productsList = null;

      async function fetchProducts(inventoryList) {
        //fetching the products
        let productList = await Promise.all(
          //creating an array of products
          inventoryList.map(async inv => {
            let p = await getProductById(inv.productId);
            return p;
          })
        ).then(values => {
          return values;
        });

        return productList;
      }

      if (!!inventories && inventories?.length > 0) {
        productsList = await fetchProducts(inventories);
        setProducts(productsList);
        setIsloaded(true);
      }
    },
    [inventories]
  );

  return (
    <>
      <MainPage color="indigo" name={siteName + " Site Inventory"}>
        <Grid container spacing={2}>
          {isLoaded ? (
            inventories.map(inventory => {
              let product = products.filter(
                p => p._id === inventory.productId
              )[0];

              //start of return
              return (
                <Grid
                  item
                  lg={2}
                  md={3}
                  sm={4}
                  xs={12}
                  key={inventory._id + inventory.productId}
                  className={classes.gridItem}
                >
                  <TableContainer className={classes.table} component={Paper}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: `${product.productColor}` }}
                          className={classes.tableCellLeft}
                        >
                          ProductSKU
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: `${product.productColor}` }}
                          className={classes.tableCellRight}
                        >
                          {product.productSKU}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: `${LightenDarkenColor(
                              product.productColor,
                              lightenColorValue
                            )}`
                          }}
                          className={classes.tableCellLeft}
                        >
                          Product Type
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableCellRight}
                        >
                          {product.productClass}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: `${LightenDarkenColor(
                              product.productColor,
                              lightenColorValue
                            )}`,
                            height: 75
                          }}
                          className={classes.tableCellLeft}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          style={{
                            height: 75
                          }}
                          align="left"
                          className={classes.tableCellRight}
                        >
                          {product.productDescription}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: `${LightenDarkenColor(
                              product.productColor,
                              lightenColorValue
                            )}`
                          }}
                          className={classes.tableCellLeft}
                        >
                          Size
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableCellRight}
                        >
                          {`${product.productSize} gal`}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: `${LightenDarkenColor(
                              product.productColor,
                              lightenColorValue
                            )}`
                          }}
                          className={classes.tableCellLeft}
                        >
                          Stock
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableCellRight}
                          style={{ fontSize: "1.5em" }}
                        >
                          {inventory.amount}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </TableContainer>
                </Grid>
              );
              //end of return
            })
          ) : (
            <CircularProgress disableShrink />
          )}
          <Grid item>
            {!isInventoriesReceived && (
              <Heading4 style={{ margin: "20px" }} color="cyan">
                No inventory in the system
              </Heading4>
            )}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" style={{marginTop: '20px'}}>
          <ButtonLink
           
            name="Back"
            onClick={() => {
              history.push({
                // pathname: user.role === 'distributor' ? '/' : "/inventory"
                pathname: "/inventory"
              });
            }}
          />
        </Box>
      </MainPage>
    </>
  );
};

export default Inventories;
