import {useMutation, useQuery} from 'react-query';
import axios from "axios";

const handleFetchTanks = async (siteId) => {
    const tanksdata = await axios.get(
        process.env.REACT_APP_BACKEND_LINK + "api/tank/read/" + siteId
      );

      if (tanksdata?.data?.length > 0) return tanksdata?.data;

      return [];
};

export const useGetTanksBySite = (siteId, doFetch) => {
  return useQuery(
    ['tanks--', siteId],
    () => handleFetchTanks(siteId),
    { 
        staleTime: 1000*60*60*3,
        enabled: Boolean(!!siteId && doFetch)
    }
  );
};

