
import React ,{useContext} from 'react';
import { TextField, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import { useHistory } from 'react-router';
import MainPage from '../../Layout/MainPage';
import ButtonLink from '../../Util/ButtonLink';
import { v4 } from 'uuid';
import Alert from "@material-ui/lab/Alert";
import { authContext } from "../../../shared/context/auth/auth-context";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  }
}));

const CreateProduct = function FormPropsTextFields() {

  const classes = useStyles();
  const { user } = useContext(authContext);
  const history = useHistory();
  const DistributorId = v4().slice(-12);

  const [DistributorName, setDistributorName] = React.useState('');
  const [DistributorPhone, setDistributorPhone] = React.useState('');
  const [DistributorContact, setDistributorContact] = React.useState('');
  const [DistributorEmail, setDistributorEmail] = React.useState('');
 
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState({
    distributorname:'',
    distributoremail:'',
    distributorphone:''
    }); 

    const validatePhone = (phone) => {
      return phone.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
    };

    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    const distributorValidation = async e => 
    {
      let temp={};
      temp.distributorname = DistributorName?"":"Please enter the Name";
      temp.distributoremail = DistributorEmail?"":"Please enter the Email";
      temp.distributorphone = DistributorPhone?"":"Please enter the Phone";
  
      if (temp.distributoremail === "" && DistributorEmail)
        temp.distributoremail = validateEmail(DistributorEmail)
        ? ""
        : "Please enter valid email";
      
      if (temp.distributorphone === "" && DistributorPhone)
      {
          temp.distributorphone = validatePhone(DistributorPhone)
          ? ""
          : "Please enter valid phone";
      }

      setErrors({
         ...temp
       })
       
        return Object.values(temp).every(x => x === "");
    }

  async function handleSubmit() {
  
    let body = {
      distributorId: DistributorId,
      distributorName: DistributorName,
      distributorPhone: DistributorPhone,
      distributorContact: DistributorContact,
      distributorEmail: DistributorEmail
    };

    distributorValidation().then(function(result) {
      //console.log(result)
      if(result)
      {
        let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/distributor/create", {
          method: "POST",
          body: JSON.stringify(body),
          mode: "cors",
          headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem("authToken")}`
          },
        }).then(response => response.json(),)

        response.then(function(result)
        {
        if(result.errors && result.errors[0].msg.includes(""))
        {
          setError(result.errors[0].msg);
          setTimeout(() => {setError("")}, 5000);
        }
        else
        {
        history.push({
          pathname: '/distributors/',
          state: {
          }
        });
        }
        });
      }
      });
      try {
        await axios.post(
          process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Distributor ${DistributorName} Created`, category: " Distributor Create" }
        )
      }
      catch (error) {
        console.log(error)
      }
     
  };

  function handleCancel() {
    history.push({
      pathname: '/distributors/',
      state: {
      }
    });
  };

  return (
    <MainPage name={'Create Distributor'}>
      <form className={classes.root} noValidate autoComplete="off">
        {error && <Alert severity="error">{error}</Alert>}
        <div>
          <Typography align='center' variant='h5' >Distributor Info.</Typography>

          <TextField id="DistributorName" required
            label="Name"
            value={DistributorName}
            onInput={e => setDistributorName(e.target.value)}
            error={errors.distributorname}
            helperText= {errors.distributorname}
            />

          <TextField id="DistributorPhone"
          label="Phone"
          value={DistributorPhone}
          onInput={e => setDistributorPhone(e.target.value)}
          error={errors.distributorphone}
          helperText= {errors.distributorphone}
          />

          <TextField id="DistributorContact"
          label="Contact"
          value={DistributorContact}
          onInput={e => setDistributorContact(e.target.value)}
          />

          <TextField id="DistributorEmail" required
          label="Email"
          value={DistributorEmail}
          onInput={e => setDistributorEmail(e.target.value)}
          error={errors.distributoremail}
          helperText= {errors.distributoremail}
          />
        </div>
      </form>

      <Box display='flex' justifyContent='flex-end'>
        <ButtonLink
          onClick={handleCancel}
          color='blue'
          name='cancel' />
        <ButtonLink
          onClick={handleSubmit}
          color='pink'
          name='submit' />
      </Box>
    </MainPage>
  )
}


export default CreateProduct
