import React, {useEffect, useRef} from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LeadText from "@material-tailwind/react/LeadText";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useGetCycleByTank } from "../../hooks/queries/useGetCycleByTank";
import H6 from "@material-tailwind/react/Heading6";
import { calculateDays } from './helpers';

const useStyles = makeStyles({
  heading1: {
    marginRight: 3,
    fontWeight: 500,
  },
  heading2: {
    marginRight: 40,

    fontWeight: 500,
  },
  heading3: {
    marginRight: 59,
    fontWeight: 500,
  },
});
export const ChartGenerationUsage = React.memo((props) => {

  const chartRef = useRef(null);
  const classes = useStyles();
  const maximumUsage = Math.max(...props.usage)
  const {data: cycleData = [] } = useGetCycleByTank(props.tankId, props.tankId);

  const {days: dd, from, to } = props;
  const {fromDateTime, toDateTime, days} = calculateDays(dd, from, to);
  let fromDate = moment(fromDateTime);
  const toDate = moment(toDateTime);
  const dates = [];

  while (toDate >= fromDate) {
      const formattedDate = fromDate.format("YYYY-MM-DD");
      dates.push(formattedDate);
      fromDate = fromDate.add(1, 'd')
  }
  
  const threshold = cycleData.length ? cycleData[0].threshold : 0;
  const estimatedUsage = cycleData.length ? cycleData[0].amount : 0;
  const max = estimatedUsage + threshold;
  const min = estimatedUsage - threshold;
  const avg = props.usage.reduce((a, b) => a + b, 0) / props.usage.length

  let barValue = 0;
  if(maximumUsage>max){
    barValue = maximumUsage
  }
  else{
    barValue = max
  }
  const series = [
    {
      name: "Usage Per Car",
      type: "line",
      data: props.usage,
    },
  ];

  var a = 0.7;
  var optionLine = {
    annotations: {
      yaxis: [
        {
          y: min,
          y2: max,
          

          yAxisIndex: 0,
          width: "100%",
          borderColor: "#FF0000",
          strokeDashArray: 0,
        },
      ],
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        dataLabels: {
          position: "center",
        },
      },
    },

    labels: dates,
    xaxis: {
      title: {
        text: "Dates",
      },
    },
   
    yaxis: [
      {
        min: 0,
        max: barValue,
        title: {
          text: "Usage Per Car",
        },
      },
    ],
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        height: 350,
        type: "line",
        export: {
          csv: {
            filename: `UsagePerCar Report ${fromDate.format("YYYY-MM-DD")} to ${toDate.format("YYYY-MM-DD")}`,
          },
        },
      },
    },
    stroke: {
      width: [1, 4],
    },
    dataLabels: {
      enabled: true,
    },
  };

  return (
    <>
      {props.report.length > 0 ? (
        <>
          <hr />
          <br />
          <H6 color="black">Usage of: {props.name}</H6>
          <br />
          <Typography>
            <span className={classes.heading3}>Expected Usage-mL: {estimatedUsage}</span>
          </Typography>
          <Typography>
            <span className={classes.heading3}>Minimum Threshold-mL: {min}</span>
          </Typography>
          <Typography>
            <span className={classes.heading3}>Maximum Threshold- mL: {max}</span>
          </Typography>
          <Typography>
            <span className={classes.heading3}>Average Usage- mL: {avg.toFixed(2)}</span>
          </Typography>
          {/* // REMOVING THRESHOLD BASED ON REQUEST
          <Typography>
            <span className={classes.heading3}>Threshold- mL: {threshold}</span>
          </Typography> */}
          <br />
          <ReactApexChart
            options={optionLine}
            series={series}
            type="line"
            height={350}
          />
          <div ref={chartRef}/>
        </>
      ) : (
        <>
          <LeadText color="teal">No Data</LeadText>
        </>
      )}
    </>
  );
});
