import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ButtonLink from "../Util/ButtonLink";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import FetchName from '../Util/FetchName'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Box, Button, Grid, Menu, MenuItem } from '@material-ui/core';
import MenuItems from './MenuItems';
import { useHistory, useLocation } from 'react-router';
import brand from '../Util/True-Vue 2 Logos-04.png'

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('xl')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
        color: 'black',

    },
    appBar: {
        [theme.breakpoints.up('xl')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        background: 'linear-gradient(to right, #16222a, #3a6073)',
    },
    menuButton: {

        [theme.breakpoints.up('xl')]: {
            display: 'none',
        },
        color: 'black',
        '&:focus': {
            outline: 'none'
        },


    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: 'linear-gradient(to left, #16222a, #3a6073)',
        color: '#e5e7eb'

    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    tool: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    profile: {
        color: '#b4bac2',
        backgroundColor: '#0c2440',

    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

    },
    options: {
        transition: '0.s',
        '&:hover': {
            color: 'white',
        }
    },
    listItem: {
        marginTop: 15,
        borderRadius: 10,
        padding: '7px 15px',
        transition: '0.3s',

        minWidth: '30px',
        color: '#728295',
        '&:hover': {
            transition: '0.3s',
            color: '#fcfcfc',

        },


    },
    active: {
        borderRadius: 10,
        marginTop: 15,
        color: '#fcfcfc',
        padding: '7px 15px',

    },
    icon: {
        color: '#728295',
        margin: 0,
        minWidth: '35px',

    },
    iconActive: {
        color: '#fcfcfc',
        minWidth: '35px'

    },
    logo: {
        marginLeft: '20px',
        marginBottom: '10px'
    },
    divider: {
        width: '80%',
        margin: '10px 0',
        background: 'gray',
        marginLeft: '20px'
    }
    ,
    box: {
        display: 'flex',
        alignItems: 'center',
    },
    nameHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }

}));

function ResponsiveDrawer(props) {
    const { window } = props;
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const drawer = (
        <Box className={classes.container}>
            <Box>
                <Divider />
                <Typography variant='h6' align='center'>
                    <Typography className={classes.logo}>
                        <img src={brand} width={200} alt='True vue icon' />
                    </Typography>
                </Typography>

                <Divider className={classes.divider} />
                <List>
                    {MenuItems.InstallerMenu.map((item, i) => (
                        <ListItem
                            // className={location.pathname === item.path ? classes.active : classes.listItem}
                            className={location.pathname === item.path ? classes.active : classes.listItem}
                            key={i}
                            onClick={() => {
                                history.push(item.path)
                            }}
                        >
                            <ListItemIcon className={location.pathname === item.path ? classes.iconActive : classes.icon}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText style={{ textAlign: 'left' }} primary={item.text} />
                        </ListItem>
                    ))}
                </List>
                <Divider className={classes.divider} />

            </Box>

            <List className={classes.profile}>
                <ListItem>
                   
                    <ButtonLink
                            name="Logout"
                            color="#0c2440"
                                onClick={props.logoutHandler}
                          />
                </ListItem>
            </List>
           
          
              
             
          
        </Box >

    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.tool}>
                    <Typography variant="h6" noWrap className={classes.title}>
                        <Typography className={classes.logo}>
                            <img src={brand} width={200} alt='True vue icon' />
                        </Typography>
                    </Typography>
                    <Box display='flex' alignItems='center'>
                        <div style={{ width: '170px', display: 'flex' }} >
                            <Typography style={{ marginRight: '5px' }}>
                                Welcome,
                            </Typography>
                            <FetchName />
                        </div>

                        <IconButton
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon
                                // onClick={handleDrawerToggle} causes error
                                className={classes.menuButton} />
                        </IconButton>
                    </Box>


                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden xlUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={'right'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden lgDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main >
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}


export default ResponsiveDrawer;
