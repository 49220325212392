import React, { useState, useEffect, useContext } from 'react'
import Subpage from '../Layout/SubPage'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { fetchAllSites, fetchAllUsers } from '../../shared/api/index'
import ButtonLink from '../Util/ButtonLink';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import swal from 'sweetalert'
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx';
import moment from 'moment';
import ClosedRequests from './ClosedRequests';
import Alert from "@material-ui/lab/Alert";
import TablePagination from "@material-ui/core/TablePagination";
import { authContext } from "../../shared/context/auth/auth-context";
import {useGetUserSites} from '../../hooks/mutations/useGetUserSites';
import {useGetUsersByRole} from '../../hooks/mutations/useGetUsersByRole';
//import {useGetSites} from '../../hooks/queries/useGetSites';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
const useStyles = makeStyles((theme) => ({
    fields: {
        width: 220,
    },
    request: {
        textAlign: 'center',
        fontSize: '25px',
        fontWeight: '500',
        color: 'rgb(7,36, 51 , 60%)'
    },
    box: {
        marginBottom: 10,
        marginRight: 20
    },
    site: {
        color: "#0c3670",
        fontWeight: 500
    },
    date: {
        marginLeft: 20
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
        textAlign: 'left'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'left',
        display: 'flex',
        justifyContent: 'space-between'
    },
    column: {
        flexBasis: '50%',
    },

    helper: {
        borderLeft: `2px solid cyan`,
        padding: theme.spacing(1, 2),
        width: '100%',

    },

    description: {
        fontWeight: 500,
    },
    info: {
        display: 'flex'

    },
    para: {
        marginLeft: "5px",
        color: '#2e6b99',
    },
    address: {
        color: '#2e9099',
        fontSize: '14px'
    }
}));


const Maintenence = () => {
    const { user: loggedInUser } = useContext(authContext);
    const {mutateAsync: getUserSites} = useGetUserSites();
    const {mutateAsync: getUsersByRole} = useGetUsersByRole();
    const [requestId, setRequestId] = useState('');
    const [requestDataId, setRequestDataId] = useState('');
    const [requests, setRequests] = useState([]);
    const [sites, setSites] = useState([]);
    const [user, setUsers] = useState([]);
    const classes = useStyles();
    const initialValues = {
        installer: '',

    }
    const [open, setOpen] = React.useState(false);
    const [secondOpen, setSecondOpen] = React.useState(false);

    const [values, setValues] = useState(initialValues);
    const [date, setDate] = React.useState();
    const [list, setList] = React.useState(false);
    const [error, setError] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [expanded, setExpanded] = useState(false);

    const handleDateChange = (date) => {
        setDate(date);
    };

    useEffect(() => {
        const fetchData = async () => {
            let fetchedSites = [];
            let requestData = {data: []};
            if(loggedInUser.role === 'admin'){
                fetchedSites = await fetchAllSites();
                requestData = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/request/requests");
            } else if(loggedInUser.role === 'distributor') {
                fetchedSites = await getUserSites({userId: loggedInUser?.id});
                requestData = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/request/requestsForUser/"+ loggedInUser?.id);
            }
            
            let fetchedUsers = await getUsersByRole({usersRole: 'installer'});

            setSites(fetchedSites)
            setRequests(requestData?.data)
            setUsers(fetchedUsers)

        }
        fetchData();

    }, [list])

    useEffect(() => {
        requests.map((req, i) => {
            if(!req.isClosed)
                setExpanded(req.siteId)
        });
    }, [requests])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    const handleResolveSubmit = async () => {
        setSecondOpen(false);
        let body = {
            _id: requestDataId,
            siteId: requestId,
            comment: values.comment
        }

        try {
            const data = await axios.post(process.env.REACT_APP_BACKEND_LINK + "api/request/close", body);
            swal({
                title: "Success!",
                text: data.data.message,
                icon: "success",
                button: "OK!",
                width: '100px'
            }).then(function() {
                setList(!list);
              });
        }
        catch (e) {

            if (e.message.includes("status")) {
                swal({
                    title: "Error!",
                    text: e.response.data.error,
                    icon: "error",
                    button: "OK!",
                })
            }
            if (e.message.includes("Network"))
                swal({
                    title: "Error!",
                    text: "Network Error",
                    icon: "error",
                    button: "OK!",
                    width: '100px'
                })
        }
    }
    const handleSubmit = async () => {
        let body = {
            _id: requestDataId,
            installerId: values.installer,
            date: date
        }

        try {
            const data = await axios.post(process.env.REACT_APP_BACKEND_LINK + "api/request/assign", body)

            swal({
                title: "Success!",
                text: data.data.message,
                icon: "success",
                button: "OK!",
                width: '100px'
            }).then(function() {
                setList(!list);
                setOpen(false);
              });
        }
        catch (e) {
            if(e.response.data.errors && e.response.data.errors[0].msg.includes(""))
            {
                setError(e.response.data.errors[0].msg);
                setTimeout(() => {
                setError("");
                }, 5000);
            } else if (e.message.includes("status")) {
                swal({
                    title: "Error!",
                    text: e.response.data.error,
                    icon: "error",
                    button: "OK!",
                })
            } else if (e.message.includes("Network"))
                swal({
                    title: "Error!",
                    text: "Network Error",
                    icon: "error",
                    button: "OK!",
                    width: '100px'
                })
        }

    }

    const handleClickOpen = (requestId, requestDataId) => {
        setRequestId(requestId);
        setRequestDataId(requestDataId)
        setOpen(true);
    };

    const handleSecondClickOpen = (requestId, requestDataId) => {
        setRequestId(requestId);
        setRequestDataId(requestDataId)
        setSecondOpen(true);
    }

    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleClose = () => {
        setOpen(false);
        setSecondOpen(false)
    };
  
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }


    return (
        <div>
        <Subpage name='Maintenence Requests' color='cyan'>

       {sites.filter(site => 
            requests.find(req => !req.isClosed && req.siteId === site._id))
            .map((site) => 
            {
                return ( 
                    <Accordion expanded={expanded === site._id
                    } onChange={handleChange(site._id)}>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <div className={classes.column}>
                               
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                <p className={classes.site}>{site.siteName}</p>
                                </Typography>
                             
                            </div>               
                        </AccordionSummary>
                        
                {requests.filter(req => !req.isClosed && req.siteId == site._id)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((req, i) => {
                return (
                          < AccordionDetails key={"rd" + i} className={classes.details} >
                                    <div className={classes.info} />
                                    <div className={clsx(classes.helper)}>

                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Requested By:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.requestedBy}
                                            </Typography>
                                        </div>
                          
                 
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Request Title:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.request_msg}
                                            </Typography>
                                        </div>
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Request Date:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {moment(req.requestDate).format("YYYY/MM/DD h:mm a")}
                                            </Typography>
                                        </div>
                                        {req.installerId && <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Installer:
                                            </Typography>
                                            {user.map((inst, i) => {
                                                return inst._id === req.installerId && (<Typography className={classes.para}>
                                                    {inst.name}
                                                </Typography>)
                                            })}

                                        </div>
                                        }
                                        {req.scheduledAt && <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Scheduled On:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.scheduledAt && moment(req.scheduledAt).format("YYYY/MM/DD")}
                                            </Typography>
                                        </div>
                                        }
        
                                    </div>
        
                            
                                    {loggedInUser?.role === 'admin' && 
                                        <>                                     
                                            <Box className={classes.box} display='flex' alignItems='center' justifyContent='flex-end'>
                                                <ButtonLink color='red' name='Resolve' onClick={() => handleSecondClickOpen(req.siteId, req._id)} />
                                            </Box>
                                            
                                            < Box className={classes.box} display='flex' alignItems='center' justifyContent='flex-end' >
                                                <ButtonLink color='pink' name='Scheduler' onClick={() => handleClickOpen(req.siteId, req._id)} />
                                            </Box>
                                        </>
                                    }
                                </AccordionDetails>
                           )   
                        })
                    }
                    </Accordion>
                    // : <Typography className={classes.request}>No Maintenence Requests</Typography> */}
                    )   
                })
            }
            
            <TablePagination
                    rowsPerPageOptions={[5, 25, 100]}
                    component="div"
                    count={requests.filter(req => !req.isClosed).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.dialog}

            >
                <DialogTitle>
                    {"Assign Installer"}
                </DialogTitle>
                <DialogContent>

                    <DialogContentText id="alert-dialog-description">
                        <Box display='flex' flexDirection='column'>
                            <FormControl>
                                <InputLabel id="role">Installer</InputLabel>
                                <Select
                                    name='installer'
                                    required
                                    className={classes.fields}
                                    value={values.installer}
                                    label='Inst'
                                    onChange={handleInputChange}
                                >
                                    {user.map((installer, i) => {
                                        return (installer.role === 'installer' && <MenuItem key={"ii" + i} value={installer._id} >{installer.name}</MenuItem>
                                        )

                                    })}
                                </Select>
                            </FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} align="left">
                                <KeyboardDatePicker
                                    disableToolbar
                                    autoOk={true}
                                    minDate={new Date()}
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="installDate"
                                    label="Scheduled Date"
                                    value={date}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </DialogContentText>
                    {error && <Alert severity="error">{error}</Alert>}
                </DialogContent>
                <DialogActions>
                    <ButtonLink name='Confirm' onClick={handleSubmit} autoFocus>

                    </ButtonLink>
                    <ButtonLink color='pink' onClick={handleClose} name='Cancel'></ButtonLink>

                </DialogActions>
            </Dialog>

            <Dialog
                open={secondOpen}
                onClose={handleClose}>
                <DialogTitle>
                    {"Confirm Resolve?"}
                </DialogTitle>
                <DialogContent>

                <TextField
                    className={classes.textfield}
                    multiline
                    required
                    rows={2}
                    rowsMax={2}
                    value={values.comment}
                    onChange={handleInputChange}
                    name='comment'
                    autoFocus
                    margin="dense"
                    id="commemnt"
                    label="Notes"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                {error && <Alert severity="error">{error}</Alert>}
                </DialogContent>
                <DialogActions>
                    <ButtonLink name='Confirm' onClick={handleResolveSubmit} autoFocus>

                    </ButtonLink>
                    <ButtonLink color='pink' onClick={handleClose} name='Cancel'></ButtonLink>

                </DialogActions>
            </Dialog>

        </Subpage >
           <ClosedRequests list = {list}/>
              </div>
    )
}

export default Maintenence
