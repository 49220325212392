import {useMutation, useQuery} from 'react-query';
import axios from "axios";

const handleFetchSites = async (userId) => {
    const sites = await axios.get(
        process.env.REACT_APP_BACKEND_LINK +
          "api/user/sitesFor/" + userId
      );
      
      if (sites?.data?.success) return sites?.data?.sites;

      return [];
};

export const useGetUserSites = () => {
  return useMutation(
    ({userId}) => handleFetchSites(userId),
    { 
        staleTime: 1000*60*60*24
    }
  );
};