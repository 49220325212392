import React, { useState, useEffect, useContext } from 'react'
import MainPage from '../Layout/MainPage';
import { TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Table, Box, FormControl, InputLabel, Paper, MenuItem, Select, Checkbox } from '@material-ui/core';
import { getSitesForUser, fetchAllSites, fetchUserbyId, updateUserbyId } from "../../shared/api";
import SearchBar from 'material-ui-search-bar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StyleTableRow from '../Util/StyleTableRow';
import ButtonLink from '../Util/ButtonLink'
import { authContext } from "../../shared/context/auth/auth-context";
import axios from 'axios';
import swal from "sweetalert";

const useStyles = makeStyles(() => ({
    fields: {
        width: 220,
    },
    button: {
        marginRight: 20,
    },
    select: {
        width: 140
    },
    search: {
        width: 200,
        marginRight: 40
    },
    loading: {
        margin: 10,
        display: 'flex', alignItems: 'center', justifyContent: 'center'
    },
    box: {
        margin: 10
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column'
    },

    dialogText: {
        color: '#0288d1',
        fontSize: '1.5em',
        fontWeight: 500,
        textAlign: 'center'
    }



}));

const AlertPreferences = (props) => {
    // const { screen } = props;
    const classes = useStyles();
    const { user } = useContext(authContext);

    const [locations, setLocations] = useState([]); // array of all the locations
    const [userAlerts, setUserAlerts] = useState([]);
    const [searched, setSearched] = useState("");
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const cancelSearch = () => {
        setSearched("");
    };

    const handleCheckboxChange = (siteId, alertType, event) => {
        setUserAlerts(prevAlerts =>
            prevAlerts.map(alert => {
                if (alert.siteId === siteId) {
                    return {
                        ...alert,
                        [alertType]: event.target.checked
                    };
                }
            return alert;
            })
        );
    };     

    const handleSave = async () => {
        let body = {
            alerts: userAlerts
        }
        const res = await updateUserbyId(user.id, body);
        if (res) {
            try {
                axios.post(
                    process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `User Alerts Changed For ${user.name}(${user.role})`, category: "Edit User" }
                )
            }
            catch (error) {
                console.log(error)
            }
            swal({
                title: "Success!",
                text: "Alert Preferences Updated",
                icon: "success",
                button: "OK!",
            })
        }
    }
    
    useEffect(() => {
        async function fetchData() {
            if (user) {
                let userDetails = await fetchUserbyId(user.id);
                let sites = user?.role === 'admin' ? await fetchAllSites() : (await getSitesForUser(user.id)).sites
                if (sites) {
                    setLocations(sites);
                    let alerts = userDetails.alerts ?? []
                    for (let site of sites) {
                        if (!alerts.some(alert => alert.siteId === site._id)) {
                            alerts.push({
                                "siteId": site._id,
                            })
                        }
                    }
                    setUserAlerts(alerts)
                } else {
                    setLocations([]);
                }
            }
        }
        fetchData();
    }, [user]);

    const StyledTableCell = withStyles(theme => ({
        head: {
            backgroundColor: "#022a45",
            color: theme.palette.common.white,
            border: "none",
            paddingTop: 7,
            paddingBottom: 7,
        },
        body: {
            fontSize: 18
        }
    }))(TableCell);


    const filteredAlerts = userAlerts.filter(row => {
        return locations.find(site => site._id === row?.siteId).siteName?.toLowerCase().includes(searched.toLowerCase());
    })

    const slicedFilteredAlerts = filteredAlerts
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    const tableCount = () => {
        return filteredAlerts?.length;
    };

    return (
        <>
          <MainPage name={"User Alert Preferences"} color="teal">
            <TableContainer component={Paper}>
                <Box display='flex' className={classes.box} >
                    <SearchBar
                        className={classes.search}
                        onChange={searchVal => setSearched(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                    />
                </Box>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell colSpan={1} style={{textAlign: "center"}}></StyledTableCell>
                            <StyledTableCell colSpan={2} style={{textAlign: "center", borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>Controller online/offline</StyledTableCell>
                            <StyledTableCell colSpan={2} style={{textAlign: "center", borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>Min/Max Usage</StyledTableCell>
                            <StyledTableCell colSpan={2} style={{textAlign: "center", borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>Tank Low Level</StyledTableCell>
                            <StyledTableCell colSpan={2} style={{textAlign: "center", borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>Inventory Low Level</StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell style={{paddingBottom: 16}}>Site</StyledTableCell>
                            <StyledTableCell style={{width: 100, borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>Popup</StyledTableCell>
                            <StyledTableCell style={{width: 100}}>Email</StyledTableCell>
                            <StyledTableCell style={{width: 100, borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>Popup</StyledTableCell>
                            <StyledTableCell style={{width: 100}}>Email</StyledTableCell>
                            <StyledTableCell style={{width: 100, borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>Popup</StyledTableCell>
                            <StyledTableCell style={{width: 100}}>Email</StyledTableCell>
                            <StyledTableCell style={{width: 100, borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>Popup</StyledTableCell>
                            <StyledTableCell style={{width: 100}}>Email</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {slicedFilteredAlerts
                            .map((alert, i) => (
                                locations.find(site => site._id === alert?.siteId) &&
                                <StyleTableRow>
                                    <TableCell>
                                    {locations.find(site => site._id === alert?.siteId).siteName}
                                    <br />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox 
                                            color="secondary"
                                            id={`${alert?.siteId}-controller-popup`}
                                            checked={alert?.controllerPopup || false}
                                            onChange={event => handleCheckboxChange(alert.siteId, 'controllerPopup', event)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox 
                                            color="secondary"
                                            id={`${alert?.siteId}-controller-email`}
                                            checked={alert?.controllerEmail || false}
                                            onChange={event => handleCheckboxChange(alert.siteId, 'controllerEmail', event)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox 
                                            color="secondary"
                                            id={`${alert?.siteId}-usage-popup`}
                                            checked={alert?.usagePopup || false}
                                            onChange={event => handleCheckboxChange(alert.siteId, 'usagePopup', event)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox 
                                            color="secondary"
                                            id={`${alert?.siteId}-usage-email`}
                                            checked={alert?.usageEmail || false}
                                            onChange={event => handleCheckboxChange(alert.siteId, 'usageEmail', event)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox 
                                            color="secondary"
                                            id={`${alert?.siteId}-tank-popup`}
                                            checked={alert?.tankPopup || false}
                                            onChange={event => handleCheckboxChange(alert.siteId, 'tankPopup', event)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox 
                                            color="secondary"
                                            id={`${alert?.siteId}-tank-email`}
                                            checked={alert?.tankEmail || false}
                                            onChange={event => handleCheckboxChange(alert.siteId, 'tankEmail', event)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox 
                                            color="secondary"
                                            id={`${alert?.siteId}-inventory-popup`}
                                            checked={alert?.inventoryPopup || false}
                                            onChange={event => handleCheckboxChange(alert.siteId, 'inventoryPopup', event)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox 
                                            color="secondary"
                                            id={`${alert?.siteId}-inventory-email`}
                                            checked={alert?.inventoryEmail || false}
                                            onChange={event => handleCheckboxChange(alert.siteId, 'inventoryEmail', event)}
                                        />
                                    </TableCell>
                                </StyleTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={tableCount()}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Box display="flex" justifyContent="flex-end">
                <ButtonLink onClick={handleSave} color="pink" name="Save" />
            </Box>
          </MainPage>
        </>
    )
}

export default AlertPreferences