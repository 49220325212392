import axios from "axios";

export const fetchProductById = async id => {
  const readURL =
    process.env.REACT_APP_BACKEND_LINK + "api/product/readOneById/" + id;
  const product = await axios.get(readURL);
  return product.data;
};

export const fetchReorderProductsBySite = async id => {
    const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/reorder/reorder-products/" + id;
    const products = await axios.get(readURL);
    return products.data;
};
