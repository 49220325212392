import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import SiteDetails from './SiteDetails'
import SitePackages from './SitePackages'
import SiteCycles from './SiteCycles'
import MainPage from "../../Layout/MainPage";
import { useLocation } from "react-router";
import { SiteContext } from "../../../shared/context/site/site-context";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import H3 from "@material-tailwind/react/Heading6";

const Site = () => {
  const { site, updateSiteContext } = useContext(SiteContext);
  //UI is based on a 12-column grid layout
  const fixedGridCols = 12;
  const location = useLocation();
  const useStyles = makeStyles((theme) => ({
    header: {
        padding: '10px',
        [theme.breakpoints.up('sm')]: {
            height: '70px',
          },
          [theme.breakpoints.only('sm')]: {
            minheight: '70px',
          },
    }
}));
const classes = useStyles();


  let _id,
    siteId,
    CarWashName,
    CarWashOwner = "";
  if (!location.state?._id && !site?._id) {
    _id = localStorage.getItem("site_id");
    siteId = localStorage.getItem("siteId");
    CarWashName = localStorage.getItem("CarWashName");
    CarWashOwner = localStorage.getItem("CarWashOwner");
  } else {
    _id = location.state?._id || site._id;
    siteId = location.state?.siteId || site?.siteId;
    CarWashName = location.state?.siteName || site?.siteName;
    CarWashOwner = location.state?.siteOwner || site?.siteOwner;
    localStorage.setItem("site_id", _id);
    localStorage.setItem("siteId", siteId);
    localStorage.setItem("CarWashName", CarWashName);
    localStorage.setItem("CarWashOwner", CarWashOwner);
  }

  useEffect(() => {
    return () => {
      updateSiteContext(_id);
    };
  }, []);

  return (
    <>
    <MainPage name={CarWashName} color="deepPurple">
    <Card elevation={5}>
                    <div style={{ width: 'fit-content', padding: '10px' }}>
                        <CardHeader className={classes.header} color="orange">
                            <H3 color="white">{"Owner -  " + CarWashOwner}</H3>
                        </CardHeader>
                    </div>
    </Card>
    <SiteDetails site_id={_id} siteId={siteId} siteName={CarWashName} siteOwner={CarWashOwner} fixedGridCols={fixedGridCols}/>
    <SitePackages site_id={_id} siteId={siteId} siteName={CarWashName} siteOwner={CarWashOwner} fixedGridCols={fixedGridCols}/>
    <SiteCycles site_id={_id} siteId={siteId} siteName={CarWashName} siteOwner={CarWashOwner} fixedGridCols={fixedGridCols}/>
    </MainPage>
    </>
  )
}

export default Site