import React from "react";
import { Redirect, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import moment from 'moment';

const PrivateRoute = ({ component: Component, ...rest }) => {
    let token = localStorage.getItem("authToken") || null;

    if (token) {
        const decodedToken = jwt_decode(token);
        const tokenIssuedAt = moment(decodedToken?.iat * 1000);
        const currentTime = moment();
        const timrDiff = currentTime.diff(tokenIssuedAt, 'seconds');

        if (timrDiff < (60 * 60 * 23)) {//check if token has passed a 1 day  
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            localStorage.removeItem("authToken");
            localStorage.clear();
            token = null;
        }
    }
    

    return (
        <Route
            {...rest}
            render={(props) =>
                token ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};
export default PrivateRoute
