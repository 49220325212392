import axios from "axios";

export const createAlert = async (id, alertData) => {
  const readURL = process.env.REACT_APP_BACKEND_LINK + "api/alert/create/" + id;
  let data = JSON.stringify({
    alert: alertData.alert,
    severity: alertData.severity
  });

  let config = {
    method: "post",
    url: readURL,
    headers: {
      "Content-Type": "application/json"
    },
    data: data
  };
  const alert = await axios(config);
  return alert.data;
};
