import axios from "axios";

export const fetchSite = async id => {
  const readURL = process.env.REACT_APP_BACKEND_LINK + "api/site/readone/" + id;
    const site = await axios.get(readURL);
  return site.data;
};

export const fetchSites = async () => {
  const readURL = process.env.REACT_APP_BACKEND_LINK + "api/site/read/";
  const site = await axios.get(readURL);
  return site.data;
};

export const fetchSitesForUser = async id => {
  const readURL =
    process.env.REACT_APP_BACKEND_LINK + "api/user/sitesFor/" + id;
  const sites = await axios.get(readURL);
  return sites.data;
};


