import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  FormHelperText,
  Typography,
  Box,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import ReactSelect, { components } from 'react-select';
import DropdownIndicator, { selectStyles } from "../../../Util/DropdownIndicator";
import { makeStyles } from "@material-ui/core/styles";
import { authContext } from "../../../../shared/context/auth/auth-context";
import "date-fns";
import { useHistory, useLocation } from "react-router";
import MainPage from "../../../../components/Layout/MainPage";
import { v4 } from "uuid";
import ButtonLink from "../../../Util/ButtonLink";
import axios from "axios";
import { SiteContext } from "../../../../shared/context/site/site-context";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "33ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: "35ch",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2),
  },
}));

const CreateTank = function FormPropsTextFields() {
  const { site, tanks, controllers, updateSiteContext } =
    useContext(SiteContext);

  const location = useLocation();

  let SiteId = location.state?._id || site?._id;
  let siteId = location.state?.siteId || site?.siteId;
  let CarWashName = location.state?.siteName || site?.siteName;
  let CarWashOwner = location.state?.siteOwner || site?.siteOwner;

  if (!SiteId) {
    SiteId = localStorage.getItem("site_id");
    siteId = localStorage.getItem("siteId");
    CarWashName = localStorage.getItem("CarWashName");
    CarWashOwner = localStorage.getItem("CarWashOwner");
  } else {

    localStorage.setItem("site_id", SiteId);
    localStorage.setItem("siteId", siteId);
    localStorage.setItem("CarWashName", CarWashName);
    localStorage.setItem("CarWashOwner", CarWashOwner);
  }

  const TankId = v4().slice(-12);
  // const SiteId = 'cfbd8bed8f8c';
  const classes = useStyles();
  const history = useHistory();
  const [Controllers, setControllers] = useState([]);
  const { user } = useContext(authContext);

  const [OtherSize, setOtherSize] = React.useState("");
  // const [siteId, setSiteId] = React.useState('');
  const [SizeSelect, setSizeSelect] = React.useState("");
  const [ProductAlias, setProductAlias] = React.useState("");
  const [NonMonitored, setNonMonitored] = React.useState(false);
  const [Stinger, setStinger] = React.useState(0);
  const [PortNum, setPortNum] = React.useState("");
  const [SPort, setSPort] = React.useState("");
  const [MinStockAlert, setMinStockAlert] = React.useState();
  const [discount, setDiscount] = React.useState(0);
  const [DistanceToController, setDistanceToController] = React.useState(0);
  const [Controller, setController] = React.useState("");
  const [SController, setSController] = React.useState("");
  const [SensorType, setSensorType] = React.useState("");
  const [Products, setProducts] = useState([]);
  const [Product, setProduct] = React.useState("");
  const [ProductClass, setProductClass] = React.useState();
  const [ProductColor, setProductColor] = React.useState("");
  const [ProductId, setProductId] = React.useState("");
  const [Product_Id, setProduct_Id] = React.useState("");
  const [availablePortList1, setavailablePortList1] = React.useState([]);
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState({
    tanksize: "",
    inputsize: "",
    stinger: "",
    minstockalert: "",
    distancetocontroller: "",
  });

  let portList = [];
  let availablePortList = [];

  useEffect(() => {
    (async ()=>{
      const _id = location.state?._id ? location.state?._id : 
      site?._id ? site?._id : localStorage.getItem("site_id");
      await updateSiteContext(_id);
    })();

    return () => {
      (async ()=>{
        const _id = location.state?._id ? location.state?._id : 
        site?._id ? site?._id : localStorage.getItem("site_id");
        await updateSiteContext(_id);
      })();
    };
  }, []);


  useEffect(() => {
    const fetchData = async () => {
        var id = "";
      const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/site/readone/" + SiteId;
        const result = await axios.get(readURL);
      id = result.data.siteId;
      const readURL1 =
        process.env.REACT_APP_BACKEND_LINK + "api/controller/read/" + id;
        const result1 = await axios.get(readURL1);
      setControllers(result1.data);
    };
    fetchData();

    for (let index = 0; index < controllers[0]?.portPerController; index++) {
      var temp = index + 1;
      portList.push(temp);
    }
    availablePortList = portList;
    tanks.forEach((e) => {
      var removePort = parseInt(e.portNum);
      var removeIndex = availablePortList.indexOf(removePort);
      availablePortList.splice(removeIndex, 1);
    });

    setavailablePortList1(availablePortList);
  }, [site?._id, controllers, tanks]);

  useEffect(() => {
    const fetchData = async () => {
      const readURL = process.env.REACT_APP_BACKEND_LINK + "api/product/read";
      const result = await axios.get(readURL);
      setProducts(result.data.sort((a,b) => (a.productSKU?.toLowerCase() > b.productSKU?.toLowerCase()) ? 1 : -1));
    };
    fetchData();
  }, [site?._id]);

  const handleProdItemClick = (product) => {
    setProduct(product.productSKU);
    setProductClass(product);
    setProductColor(product.productColor);
    setProductId(product.productId);
    setProduct_Id(product._id);
  };

  const handleContrItemClick = (event, controller) => {
    setController(controller._id);
    setSController(controller.controllerMacAddress);
    if (controller._id !== controllers[0]._id) {
      portList = [];
      for (let index = 0; index < controller.portPerController; index++) {
        var temp = index + 1;
        portList.push(temp);
      }
      availablePortList = portList;
      tanks.forEach((e) => {
        var removePort = parseInt(e.portNum);
        var removeIndex = availablePortList.indexOf(removePort);
        availablePortList.splice(removeIndex, 1);
      });
      setavailablePortList1(availablePortList);
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "SizeSelect") {
      setSizeSelect(event.target.value);
    }
    if (event.target.name === "SensorType") {
      setSensorType(event.target.value);
    }
    if (event.target.name === "Port") {
      setPortNum(event.target.value);
      setSPort(event.target.value);
    }
  };

  const tankValidation = async (e) => {
    let temp = {};
    temp.tanksize = NonMonitored || SizeSelect ? "" : "Please select the Tank Size";
    temp.inputsize = NonMonitored || OtherSize ? "": "Please enter the size"
    //temp.controller = SController ? "": "Please select the controller"
    //temp.sensor = SensorType ? "": "Please select the Sensor Type"
    //temp.product = ProductAlias ? "": "Please enter Product Type"
    //temp.stinger = Stinger ? "":"Please enter Stinger Quantity"
    //temp.port = PortNum ? "" :"Please select Port Number"
    temp.minstockalert = MinStockAlert ? "": "Please enter Min Stock"
    temp.products = Product ? "" :"Please select Product"

    
    temp.inputsize =
      OtherSize && OtherSize >= 0
        ? ""
        : "Please enter valid positive input size";
    temp.stinger = Stinger >= 0
        ? ""
        : "Please enter positive Stinger Quantity"

    temp.minstockalert = MinStockAlert >=0
        ? ""
        : "Please enter positive min stock alert" 

    temp.distancetocontroller = DistanceToController >= 0
        ? ""
        : "Please enter positive distance to controller"
    temp.discount =
      (discount >= 0 && discount <=100 )? "" : "Please enter value between (0-100) of discount";

    if (SizeSelect !== "other") temp.inputsize = "";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  async function handleSubmit() {
    let Size = SizeSelect;
    if (SizeSelect === "other") {
      Size = OtherSize;
    } else {
      Size = parseInt(SizeSelect);
    }

    let body = {
      tankId: TankId,
      productAlias: ProductAlias,
      product: Product,
      productColor: ProductColor,
      productId: ProductId,
      product_Id: Product_Id,
      size: Size,
      siteId: SiteId,
      distanceToController: DistanceToController,
      stinger: Stinger,
      minStockAlert: MinStockAlert,
      portNum: PortNum,
      controllerId: Controller,
      sensorType: SensorType,
      nonMonitored: NonMonitored,
      discount,
    };

    tankValidation().then(function (result) {
      if (result) {
        let response = fetch(
          process.env.REACT_APP_BACKEND_LINK + "api/tank/create",
          {
            method: "POST",
            body: JSON.stringify(body),
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        ).then((response) => response.json());

        response.then(function (result) {
          if (result.errors && result.errors[0].msg.includes("")) {
            setError(result.errors[0].msg);
            setTimeout(() => {
              setError("");
            }, 5000);
          } else {
            history.push({
              pathname: "/tanks/",
              state: {
                _id: SiteId,
                siteName: CarWashName,
                siteId: siteId,
                siteOwner: CarWashOwner,
              },
            });
          }
        });
      }
    });
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Tank ${Product} Created at ${CarWashName}`, category: "Create Tank" }
      )
    }
    catch (error) {
      console.log(error)
    }
  }

  function handleCancel() {
    history.push({
      pathname: "/tanks/",
      state: {
        _id: SiteId,
        siteName: CarWashName,
        siteId: siteId,
        siteOwner: CarWashOwner,
      },
    });
  }

  return (
    <MainPage name={"Create Tank"}>
      <form className={classes.root} noValidate autoComplete="off">
        <div id="TankSize">
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="h5">
            Tank Info.
          </Typography>
          <div>
            <FormControl className={classes.formControl}>
            <FormControlLabel 
            control={
                <Checkbox 
                    name="NonMonitored"
                    checked={NonMonitored}
                    onChange={
                        (e, checked) => setNonMonitored(checked)
                    }
                />} 
            label="Non-monitored Product" 
            />
            </FormControl>
           </div>
          <FormControl
            className={classes.formControl}
            style={{ margin: "20px" }}
            disabled={NonMonitored}
          >
            <InputLabel id="SizeSelect">Sensor Type</InputLabel>
            <Select
              name="SensorType"
              labelId="Sensor Type"
              id="SensorType"
              value={SensorType}
              error={errors.sensor}
              onChange={handleChange}
            >
              <MenuItem value={"UK1C-G2-0E"}>UK1C-G2-0E (New)</MenuItem>
              <MenuItem value={"UK1C-E2-0E"}>UK1C-E2-0E (Old)</MenuItem>
            </Select>
            {errors.sensor && (
              <FormHelperText htmlFor="render-select" error>
                {errors.sensor}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            className={classes.formControl}
            style={{ margin: "20px" }}
            disabled={NonMonitored}
          >
            <InputLabel id="Controller">Controller</InputLabel>
            <Select
              name="Controller"
              labelId="Controller"
              id="Controller"
              value={SController ? SController : ''}
              // error={errors.controller}
              onChange={handleChange}
            >
              {Controllers.map((row) => (
                <MenuItem
                  key={`${row?._id}-create-tank`}
                  value={row.controllerMacAddress}
                  onClick={(event) => handleContrItemClick(event, row)}
                >
                  {row.controllerMacAddress}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className={classes.formControl}
            style={{ margin: "20px" }}
            disabled={NonMonitored}
            required={!NonMonitored}
          >
            <InputLabel id="SizeSelect" error={errors.tanksize}>
              Tank Size
            </InputLabel>
            <Select
              name="SizeSelect"
              labelId="Tank Size"
              id="SizeSelect"
              value={SizeSelect}
              onChange={handleChange}
              error={errors.tanksize}
            >
              <MenuItem value={"10 Gal"}>10 Gal</MenuItem>
              <MenuItem value={"20 Gal"}>20 Gal</MenuItem>
              <MenuItem value={"other"}>other, input aside</MenuItem>
            </Select>
            {errors.tanksize && (
              <FormHelperText htmlFor="render-select" error>
                {errors.tanksize}
              </FormHelperText>
            )}
          </FormControl>
          {SizeSelect === "other" && (
            <TextField
              id="otherSize"
              style={{ margin: "20px" }}
              label="Input Size"
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: 1 } }}
              value={OtherSize}
              onInput={(e) => setOtherSize(e.target.value)}
              error={errors.inputsize}
              helperText={errors.inputsize}
              required={NonMonitored}
              disabled={NonMonitored}
            />
          )}
          <TextField
            id="productDiscount"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 0, max:100 } }}
            label="Product Discount (%)"
            value={discount}
            onInput={(e) => setDiscount(e.target.value)}
            error={errors.discount}
            helperText={errors.discount}
            className={classes.formControl}
            disabled={NonMonitored}
          />
          <TextField
            id="ProductAlias"
            style={{ margin: "20px" }}
            label="Product Alias"
            value={ProductAlias}
            className={classes.formControl}
            // error={errors.product}
            // helperText={errors.product}
            onInput={(e) => setProductAlias(e.target.value)}
            
          />
          <TextField
            id="Stinger"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 1 } }}
            style={{ margin: "20px" }}
            label="Stinger Quantity"
            value={Stinger}
            onInput={(e) => setStinger(e.target.value)}
            error={errors.stinger}
            helperText={errors.stinger}
            disabled={NonMonitored}
          />
          <FormControl
            className={classes.formControl}
            style={{ margin: "20px" }}
            disabled={NonMonitored}
          >
            <InputLabel id="Port">Port</InputLabel>
            <Select
              name="Port"
              labelId="Port"
              id="Port"
              value={SPort}
              error={errors.port}
              onChange={handleChange}
            >
              {availablePortList1.map((e) => (
                <MenuItem key={`${e}-1515`} value={e}>{e}</MenuItem>
              ))}
            </Select>
            {/* {errors.port && (
              <FormHelperText htmlFor="render-select" error>
                {errors.port}
              </FormHelperText>
            )} */}
          </FormControl>

          <TextField
            id="MinStockAlert"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 1 } }}
            style={{ margin: "20px" }}
            label="Min Stock (Alert)"
            value={MinStockAlert ? MinStockAlert : 0}
            onInput={(e) => setMinStockAlert(e.target.value)}
            error={errors.minstockalert}
            helperText={errors.minstockalert ? errors.minstockalert : "Min Stock (Alert) must be set greater than '0' to enable automatic inventory management"}
          />
          <TextField
            id="DistanceToController"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 1 } }}
            style={{ margin: "20px" }}
            label="Distance To Controller (ft)"
            value={DistanceToController}
            onInput={(e) => setDistanceToController(e.target.value)}
            error={errors.distancetocontroller}
            helperText={errors.distancetocontroller}
            disabled={NonMonitored}
          />
          <FormControl
            className={classes.formControl}
            style={{ margin: "20px" }}
          >
            <InputLabel id="Product" style={Product ? {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
            } : {}}>Product</InputLabel>
            <ReactSelect
              name="Product"
              labelId="Product"
              id="Product"
              placeholder="Product"
              styles={selectStyles}
              error={errors.products}
              onChange={handleProdItemClick}
              components={{ DropdownIndicator }}
              value={ProductClass}
              options={Products.map((row) => (
                {
                    ...row, 
                    value: `${row?.productId}-321321`, 
                    label: `${row.productSKU}-${row.productClass}`
                }
              ))}
            ></ReactSelect>
            {errors.products && (
              <FormHelperText htmlFor="render-select" error>
                {errors.products}
              </FormHelperText>
            )}
            {/* <FormHelperText>Selected Product is {Product}</FormHelperText> */}
          </FormControl>
          <Grid item xs={6} sm={4}>
            <Box
              bgcolor={ProductColor}
              color="background.paper"
              p={0.7}
              style={{ marginLeft: "20px", marginBottom: "40px" }}
            >
              Tank Color
            </Box>
          </Grid>
        </div>
      </form>
      <Box display="flex" justifyContent="flex-end">
        <ButtonLink onClick={handleCancel} color="blue" name="cancel" />
        <ButtonLink onClick={handleSubmit} color="pink" name="submit" />
      </Box>
    </MainPage>
  );
};

export default CreateTank;
