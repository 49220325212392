import {useMutation, useQuery} from 'react-query';
import axios from "axios";

const handleFetchUser = async (userId) => {
    const userData = await axios.get(
        process.env.REACT_APP_BACKEND_LINK +
          "api/auth/userbyId/" +
          userId
      );

      if (userData?.data) return userData?.data;

      return [];
};

export const useGetUserById = (userId) => {
  return useQuery(
    ['user--', userId],
    () => handleFetchUser(userId),
    { 
        staleTime: 1000*60*60*24,
        enabled: Boolean(!!userId)
    }
  );
};