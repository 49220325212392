import React from "react";
import { useState, useEffect } from "react";
import MainPage from "../Layout/MainPage";
import SitesDropdown from "../Util/SitesDropdown";
import axios from "axios";
//import { Dropdown, DropdownLink } from "@material-tailwind/react";
import LeadText from "@material-tailwind/react/LeadText";
import { ChartGenerationUsage } from "./ProductUsageChart";
import { Box, Button, } from "@material-ui/core";
import ButtonLink from "../Util/ButtonLink";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import {useGetReportsBySite} from "../../hooks/queries/useGetReportsBySite";
import {useGetTanksBySite} from "../../hooks/queries/useGetTanksBysite";
import ReactSelect, { components } from 'react-select';
import { Typography } from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { calculateDays } from './helpers';

const useStyles = makeStyles({
    heading1: {
      marginRight: 3,
      fontWeight: 500,
    },
    heading2: {
      marginRight: 40,
  
      fontWeight: 500,
    },
    heading3: {
      marginRight: 59,
      fontWeight: 500,
    },
  });

export const ProductUsage = React.memo(() => {
  const [siteSelected, setSiteSelected] = useState(null);
  const [tankData, setTankData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [custom, setCustom] = useState(false);
  const [productName, setProductName] = useState([]);
  const [tankId, setTankId] = useState("");
  const [days, setDays] = useState(0);
  const [title, setTitle] = useState("");
  const classes = useStyles();
  const [selectedToDate, setSelectedToDate] = React.useState(null);
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);

//   let {data: reports = [] } = useGetReportsBySite(siteSelected?._id, {days});
  let {data: reports = [] } = useGetReportsBySite(
    siteSelected?._id, 
    {
        periodFrom: selectedFromDate?.format("YYYY-MM-DD") || "",
        periodTo: selectedToDate?.format("YYYY-MM-DD") || "" 
    } 
  );  
  const {data: tanks = [] } = useGetTanksBySite(siteSelected?._id, reports.length > 0);
 
//   reports = reports.filter(r=>r);

  const handleSiteChange = (site) => {
    if (siteSelected?._id !== site?._id) {
      setSiteSelected(site);
      setTankId("");
    };
  };
  
    const handleDateFromChange = async (date) => {
        setSelectedFromDate(moment(date));
        setSiteSelected(null)
    };

    const handleDateToChange = async (date) => {
        setSelectedToDate(moment(date));
        setSiteSelected(null)
    };
  
  useEffect(function loadTanks() {
    if (tanks.length && reports.length) {
        let usage = null;

        const {fromDateTime, toDateTime, dd} = calculateDays(days, selectedFromDate, selectedToDate);
        let fromDate = moment(fromDateTime);
        const toDate = moment(toDateTime);
        const array = [];

        while (toDate >= fromDate) {
            const formattedDate = fromDate.format("YYYY-MM-DD");
            array.push({date: formattedDate});
            fromDate = fromDate.add(1, 'd')
        }

        tanks.forEach(tank => {
            usage = null;
            reports.forEach(rep => {
                if(usage) return;
                rep?.tanks.forEach(tnk => {
                    if(usage) return;
                    if(tnk?._id === tank._id) usage = tnk?.packageUsagePerCarArr;
                });
            });
            tank.usage = usage
            if (usage) {
                usage.forEach((u, i) => {
                    if (i < array.length) {
                        array[i][`${tank.product} ${tank.productAlias}`] = u
                    }
                })
            }
        })
        setTankData(tanks);
        setCsvData(array);
    }
  }, [tanks, reports])

  const styles = {
    container: (styles) => ({ ...styles,
        display: "inline-block",
        marginBottom: "20px",
        marginLeft: '10px',
        minWidth: '200px'
    }),
  }

  return (
    <MainPage name="Product Usage" color="red">
      <Box display="flex">
        <ButtonLink
          onClick={() => {
            setCustom(false);
            setDays(7);
            setSelectedToDate(moment().subtract(1, 'd'));
            setSelectedFromDate(moment().subtract(7, 'd'));
            setSiteSelected(null);
            setTitle("Last 7 days Report");
          }}
          color="pink"
          name="Last 7 days"
        />
        <ButtonLink
          onClick={() => {
            setCustom(false);
            setDays(30);
            setSelectedToDate(moment().subtract(1, 'd'));
            setSelectedFromDate(moment().subtract(30, 'd'));
            setSiteSelected(null);
            setTitle("Last 30 days Report");
          }}
          color="pink"
          name="Last 30 days"
        />
        <ButtonLink
          onClick={() => {
            setCustom(true);
            setDays(0);
            setTitle("Please Select Dates: 'From' Date should be lesser than 'To' Date ")
            setSiteSelected(null)
          }}
          color="pink"
          name="Custom Dates"
        />
      </Box>
      {days || custom ? (
        <>
          <LeadText color="red"> {title}</LeadText>
        {custom ? (
            <>
                <Box display="flex" justifyContent="flex-end">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    style={{ marginTop: 0, paddingRight: "10px" }}
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    id="date-picker-dialog"
                    label="From "
                    format="yyyy-MM-dd"
                    autoOk={true}
                    maxDate={new Date()}
                    value={selectedFromDate}
                    onChange={handleDateFromChange}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    style={{ marginTop: 0, paddingRight: "10px" }}
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    id="date-picker-dialog"
                    label="To "
                    format="yyyy-MM-dd"
                    autoOk={true}
                    value={selectedToDate}
                    maxDate={new Date()}
                    onChange={handleDateToChange}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    />
                </MuiPickersUtilsProvider>
                </Box>
            </>
            ) : (
            <></>
            )}
          <SitesDropdown onShowClick={handleSiteChange} />
          {siteSelected && reports ? 
          reports.length === 0 ? (<LeadText color="teal">No reports available for this site</LeadText>) 
          : (
            <>
              {tanks.length > 0 ? (
                <>
                <Typography>
                    <span className={classes.heading1}>Site Name:</span>
                    {siteSelected?.siteName}
                </Typography>
                <Typography>
                    <span className={classes.heading2}>From: </span>
                    {selectedFromDate.format("YYYY/MM/DD")}
                </Typography>
                <Typography>
                    <span className={classes.heading3}>To: </span>
                    {selectedToDate.format("YYYY/MM/DD")}
                </Typography>
                <br />
                <CSVLink data={csvData} filename={`${siteSelected.siteName} UsagePerCar Report ${selectedFromDate.format("YYYY-MM-DD")} to ${selectedToDate.format("YYYY-MM-DD")}.csv`} className="btn btn-primary" target="_blank">Download CSV</CSVLink>
                  {tankData ? (tankData.map((tank, i) => (
                    tank.usage &&
                      <ChartGenerationUsage
                        site={siteSelected}
                        report={reports}
                        tankId={tank._id}
                        name={`${tank.product} ${tank.productAlias}`}
                        usage={tank.usage}
                        days={days}
                        to={selectedToDate}
                        from={selectedFromDate}
                      />
                  ))) : (
                    <>
                      <LeadText color="teal">Select a tank</LeadText>
                    </>
                  )}
                </>
              ) : (
                <LeadText color="teal">No Tank's Data</LeadText>
              )}
            </>
          ) : (
            <>
              <LeadText color="teal">Select a Site</LeadText>
            </>
          )}
        </>
      ) : (
        <>
          <LeadText color="teal">Select any button</LeadText>
        </>
      )}
    </MainPage>
  );
});
