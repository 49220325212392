import moment from "moment";
import { object } from "prop-types";

export const sortArrayData = (arrayData)=>{
    const sortedArray = arrayData.sort((a, b) =>{
        if(a._id < b._id) return -1;
        if(a._id > b._id) return 1;
        return 0;
    });

    return sortedArray;
};

export const converMilToGalon = (value)=>{

    return !!value ? +(+value / 3785.411784).toFixed(2) : 0;
};

///******tanks******************

export const generateTanksChartData = (tanks)=> {

    const tanksData = {
      tanksIds:[],
      tanksTotalUsagePerDay:[],
      tanksTotalCostPerDay:[],
      tanksUsage:[],
      tanksCost:[],
      tanksAliases:[],
      tanksNames:[],
      tanksColors:[],
      tanksProductPrice:[],
      tanksSizes:[],
      tanksProductsSizes:[],
      tanksCount:[]
    };
  
    sortArrayData(tanks).map((tank)=> {
      tanksData.tanksIds.push(tank?._id);
      tanksData.tanksTotalUsagePerDay.push(+tank?.totalUsagePerCar?.toFixed(2));
      tanksData.tanksTotalCostPerDay.push(+tank?.totalCostPerCar?.toFixed(2));
      tanksData.tanksUsage.push(+tank?.usagePerCar);
      tanksData.tanksCost.push(+tank?.costPerCar?.toFixed(2));
      tanksData.tanksAliases.push(tank?.product);
      tanksData.tanksNames.push(tank?.productAlias);
      tanksData.tanksColors.push(tank?.productColor?.concat("80"));
      tanksData.tanksProductPrice.push(+tank?.productPrice);
      tanksData.tanksSizes.push(+tank?.size);
      tanksData.tanksProductsSizes.push(+tank?.productSize);
      tanksData.tanksCount.push(+tank?.count);
    })
  
    return tanksData;
  };

export const buildTanksChartData = (tanksData, toDateTime, fromDateTime) =>{

    const seriesLine = [
      {
        name: "Product Usage Per Car (in mL)",
        data: tanksData.tanksUsage,
      },
      {
        name: "Product Cost Per Car ($)",
        data: tanksData.tanksCost,
      },
    ];
  
    const optionsLine = {
      title: {
        text: "Usage Information",
      },
      legend:{
        position: 'bottom',
        fontSize: '18px',
        fontWeight: 400,
      },
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {},
        },
      },
      colors: tanksData.tanksColors,
      plotOptions: {
        bar: {
          distributed: true,
          columnWidth: "90%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
  
      xaxis: {
        categories: tanksData.tanksAliases,
        labels: {
          style: {
            colors: "#111111",
            fontSize: "16px",
          },
        },
      },
  
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
          height: 350,
          export: {
            csv: {
              filename: `Latest Report ${moment(fromDateTime).format("YYYY/MM/DD, hh:mm a")} to ${moment(toDateTime).format("YYYY/MM/DD, hh:mm a")}`,
              headerCategory: 'Tanks',
              headerValue: 'value',
              
            },
           
          },
        },
      },
      tooltip: {
        intersect: false,
        shared: true,
        x: {
          formatter: function (val,i) {
            let result1 = "";
            let result2 = 0;
            let result3 = 0;
            let result4 = 0
            let result5 = 0
            let result6 = 0
            tanksData.tanksAliases.forEach(function (value) {
              if (value == val) {
                result1 = tanksData.tanksNames[i.dataPointIndex];
                result2 = `$${tanksData.tanksProductPrice[i.dataPointIndex]} per ${tanksData.tanksProductsSizes[i.dataPointIndex]} Gallon`;
                result3 = tanksData.tanksSizes[i.dataPointIndex];
                result4 = `${converMilToGalon(tanksData.tanksTotalUsagePerDay[i.dataPointIndex])} Gallon `
                result5 = `$${tanksData.tanksTotalCostPerDay[i.dataPointIndex]}` 
                result6 = tanksData.tanksCount[i.dataPointIndex]
              }
            });
            return (  
             
              `<div key={${i+'tableTanks'}}>`+"<b>Name</b>: " +
              result1 +
              "<br><b>Alias</b>: " +
              val +
              "<br><b>Application Count</b>: " +
              result6 +
              "<br><b>Total Product Usage Per Day</b>: " +
              result4 +
              "<br><b>Total Product Cost Per Day</b>: " +
              result5 +
              "<br><b>Product Price</b>: " +
              result2 +
              "<br><b>Tank Size</b>: " +
              result3 +
              " Gallon" +"</div>"
              
            );
          },
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    };
    return {optionsLine, seriesLine};
  };


//// **************packages**********

export const generatePackagesReportData = (packages) =>{

    const packagesData = {
      packagesIds:[],
      packagesCount:[],
      packagesNames:[],
      packagesCostPerCAr:[],
      packagesCost:[]
    };
  
    sortArrayData(packages).map((packag) => {
      packagesData.packagesIds.push(+packag?._id);
      packagesData.packagesCount.push(+packag?.count);
      packagesData.packagesNames.push(packag?.packageName);
      packagesData.packagesCostPerCAr.push(packag?.packageCostPerCar ? +(+packag?.packageCostPerCar).toFixed(2): 0);
      packagesData.packagesCost.push((packag?.packageCost) ? +(+packag?.packageCost).toFixed(2): 0);
    });
  
    return packagesData;
  };

  export const buildPackagesData = (packagesData) => {
   
    const data = {
      series: packagesData.packagesCount,
      options: {
        chart: {
          width: 380,
          type: "donut",
          dropShadow: {
            enabled: true,
            color: "#111",
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2,
          },
        },
  
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  label: "Total Washes",
                  showAlways: true,
                  show: true,
                },
              },
            },
          },
        },
        labels: packagesData.packagesNames,
        dataLabels: {
          dropShadow: {
            blur: 10,
            opacity: 0.3,
          },
        },
        fill: {
          type: "gradient",
          opacity: 1,
          pattern: {
            enabled: true,
            style: ["squares", "horizontalLines", "circles", "slantedLines"],
          },
        },
        theme: {
          palette: "palette2",
        },
        colors: ["#7b46e3", "#4670e3", "#46c4e3", "#c446e3"],
  
        title: {
          text: "Package Information",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
  
        tooltip: {
          y: {
            formatter: function (value,i) {
              let result = 0;
              let result1 = 0;
              let name;
              result = packagesData.packagesCost[i.dataPointIndex];
              result1 = packagesData.packagesCostPerCAr[i.dataPointIndex];
              name = packagesData.packagesNames[i.dataPointIndex]
              
              return (
                `<div key={${i + 'lastChart-5'}}>`+ "<b>Package Count</b>: " +
                value +
                "<br><b>Average Package Cost Per Car</b>: $" +
                result1 +
                "<br><b>Total Package Cost</b>: $" +
                result+"</div>"
              );
            },
          },
        },
      },
    };
  
    return {data};
  };
  

  /////**************alacarte packages ********** */
  export const calculatePackages = (reports) =>{
    const allAlacartePackagesObj = {};

    reports.forEach((report, i) => {
        
        if(!report) {
            allAlacartePackagesObj[i.toString()] = null;
            return;
        }
      
        report.aLacartePackages.forEach(pack => {
            if(!allAlacartePackagesObj.hasOwnProperty(pack?._id)) {
                allAlacartePackagesObj[pack?._id] = {relatedPackages: [pack]};
                allAlacartePackagesObj[pack?._id].isAlacarte = pack?.isAlacarte;
                allAlacartePackagesObj[pack?._id].isInclusive = pack?.isInclusive;
                allAlacartePackagesObj[pack?._id].packageName = pack?.packageName;
                allAlacartePackagesObj[pack?._id]._id = pack?._id;
            } 
            else {
                allAlacartePackagesObj[pack?._id].relatedPackages.push(pack);
            }
        });
    });
 
    for (const [key, value] of Object.entries(allAlacartePackagesObj)) {
        if(!value) continue;
        let totalCounts = 0;
        let totalCostPerCar= 0;
        value.relatedPackages.forEach(pack =>{
            totalCounts += +pack.count;
            totalCostPerCar += +pack.packageCost;
        });
        allAlacartePackagesObj[key].totalCount = totalCounts;
        allAlacartePackagesObj[key].avgCostPerCar = +(totalCostPerCar / allAlacartePackagesObj[key].relatedPackages.length).toPrecision(3);
    }

    return Object.values(allAlacartePackagesObj);
};


///// ********* multi days tanks **************

export const generateMultiDaysTanksChartData = (reports)=> {

   const tanksData = {
    tanksIds:[],
    tanksTotalUsagePerDay:[],
    tanksTotalCostPerDay:[],
    tanksUsage:[],
    tanksCost:[],
    tanksAliases:[],
    tanksNames:[],
    tanksColors:[],
    tanksProductPrice:[],
    tanksSizes:[],
    tanksProductsSizes:[],
    tanksCount:[]
  };

   reports.forEach((report)=> {
        if(!report) return;
        if(tanksData.tanksIds.length > 0) return;

        report.tanks.forEach((tank, i)=>{
            tanksData.tanksIds.push(report.tanks[i]._id);
            tanksData.tanksColors.push(report.tanks[i].productColor);
            tanksData.tanksAliases.push(report.tanks[i].product);
            tanksData.tanksNames.push(report.tanks[i].productAlias);
            tanksData.tanksSizes.push(report.tanks[i].size);
            tanksData.tanksProductPrice.push(+report.tanks[i].productPrice);
            tanksData.tanksProductsSizes.push(report.tanks[i].productSize);
            tanksData.tanksTotalUsagePerDay.push(+converMilToGalon(+report.tanks[i].tankUsagePeriod).toFixed(2));
            tanksData.tanksTotalCostPerDay.push(+report.tanks[i].tankCostPeriod.toFixed(2));
            tanksData.tanksUsage.push(+converMilToGalon(+report.tanks[i].dailyAvgUsagePeriod).toFixed(2));
            tanksData.tanksCost.push(+report.tanks[i].dailyAvgCostPeriod.toFixed(2));
            tanksData.tanksCount.push(report.tanks[i].count);
        });
   });

return tanksData;
};

export const buildMultiDaysTanksChartData = (tanksData, toDateTime, fromDateTime) =>{

    const seriesLine = [ 
        {
            name: "Avg Daily Product Usage (Gallon)",
            data: tanksData.tanksUsage,
        },
        {
            name: "Avg Daily Product Cost ($)",
            data: tanksData.tanksCost,
        },
    ];

    const optionsLine = { 
        title: {
            text: "Usage Information",
        },
        legend: {
            position: "bottom",
            fontSize: '18px',
            fontWeight: 400,
        },
        chart: {
            toolbar: {
                show: true,
                tools: {
                    download: true,
                },
                height: 350,
                export: {
                    csv: {
                        filename: `MultiDay Report ${ moment( fromDateTime ).format( "YYYY/MM/DD, hh:mm a" ) } to ${ moment( toDateTime ).format( "YYYY/MM/DD, hh:mm a" ) }`,
                        headerCategory: 'Tanks',
                        headerValue: 'value',

                    },
                },
            },
            height: 350,
            type: "bar",
            events: {
                click: function ( chart, w, e ) { },
            },
        },
        colors: tanksData.tanksColors,
        plotOptions: {
            bar: {
                distributed: true,
                columnWidth: "90%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },

        xaxis: {
            categories: tanksData.tanksAliases,
            labels: {
                style: {
                    colors: "#111111",
                    fontSize: "16px",
                },
            },
        },

        tooltip: {
            intersect: false,
            shared: true,
            x: {
                formatter: function ( val, i )
                {
                    let result1 = "";
                    let result2 = "";
                    let result3 = "";
                    let result4 = "";
                    let result5 = "";
                    let result6 = ""
                    let result7 = ""
                    let result8 = ""

                    tanksData.tanksAliases.forEach( function ( value )
                    {
                        if ( value == val )
                        {
                            result1 = tanksData.tanksNames[ i?.dataPointIndex ];
                            result2 = `$${ tanksData.tanksProductPrice[ i?.dataPointIndex ] } per ${ tanksData.tanksProductsSizes[ i?.dataPointIndex ] } Gallon`;
                            result3 = `${ tanksData.tanksSizes[ i?.dataPointIndex ] } Gallon`;
                            result4 = `$${ ( tanksData.tanksCost[ i?.dataPointIndex ] ) }`;
                            result5 = `${ ( ( tanksData.tanksUsage[ i?.dataPointIndex ] ) ) }ml`;
                            result6 = `${ tanksData.tanksTotalUsagePerDay[ i?.dataPointIndex ] } Gallon`
                            result7 = `$${ tanksData.tanksTotalCostPerDay[ i?.dataPointIndex ] }`
                            result8 = tanksData.tanksCount[ i?.dataPointIndex ]
                        }
                    } );
                    return (
                        `<div key={${i+'table-Tanks-multi'}}>`+"<b>Name</b>: " +
                        result1 +
                        "<br><b>Alias</b>: " +
                        val +
                        "<br><b>Product Price</b>: " +
                        result2 +
                        "<br><b>Tank Size</b>: " +
                        result3 +
                        "<br><b>Application Count</b>: " +
                        result8 +
                        "<br><b>Total Product Usage</b>: " +
                        result6 +
                        "<br><b>Total Product Cost</b>: " +
                        result7+"</div>"
                    );
                },
            },
            y: {
                formatter: function ( val )
                {
                    return val;
                },
            },
        },
    };

    
    return {optionsLine, seriesLine};
  };

///// ********* multi days packages **************

export const generateMultiDaysPackagesReportData = (reports, isAlacarte = false) =>{

    const packagesData = {
        packagesIds:[],
        packagesCount:[],
        packagesNames:[],
        packagesCostPerCAr:[],
        packagesCost:[]
      };

    reports.forEach(report => {
        if(!report) return;
        // if(packagesData.packagesIds.length > 0) return;
        
        if(!isAlacarte) {
            report.packages.forEach(packg => {
                if(packagesData.packagesIds.includes(packg._id)) return;
                packagesData.packagesIds.push(packg?._id);
                packagesData.packagesCount.push(packg?.countPeriod);
                packagesData.packagesNames.push(packg?.packageName);
                packagesData.packagesCostPerCAr.push(+(+packg?.avgPackageCostPerCar).toFixed(2));
                packagesData.packagesCost.push(+(+packg?.totalCostPeriod).toFixed(2));

            })
        } else {
            report.aLacartePackages.forEach(packg => {
                if(packagesData.packagesIds.includes(packg._id)) return;
                packagesData.packagesIds.push(packg?._id);
                packagesData.packagesCount.push(packg?.countPeriod);
                packagesData.packagesNames.push(packg?.packageName);
                packagesData.packagesCostPerCAr.push(+(+packg?.avgPackageCostPerCar).toFixed(2));
                packagesData.packagesCost.push(+(+packg?.totalCostPeriod).toFixed(2));
            })
        }

   });

    return packagesData;
  };

  export const bulidMultiDaysPackagesChart = (packagesData)=>{
    const data = {
        series: packagesData.packagesCount,
        options: {
            chart: {
                width: 380,
                type: "donut",
                dropShadow: {
                    enabled: true,
                    color: "#111",
                    top: -1,
                    left: 3,
                    blur: 3,
                    opacity: 0.2,
                },
            },

            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                label: "Total Washes",
                                showAlways: true,
                                show: true,
                            },
                        },
                    },
                },
            },
            labels: packagesData.packagesNames,
            dataLabels: {
                dropShadow: {
                    blur: 10,
                    opacity: 0.3,
                },
            },
            fill: {
                type: "gradient",
                opacity: 1,
                pattern: {
                    enabled: true,
                    style: [ "squares", "horizontalLines", "circles", "slantedLines" ],
                },
            },
            theme: {
                palette: "palette2",
            },
            colors: [ "#7b46e3", "#4670e3", "#46c4e3", "#c446e3" ],

            title: {
                text: "Package Information",
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],

            tooltip: {
                y: {
                    formatter: function ( value )
                    {
                        let result1 = 0;
                        let avgresult = 0;
                        packagesData.packagesCount.forEach( function ( val, i )
                        {
                            if ( value === val )
                            {
                                result1 = packagesData.packagesCost[ i ];
                                avgresult = packagesData.packagesCostPerCAr[ i ];
                            }
                        } );

                        return (
                            `<div key={${value+'table-multi'}}>`+ "<b>Total Package Count</b>: " +
                            value +
                            "<br><b>Avergae Package Cost Per Car</b>: $" +
                            avgresult +
                            "<br><b>Total Package Cost</b>: $" +
                            result1+"</div>"

                        );
                    },
                },
            },
        },
    };

    return {data};
  };

///////////// ******** multi days packages in line charts **************////

export const buildInLinePackagesChartData = (reports, days, toDateTime, fromDateTime) => {

    const packagesChartData = {};
    const generatedInLineData = [];

    const optionLine = createInLineOptionData(days, toDateTime, fromDateTime);

    //fill object with packages
    reports.forEach((report)=> {
        if(!report) return;

        // if(Object.keys(packagesChartData).length > 0) return;

        report.packages.forEach((pack)=> {
                packagesChartData[pack?._id] = {
                    packageName: pack?.packageName,
                    _id: pack?._id,
                    counts: pack?.packageCountsArr,
                    costs: pack?.packageCostPerCarArr
                };
        })
    })

    //generate in line charts data
    for (const [key, value] of Object.entries(packagesChartData)) {
        generatedInLineData.push({
            ...value,
            seriesPackage: createInlineSeriesData(value.counts, value.costs),
            optionLine
        });
    };
   
    return generatedInLineData;
};

export const buildInLineAlacartePackagesChartData = (reports, days, toDateTime, fromDateTime) => {

    const packagesChartData = {};
    const generatedInLineData = [];

    const optionLine = createInLineOptionData(days, toDateTime, fromDateTime);

    //fill object with packages
    reports.forEach((report)=> {
        if(!report) return;

        // if(Object.keys(packagesChartData).length > 0) return;

        report.aLacartePackages.forEach((pack)=> {
                packagesChartData[pack?._id] = {
                    packageName: pack?.packageName,
                    _id: pack?._id,
                    counts: pack?.packageCountsArr,
                    costs: pack?.packageCostPerCarArr
                };
        })
    })


    //generate in line charts data
    for (const [key, value] of Object.entries(packagesChartData)) {
        generatedInLineData.push({
            ...value,
            seriesPackage: createInlineSeriesData( value.counts, value.costs),
            optionLine
        });
    };

    return generatedInLineData;
};

const createInlineSeriesData = (costsPerCar, counts) => {
    const seriesPackage = [
        {
            name: "Car Count",
            type: "column",
            data: costsPerCar,
        },
        {
            name: "Cost Per Car",
            type: "line",
            data: counts,
        },
    ];

    return seriesPackage;
};

const createInLineOptionData = (days, toDateTime, fromDateTime) => {
    let fromDate = moment(fromDateTime);
    const toDate = moment(toDateTime);
    const dates = [];

    while (toDate >= fromDate) {
        const formattedDate = fromDate.format("YYYY-MM-DD");
        dates.push(formattedDate);
        fromDate = fromDate.add(1, 'd')
    }

    const optionLine = {
        legend: {
            position: "bottom",
            onItemClick: {
                toggleDataSeries: false
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "30%",
                dataLabels: {
                    position: "center",
                },
            },
        },

        labels: days,
        yaxis: [
            {
                title: {
                    text: "Car Count",
                },
            },
            {
                opposite: true,
                title: {
                    text: "Cost Per Car",
                },
            },
        ],
        xaxis: {
            categories: dates,
        },
        chart: {
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
                height: 350,
                type: "line",
                export: {
                    csv: {
                        filename: "Package Information",
                    },
                },
            },
        },
        stroke: {
            width: [ 1, 4, 4 ],
        },
        title: {
            text: "Package information",
        },
        dataLabels: {
            enabled: true,
        },
    };

    return optionLine;
}

///// ********* Blended Cost Per Car **************
export const calculateLastReportBlendedCost = (packages)=> {

  if(!packages || packages.length <= 0) return 0; 
  let totalCost = 0;
  let totalCount = 0;
  
  packages.forEach(pkg =>{
    totalCost += pkg?.packageCost ? +pkg.packageCost : 0;
    totalCount += pkg?.count ? +pkg?.count : 0;
  })


  return +(totalCost/totalCount).toFixed(2);
}

export const calculateMultiReportBlendedCost = (packages)=> {

  if(!packages || Object.keys(packages).length <= 0) return 0; 
  if(!packages?.packagesCost || packages?.packagesCost.length <= 0) return 0;
  if(!packages.packagesCount || packages?.packagesCount.length <= 0) return 0;

  const initialValue = 0;

  const totalCost = packages.packagesCost.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue
  );

  const totalCount = packages.packagesCount.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue
  );

  return +(totalCost/totalCount).toFixed(2);
}

/////////////////////////////

const cleanProductPrice = (price) => price ? price?.replace(/[^0-9\.]+/g, "") : 0;

const getNumberOfReports = (reports) => +reports.filter(r => r).length;



  export const calculateDays = (days, from, to)=> {
    
    if (days) {
        return {fromDateTime: moment(Date.now()).subtract(days,'days').format( "YYYY/MM/DD, hh:mm a" ), toDateTime: moment(Date.now()).subtract(1,'days').format( "YYYY/MM/DD, hh:mm a" ), days}
    };

    return {fromDateTime: moment( from ).format( "YYYY/MM/DD, hh:mm a" ), toDateTime: moment( to ).format( "YYYY/MM/DD, hh:mm a" ), days: moment( to).diff(from , 'days')};
  }


  