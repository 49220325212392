import React, { useState } from "react"
import MainPage from "../../Layout/MainPage";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import OrdersTable from "./OrdersTable"
import ButtonLink from "../../Util/ButtonLink";
import { Box } from "@material-ui/core";

const Reorder = () => {
    const [isSubmit, setIsSubmit] = useState(false);
    const history = useHistory();
    
    let id,
        siteName = "";
    if (!history.location.state?.site_id) {
        id = localStorage.getItem("site_id");
        siteName = localStorage.getItem("site_name");
    } else {
        id = history.location.state?.site_id;
        siteName = history.location.state?.siteName;
        localStorage.setItem("site_id", id);
        localStorage.setItem("site_name", siteName);
    }
    
    const ordersTable = (val) => {
    return (
        <OrdersTable site_id={id} isHistory={val} isSubmit = {isSubmit} setIsSubmit = {setIsSubmit}/>
    );
    }

    return (
        <>
            <MainPage name={"Requested Orders - " + siteName} color="green">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography align='left' variant='h6' >Request Orders:</Typography>
                        {ordersTable(false)}
                    </Grid>
                
                <Grid item xs={12}>
                <Typography align='left' variant='h6' >Delivered Orders:</Typography>
                    {ordersTable(true)}
             </Grid>
                </Grid>
                
                <Box display="flex" justifyContent="flex-end">
          <ButtonLink
            name="Back"
            onClick={() => {
              history.push({
                pathname: "/inventory"
              });
            }}
          />
        </Box>
            </MainPage>
        </>
    );
};

export default Reorder;
