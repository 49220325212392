import React, { useState, useEffect, useContext } from 'react'
import Subpage from '../Layout/SubPage'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { fetchAllSites, fetchAllUsers } from '../../shared/api/index'
import clsx from 'clsx';
import moment from 'moment';
import TablePagination from "@material-ui/core/TablePagination";
import { authContext } from "../../shared/context/auth/auth-context";
import {useGetUserSites} from '../../hooks/mutations/useGetUserSites';
import {useGetUsersByRole} from '../../hooks/mutations/useGetUsersByRole';

const useStyles = makeStyles((theme) => ({
    fields: {
        width: 220,
    },
    request: {
        textAlign: 'center',
        fontSize: '25px',
        fontWeight: '500',
        color: 'rgb(7,36, 51 , 60%)'
    },
    box: {
        marginBottom: 10,
        marginRight: 20
    },
    site: {
        color: "#0c3670",
        fontWeight: 500
    },
    date: {
        marginLeft: 20
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
        textAlign: 'left'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'left',
        display: 'flex',
        justifyContent: 'space-between'
    },
    column: {
        flexBasis: '50%',
    },

    helper: {
        borderLeft: `2px solid rgba(25, 118, 210, 0.6)`,
        padding: theme.spacing(1, 2),
        width: '100%',

    },

    description: {
        fontWeight: 500,
    },
    info: {
        display: 'flex'

    },
    para: {
        marginLeft: "5px",
        color: '#2e6b99',
    },
    address: {
        color: '#2e9099',
        fontSize: '14px'
    }
}));


const Maintenence = (props) => {
    const { user: loggedInUser } = useContext(authContext);
    const {mutateAsync: getUserSites} = useGetUserSites();
    const {mutateAsync: getUsersByRole} = useGetUsersByRole();

    const [requests, setRequests] = useState([]);
    const [sites, setSites] = useState([]);
    const [user, setUsers] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const fetchData = async () => {
            let fetchedSites = [];
            let requestData = {data: []};
            if(loggedInUser.role === 'admin'){
                fetchedSites = await fetchAllSites();
                requestData = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/request/requests");
            } else if(loggedInUser.role === 'distributor') {
                fetchedSites = await getUserSites({userId: loggedInUser?.id});
                requestData = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/request/requestsForUser/"+ loggedInUser?.id);
            }
            
            let fetchedUsers = await getUsersByRole({usersRole: 'installer'});

            setSites(fetchedSites)
            setRequests(requestData?.data)
            setUsers(fetchedUsers)

        }
        fetchData();

    }, [props.list])

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setPage(0);
    }


    return (
        <Subpage name='Closed Requests' color='blue'>
            {sites.filter(site => 
            requests.find(req => req.isClosed && req.siteId === site._id))
            .map((site) => 
            {
                return (
                    <Accordion expanded={expanded === site._id} onChange={handleChange(site._id)}>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                        
                            <div className={classes.column}>
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                <p className={classes.site}>{site.siteName}</p>
                                </Typography>
                            </div>

                        </AccordionSummary>

                        {requests.filter(req => req.isClosed && req.siteId == site._id)
                         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                         .map((req, i) => {
                            return (
                                <AccordionDetails key={"rd" + i} className={classes.details} >
                                    <div className={classes.info} />
                                    <div className={clsx(classes.helper)}>

                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Requested By:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.requestedBy}
                                            </Typography>
                                        </div>


                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Request Title:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.request_msg}
                                            </Typography>
                                        </div>
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Request Date:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {moment(req.requestDate).format("YYYY/MM/DD h:mm a")}
                                            </Typography>
                                        </div>
                                        {req.installerId &&
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Installer:
                                            </Typography>
                                            {user.map((inst, i) => {
                                                return inst._id === req.installerId && (<Typography className={classes.para}>
                                                    {inst.name}
                                                </Typography>)
                                            })}

                                        </div>}

                                        {req.scheduledAt &&
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Scheduled On:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.scheduledAt && moment(req.scheduledAt).format("YYYY/MM/DD")}
                                            </Typography>
                                        </div>}

                                        {req.installerReport &&
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Installer Report:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.installerReport}
                                            </Typography>
                                        </div>}

                                        {req.adminNotes &&
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Admin Notes:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.adminNotes}
                                            </Typography>
                                        </div>}

                                        {req.closedDate &&
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Closed Date:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.closedDate && moment(req.closedDate).format("YYYY/MM/DD h:mm a")}
                                            </Typography>
                                        </div>}
                                    </div>

                                </AccordionDetails>
                            )
                        }
                        )}
                    </Accordion>
                )
            })
            }

            <TablePagination
                    rowsPerPageOptions={[5, 25, 100]}
                    component="div"
                    count={requests.filter(req => req.isClosed).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

        </Subpage >
    )
}

export default Maintenence
