import React, { useState, useEffect, useContext } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import MainPage from "../Layout/MainPage";
import H4 from "@material-tailwind/react/Heading4";
import axios from "axios";
import { Dropdown, DropdownLink, LeadText } from "@material-tailwind/react";
import ButtonLink from "../Util/ButtonLink";
import { Box } from "@material-ui/core";
import { authContext } from "../../shared/context/auth/auth-context";
import { getSitesForUser, fetchAllSites } from "../../shared/api";
import moment from 'moment';
import SitesDropdown from "../Util/SitesDropdown";
import {useGetUserSites} from '../../hooks/mutations/useGetUserSites';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@mui/material/TablePagination';
import { Grid } from "semantic-ui-react";

const Alerts = React.memo(props => {
  const [_id, setId] = useState();
  const [alerts, setAlerts] = useState([]);
  const [locations, setLocations] = useState([]); // array of all the locations
  const [siteName, setSiteName] = useState(); //site name of the selected site
  const [siteSelected, setSiteSelected] = useState(false); //to check if the site is selected
  const title = "Alerts " + (siteName ? "in " + siteName : "");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { user } = useContext(authContext);
  const {mutateAsync: getUserSites} = useGetUserSites();

  useEffect(async () => {
    let fetchedSites = [];
 
    if(user.role === 'admin'){
        fetchedSites = await fetchAllSites();
    } else if(user.role === 'distributor') {
        fetchedSites = await getUserSites({userId: user?.id});
    }
    setLocations(fetchedSites);
  }, []);

  const handleClick = ({siteId, siteName}) => {
    setSiteSelected(true);
    setSiteName(siteName);
    fetchData(siteId);
  }

  const fetchData = async (id) => {
    //use mango biuld-in site id (_id) to find alerts belongs to one site
    const readURL1 = process.env.REACT_APP_BACKEND_LINK + "api/alert/read/" + id;
    const result = await axios.get(readURL1);
    const alertsWithTanksPromises = result?.data.map(async alert => {
      if (alert?.tanks.length > 0) {
        const tankPromises = await alert.tanks.map(async tankId => {
          const tankURL = process.env.REACT_APP_BACKEND_LINK + "api/tank/readone/" + tankId;
          const tank = await axios.get(tankURL);
          return tank.data;
        });
        const tanksObjects = await Promise.all(tankPromises);

        return { ...alert, tanksObj: tanksObjects };
      }
      return alert;
    });

    const alertsWithTanks = await Promise.all(alertsWithTanksPromises);

    setAlerts(alertsWithTanks);

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 
  return (
    <MainPage name={title} color="red">

      <SitesDropdown onShowClick={handleClick} />
      {
        siteSelected ? (
          <>
            { alerts?.length > 0 ? 
            (
              <Grid container>
                <Grid item xs={12}>
                  {
                    (rowsPerPage > 0
                      ? alerts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : alerts
                    ).map((row, index) => {
                          if (row.statusCode == 1) {
                            return (
                              <Alert key={index + 'alert1' + row._id} severity="error">
                                <AlertTitle key={index + 'alertTitle1' + row._id}>Controller offline!</AlertTitle>
                                <strong key={index + 'alert1strong' + row._id}>Date:</strong> {moment(+row.dt).format("YYYY/MM/DD h:mm a")}
                              </Alert>
                            );
                          }
          
                          if (row.statusCode == 2) {
                            return (
                              <Alert key={index + 'alert2' + row._id} severity="warning">
                                <AlertTitle key={index + 'alertTitle2' + row._id} >Sensor error!</AlertTitle>
                                <strong key={index + 'alert2strong' + row._id}>Date:</strong> {moment(+row.dt).format("YYYY/MM/DD h:mm a")}
                                <br />
                                <strong key={index + 'alert2strong2' + row._id}>Related Tanks:</strong>
                                {row.tanksObj.map(tank => <> *<strong key={index + 'alert2strong3' + row._id}><u key={index + 'alert2u' + row._id}>{tank.product}</u></strong>:port:<strong key={index + 'alert2strong4' + row._id}><u key={index + 'alert2u2' + row._id}>{tank.portNum}</u></strong></>)}
                              </Alert>
                            );
                          }
                      })
                  }
                </Grid>
                <Grid item xs={12}>
                    <TablePagination 
                      component="div"
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      count={alerts?.length} 
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      color="primary" 
                    />
                </Grid>
              </Grid>

            )
            //   alerts.map((row, index) => {
            //     if (row.statusCode == 1) {
            //       return (
            //         <Alert key={index + 'alert1' + row._id} severity="error">
            //           <AlertTitle key={index + 'alertTitle1' + row._id}>Controller offline!</AlertTitle>
            //           <strong key={index + 'alert1strong' + row._id}>Date:</strong> {moment(+row.dt).format("YYYY/MM/DD h:mm a")}
            //         </Alert>
            //       );
            //     }

            //     if (row.statusCode == 2) {
            //       return (
            //         <Alert key={index + 'alert2' + row._id} severity="warning">
            //           <AlertTitle key={index + 'alertTitle2' + row._id} >Sensor error!</AlertTitle>
            //           <strong key={index + 'alert2strong' + row._id}>Date:</strong> {moment(+row.dt).format("YYYY/MM/DD h:mm a")}
            //           <br />
            //           <strong key={index + 'alert2strong2' + row._id}>Related Tanks:</strong>
            //           {row.tanksObj.map(tank => <> *<strong key={index + 'alert2strong3' + row._id}><u key={index + 'alert2u' + row._id}>{tank.product}</u></strong>:port:<strong key={index + 'alert2strong4' + row._id}><u key={index + 'alert2u2' + row._id}>{tank.portNum}</u></strong></>)}
            //         </Alert>
            //       );
            //     }
            // })
            :
            (
              <>
                <Typography>
                   No Alerts found for {siteName}
                </Typography>
              </>
            )
            
            }
          </>
        ) : (
          <>
            <LeadText color='teal'>Select a Site</LeadText>
          </>
        )
      }
    </MainPage >
  );
});
export default Alerts;
