
import React, {useState} from 'react';
import { TextField, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import { useHistory, useLocation } from 'react-router';
import MobileLayout from '../../components/Layout/MobileLayout';
import { v4 } from 'uuid';
import ButtonLink from '../Util/ButtonLink';
import Alert from "@material-ui/lab/Alert";
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '35ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '50ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }, button: {
        margin: theme.spacing(2),
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(2)
    },
}));

const CreatePackage = function FormPropsTextFields() {
    const location = useLocation();
    //   const SiteId = location.state._id;
    //   const CarWashName = location.state.siteName;
    //   const CarWashOwner = location.state.siteOwner;
    const PackageId = v4().slice(-12);
    const SiteId = location.state._id;
    const CarWashName = location.state.siteName;
    const CarWashOwner = location.state.siteOwner;
    const classes = useStyles();
    const history = useHistory();
    const [PackageName, setPackageName] = React.useState('');
    const [isAlacarte, setIsAlacarte] = useState(false);
    const [isInclusive, setIsInclusive] = useState(false);
    const CycleList = [];
    const [error, setError] = useState("");
    const [Input, setInput] = React.useState("");
    const [errors, setErrors] = React.useState({
      packagename: '', 
      carwashinput: ''
    }); 

    const packageValidation = async e => 
    {
      let temp={};
      temp.packagename = PackageName?"":"Please enter the package name";
      temp.carwashinput = Input && Input > 0?"":"Please enter positive car wash input";
      
      setErrors({
         ...temp
       })
       
        return Object.values(temp).every(x => x === "");
    }
    
    function handleSubmit() {
        let body = {
            packageId: PackageId,
            packageName: PackageName,
            siteId: SiteId,
            input: Input,
            isAlacarte,
            isInclusive
        };

        packageValidation().then(function(result)
        {
        if(result)
        {
        let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/package/create", {
            method: "POST",
            body: JSON.stringify(body),
            mode: "cors",
            headers: {
                "Content-Type": "application/json", // request content type
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            },
        }).then(
            response => response.json(), 
          )
          response.then(function(result){
            if(result.errors && result.errors[0].msg.includes(""))
            {
            setError(result.errors[0].msg);
            setTimeout(() => {
              setError("");
              }, 5000);
            } else if(result?.error && result.error.includes("not"))
            {
                setError(result?.error);
                setTimeout(() => {
                  setError("");
                  }, 5000);
            } else {
              history.push({
                pathname: "/site/",
                state: {
                  _id: SiteId,
                  siteName: CarWashName,
                  siteOwner: CarWashOwner
                }
              });
            }
       
          });
        }
    });
    };

    function handleCancel() {
        history.push({
            pathname: '/site/',
            state: {
                _id: SiteId,
                siteName: CarWashName,
                siteOwner: CarWashOwner
            }
        });
    };

    const handleAlacarteChange = (event) => {
        setIsAlacarte(event?.target?.checked);
      };
    
      const handleInclusiveChange = (event) => {
        setIsInclusive(event?.target?.checked);
      };

    return (
        <div style={{ marginTop: '10vh' }}>

            <MobileLayout name={'Create Package'}>
            {error && <Alert style={{width:"20%"}} severity="error">{error}</Alert>}
                <form className={classes.root} noValidate autoComplete="off">
                    <div id="packageInfo">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField required id="PackageName"
                                    style={{ margin: '20px' }}
                                    label="Package Name"
                                    value={PackageName}
                                    onInput={e => setPackageName(e.target.value)}
                                    error = {errors.packagename}
                                    helperText = {errors.packagename}
                                    />
                                <TextField
                                    required
                                    id="Input"
                                    type = "number"
                                    style={{ margin: "20px" }}
                                    label="Carwash Input"
                                    value={Input}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onInput={e => setInput(e.target.value)}
                                    error = {errors.carwashinput}
                                    helperText = {errors.carwashinput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel 
                                control={
                                <Checkbox 
                                checked={isAlacarte}
                                onChange={handleAlacarteChange}
                                />} 
                                label="A Lacarte" 
                                />
                                <FormControlLabel 
                                control={
                                <Checkbox  
                                checked={isInclusive}
                                onChange={handleInclusiveChange}
                                />} 
                                label="Inclusive" 
                                />
                            </Grid>
                        </Grid>
                    </div>
                </form>
                <Box display='flex' justifyContent='flex-end'>
                    <ButtonLink
                        onClick={handleCancel}
                        color='blue'
                        name='cancel' />
                    <ButtonLink
                        onClick={handleSubmit}
                        color='pink'
                        name='submit' />
                </Box>
            </MobileLayout>
        </div>
    )
}


export default CreatePackage
