import React, { useEffect, useState, useContext } from "react";
import MainPage from "../Layout/MainPage";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {RiMailSendLine} from "react-icons/ri";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import InputUnstyled from '@mui/base/InputUnstyled';
import { styled } from '@mui/system';
import { inputLabelClasses } from "@mui/material/InputLabel";
import {
  deleteUser,
  fetchAllUsers,
  fetchUserbyId,
  updateUserbyId,
} from "../../shared/api";
import Alert from "@material-ui/lab/Alert";
import { authContext } from "../../shared/context/auth/auth-context";
import { sendSuppotEmail } from "../../shared/api";
import Swal from "sweetalert2";

const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
  };
  
  const StyledInputElement = styled('input')(
    ({ theme }) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
  `,
  );
  
  const StyledTextareaElement = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    min-height: 300px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
  `,
  );
  
  const CustomInput = React.forwardRef(function CustomInput(props, ref) {
    return (
      <InputUnstyled
        components={{ Input: StyledInputElement, Textarea: StyledTextareaElement }}
        {...props}
        ref={ref}
      />
    );
  });
  
  const covertMessageToHtml = (msg, username, email) => {
    const strArr = msg.split(/\r?\n/)
    return strArr.map(elem => (`<p>${elem}</p>`)).join('') + `<p>--</p><p>From user: ${username}, ${email}</p>`;
   };

const Support = React.memo(() => {
    const { user } = useContext(authContext);
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState({
        name: false,
        subject: false,
        message: false,
      });

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: false
    });

    const handleNameChange = (e) =>{
        setName(e.target.value);
    };
    const handleSubjectChange = (e) =>{
        setSubject(e.target.value);
    };
    const handleMessageChange = (e) =>{
        setMessage(e.target.value);
    };

    const checkValidation = () => {
        const err = {
            name: !name,
            subject: !subject,
            message: !message,
        };
        setError(err);
        return !Object.values(err).some(elem => elem);
    };

    const handleOnSubmit = async () =>{
        const isValidated = checkValidation();
        if(isValidated){
            const { name: username, email } = await fetchUserbyId(user?.id);
            const mailed = await sendSuppotEmail(user?.id, {name, subject, message: covertMessageToHtml(message, username, email)})
            if(mailed?.success) {
                setName('');
                setSubject('');
                setMessage('');
                setError({
                  name: false,
                  subject: false,
                  message: false,
                })

                await Toast.fire({
                    icon: "success",
                    title: "Email sent successfully"
                  });
                 
            } else {
                await Toast.fire({
                    icon: "error",
                    title: "Oops something went wrong!"
                  });
            }
        }
    };

  return (
    <MainPage name="Support" color="blue">
        <Grid 
            container 
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            width={'100%'}
        >
            <Grid 
                item 
                xs={12}
                minWidth={'50%'}
                maxWidth={'70%'}
            >
                <TextField 
                    required
                    error={error.name}
                    id="Name-outlined-basic" 
                    label="Name"
                    variant="outlined" 
                    fullWidth={true}
                    value={name}
                    InputLabelProps={{
                        sx: {
                          color: grey[400]
                        }
                      }}
                    onChange={handleNameChange}
                    onFocus={()=> setError(state =>{
                        return {...state, name: false};
                    })}
                />
            </Grid>
            <Grid
                item 
                xs={12}
                minWidth={'50%'}
                maxWidth={'70%'}
            >
                <TextField 
                    required
                    error={error.subject}
                    id="Subject-outlined-basic" 
                    label="Subject" 
                    variant="outlined" 
                    fullWidth={true}
                    value={subject}
                    InputLabelProps={{
                        sx: {
                          color: grey[400]
                        }
                      }}
                    onChange={handleSubjectChange}
                    onFocus={()=> setError(state =>{
                        return {...state, subject: false};
                    })}
                />
            </Grid>
            <Grid 
                item 
                xs={12}
                minWidth={'50%'}
                maxWidth={'70%'}
            >
                   <CustomInput 
                    required
                    error={error.message}
                    aria-label="Demo input" 
                    multiline 
                    placeholder="Enter your message here." 
                    value={message}
                    onChange={handleMessageChange}
                    onFocus={()=> setError(state =>{
                        return {...state, message: false};
                    })}
                   />
                   {error.message && <Alert severity="error"> add a message</Alert>}
            </Grid>
            <Grid item xs={12}>
                <Button 
                variant="contained" 
                endIcon={<RiMailSendLine />} 
                style={{width:200, height:50}}
                onClick={handleOnSubmit}
                >
                    Send Email 
                </Button>
            </Grid>
        </Grid>
    </MainPage>
  );
});


export default Support;