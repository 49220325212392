import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MainPage from "../../Layout/MainPage";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { TableContainer, TablePagination, Link, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import SiteData from "../../Sites/SiteData";
import { readAllOrders } from "../../../shared/api";
import OrderRow from "./OrderRow";

const useStyles = makeStyles({
    pageContent: {
        marginTop: 40
    },
    table: {
        minWidth: 650,

        transition: "0.5s ease"
    },
    searchBar: {
        marginBottom: "30px"
    },
    tableRow: {
        transition: "0.3s ease",
        "&:hover": {
            backgroundColor: "#b8eaff !important"
        }
    },
 
});

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.disabled
        }
    }
}))(TableRow);


const OrdersTable = props => {
    const { site_id, isSubmit, isHistory, setIsSubmit } = props;
    const classes = useStyles();

    const [orders, setOrders] = useState([]);
    const [isRemoved, setIsRemoved] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    //const [rows, setRows] = useState([]);

    const tableHeaders = isHistory ? SiteData.columnsOrdersHistoryList : SiteData.columnsOrdersShoppingList;
    useEffect(async () => {
        //fetch orders
        const ordersReply = await readAllOrders(site_id);
        if (ordersReply?.success) {
            if (!isHistory) {
                //console.log('i am updatin in shopping list')
                const ordersList = ordersReply?.orders.filter(order => (order.status == 0));
                setOrders(ordersList);
                //setRows(ordersList);
            } else {
                //console.log('i am updatin in history')
                const ordersList = ordersReply?.orders.filter(order => (order.status !== 0)).reverse();
                setOrders(ordersList);
                //setRows(ordersList);
            }
            
        }

    }, [isSubmit, isRemoved]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //console.log('orders table', orders);
    return (
        <>
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                            {tableHeaders.map(column => (
                            <StyledTableCell
                                key={column.id + 'lkjhlkjljk'}
                                align={column.align}
                                style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                            >
                                {column.label}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                    <TableBody>
                        {orders
                         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(order => (
                                <OrderRow key={'asd654' + order?._id} order={order} setIsRemoved={setIsRemoved} setIsSubmit={setIsSubmit} isHistory={isHistory}/>
                            ))}
                    </TableBody>
            </Table>
        </TableContainer>
            {!(orders?.length > 0) ? (<h6>No order created</h6>) : (<></>)}
            <TablePagination
                    rowsPerPageOptions={[5, 25, 100]}
                    component="div"
                    count={orders?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
        );
};

export default OrdersTable;