import React, { useState } from 'react'
import { Route, Switch, HashRouter } from 'react-router-dom'
import InstallerDashboard from '../InstallerComponents/InstallerDashboard'
import InstallerNavigation from '../Navigation/InstallerNavigation'
import { makeStyles } from "@material-ui/core/styles";
import ConfigureSites from '../InstallerComponents/ConfigureSites'
import CompletedSites from '../InstallerComponents/CompletedSites';
import Site from '../Sites/Site/Site'
import Bays from '../Sites/Bays/Bays'
import Packages from '../Sites/Packages/Packages'
import CreateBay from '../Sites/Bays/CreateBay/CreateBay';
import UpdateBay from '../Sites/Bays/UpdateBay/UpdateBay';
import Tanks from '../Sites/Tanks/Tanks';
import UpdateSite from "../Sites/UpdateSite/UpdateSite";
import CreateTank from '../Sites/Tanks/CreateTank/CreateTank';
import UpdateTank from '../Sites/Tanks/UpdateTank/UpdateTank';
import Controllers from "../Sites/Controllers/Controllers";
import CreateController from "../Sites/Controllers/CreateController/CreateController";
import UpdateController from "../Sites/Controllers/UpdateController/UpdateController";
//import Management from '../InstallerComponents/Management/Management'
import InstallerCycle from '../InstallerComponents/InstallerCycle'
import UpdateCycle from '../Sites/Packages/UpdateCycle';
import InstallerSite from '../InstallerComponents/Sites/SiteDetails/InstallerSite'
import InstallerPackage from '../InstallerComponents/InstallerPackage'
import UpdateInput from "../Sites/Packages/UpdateInput";
import { useHistory } from 'react-router-dom';
import TestSensor from '../TestSensor/TestSensor'
import Inventories from "../Inventory/owner/Inventories";
import Management from "../Management/Management";

const useStyles = makeStyles({
    container: {
        display: "flex",
    },
    content: {
        margin: 'auto',
        width: '90%',
    }
});

const Installer = ({ logoutHandler }) => {
    const classes = useStyles();
    const history = useHistory();


    return (
        <HashRouter history={history}>
            <div className={classes.container}>
                <InstallerNavigation logoutHandler={logoutHandler} />
                <div className={classes.content}>
                    <Switch>
                        <Route exact path='/' component={InstallerDashboard} />
                        <Route path='/sitesAssigned' render={() => <ConfigureSites />} />
                        <Route path='/configuredSite' render={() => <CompletedSites />} />
                        <Route path='/site' component={InstallerSite} />
                        <Route path='/siteSetup'>
                            <InstallerSite />
                        </Route>
                        <Route path='/site'>
                            <Site/>
                        </Route>
                        <Route path="/updateSite">
                            <UpdateSite />
                        </Route>
                        {/* <Route path='/management'>
                            <Management screen={'installer'}/>
                        </Route> */}
                        <Route path='/bays'>
                            <Bays />
                        </Route>
                        <Route path='/createBay'>
                            <CreateBay />
                        </Route>
                        <Route path='/updateBay'>
                            <UpdateBay />
                        </Route>
                        <Route path='/tanks'>
                            <Tanks />
                        </Route>
                        <Route path='/updateTank'>
                            <UpdateTank />
                        </Route>
                        <Route path='/createTank'>
                            <CreateTank />
                        </Route>
                        <Route path="/controllers">
                            <Controllers />
                        </Route>
                        <Route path="/createController">
                            <CreateController />
                        </Route>
                        <Route path="/updateController">
                            <UpdateController />
                        </Route>
                        <Route path='/createPackage'>
                            <InstallerPackage />
                        </Route>
                        <Route path="/updateInput">
                            <UpdateInput />
                        </Route>
                        <Route path='/packages'>
                            <Packages />
                        </Route>
                        <Route path='/createCycle'>
                            <InstallerCycle />
                        </Route>
                        <Route exact path='/updateCycle'>
                            <UpdateCycle />
                        </Route>
                        <Route exact path='/testSensor'>
                            <TestSensor />
                        </Route>
                        <Route path="/inventories">
                            <Inventories />
                        </Route>
                        <Route path="/inventory">
                            <ConfigureSites />
                        </Route>
                    </Switch>
                </div>
            </div>
        </HashRouter>
    )
}

export default Installer