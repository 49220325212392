import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MobileLayout from '../Layout/MobileLayout'
import React, { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
      
    },
    column: {
        flexBasis: '50%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
        textAlign: 'left'
    },
    address: {
        color: '#2e9099',
        fontSize: '14px'
    },
    accordian: {
        borderRadius: '10px',
        marginBottom: '10px',
        borderTop: '0'
    },
    details: {
        alignItems: 'left',
    },
    description: {
        fontWeight: 500,
    },
    info: {
        display: 'flex'

    },
    para: {
        marginBottom: 0,
        color: '#2e6b99',


    }
}));

export default function ControlledAccordions() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [installerId, setInstallerId] = useState();
    const [site, setSite] = useState([]);
    const [installer, setInstaller] = useState([]);
    const [siteId, setSiteId] = useState([]);
    const [selectedSite, setSelectedSite] = useState([]);
    const [address, setAddress] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            const decoded = jwtDecode(token);
            setInstallerId(decoded.id);
        }
        const fetchData = async () => {
            const site = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/site/read");
            site.data = site.data.sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1);
            const installerData = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/auth/installer");
            const address = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/auth/address");
            setInstaller(installerData.data);
            setSite(site.data);
            setAddress(address.data);
        }
        fetchData();

    }, []);
    useEffect(() => {
        for (let i = 0; i < installer.length; i++) {
            if ((installer[i].installer1 === installerId || installer[i].installer2 === installerId) && installer[i].installationStatus === "Completed") {
                setSiteId(prevState =>
                    [...prevState, installer[i].siteId]);
            }
        }
    }, [installerId, installer])
    useEffect(() => {
        setSelectedSite(site.filter(element => siteId.includes(element.siteId)))

    }, [site, siteId])

    useEffect(() => {
        for (let i = 0; i < address.length; i++)
            for (let j = 0; j < selectedSite.length; j++) {
                if (selectedSite[j].addressId === address[i].addressId) {
                    selectedSite[j].address = `${address[i].addressLine}, ${address[i].city}, ${address[i].postalCode.toUpperCase()} `;
                }

            }
    }, [selectedSite, address])


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <MobileLayout name='Sites Configured' color='blue'>
                {selectedSite.map((site, i) => (

                    <Accordion key={i} className={classes.accordian}
                        expanded={expanded === `panel${i + 1}`} onChange={handleChange(`panel${i + 1}`)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <div className={classes.column}>
                            <Typography className={classes.heading}>
                                {site.siteName}</Typography>
                            </div>
                            <div className={classes.column}>
                            <Typography className={classes.address} >{
                                site.address
                            }</Typography >
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                        <div className={classes.info} />

                        <div className={clsx(classes.helper)}>

                            <div className={classes.info}>
                                <span className={classes.description}>
                                    Site Owner :
                                </span>
                                <Typography className={classes.para}>
                                    {site.siteOwner}
                                </Typography>
                            </div>


                            <div className={classes.info}>
                                <span className={classes.description}>
                                    Phone   :
                                </span>
                                <Typography className={classes.para}>
                                    {site.phone}
                                </Typography>
                            </div>



                            <div className={classes.info}>

                                <span className={classes.description}>
                                    Email :
                                </span>
                                <Typography className={classes.para}>
                                    {site.email}
                                </Typography>
                            </div>


                        </div>
                    </AccordionDetails>
                    </Accordion >
                ))}


            </MobileLayout>

        </div>
    );
}