import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ButtonLink from "../../Util/ButtonLink";
import { updateOrder, viewOrder } from "../../../shared/api";
import moment from "moment";


const useStyles = makeStyles({
    pageContent: {
        marginTop: 40
    },
    table: {
        minWidth: 650,

        transition: "0.5s ease"
    },
    searchBar: {
        marginBottom: "30px"
    },
    tableRow: {
        transition: "0.3s ease",
        "&:hover": {
            backgroundColor: "#b8eaff !important"
        }
    },
    link: {
        transition: "0.5s ease",
        "&:hover": {
            fontWeight: 500
        }
    },
});

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.disabled
        }
    }
}))(TableRow);

const OrderRow = props => {
    const { order, isHistory, isSubmit , setIsSubmit } = props; 
    const classes = useStyles();
    const [status, setStatus] = useState(1);
    const [viewText, setViewText] = useState("Email Received");
    const [viewStatusText, setViewStatusText] = useState("Email Sent");
   

    const handleViewOrder = async (order_id) => {
        const OrderPdfURL = await viewOrder(order_id);
        //Open the URL on new Window
        window.open(OrderPdfURL);
    };

    const updateReorderStatus = (orderid, status) => {
        //fetch orders
        const ordersReply = updateOrder(orderid, status);
        if (ordersReply?.success) {
                //console.log('Order Status Updated Successfully');
        }
    }

    useEffect(async () => {
        if(status === 5)
        {
            setIsSubmit(state => {
                return (state ? false : true);
            })
        }
    },[status]);

    useEffect(async () => {
        setStatus(order?.status);
    },[isSubmit]);

    useEffect(async () => {
        let statustxt = "Order Delivered",
        view = "Email Received";

        switch (status) {
            case 1:
                view = "Email Received";
                statustxt = "Email Sent";
                break;
            case 2:
                view = "In Progress";
                statustxt = "Email Received";
                break;
            case 3:
                view = "Order Sent";
                statustxt = "In Progress";
                break;
            case 4:
                view = "Order Received";
                statustxt = "In delivery";
                break;
            default:
                view = "View";
                statustxt = "Order Delivered";
                break;
        }
      
        setViewText(view);  
        setViewStatusText(statustxt);  
    },[status]);

    const drawStatusCell = () => {
        return (viewStatusText);
    }

    const handleStatus = () => {
         if(status < 5)
            setStatus(status + 1);

        setViewText(viewText); 
        setViewStatusText(viewStatusText); 
        let localstatus = status + 1;
        updateReorderStatus(order._id, localstatus);
    }
  
    const drawViewCell = () => {
        return (
            <ButtonLink
             name = {viewText}
            color="orange"
            onClick= {e => {
                if(status < 5)
                {
                handleStatus();
                } else {
                    handleViewOrder(order._id);
                }
            }}
            />
        )
    }

    return (
        ((!isHistory && status < 5) || (isHistory && status == 5)) &&
        <StyledTableRow
            className={classes.tableRow}
            key={order._id + 'or.styledCell'}
            style={{ minWidth: 50, maxWidth: 100 }}
        >
             <TableCell key={order._id + 'or.reqno'} component="th" scope="row">
                {`REQ-${Number(order?.orderno).toString().padStart(3, '0')}`}
            </TableCell>

            <TableCell key={order._id + 'or.id'} component="th" scope="row">
                {moment(order.createDate).format("YYYY/MM/DD h:mm a")}
            </TableCell>
            <TableCell key={order._id + 'or.requestedBy'} component="th" scope="row">
                {order.requestedBy.name}
            </TableCell>
            <TableCell key={order._id + 'or.skuordered'} component="th" scope="row">
                {order?.order?.length}
            </TableCell>
    
            <TableCell key={order._id + 'or.totalcontainers'} component="th" scope="row">
                {order.totalAmount}
            </TableCell>
    
            <TableCell key={order._id + 'or.view'} component="th" scope="row">
                {drawViewCell()}
            </TableCell>
            <TableCell key={order._id + 'or.send'} component="th" scope="row">
                {drawStatusCell()}
            </TableCell>
        </StyledTableRow>
        );
};

export default OrderRow;