import React, { useState, useEffect, useContext } from "react";
import MainPage from "../../Layout/MainPage";
import Grid from "@material-ui/core/Grid";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useHistory } from "react-router";
import { SiteContext } from "../../../shared/context/site/site-context";
import { fetchAllTanks } from "../../../shared/api";
import Card from "@material-ui/core/Card";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import LightenDarkenColor from "../../../shared/uitils/colorOpacity";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonLink from "../../Util/ButtonLink";
import { Box } from "@material-ui/core";
import H6 from "@material-tailwind/react/Heading6";

const hundred = 100;
const galonInMililiters = 3785;
const lightenColorValue = 37;

const useStyles = makeStyles({
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 280,
    margin: "0 5px",
    minWidth: 278
  },
  tableCellLeft: {
    padding: "4px",
    fontSize: "1.1em",
    fontWeight: 600,
    minWidth: 90,
    textAlign: "center",
    minHeight: 30
  },
  tableCellRight: {
    padding: "4px",
    fontSize: "1.1em",
    fontWeight: 600,
    minWidth: 175,
    textAlign: "center",
    minHeight: 30
  },

  table: {
    maxWidth: 275,
    minWidth: 270
  },
  background: {
    backgroundColor: "coral"
  },
  header: {
    padding: "25px",
    height: "80px"
  }
});

const TanksLevel = props => {
  const classes = useStyles();
  const history = useHistory();
  const { site } = useContext(SiteContext);
  const [tanks, setTanks] = useState(null);
  const [date, setDate] = useState()

  let id,
    siteName = "";
  if (!history.location.state?.site_id && !site?._id) {
    id = localStorage.getItem("site_id");
    siteName = localStorage.getItem("site_name", siteName);
  } else {
    id = history.location.state?.site_id || site._id;
    siteName = history.location.state?.siteName || site.siteName;
    localStorage.setItem("site_id", id);
    localStorage.setItem("site_name", siteName);
  }

  useEffect(async () => {
    let tanks = await fetchAllTanks(id);
    setTanks(tanks);
    if(tanks.length > 0){
      setDate(tanks[0]?.dt)
    }

    return () => {
      localStorage.removeItem("site_id");
    };
  }, []);

  return (
    <MainPage name={`${siteName} Tank Levels`} color="orange">
         <H6
        style={{ margin: "0px 10px 30px" }}
        color="black"
      >
    { date && `Updated ${moment(date).format("YYYY/MM/DD, hh:mm a")}`}
    </H6>
      <Grid container spacing={2}>
        {tanks ? 
        tanks.length === 0 ? 
        (
            <H6
            style={{ margin: "0px 10px 30px" }}
            color="black"
            >
              {`${siteName} has no tanks set up`}
          </H6>
        )
        :
        (
          tanks.map(tank => {
            let levelPercentage = Math.floor(
              tank.tankLevel * hundred / (tank.size * galonInMililiters)
            );
            const startColor = tank.productColor; //"#dc143c"; // red
            const endColor = tank.productColor; //"#0cad07"; // green
            const radius = 90;
            const interpolate = interpolateRgb(startColor, endColor);
            const fillColor = interpolate(levelPercentage / 100);
            const gradientStops = [
              {
                key: "0%",
                stopColor: color(fillColor)
                  .darker(0.5)
                  .toString(),
                stopOpacity: 1,
                offset: "0%"
              },
              {
                key: "20%",
                stopColor: fillColor,
                stopOpacity: 0.75,
                offset: "20%"
              },
              {
                key: "100%",
                stopColor: color(fillColor)
                  .brighter(0.5)
                  .toString(),
                stopOpacity: 0.5,
                offset: "100%"
              }
            ];

            return (
              <Grid
                item
                lg={2}
                md={3}
                sm={4}
                xs={12}
                key={tank._id + tank.portNum}
                className={classes.gridItem}
              >
                <LiquidFillGauge
                  style={{ margin: "0 auto" }}
                  width={radius * 2}
                  height={radius * 2}
                  value={!!levelPercentage ? levelPercentage : 0}
                  percent="%"
                  textSize={1}
                  textOffsetX={0}
                  textOffsetY={0}
                  textRenderer={props => {
                    const value = Math.round(props.value);
                    const radius = Math.min(props.height / 2, props.width / 2);
                    const textPixels = props.textSize * radius / 2;
                    const valueStyle = {
                      fontSize: textPixels
                    };
                    const percentStyle = {
                      fontSize: textPixels * 0.6
                    };

                    return (
                      <tspan>
                        <tspan className="value" style={valueStyle}>
                          {value}
                        </tspan>
                        <tspan style={percentStyle}>{props?.percent}</tspan>
                      </tspan>
                    );
                  }}
                  riseAnimation
                  waveAnimation
                  waveFrequency={2}
                  waveAmplitude={1}
                  gradient
                  gradientStops={gradientStops}
                  circleStyle={{
                    fill: tank.productColor
                  }}
                  waveStyle={{
                    fill: fillColor
                  }}
                  textStyle={{
                    fill: color("#111").toString(),
                    fontFamily: "Arial"
                  }}
                  waveTextStyle={{
                    fill: color("#111").toString(),
                    fontFamily: "Arial"
                  }}
                />
                <TableContainer className={classes.table} component={Paper}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{ backgroundColor: `${tank.productColor}` }}
                        className={classes.tableCellLeft}
                      >
                        ProductSKU
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ backgroundColor: `${tank.productColor}` }}
                        className={classes.tableCellRight}
                      >
                        {tank.product}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{ backgroundColor: `${tank.productColor}` }}
                        className={classes.tableCellLeft}
                      >
                        Product Alias
                      </TableCell>
                      <TableCell
                        align="left"
                        
                        className={classes.tableCellRight}
                      >
                        {tank.productAlias}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: `${LightenDarkenColor(
                            tank.productColor,
                            lightenColorValue
                          )}`
                        }}
                        className={classes.tableCellLeft}
                      >
                        Size (gal)
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellRight}>
                        {tank.size}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: `${LightenDarkenColor(
                            tank.productColor,
                            lightenColorValue
                          )}`
                        }}
                        className={classes.tableCellLeft}
                      >
                        Level (gal)
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellRight}>
                      {(tank.tankLevel/3785.41).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: `${LightenDarkenColor(
                            tank.productColor,
                            lightenColorValue
                          )}`
                        }}
                        className={classes.tableCell}
                      >
                        Updated
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {moment(tank.dt).format("YYYY/MM/DD h:mm a")}
                      </TableCell>
                    </TableRow> */}
                  </TableBody>
                </TableContainer>
              </Grid>
            );
          })
        ) : (
          <CircularProgress disableShrink />
        )}
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <ButtonLink
          name="Back"
          onClick={() => {
            history.push({
              pathname: "/"
            });
          }}
        />
      </Box>
    </MainPage>
  );
};

export default TanksLevel;
