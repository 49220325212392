import React, { useState, useEffect, useContext } from "react";
import { authContext } from "../../../../shared/context/auth/auth-context";
import {
  TextField,
  FormControl,
  RadioGroup,
  FormLabel,
  Box,
  Link,
  InputLabel,
  Checkbox,
  FormGroup,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Paper,
  Typography,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from "@material-ui/core";
import "date-fns";
import { useHistory, useLocation } from "react-router";
import MainPage from "../../../../components/Layout/MainPage";
import { v4 } from "uuid";
import ButtonLink from "../../../Util/ButtonLink";
import axios from "axios";
import { SiteContext } from "../../../../shared/context/site/site-context";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "35ch"
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "50ch"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2)
  }
}));

const CreateBay = function FormPropsTextFields() {
  const { site, updateSiteContext } = useContext(SiteContext);
  const { user } = useContext(authContext);


  const location = useLocation();
  const BayId = v4().slice(-12);

  const _id = location.state?._id || site?._id;
  const siteId = location.state?.siteId || site?.siteId;
  const CarWashName = location.state?.siteName || site?.siteName;
  const CarWashOwner = location.state?.siteOwner || site?.siteOwner;

  const classes = useStyles();
  const history = useHistory();
  const [BayName, setBayName] = React.useState("");
  const [BayDescription, setBayDescription] = React.useState("");
  const [WashType, setWashType] = React.useState("");
  const [ControllerSelect, setControllerSelect] = useState();
  const [Controllers, setControllers] = useState([]);
  let PackageList = [];
  const [Packages, setPackages] = useState([]);
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState({
    bayname:''
    });

  const [checked, setChecked] = React.useState([0]);

  useEffect(() => {
    (async ()=>{
      const _id = location.state?._id ? location.state?._id : 
      site?._id ? site?._id : localStorage.getItem("site_id");
      await updateSiteContext(_id);
    })();

    return () => {
      (async ()=>{
        const _id = location.state?._id ? location.state?._id : 
        site?._id ? site?._id : localStorage.getItem("site_id");
        await updateSiteContext(_id);
      })();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      var id = "";
      const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/site/readone/" + _id;
      const result = await axios.get(readURL);
      id = result.data.siteId;
      //console.log(id);
      const readURL1 =
        process.env.REACT_APP_BACKEND_LINK + "api/controller/read/" + id;
      const result1 = await axios.get(readURL1);
      setControllers(result1.data);
    };
    fetchData();
  }, [site]);

  useEffect(() => {
    const fetchData = async () => {
      const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/package/read/" + _id;
      const result = await axios.get(readURL);
      setPackages(result.data);
    };
    fetchData();
  }, []);

  const handleChange = event => {
    setWashType(event.target.value);
  };

  // const handleCheckChange = (event) => {
  //   setCheck({ ...CheckStates, [event.target.name]: event.target.checked });
  // };

  const handleSelectChange = event => {
    if (event.target.name === "ControllerSelect") {
      setControllerSelect(event.target.value);
    }
  };

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const bayValidation = async e => 
  {
    let temp={};
    temp.bayname = BayName?"":"Please enter the Bay Name";
   
    setErrors({
       ...temp
     })
     
      return Object.values(temp).every(x => x === "");
  }

  async function handleSubmit() {
    PackageList = [];
    checked.forEach(element => {
      if (element.packageName !== undefined)
        PackageList.push(element.packageName);
    });
   
    let body = {
      bayId: BayId,
      bayName: BayName,
      bayDescription: BayDescription,
      washType: WashType,
      siteId: _id,
      controllerId: ControllerSelect,
      packageList: PackageList
    };
    //console.log(body);
    bayValidation().then(function(result) {
    if(result)
    {
      let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/bay/create", {
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem("authToken")}`
      }
    }).then(
      response => response.json(), 
    )

    response.then(function(result) {
     if(result.errors && result.errors && result.errors[0].msg.includes(""))
      {
      setError(result.errors[0].msg);
      setTimeout(() => {
        setError("");
        }, 5000);
    } else {
    history.push({
      pathname: "/bays/",
      state: {
        _id: _id,
        siteId: siteId,
        siteName: CarWashName,
        siteOwner: CarWashOwner
      }
    });
    }
  });
    }
  });

  try {
    await axios.post(
      process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Bay ${BayName} Created at ${CarWashName}`, category: "Create Bay" }
    )
  }
  catch (error) {
    console.log(error)
  }
  }

  function handleCancel() {
    history.push({
      pathname: "/bays/",
      state: {
        _id: _id,
        siteId: siteId,
        siteName: CarWashName,
        siteOwner: CarWashOwner
      }
    });
  }

  return (
    <MainPage name={"Create Bay"}>
      <form className={classes.root} noValidate autoComplete="off">
        <div id="bayInfo">
        {error && <Alert severity="error">{error}</Alert>}
          <Typography align="center" variant="h5">
            Bay Info.
          </Typography>
          <TextField
            required
            id="BayName"
            style={{ margin: "20px" }}
            label="Bay Name"
            value={BayName}
            onInput={e => setBayName(e.target.value)}
            error={errors.bayname}
            helperText= {errors.bayname}
          />   
          <TextField
            id="BayDescription"
            style={{ margin: "20px" }}
            label="Bay Description"
            value={BayDescription}
            onInput={e => setBayDescription(e.target.value)}
          />
            <FormControl
            className={classes.formControl}
            style={{ margin: "20px" }}
          >
            <InputLabel id="ControllerSelect">Controller</InputLabel>
            <Select
              name="ControllerSelect"
              labelId="ControllerSelect"
              id="ControllerSelect"
              value={ControllerSelect ? ControllerSelect : ''}
              onChange={handleSelectChange}
            >
              {Controllers.map(row => (
                <MenuItem value={row._id}>
                  {row.controllerMacAddress}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <FormControl component="fieldset" style={{ margin: "20px" }}>
            <FormLabel component="legend">Wash Type</FormLabel>
            <RadioGroup
              aria-label="Wash Type"
              name="washType"
              value={WashType}
              onChange={handleChange}
            >
              <FormControlLabel
                value="tunnel"
                control={<Radio />}
                label="Tunnel"
              />
              <FormControlLabel
                value="automatic"
                control={<Radio />}
                label="In-Bay Automatic"
              />
            </RadioGroup>
          </FormControl>
        
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Packages in the bay:
              </ListSubheader>
            }
            className={classes.root}
          >
            {Packages.map(value => {
              const labelId = `checkbox-list-label-${value.packageId}`;

              return (
                <ListItem
                  key={value}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value.packageName}`} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </form>
      <Box display="flex" justifyContent="flex-end">
        <ButtonLink onClick={handleCancel} color="blue" name="cancel" />
        <ButtonLink onClick={handleSubmit} color="pink" name="submit" />
      </Box>
    </MainPage>
  );
};

export default CreateBay;
