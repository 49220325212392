import React, { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import TableRow from "@material-ui/core/TableRow";
import Cellinput from "./Cellinput";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles({

    tableRow: {
        transition: "0.3s ease",
        "&:hover": {
            backgroundColor: "#b8eaff !important"
        }
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.disabled
        }
    }
}))(TableRow);


const TableRowProduct = props => {
    const { reorderProduct, setUpdatedOrders} = props;
    const classes = useStyles();

    const handleautoFillChange = (pId, value) => (
        setUpdatedOrders((state) => ({
            ...state,
            [pId]: { ...state[pId], autoFillOrder: +value }
        })
        ));

    return (
        <StyledTableRow
            className={classes.tableRow}
            key={reorderProduct.tankdId}
            style={{ minWidth: 50, maxWidth: 100 }}
        >
            <TableCell key={reorderProduct.tankdId + 'row.product'} component="th" scope="row">
                {reorderProduct.product}
            </TableCell>
            <TableCell key={reorderProduct.tankdId + 'row.stock'}>
                {reorderProduct.stock}
            </TableCell>
            <TableCell
                key={reorderProduct.tankdId + 'row.Cellinput'}
                align="left"
                className={classes.tableCellRight}
                style={{ fontSize: "1.5em" }}
            >
                <Cellinput key={reorderProduct.tankdId + 'row.CellinputCom'}
                    productId={reorderProduct.product_id}
                    low={reorderProduct.low}
                    stock={reorderProduct.stock}
                    autoFill={reorderProduct.autoFillOrder}
                    setOrders={setUpdatedOrders}
                />
                    
            </TableCell>
            <TableCell
                key={reorderProduct.tankdId + 'row.autoFillOrder'}
                align="left"
                className={classes.tableCellRight}
                style={{ fontSize: "1.5em" }}
            >
                <TextField
                    key={reorderProduct.tankdId + 'row.textField'}
                    type="number"
                    defaultValue={reorderProduct.autoFillOrder}
                    style={{ maxWidth: 75 }}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    onChange={event => {
                        if (event.target.value >= 0) {
                            handleautoFillChange(reorderProduct.product_id, event.target.value);
                        } else {
                            event.target.value = 0;
                        }
                    }}
                />
            </TableCell>
            <TableCell key={reorderProduct.tankdId + 'row.minStockAlert'}>
                {reorderProduct.minStockAlert}
            </TableCell>
        </StyledTableRow>
    );
};

export default TableRowProduct;