import { Typography } from '@material-ui/core'
import React from 'react'
import brand from './True-Vue 2 Logos-04.png'
const Logo = (props) => {
    return (
        <Typography className={props.className}>
            <img src={brand}  width={200} alt='True vue icon' />
        </Typography>
    )
}

export default Logo
