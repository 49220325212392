import axios from "axios";

export const createOrder = async (siteId, userId, orderProducts) => {
    const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/reorder/create/" + siteId;
    let data = JSON.stringify({
        userId,
        orderProducts
    });

    let config = {
        method: "post",
        url: readURL,
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    };
    const order = await axios(config);
    return order.data;
};

export const readAllOrders = async (siteId) => {
    const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/reorder/read/" + siteId;
    let data = JSON.stringify({});

    let config = {
        method: "get",
        url: readURL,
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    };
    const orders = await axios(config);
    return orders.data;
};

export const updateOrder = async (orderId, status) => {
    //console.log('status in status=', status);
    const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/reorder/update/" + orderId;
    let data = JSON.stringify({status});

    let config = {
        method: "put",
        url: readURL,
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    };
    const orderUpdated = await axios(config);
    return orderUpdated.data;
};

export const deleteOrder = async (orderId) => {
    const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/reorder/delete/" + orderId;
    let data = JSON.stringify({});

    let config = {
        method: "post",
        url: readURL,
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    };
    const orderDeleted = await axios(config);
    return orderDeleted.data;
};

export const sendOrderEmail = async (orderId) => {
    const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/reorder/sendReorderEmail/" + orderId;
   
    let data = JSON.stringify({});

    let config = {
        method: "post",
        url: readURL,
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    };
    const orderEmailed = await axios(config);
    return orderEmailed.data;
};

export const viewOrder = async (orderId) => {
    const readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/reorder/view-order/" + orderId;
    let data = JSON.stringify({});

    let config = {
        method: "post",
        url: readURL,
        headers: {
            "Content-Type": "application/json"
        },
        responseType: 'blob'
        
    };
    const orderPdf = await axios(config)
    //Create a Blob from the PDF Stream
    const file = new Blob(
        [orderPdf.data],
        { type: 'application/pdf' });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    
    return fileURL;
};