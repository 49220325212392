import React, { useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useHistory } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const MAP_MARKER =
  "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";
const libraries = ["places"];

const Map = (props) => {
  const [selectedPark, setSelectedPark] = useState(null);
  const history = useHistory();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_APIS_KEY,
    libraries,
  });

  if (loadError) return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="error">Error occurred when loading the map!</Alert>
    </Stack>
  );

  return (isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={props.mapContainerStyle}
        zoom={props.zoom}
        center={props.center}
        options={props.options}
      >
        {props.points?.map((marker, i) => (
          <Marker
            key={i}
            position={{ lat: marker.lat, lng: marker.lng }}
            icon={{
              path: MAP_MARKER,
              scale: 1.4, // > 1 to make it bigger
              fillColor: "rgba(100,200,256,1)", // Use RGBa function
              fillOpacity: 1,
              strokeColor: "hsla(291,47%,51%,1)", // Use HSLa function

              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(0, 0),
            }}
            onClick={() => {
              props.site.forEach((site) => {
                if (site?.addressId === marker?.id) {
                  history.push({
                    pathname: "/site/",
                    search: site._id,
                    state: {
                      _id: site._id,
                      siteId: site.siteId,
                      siteName: site.siteName,
                      siteOwner: site.siteOwner,
                    },
                  });
                }
                
              });
            }}
            onMouseOver={() => {
              props.site.forEach((site) => {
                if (site?.addressId === marker?.id) {
                  marker.siteName = site.siteName;
                }
              });
              props.address.forEach((addr) => {
                if (addr.addressId === marker?.id) {
                  marker.address = `${addr.addressLine}, ${addr.city}, ${addr.country}`;
                }
              });
              setSelectedPark(marker);
            }}
            onMouseOut={() => {
              setSelectedPark(null);
            }}
          />
        ))}

        {selectedPark && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedPark(null);
            }}
            position={selectedPark}
          >
            <h6>{`${selectedPark.siteName} (${selectedPark.address})`}</h6>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  ) 
  : 
  (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  )
  );
};

export default Map;
