import React, { useEffect, useState, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { TableContainer, Link, Box } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import { useHistory } from "react-router";
import MainPage from "../Layout/MainPage";
import SubPage from "../Layout/SubPage";
import SiteData from "./SiteData";
import ButtonLink from "../Util/ButtonLink";
import axios from "axios";
import { authContext } from "../../shared/context/auth/auth-context";
import {useGetUserSites} from '../../hooks/mutations/useGetUserSites';

const useStyles = makeStyles({
    table: {
        minWidth: 650,

        transition: "0.5s ease"
    },
    searchBar: {
        marginBottom: "30px"
    },
    tableRow: {
        transition: "0.3s ease",
        "&:hover": {
            backgroundColor: "#b8eaff !important"
        }
    },
    link: {
        transition: "0.5s ease",
        "&:hover": {
            fontWeight: 500
        }
    }
});
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.disabled
        }
    }
}))(TableRow);
const Sites = props => {
    const history = useHistory();
    const classes = useStyles();
    const { user } = useContext(authContext);
    const {mutateAsync: getUserSites} = useGetUserSites()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // search bar hooks and methods
    const [searched, setSearched] = useState("");
    const [rows, setRows] = useState(SiteData.originalRows);
    const [users, setUsers] = useState([]);
    const [installers, setInstaller] = useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filteredRows = rows.filter(row => {
        return row?.siteName?.toLowerCase().includes(searched.toLowerCase());
    });
    const cancelSearch = () => {
        setSearched("");
    };

    useEffect(() => {
        (async ()=>{
            let sites = []
            if(user?.role === 'admin'){
                const sitesData = await axios.get(
                    process.env.REACT_APP_BACKEND_LINK + "api/site/read"
                );
    
                if(sitesData?.data) sites = sitesData?.data;
            } 
            else if (user?.role === 'distributor') {
                const sitesData = await getUserSites({userId: user?.id});

                if(sitesData) sites = sitesData;
            };


            const data = sites.sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1);
            
            setRows(data);
        })();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const users = await axios.get(
                process.env.REACT_APP_BACKEND_LINK + "api/auth/users"
            );
            const installer = await axios.get(
                process.env.REACT_APP_BACKEND_LINK + "api/auth/installer"
            );
            setUsers(users.data);
            setInstaller(installer.data);
        };
        fetchData();
    }, []);

    return (
        <>
            <MainPage name={"SITES"} color="teal">
                <SearchBar
                    className={classes.searchBar}
                    onChange={searchVal => setSearched(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                />
                {/* Table */}
                <TableContainer component={Paper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {SiteData.columns.map(column => (
                                    <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(row => (
                                    <StyledTableRow className={classes.tableRow} key={row._id}>
                                        <TableCell component="th" scope="row">
                                            <Link
                                                style={{ cursor: "pointer" }}
                                                className={classes.link}
                                                href={row.id}
                                                onClick={e => {
                                                   
                                                    e.preventDefault();
                                                    history.push({
                                                        pathname: "/site/",
                                                        search: row._id,
                                                        state: {
                                                            _id: row._id,
                                                            siteId: row.siteId,
                                                            siteName: row.siteName,
                                                            siteOwner: row.siteOwner
                                                        }
                                                    });
                                                }}
                                                color="inherit"
                                                underline="none"
                                            >
                                                {row.siteName}
                                            </Link>
                                        </TableCell>

                                        <TableCell>{row.siteOwner}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>
                                            {installers.map((installer, i) => {
                                                return (
                                                    installer.siteId === row.siteId && (
                                                        <>
                                                            <div key={i}>
                                                                {users.map((user, i) => {
                                                                    return (
                                                                        user._id === installer.installer1 && (
                                                                            <div key={i}> {user.name}</div>
                                                                        )
                                                                    );
                                                                })}
                                                            </div>

                                                            <div>
                                                                {users.map((user, i) => {
                                                                    return (
                                                                        user._id === installer.installer2 && (
                                                                            <div key={i}> {user.name}</div>
                                                                        )
                                                                    );
                                                                })}
                                                            </div>
                                                        </>
                                                    )
                                                );
                                            })}
                                        </TableCell>
                                        <TableCell>{installers.map((installer, i) => {
                                             return (
                                                installer.siteId === row.siteId && (
                                                 <>
                                                 {installer.installationStatus=="Completed"? "Yes":"No"}
                                                 </>
                                                )
                                            );
                                       
                                        })}</TableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            {user?.role === 'admin' &&
            
                <Box display="flex" justifyContent="flex-end">
                <ButtonLink
                    onClick={() => {
                        history.push("/createSite");
                    }}
                    color="pink"
                    name="Create New Site"
                    />
                </Box>
            }
            </MainPage>
        </>
    );
};

export default Sites;
