  import React, { useEffect, useContext } from "react";
import { authContext } from "../../../shared/context/auth/auth-context";


import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Paper,
  Typography,
  Button,
  Box
} from "@material-ui/core";
import ReactSelect, { components } from 'react-select';
import DropdownIndicator, { selectStyles } from "../../Util/DropdownIndicator";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { useHistory, useLocation } from "react-router";
import MainPage from "../../Layout/MainPage";
import { v4 } from "uuid";
import ButtonLink from "../../Util/ButtonLink";
import axios from "axios";
import { FormHelperText } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Geocode from "react-geocode";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "25ch"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2)
  }
}));

const UpdateSite = function FormPropsTextFields() {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();

  let Site_Id,
    siteId,
    AddressId = null;
  if (!location.state?._id) {
    Site_Id = localStorage.getItem("Site_Id");
    siteId = localStorage.getItem("siteId");
    AddressId = localStorage.getItem("addressId");
  } else {
    Site_Id = location.state._id;
    siteId = location.state.siteId;
    AddressId = location.state.addressId;

    localStorage.setItem("Site_Id", Site_Id);
    localStorage.setItem("siteId", siteId);
    localStorage.setItem("addressId", AddressId);
  }
  const { user } = useContext(authContext);
  const [CarWashName, setCarWashName] = React.useState("");
  const [CarWashOwner, setCarWashOwner] = React.useState("");
  const [CarWashContact, setCarWashContact] = React.useState("");
  const [CarWashPhone, setCarWashPhone] = React.useState("");
  const [CarWashEmail, setCarWashEmail] = React.useState("");
  const [CarWashAddress, setCarWashAddress] = React.useState("");
  const [BayNum, setBayNum] = React.useState("");
  const [City, setCity] = React.useState("");
  const [Postalcode, setPostalcode] = React.useState("");
  const [Country, setCountry] = React.useState("");
  const [DistributorName, setDistributorName] = React.useState("");
  const [Province, setProvince] = React.useState("");
  const [ProvinceLabel, setProvinceLabel] = React.useState('Province or State');
  const [PostalcodeLabel, setPostalcodeLabel] = React.useState('Postal Code or Zip Code');
  const [Regions, setRegions] = React.useState([]); 
  const [WashPackageOri, setWashPackageOri] = React.useState("");

  const [installer, SetInstaller] = React.useState([]);
  const [Installer1, setInstaller1] = React.useState("");
  const [Installer2, setInstaller2] = React.useState("");
  const [InstallationStatus, setInstallationStatus] = React.useState("");
  const [WashSys, setWashSys] = React.useState("");
  const [distributors, setDistributors] = React.useState([]);
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState({
    carwashname: '', 
    carwashowner: '',
    carwashcontact: '',
    carwashphone: '',
    carwashemail:'',
    carwashaddress:'',
    city:'',
    postalcode:'',
    country:'',
    distributorname:'',
    numberofbays:''
    }); 

  const [CheckStates, setCheck] = React.useState({
    tunnel: false,
    auto: false
  });
  const [selectedDate, setSelectedDate] = React.useState("");

  const { tunnel, auto } = CheckStates;

  React.useEffect(() => {
    const fetchData = async () => {
      let readURL1 =
        process.env.REACT_APP_BACKEND_LINK + "api/site/readone/" + Site_Id;
      let result = await axios.get(readURL1);
      setCarWashName(result.data.siteName);
      setCarWashOwner(result.data.siteOwner);
      setCarWashContact(result.data.contactPerson);
      setCarWashPhone(result.data.phone);
      setCarWashEmail(result.data.email);
      // setAddressId(result.data.addressId);
      setBayNum(result.data.bayNumber);
      setWashSys(result.data.trueVueSystem);
      setWashPackageOri(result.data.washPackage);
      setDistributorName(result.data.distributorId);
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_LINK + 'api/distributor/read',
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        }
    )
        .then(res => {
            return res.json();
        }).then((data) => {
            setDistributors(data);
         //   setDistributorName(data.filter(row => (row._id === distributorId)).distributorName);
        })
}, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const readURL1 =
        process.env.REACT_APP_BACKEND_LINK +
        "api/site/readAddress/" +
        AddressId;
      const result = await axios.get(readURL1);
      setCity(result.data.city);
      setPostalcode(result.data.postalCode);
      setCarWashAddress(result.data.addressLine);
      setCountry(result.data.country);
      if(result.data.country == 'USA')
      {
          setProvinceLabel("State");
          setRegions([
            {name: 'Alabama', value: 'Alabama'},
            {name: 'Alaska', value: 'Alaska'},
            {name: 'Arizona', value: 'Arizona'},
            {name: 'Arkansas', value: 'Arkansas'},
            {name: 'California', value: 'California'},
            {name: 'Colorado', value: 'Colorado'},
            {name: 'Connecticut', value: 'Connecticut'},
            {name: 'Delaware', value: 'Delaware'},
            {name: 'Florida', value: 'Florida'},
            {name: 'Georgia', value: 'Georgia'},
            {name: 'Hawaii', value: 'Hawaii'},
            {name: 'Idaho', value: 'Idaho'},
            {name: 'Illinois', value: 'Illinois'},
            {name: 'Indiana', value: 'Indiana'},
            {name: 'Iowa', value: 'Iowa'},
            {name: 'Kansas', value: 'Kansas'},
            {name: 'Kentucky', value: 'Kentucky'},
            {name: 'Louisiana', value: 'Louisiana'},
            {name: 'Maine', value: 'Maine'},
            {name: 'Maryland', value: 'Maryland'},
            {name: 'Massachusetts', value: 'Massachusetts'},
            {name: 'Michigan', value: 'Michigan'},
            {name: 'Minnesota', value: 'Minnesota'},
            {name: 'Mississippi', value: 'Mississippi'},
            {name: 'Missouri', value: 'Missouri'},
            {name: 'Montana', value: 'Montana'},
            {name: 'Nebraska', value: 'Nebraska'},
            {name: 'Nevada', value: 'Nevada'},
            {name: 'New Hampshire', value: 'New Hampshire'},
            {name: 'New Jersey', value: 'New Jersey'},
            {name: 'New Mexico', value: 'New Mexico'},
            {name: ' North Carolina', value: 'North Carolina'},
            {name: 'North Dakota', value: 'North Dakota'},
            {name: 'Ohio', value: 'Ohio'},
            {name: 'Oklahoma', value: 'Oklahoma'},
            {name: 'Oregon', value: 'Oregon'},
            {name: 'Pennsylvania', value: 'Pennsylvania'},
            {name: 'Rhode Island', value: 'Rhode Island'},
            {name: 'South Carolina', value: 'South Carolina'},
            {name: 'South Dakota', value: 'South Dakota'},
            {name: 'Tennessee', value: 'Tennessee'},
            {name: 'Texas', value: 'Texas'},
            {name: 'Utah', value: 'Utah'},
            {name: 'Vermont', value: 'Vermont'},
            {name: 'Virginia', value: 'Virginia'},
            {name: 'Washington', value: 'Washington'},
            {name: 'West Virginia', value: 'West Virginia'},
            {name: 'Wisconsin', value: 'Wisconsin'},
            {name: 'Wyoming', value: 'Wyoming'},
          ]);
          setPostalcodeLabel("Zip Code");
        } else {
          setProvinceLabel("Province");
          setRegions([
            {name: 'Alberta', value: 'Alberta'},
            {name: 'British Columbia', value: 'British Columbia'},
            {name: 'Manitoba', value: 'Manitoba'},
            {name: 'New Brunswick', value: 'New Brunswick'},
            {name: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador'},
            {name: 'Northwest Territories', value: 'Northwest Territories'},
            {name: 'Nova Scotia', value: 'Nova Scotia'},
            {name: 'Nunavut', value: 'Nunavut'},
            {name: 'Ontario', value: 'Ontario'},
            {name: 'Prince Edward Island', value: 'Prince Edward Island'},
            {name: 'Quebec', value: 'Quebec'},
            {name: 'Saskatchewan', value: 'Saskatchewan'},
            {name: 'Yukon', value: 'Yukon'}
          ]
        );
          //The U.S.: 50 States: Alabama, Alaska, Arizona, Arkansas, California, Colorado, Connecticut, Delaware, Florida, Georgia, Hawaii, Idaho, Illinois, (
          setPostalcodeLabel("Postal Code");
        }
      setProvince(result.data.province);
    };
    fetchData();
  }, []);



  React.useEffect(() => {
    const fetchData = async () => {
      const readURL1 =
        process.env.REACT_APP_BACKEND_LINK +
        "api/site/readInstallations/" +
        siteId;
      const result = await axios.get(readURL1);
      setInstallationStatus(result.data.installationStatus);
      setInstaller1(result.data.installer1);
      setInstaller2(result.data.installer2);
      setSelectedDate(result.data.installationDate);
    };
    fetchData();
  }, []);

  const handleChange = event => {
    if (event.target.name === "DistributorName") setDistributorName(event.target.value);
    if (event.target.name === "Province") setProvince(event.target.value);
    if (event.target.name === "Country") 
    {
      setCountry(event.target.value);
      if(event.target.value == 'USA') 
      {
        setProvinceLabel("State");
        setRegions([
          {name: 'Alabama', value: 'Alabama'},
          {name: 'Alaska', value: 'Alaska'},
          {name: 'Arizona', value: 'Arizona'},
          {name: 'Arkansas', value: 'Arkansas'},
          {name: 'California', value: 'California'},
          {name: 'Colorado', value: 'Colorado'},
          {name: 'Connecticut', value: 'Connecticut'},
          {name: 'Delaware', value: 'Delaware'},
          {name: 'Florida', value: 'Florida'},
          {name: 'Georgia', value: 'Georgia'},
          {name: 'Hawaii', value: 'Hawaii'},
          {name: 'Idaho', value: 'Idaho'},
          {name: 'Illinois', value: 'Illinois'},
          {name: 'Indiana', value: 'Indiana'},
          {name: 'Iowa', value: 'Iowa'},
          {name: 'Kansas', value: 'Kansas'},
          {name: 'Kentucky', value: 'Kentucky'},
          {name: 'Louisiana', value: 'Louisiana'},
          {name: 'Maine', value: 'Maine'},
          {name: 'Maryland', value: 'Maryland'},
          {name: 'Massachusetts', value: 'Massachusetts'},
          {name: 'Michigan', value: 'Michigan'},
          {name: 'Minnesota', value: 'Minnesota'},
          {name: 'Mississippi', value: 'Mississippi'},
          {name: 'Missouri', value: 'Missouri'},
          {name: 'Montana', value: 'Montana'},
          {name: 'Nebraska', value: 'Nebraska'},
          {name: 'Nevada', value: 'Nevada'},
          {name: 'New Hampshire', value: 'New Hampshire'},
          {name: 'New Jersey', value: 'New Jersey'},
          {name: 'New Mexico', value: 'New Mexico'},
          {name: ' North Carolina', value: 'North Carolina'},
          {name: 'North Dakota', value: 'North Dakota'},
          {name: 'Ohio', value: 'Ohio'},
          {name: 'Oklahoma', value: 'Oklahoma'},
          {name: 'Oregon', value: 'Oregon'},
          {name: 'Pennsylvania', value: 'Pennsylvania'},
          {name: 'Rhode Island', value: 'Rhode Island'},
          {name: 'South Carolina', value: 'South Carolina'},
          {name: 'South Dakota', value: 'South Dakota'},
          {name: 'Tennessee', value: 'Tennessee'},
          {name: 'Texas', value: 'Texas'},
          {name: 'Utah', value: 'Utah'},
          {name: 'Vermont', value: 'Vermont'},
          {name: 'Virginia', value: 'Virginia'},
          {name: 'Washington', value: 'Washington'},
          {name: 'West Virginia', value: 'West Virginia'},
          {name: 'Wisconsin', value: 'Wisconsin'},
          {name: 'Wyoming', value: 'Wyoming'},
        ]);
        setPostalcodeLabel("Zip Code");
        setProvince("New Mexico");
      } else {
        setProvinceLabel("Province");
        setRegions([
          {name: 'Alberta', value: 'Alberta'},
          {name: 'British Columbia', value: 'British Columbia'},
          {name: 'Manitoba', value: 'Manitoba'},
          {name: 'New Brunswick', value: 'New Brunswick'},
          {name: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador'},
          {name: 'Northwest Territories', value: 'Northwest Territories'},
          {name: 'Nova Scotia', value: 'Nova Scotia'},
          {name: 'Nunavut', value: 'Nunavut'},
          {name: 'Ontario', value: 'Ontario'},
          {name: 'Prince Edward Island', value: 'Prince Edward Island'},
          {name: 'Quebec', value: 'Quebec'},
          {name: 'Saskatchewan', value: 'Saskatchewan'},
          {name: 'Yukon', value: 'Yukon'}
        ]
      );
        //The U.S.: 50 States: Alabama, Alaska, Arizona, Arkansas, California, Colorado, Connecticut, Delaware, Florida, Georgia, Hawaii, Idaho, Illinois, (
        setPostalcodeLabel("Postal Code");
        setProvince("Ontario");
      }
    }
    if (event.target.name === "Installer1") {
      setInstaller1(event.target.value);
      //console.log(event.target.value);
    }
    if (event.target.name === "Installer2") setInstaller2(event.target.value);
    if (event.target.name === "InstallationStatus")
      setInstallationStatus(event.target.value);
    if (event.target.name === "washSys") setWashSys(event.target.value);

    setCheck({ ...CheckStates, [event.target.name]: event.target.checked });
  };
  const handleDateChange = date => {
    setSelectedDate(date);
  };


  const validatePhone = phone => {
    return phone.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
  }
  const validatePostal = postal =>{
    return postal.match(/^[A-Z]\d[A-Z][ -]?\d[A-Z]\d$/)
  }
  const validateZipPostal = postal =>{
    return postal.match(/^\d{5}(?:[-\s]\d{4})?$/)
  }


  const validateEmail = email => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

  const siteValidation = async e => 
  {
    let temp={};
    temp.carwashname = CarWashName?"":"Please enter the Car Wash Name";
    temp.carwashowner = CarWashOwner?"":"Please enter the Car Wash Owner";
    temp.carwashcontact = CarWashContact?"":"Please enter the Car Wash Contact";
    temp.carwashphone = CarWashPhone?"":"Please enter the Phone";
    temp.carwashemail = CarWashEmail?"":"Please enter the Email";
    temp.carwashaddress = CarWashAddress?"":"Please enter the Address";
    temp.distributorname = DistributorName ? "" : "Please select Distributor Name";
    temp.city = City?"":"Please enter the City";
    temp.postalcode = Postalcode?"":"Please enter the Postal Code";
    if(PostalcodeLabel ==="Postal Code"){  
      if(Postalcode===""){
        temp.postalcode = Postalcode?"":"Please enter the Postal Code";
      }
      else{
    temp.postalcode = validatePostal(Postalcode)?"":"Please enter a valid Postal Code";
  }
}
else if(PostalcodeLabel ==="Zip Code"){
  if(Postalcode){
    temp.postalcode = validateZipPostal(Postalcode)?"":"Please enter a valid Zip Code";
}
else{
  temp.postalcode = "Please enter the Zip Code";
}
}
else{
  temp.postalcode = "Please enter the Postal or Zip Code";
}
    temp.country = Country?"":"Please select the Country";
    //console.log("numberofbays:", temp.numberofbays);
    temp.numberofbays = BayNum?BayNum>0?"":"Please enter the postive bays number":"";
    //console.log("numberofbays1:", temp.numberofbays);
    if(temp.carwashemail === "" && CarWashEmail)
      temp.carwashemail = validateEmail(CarWashEmail)?"":"Please enter valid email";
    if(temp.carwashphone === "" && CarWashPhone)
      temp.carwashphone = validatePhone(CarWashPhone)?"":"Please enter valid phone";
  
    setErrors({
       ...temp
     })
     
      return Object.values(temp).every(x => x === "");
  }

  async function handleSubmit() {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_APIS_KEY);

    // // set response language. Defaults to english.
    Geocode.setLanguage("en");

    // // set response region. Its optional.
    // // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("es");
    let address = `${CarWashAddress}, ${City}, ${Postalcode}, ${Province}, ${Country}`;

    const result = await Geocode.fromAddress(address);
    let lat = result.results[0].geometry.location?.lat;
    let lng = result.results[0].geometry.location?.lng;
    var WashPackage = WashPackageOri;
    // console.log('Your UUID is: ' + AddressId);
    if (CheckStates.tunnel === true || CheckStates.auto === true) {
      WashPackage = "";
      if (CheckStates.tunnel === true) WashPackage = "tunnel ";
      if (CheckStates.auto === true) WashPackage += "auto";
    }

    let body = {
      Site_Id,
      siteId: siteId,
      siteName: CarWashName,
      siteOwner: CarWashOwner,
      email: CarWashEmail,
      phone: CarWashPhone,
      contactPerson: CarWashContact,
      addressId: AddressId,
      addressLine: CarWashAddress,
      city: City,
      postalCode: Postalcode,
      lat: lat,
      lng: lng,
      province: Province,
      country: Country,
      bayNumber: BayNum,
      trueVueSystem: WashSys,
      washPackage: WashPackage,

      distributorId: DistributorName,

      installationDate: selectedDate,
      installer1: Installer1,
      installer2: Installer2,
      installationStatus: InstallationStatus
    };
    //console.log(body);

    // console.log('going to call siteValidation');
    siteValidation().then(function(result) {
      // console.log("result =", result);
      if(result)
      {
      let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/site/update", {
          method: "POST",
          body: JSON.stringify(body),
          mode: "cors",
          headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem("authToken")}`
          }
        }).then(response => response.json(),)

        response.then(function(result)
        {
        if(result.errors && result.errors[0].msg.includes(""))
        {
          setError(result.errors[0].msg);
          setTimeout(() => { setError("") }, 5000);
        }
        else
        {
        history.push({
          pathname: "/site/",
          state: {
            _id: Site_Id,
            siteId: siteId,
            siteName: CarWashName,
            siteOwner: CarWashOwner
          }
        });
        }
        });
      }
      });
    
      try {
            await axios.post(
              process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Site ${CarWashName} Updated`, category: "Update Site" }
            )
          }
          catch (error) {
            console.log(error)
          }
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_BACKEND_LINK + "api/auth/users"
      );
      //console.log(result.data);
      for (let i = 0; i < result.data.length; i++) {
        if (result.data[i].role === "installer") {
          SetInstaller(oldArray => [...oldArray, result.data[i]]);
        }
      }
    };
    fetchData();
  }, []);

  function handleCancel() {
    history.push({
      pathname: "/site/",
      state: {
        _id: Site_Id,
        siteId: siteId,
        siteName: CarWashName,
        addressId: AddressId,
        siteOwner: CarWashOwner
      }
    });
  }

  return (
    <MainPage name={"Update Site"}>
      <form className={classes.root} noValidate autoComplete="off">
        <div id="siteBasic">
          {error && <Alert severity="error">{error}</Alert>}
          <Typography align="center" variant="h5">
            Site Basic Info.
          </Typography>
          <TextField
            required
            id="carWashName"
            InputLabelProps={{ shrink: true }}
            label="Car Wash Name"
            value={CarWashName}
            onInput={e => setCarWashName(e.target.value)}
            error={errors.carwashname}
            helperText= {errors.carwashname}
          />
          <TextField
            required
            id="carWashOwner"
            InputLabelProps={{ shrink: true }}
            label="Company"
            value={CarWashOwner}
            onInput={e => setCarWashOwner(e.target.value)}
            error = {errors.carwashowner}
            helperText = {errors.carwashowner}
          />
          <TextField
            required
            id="carWashContact"
            label="Car Wash Contact"
            InputLabelProps={{ shrink: true }}
            value={CarWashContact}
            onInput={e => setCarWashContact(e.target.value)}
            error = {errors.carwashcontact}
            helperText = {errors.carwashcontact}
          />
          <TextField
            required
            id="carWashPhone"
            label="Phone"
            InputLabelProps={{ shrink: true }}
            value={CarWashPhone}
            onInput={e => setCarWashPhone(e.target.value)}
            error = {errors.carwashphone}
            helperText = {errors.carwashphone}
          />
          <TextField
            required
            id="carWashEmail"
            label="Email"
            InputLabelProps={{ shrink: true }}
            value={CarWashEmail}
            onInput={e => setCarWashEmail(e.target.value)}
            error = {errors.carwashemail}
            helperText = {errors.carwashemail}
          />
            <FormControl required className={classes.formControl}>
            <InputLabel id="Country" error = {errors.country} InputLabelProps={{ shrink: true }}>
              Country
            </InputLabel>
            <Select
              name="Country"
              labelId="Country"
              id="Country"
              value={Country}
              onChange={handleChange}
              error = {errors.country}
            >
              <MenuItem value={"Canada"}>Canada</MenuItem>
              <MenuItem value={"USA"}>USA</MenuItem>
            </Select>
            {(errors.country) && <FormHelperText htmlFor='render-select' error>{errors.country}</FormHelperText>}
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="Province" InputLabelProps={{ shrink: true }}>
            {ProvinceLabel}
            </InputLabel>
            <Select
              name="Province"
              labelId="Province"
              id="ProvinceSelect"
              value={Province}
              onChange={handleChange}
            >
              {Regions.map((region, i) => (
                <MenuItem key={i} value={region.value}>{region.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            required
            id="Address"
            label="Address"
            value={CarWashAddress}
            onInput={e => setCarWashAddress(e.target.value)}
            error = {errors.carwashaddress}
            helperText = {errors.carwashaddress}
          />
          <TextField
            required
            id="City"
            label="City"
            InputLabelProps={{ shrink: true }}
            value={City}
            onInput={e => setCity(e.target.value)}
            error = {errors.city}
            helperText = {errors.city}
          />
          <TextField
            required
            id="PostalCode"
            label={PostalcodeLabel}
            InputLabelProps={{ shrink: true }}
            value={Postalcode}
            onInput={e => setPostalcode(e.target.value)}
            error = {errors.postalcode}
            helperText = {errors.postalcode}
          />

          <FormControl
            className={classes.formControl}
          >
            <InputLabel id="DistributorName" 
             error={errors.distributorname}
             InputLabelProps={{ shrink: true }} style={DistributorName ? {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
            } : {}}>Distributor Name *</InputLabel>
            <ReactSelect
              name="DistributorName"
              labelId="DistributorName"
              id="DistributorName"
              placeholder="Distributor Name *"
              styles={selectStyles}
              error={errors.distributorname}
              onChange={(val) => {handleChange({ target: { name: "DistributorName", value: val.value}})}}
              components={{ DropdownIndicator }}
              value={DistributorName ? {
                value: DistributorName,
                label: distributors.find(dis => dis?._id == DistributorName)?.distributorName
              } : null}
              options={distributors.map((row) => (
                {
                    value: `${row?._id}`, 
                    label: `${row.distributorName}`
                }
              ))}
            ></ReactSelect>
            {errors.distributorname && (
              <FormHelperText htmlFor="render-select" error>
                {errors.distributorname}
              </FormHelperText>
            )}
          </FormControl>

        </div>
        <div id="installBasic">
          <br />
          <Typography align="center" variant="h5">
            Installation Info.
          </Typography>
          <FormControl component="fieldset" style={{ margin: "20px" }}>
            <FormLabel component="legend">TrueVue System Package</FormLabel>
            <RadioGroup
              aria-label="Wash Sys"
              name="washSys"
              value={WashSys}
              onChange={handleChange}
            >
              <FormControlLabel
                value="deluxe"
                control={<Radio />}
                label="Deluxe"
              />
              <FormControlLabel
                value="standard"
                control={<Radio />}
                label="Standard"
              />
            </RadioGroup>
          </FormControl>
          {/* <FormControl component="fieldset" style={{ margin: '20px' }} className={classes.formControl}>
            <FormLabel component="legend">Wash Type</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tunnel}
                    onChange={handleChange}
                    name="tunnel"
                    color="primary"
                  />
                }
                label="Tunnel"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={auto}
                    onChange={handleChange}
                    name="auto"
                    color="primary"
                  />
                }
                label="In-Bay Automatic"
              />
            </FormGroup>
          </FormControl> */}
          <TextField
            id="bayNum"
            label="Number of Bays"
            type = "number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 1 } }}
            value={BayNum}
            InputLabelProps={{ shrink: true }}
            onInput={e => setBayNum(e.target.value)}
            error = {errors.numberofbays}
            helperText = {errors.numberofbays}
          />

          <FormControl
            className={classes.formControl}
          >
            <InputLabel id="Installer1"
             InputLabelProps={{ shrink: true }} style={Installer1 ? {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
            } : {}}>Installer 1</InputLabel>
            <ReactSelect
              name="Installer1"
              labelId="Installer1"
              id="Installer1"
              placeholder="Installer 1"
              styles={selectStyles}
              onChange={(val) => {
                handleChange({ target: { name: "Installer1", value: val?.value}})
              }}
              components={{ DropdownIndicator }}
              value={Installer1 ? {
                value: Installer1,
                label: installer.find(row => row?._id == Installer1)?.name
              } : null}
              options={installer.map((row) => (
                {
                    value: `${row?._id}`, 
                    label: `${row.name}`
                }
              ))}
              isClearable="true"
            ></ReactSelect>
          </FormControl>

          <FormControl
            className={classes.formControl}
          >
            <InputLabel id="Installer2"
             InputLabelProps={{ shrink: true }} style={Installer2 ? {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
            } : {}}>Installer 2</InputLabel>
            <ReactSelect
              name="Installer2"
              labelId="Installer2"
              id="Installer2"
              placeholder="Installer 2"
              styles={selectStyles}
              onChange={(val) => {
                handleChange({ target: { name: "Installer2", value: val?.value}})
              }}
              components={{ DropdownIndicator }}
              value={Installer2 ? {
                value: Installer2,
                label: installer.find(row => row?._id == Installer2)?.name
              } : null}
              options={installer.map((row) => (
                {
                    value: `${row?._id}`, 
                    label: `${row.name}`
                }
              ))}
              isClearable="true"
            ></ReactSelect>
          </FormControl>

          <MuiPickersUtilsProvider utils={DateFnsUtils} align="left">
            <KeyboardDatePicker
              InputLabelProps={{ shrink: true }}
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="installDate"
              label="Install Date"
              autoOk="true"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
          <FormControl className={classes.formControl}>
            <InputLabel
              id="InstallationStatus"
              InputLabelProps={{ shrink: true }}
            >
              Installation Status
            </InputLabel>
            <Select
              name="InstallationStatus"
              labelId="InstallationStatus"
              id="InstallationStatus"
              value={InstallationStatus}
              onChange={handleChange}
            >
              <MenuItem value={"Unassigned"}>Unassigned</MenuItem>
              <MenuItem value={"Assigned"}>Assigned</MenuItem>
              <MenuItem value={"In-Progress"}>In-Progress</MenuItem>
              <MenuItem value={"Completed"}>Completed</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/* <div id="controllerBasic">
          <br />
          <Typography align='center' variant='h5' >Controller Info.</Typography>
          <TextField id="hardwareId"
            label="Controller Id"
            value={HardwareId}
            onInput={e => setHardwareId(e.target.value)} />
          <TextField id="controllerMacAddress"
            label="Controller Mac Address"
            value={ControllerMacAddress}
            onInput={e => setControllerMacAddress(e.target.value)} />
          <TextField id="controllerModel"
            label="Controller Model"
            value={ControllerModel}
            onInput={e => setControllerModel(e.target.value)} />
          <TextField id="oemModel"
            label="OEM Model"
            value={oemModle}
            onInput={e => setOemModle(e.target.value)} />
          <TextField id="PortPerController"
            label="Port Amount"
            value={PortPerController}
            onInput={e => setPortPerController(e.target.value)} />

        </div> */}
      </form>
      <Box display="flex" justifyContent="flex-end">
        <ButtonLink name="Cancel" color="blue" onClick={handleCancel} />
        <ButtonLink name="Submit" color="pink" onClick={handleSubmit} />
      </Box>
    </MainPage>
  );
};

export default UpdateSite;
