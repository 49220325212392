import React, { useState, useEffect } from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import SubPage from '../Layout/SubPage'
import { fetchAllUsers } from '../../shared/api/index'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchBar from "material-ui-search-bar";
import { withStyles } from "@material-ui/core/styles";
import UserRow from './UserRow';
import TablePagination from "@material-ui/core/TablePagination";
import { cloneDeep } from 'lodash';


const useStyles = makeStyles(() => ({
    fields: {
        width: 220,
    },
    button: {
        marginRight: 20,
    },
    select: {
        width: 140
    },
    search: {
        width: 200,
        marginRight: 40
    },
    loading: {
        margin: 10,
        display: 'flex', alignItems: 'center', justifyContent: 'center'
    },
    box: {
        margin: 10
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column'
    },

    dialogText: {
        color: '#0288d1',
        fontSize: '1.5em',
        fontWeight: 500,
        textAlign: 'center'
    }



}));


const SiteManagement = React.memo((props) => {
    const { isSubmited, setIsSubmited } = props;
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [searched, setSearched] = useState("");
    const [role, setRole] = useState("All");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const cancelSearch = () => {
        setSearched("");
    };

    const handleSearchByRoleChange = (event) => {
        setRole(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            let fetchedUsers = await fetchAllUsers();
            if (!fetchedUsers) return;
            fetchedUsers = fetchedUsers.filter(user => user?.role && user?.email); 
            let users = fetchedUsers.filter(user => user.role !== "installer" && user.role !== "admin")
            users = users.sort((a,b) => (a.name?.toLowerCase() > b.name?.toLowerCase()) ? 1 : -1);
                
            setUsers(users);
        }
        fetchData();
    }, [isSubmited])

    const StyledTableCell = withStyles(theme => ({
        head: {
            backgroundColor: "#022a45",
            color: theme.palette.common.white
        },
        body: {
            fontSize: 18
        }
    }))(TableCell);

    const filteredUsers = users.filter(row => {
        return row?.name?.toLowerCase().includes(searched.toLowerCase());
    }).filter(user => {
        if (role === 'All') {
            return true;
        }
        if (user?.role === role) {
            return true;
        } else {
            return false;
        }
    });

    const slicedFilteredUsers = cloneDeep(filteredUsers
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));

    return (
        <SubPage name='Assign User
        ' color='teal'>
            
            <TableContainer component={Paper}>
                <Box display='flex' className={classes.box} >
                    <SearchBar
                        className={classes.search}
                        onChange={searchVal => setSearched(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                    />
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="grouped-select">Role</InputLabel>
                        <Select defaultValue="All" onChange={handleSearchByRoleChange} className={classes.select}>
                        <MenuItem value="distributor">Distributor</MenuItem>
                            <MenuItem value="owner">Company</MenuItem>
                            <MenuItem value="operator">Operator</MenuItem>
                            <MenuItem value="All">All</MenuItem>

                        </Select>
                    </FormControl>
                </Box>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >User</StyledTableCell>
                            <StyledTableCell >Edit</StyledTableCell>
                            <StyledTableCell >Sites Assigned</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {slicedFilteredUsers
                            .map((usr, i) => {
                                return (<UserRow key={'userRow' + i + usr?._id} user={usr} isSubmited={isSubmited} setIsSubmited={setIsSubmited} classes={classes} />);
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </SubPage >
    )
}
)

export default SiteManagement
