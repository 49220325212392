import {useMutation, useQuery} from 'react-query';
import axios from "axios";

const handleFetchAddresses = async () => {
    const allSiteAddresses = await axios.get(
        process.env.REACT_APP_BACKEND_LINK +
          "api/auth/address"
      );
      
      if (allSiteAddresses?.data) return allSiteAddresses?.data;

      return [];
};

export const useGetSitesAddresses = () => {
  return useQuery(
    ['allSiteAddresses-*'],
    () => handleFetchAddresses(),
    { 
        staleTime: 1000*60*60*24
    }
  );
};