import { Route, Switch, HashRouter } from "react-router-dom";
import "@material-tailwind/react/tailwind.css";
import CreateSite from "../Sites/CreateSite/CreateSite";
import Tanks from "../Sites/Tanks/operator/Tanks";
import Products from "../Products/Products";
import Reorder from "../Sites/Reorders/Reorder";
import SideNavigation from "../Navigation/SideNavigation";
import { makeStyles } from "@material-ui/core/styles";
import Dashboard from "../Dashboard/operator/Dashboard";
import Alerts from "../Alerts/owner/Alerts";
import React from "react";
import TanksLevel from "../Sites/Tanks/TanksLevel";
import SitesView from "../Sites/SitesView";
import RestrictedSites from "../Sites/Site/RestrictedSites";
import Inventory from "../Inventory/owner/Inventory";
import Inventories from "../Inventory/owner/Inventories";
import {MultiDayReport} from "../Reports/MultidayReport";
import {LatestReport} from '../Reports/LatestReport'
import { ProductUsage } from "../Reports/ProductUsageReport";
import Support from "../support/Support";
import AlertPreferences from "../Alerts/AlertPreferences";

const useStyles = makeStyles({
  container: {
    display: "flex"
  },
  content: {
    margin: "auto",
    width: "78%"
  }
});

function SiteOwner({ logoutHandler }) {
  const classes = useStyles();
  return (
    <>
      <HashRouter>
        <div className={classes.container}>
          <SideNavigation logoutHandler={logoutHandler} />
          <div className={classes.content}>
            <Switch>

              <Route path="/sites">
                <SitesView />
              </Route>
              <Route path="/createSite">
                <CreateSite />
              </Route>
              <Route path="/products">
                <Products />
              </Route>
              <Route path="/tanksLevel">
                <Tanks />
              </Route>      
              <Route path="/reorders">
                 <Reorder />
              </Route>
              <Route path="/site">
                <RestrictedSites />
              </Route>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route path="/alerts">
                <Alerts />
              </Route>
              <Route path="/alertPreferences">
                <AlertPreferences />
              </Route>
              <Route path="/inventory">
                <Inventory />
              </Route>
              <Route path="/inventories">
                <Inventories />
              </Route>
              <Route path="/latestReport">
                <LatestReport />
              </Route>
              <Route path="/productUsage">
                <ProductUsage />
              </Route>
              <Route path="/multidayReport">
                <MultiDayReport />
                </Route>
                <Route path="/support">
                <Support />
              </Route>
            </Switch>
          </div>
        </div>
      </HashRouter>
    </>
  );
}

export default SiteOwner;
