import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import H6 from "@material-tailwind/react/Heading6";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { withRouter } from "react-router-dom";
import authContext from "../../shared/context/auth/auth-context";

const MainPage = props => {
  const useStyles = makeStyles(theme => ({
    pageContent: {
      padding: theme.spacing(2),
      marginTop: "20px"
    },
    header: {
      padding: "25px",
      height: "80px"
    },
    bread: {
      marginBottom: "30px"
    }
  }));
  const classes = useStyles();
  const {
    history,
    location: { pathname }
  } = props;
  const pathnames = pathname.split("/").filter(x => x);

  const { user } = useContext(authContext);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ marginTop: "13vh" }}>
        <Breadcrumbs className={classes.bread} aria-label="breadcrumb">
          <Link
            data-testid="role"
            color="inherit"
            onClick={() => history.push("/")}
          >
            {user.role?.toUpperCase()}
          </Link>
          {pathnames.map((name, i) => {
            const routeTo = `/${pathnames.slice(0, i + 1).join("/")}`;
            return (
              <Link
                key={i}
                onClick={() => history.push(routeTo)}
                color="inherit"
              >
                {name.charAt(0).toUpperCase()+name.slice(1)}
              </Link>
            );
          })}
        </Breadcrumbs>

        {/* Body layout */}
        <Card className={classes.pageContent} elevation={2}>
          <div style={{ width: "fit-content", padding: "10px" }}>
            <CardHeader className={classes.header} color={props.color}>
              <H6 color="white">{props.name}</H6>
            </CardHeader>
          </div>
          {props.children}
        </Card>
      </Grid>
    </Grid>
  );
};

export default withRouter(MainPage);
