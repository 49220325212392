import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ButtonLink from "../../Util/ButtonLink";
import { deleteOrder, sendOrderEmail, viewOrder } from "../../../shared/api";
import moment from "moment";


const useStyles = makeStyles({
    pageContent: {
        marginTop: 40
    },
    table: {
        minWidth: 650,

        transition: "0.5s ease"
    },
    searchBar: {
        marginBottom: "30px"
    },
    tableRow: {
        transition: "0.3s ease",
        "&:hover": {
            backgroundColor: "#b8eaff !important"
        }
    },
    link: {
        transition: "0.5s ease",
        "&:hover": {
            fontWeight: 500
        }
    },
});

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.disabled
        }
    }
}))(TableRow);

const OrderRow = props => {
    const { order, setIsRemoved, setIsSubmit, isHistory } = props;
    const classes = useStyles();
    //console.log('order', order);
    const handleRemoveOrder = async (order_id) => {
        const deletedOrder = await deleteOrder(order_id);
        if (deletedOrder?.success) {
            setIsRemoved(state => {
                return (state ? false : true);
            })

        }
    };

    const handleEmailOrder = async (order_id) => {//made send and view email if it is status 0 
        const OrderPdf = await sendOrderEmail(order_id);

        if (OrderPdf?.success) {
            setIsSubmit(state => {
                return (state ? false : true);
            })
        }
           
        
    };

    const handleViewOrder = async (order_id) => {
        const OrderPdfURL = await viewOrder(order_id);
        //Open the URL on new Window
        window.open(OrderPdfURL);
    };

    const drawSendCell = () => {
        switch (order?.status) {
            case 1:
                return (<h6>Sent</h6>);
            case 2:
                return (<h6>Received</h6>);
            default:
                return (
                    <ButtonLink
                        name="Send"
                        color="green"
                        onClick={() => handleEmailOrder(order?._id)}
                    />
                )
        }
    }


    const drawRemoveCell = () => {
        switch (order?.status) {
            case 0:
                return (
                    <ButtonLink
                        name="Remove"
                        color="pink"
                        onClick={() => { handleRemoveOrder(order._id) }}
                    />
                    );
            default:
                return (
                    <ButtonLink
                        name="View"
                        color="orange"
                        onClick={() => { handleViewOrder(order._id) }}
                    />
                );
        }
    }
  
    return (
        <StyledTableRow
            className={classes.tableRow}
            key={order._id + 'or.styledCell'}
            style={{ minWidth: 50, maxWidth: 100 }}
        >
             <TableCell key={order._id + 'or.reqno'} component="th" scope="row">
             {`REQ-${Number(order?.orderno).toString().padStart(3, '0')}`}
            </TableCell>

            <TableCell key={order._id + 'or.id'} component="th" scope="row">
                {isHistory ? moment(order?.submissionDate).format("YYYY/MM/DD h:mm a") : moment(order?.createDate).format("YYYY/MM/DD h:mm a")}
            </TableCell>
            <TableCell key={order._id + 'or.requestedBy'} component="th" scope="row">
                {order?.requestedBy?.name}
            </TableCell>
            <TableCell key={order._id + 'or.1products'} component="th" scope="row">
                {`${order?.order?.length}`}
            </TableCell>
            <TableCell key={order._id + 'or.2products'} component="th" scope="row">
                {`${order?.totalAmount}`}
            </TableCell>
            {(order?.status == 0) ?
                (
                <TableCell key={order._id + 'or.view'} component="th" scope="row">
                        <ButtonLink
                            name="View"
                            color="orange"
                            onClick={() => handleViewOrder(order._id)}
                        />
                </TableCell>
            ):(<></>)}
            <TableCell key={order._id + 'or.rmv'} component="th" scope="row">
                {drawRemoveCell()}
            </TableCell>
            <TableCell key={order._id + 'or.send'} component="th" scope="row">
                {drawSendCell()}
            </TableCell>
        </StyledTableRow>
        );
};

export default OrderRow;