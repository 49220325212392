const TankData = {
    columns: [
        { id: 'tankId', label: 'Port Num', minWidth: 120 },
        { id: 'productSKU', label: 'Product SKU', minWidth: 150 },
        { id: 'product', label: 'Product Alias', minWidth: 150 },
        { id: 'productColor', label: 'Tank Color', minWidth: 100 },
        { id: 'size', label: 'Tank Size', minWidth: 100 },
        { id: 'edit', label: 'Edit', minWidth: 100 },
        { id: 'delete', label: 'Delete', minWidth: 100 }
    ],

 
    originalRows: [
        createData('111','HP-113','25'),
        createData('222','HP-114','25'),
        createData('333','HP-115','10'),
        createData('444','HP-116','25'),
        createData('555','HP-110','25'),
    ]


}
function createData(id, product, size) {
    return { id, product, size };
}

export default TankData