import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MainPage from "../../Layout/MainPage";
import { Heading4 } from "@material-tailwind/react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import { SiteContext } from "../../../shared/context/site/site-context";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import {
  getProductById,
  getInventoriesBySiteId,
  updateStock,
  createAlertForSite
} from "../../../shared/api";
import LightenDarkenColor from "../../../shared/uitils/colorOpacity";
import ButtonLink from "../../Util/ButtonLink";
import { Box, Typography } from "@material-ui/core";
import Swal from "sweetalert2";
import InvUpdateInput from './InvUpdateInput';

const useStyles = makeStyles({
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 280,
    margin: "0 5px",
    minWidth: 278
  },
  table: {
    maxWidth: 276,
    minWidth: 274,
    overflowX: 'hidden'
  },
  tableCellLeft: {
    padding: "4px",
    fontSize: "1.1em",
    fontWeight: 600,
    minWidth: 90,
    textAlign: "center",
    minHeight: 30
  },
  tableCellRight: {
    padding: "4px",
    fontSize: "1.1em",
    fontWeight: 600,
    minWidth: 180,
    textAlign: "center",
    minHeight: 30
  },
  invFields: {
    height: "10px",
    padding: "2px"
  }
});

const lightenColorValue = 37;

const Inventories = props => {
  const classes = useStyles();
  const history = useHistory();
  const { site } = useContext(SiteContext);
  const [inventories, setInventories] = useState(null);
  const [isInventoriesReceived, setIsInventoriesReceived] = useState(false);
  const [products, setProducts] = useState(null);
  const [isLoaded, setIsloaded] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isStockUpdated, setIsStockUpdated] = useState(false);

  //let stockUpdates = null;
    const [stockUpdates, setStockUpdates] = useState(null);

  let id,
    siteName = "";
  if (!history.location.state?.site_id && !site?._id) {
    id = localStorage.getItem("site_id");
    siteName = localStorage.getItem("site_name", siteName);
  } else {
    id = history.location.state?.site_id || site._id;
    siteName = history.location.state?.siteName || site.siteName;
    localStorage.setItem("site_id", id);
    localStorage.setItem("site_name", siteName);
  }
  //change between update and submit button
  let handleUpdateClick = () => {
    if (isUpdating) {
      setIsUpdating(false);
    } else {
      setIsUpdating(true);
    }
  };

  //update stock values when values change
  let handleStockChange = stockValues => {
    let updatedInventories = stockUpdates.map(inventory => {
      if (inventory._id == stockValues.inventoryId) {
        inventory.amount = stockValues.amount;
        return inventory;
      } else {
        return inventory;
      }
    });
    setStockUpdates(updatedInventories);
  };
  //submit new values to Database
  let handleSubmitNewStockValues = async (id, newInventoris) => {
    let updatedStock = await updateStock(id, newInventoris);
    if (updatedStock.success) {
      isStockUpdated ? setIsStockUpdated(false) : setIsStockUpdated(true);
      //alert that update successfuly
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false
      });

      Toast.fire({
        icon: "success",
        title: "Stock updated successfully"
      });

      //send alerts to admin
      updatedStock.changedInventories.map(async inventory => {
        try {
          if (inventory) {
            let oldInventory = inventories.filter(
              inv => inv.productId == inventory.productId
            );
            let product = await getProductById(inventory.productId);
            let alert = {
              alert: `Product ${product.productSKU} stock updated from ${
                oldInventory[0].amount
              } to ${inventory.amount}`,
              severity: 2
            };
            let createAlert = await createAlertForSite(id, alert);
            //console.log(createAlert);
          }
        } catch (e) {
          console.log("error occure when creating an alert");
        }
      });
    }
  };

  //calncel update stock
  let handleCancelUpdate = () => {
    //reamount the component
    isStockUpdated ? setIsStockUpdated(false) : setIsStockUpdated(true);
    //alert operation canceled
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: false
    });

    Toast.fire({
      icon: "warning",
      title: "Operation canceled"
    });
  };

  useEffect(
    () => {
      let inventories = [];
      //get invetories related to the site
      async function fetchInventories() {
        inventories = await getInventoriesBySiteId(id);
        setIsInventoriesReceived(inventories.success);
        if (inventories.success) {
          setInventories(inventories.inventories);
          // setStockUpdates(inventories);
          let newArray = JSON.parse(JSON.stringify(inventories.inventories));
          setStockUpdates(newArray);
        }
      }
      fetchInventories();

      //setIsloaded(true);

      return () => {
        // localStorage.removeItem("site_id");
      };
    },
    [isStockUpdated]
  );

  useEffect(
    async () => {
      // get products related to the site inventories
      let productsList = null;

      async function fetchProducts(inventoryList) {
        //fetching the products
        let productList = await Promise.all(
          //creating an array of products
          inventoryList.map(async inv => {
            let p = await getProductById(inv.productId);
            return p;
          })
        ).then(values => {
          return values;
        });

        return productList;
      }

      if (!!inventories && inventories?.length > 0) {
        productsList = await fetchProducts(inventories);
        setProducts(productsList);
        setIsloaded(true);
      }
    },
    [inventories]
  );

  return (
    <>
          <MainPage color="indigo" name={siteName + " Site Inventory"}>
        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginBottom: "10px" }}
        >
          <>
            {isInventoriesReceived && inventories?.length > 0 ? (
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ marginBottom: "10px" }}
              >
                <>
                  {isUpdating ? (
                    <>
                      <ButtonLink
                        color="orange"
                        name="Submit"
                        onClick={() => {
                          handleUpdateClick();
                          handleSubmitNewStockValues(id, stockUpdates);
                        }}
                      />
                      <ButtonLink
                        name="Cancel"
                        onClick={() => {
                          handleUpdateClick();
                          handleCancelUpdate();
                        }}
                      />
                    </>
                  ) : (
                    <ButtonLink
                      name="Update"
                      onClick={() => {
                        if (isInventoriesReceived) {
                          handleUpdateClick();
                        }
                      }}
                    />
                  )}
                </>
              </Box>
            ) : (
              <></>
            )}
          </>
        </Box>

        {isUpdating ? (
        <Typography style={{padding: '15px'}}>Enter updated stock level of full containers for each product, and press "SUBMIT" to update Inventory levels.  Do not include open containers, or product contained in True-Vue tanks.</Typography> ) : ( <> </>)}
        <Grid container spacing={2}>
          {isLoaded ? (
            inventories.map(inventory => {
              let product = products.filter(
                p => p._id === inventory.productId
              )[0];

              //start of return
              return (
                <Grid
                  item
                  lg={2}
                  md={3}
                  sm={4}
                  xs={12}
                  key={inventory._id + inventory.productId}
                  className={classes.gridItem}
                >
                  <TableContainer className={classes.table} component={Paper}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: `${product.productColor}` }}
                          className={classes.tableCellLeft}
                        >
                          ProductSKU
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: `${product.productColor}` }}
                          className={classes.tableCellRight}
                        >
                          {product.productSKU}
                        </TableCell>
                      </TableRow>
                      
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: `${LightenDarkenColor(
                              product.productColor,
                              lightenColorValue
                            )}`
                          }}
                          className={classes.tableCellLeft}
                        >
                          Product Type
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableCellRight}
                        >
                          {product.productClass}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: `${LightenDarkenColor(
                              product.productColor,
                              lightenColorValue
                            )}`,
                            height: 75
                          }}
                          className={classes.tableCellLeft}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          style={{
                            height: 75
                          }}
                          align="left"
                          className={classes.tableCellRight}
                        >
                          {product.productDescription}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: `${LightenDarkenColor(
                              product.productColor,
                              lightenColorValue
                            )}`
                          }}
                          className={classes.tableCellLeft}
                        >
                          Size
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableCellRight}
                        >
                          {`${product.productSize} gal`}
                        </TableCell>
                              </TableRow>
                              {isUpdating ? (
                                  <InvUpdateInput
                                      inventory={inventory}
                                      handleStockChange={handleStockChange}
                                      classes={classes}
                                      product={product}
                                      stockUpdates={stockUpdates}
                                  />
                              ) : (
                                      <TableRow>
                                          <TableCell
                                              align="left"
                                              style={{
                                                  backgroundColor: `${LightenDarkenColor(
                                                      product.productColor,
                                                      lightenColorValue
                                                  )}`
                                              }}
                                              className={classes.tableCellLeft}
                                          >
                                              Current Stock
                                          </TableCell>
                                          <TableCell
                                              align="left"
                                              className={classes.tableCellRight}
                                              style={{ fontSize: "1.5em" }}
                                          >
                                              {inventory.amount}
                                          </TableCell>
                                      </TableRow>                                     
                                      )}                      
                    </TableBody>
                  </TableContainer>
                </Grid>
              );
              //end of return
            })
          ) : (
            <CircularProgress disableShrink />
          )}
          <Grid item>
            {!isInventoriesReceived && (
              <Heading4 style={{ margin: "20px" }} color="cyan">
                No inventory in the system
              </Heading4>
            )}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          <ButtonLink
            name="Back"
            onClick={() => {
              history.push({
                pathname: "/inventory"
              });
            }}
          />
        </Box>
      </MainPage>
    </>
  );
};

export default Inventories;
