import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MainPage from "../../Layout/MainPage";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { TableContainer, TablePagination, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import SiteData from "../../Sites/SiteData";
import ButtonLink from "../../Util/ButtonLink";
import { authContext } from "../../../shared/context/auth/auth-context";
import Grid from "@material-ui/core/Grid";
import { getReorderingProductsForSite, createOrder, getInventoriesBySiteId, updateStock } from "../../../shared/api";
import Typography from "@material-ui/core/Typography";
import TableRowProduct from "./TableRowProduct";
import OrdersTable from "./OrdersTable"
import swal from 'sweetalert'

const useStyles = makeStyles({
    pageContent: {
        marginTop: 40
    },
    table: {
        minWidth: 650,

        transition: "0.5s ease"
    },
    searchBar: {
        marginBottom: "30px"
    },
    tableRow: {
        transition: "0.3s ease",
        "&:hover": {
            backgroundColor: "#b8eaff !important"
        }
    },

});

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.disabled
        }
    }
}))(TableRow);



const Reorder = props => {
    const history = useHistory();
    const classes = useStyles();
    
  //
    const [rows, setRows] = useState(SiteData.originalRows);
    //context APIs
    const { user } = useContext(authContext);
    const [updatedOrders, setUpdatedOrders] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    

    let id,
        siteName = "";
    if (!history.location.state?._id) {
        id = localStorage.getItem("site_id");
        siteName = localStorage.getItem("site_name");
    } else {
        id = history.location.state?._id;
        siteName = history.location.state?.siteName;
        localStorage.setItem("site_id", id);
        localStorage.setItem("site_name", siteName);
    }

    useEffect(async () => {

        //fetch products from tanks
        const productsReply = await getReorderingProductsForSite(id);
        if (productsReply?.success) {
            setRows(productsReply?.Reorder_products || []);
            const initOrders = productsReply?.Reorder_products.map(product => {
                const { product_id, autoFillOrder } = product;
                return [product_id, { order: 0, autoFillOrder }];
            });
            let ordersState = Object.assign(...initOrders.map(([key, val]) => ({ [key]: val })))
            setUpdatedOrders(ordersState);
        }
    }, []);

    const handleCreateOrder = async () => {
        let isThereSku = false;
        Object.values(updatedOrders).forEach(sku => {
            if (sku?.order > 0) isThereSku = true;
        });
        if (!isThereSku) {
            swal({
                title: "Cart is empty!",
                text: "No SKU was ordered",
                icon: "error",
                button: "OK!"
            })
        } else {
            const orderReply = await createOrder(id, user?.id, updatedOrders);
            setIsSubmit(state => !state);
        }
    };

    const handleUpdateAutofill = async () => {
        const data = await getInventoriesBySiteId(id);
        const updatedInventories = data.inventories.map(item => ({ ...item, autoFillOrder: updatedOrders[item.productId]?.autoFillOrder ?? item.autoFillOrder }));
        const res = await updateStock(id, updatedInventories)
        if (res) {
            swal({
                title: "Success!",
                text: "Auto Fill Amounts Updated",
                icon: "success",
                button: "OK!",
            })
        }
    };

    return (
        <>
            <MainPage name={"Reordering - " + siteName} color="green">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography align='left' variant='h6' >Order Products:</Typography>
                        <Typography align='left' variant='body2'>
                            Current stock of active Product SKU's are listed for this location.  To add products to an order request, simply add quantity of products to "Order Amount" field. 
                            <br />
                            The "Order Amount" field is automatically populated with the amount set in the "Auto Fill Amount" field when "Current Stock" quantity falls below the "Stock Trigger" setting.
                            <br />
                            "Stock Trigger" setting can be adjusted by the True-Vue customer service team.  Simply email truevue@transchem.com.
                        </Typography>
                        <br />
                        <TableContainer component={Paper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {SiteData.columnsReordering.map(column => (
                                            <StyledTableCell
                                                key={column.id + '654dgfdgdfg'}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                            >
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .map(row => (
                                            <TableRowProduct key={row.tankdId + 'tableRowP'} reorderProduct={row} setUpdatedOrders={setUpdatedOrders}/>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <ButtonLink
                                color="pink"
                                name="Update Auto Fill Amounts"
                                onClick={handleUpdateAutofill}
                            />
                            <ButtonLink
                                color="pink"
                                name="Add to cart"
                                onClick={handleCreateOrder}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align='left' variant='h6' >Shopping Cart:</Typography>
                        <Typography align='left' variant='body2'>
                            Order requests in the Shopping Cart are on hold, and waiting to be sent to your chemical distributor.  You can view, remove, or send the order request via email to your distributor.
                        </Typography>
                        <br />
                        <OrdersTable site_id={id} isSubmit={isSubmit} setIsSubmit={setIsSubmit} isHistory={ false}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align='left' variant='h6' >Order History:</Typography>
                        <Typography align='left' variant='body2'>
                            Order requests listed here have been sent to your distributor.  View contents of past orders by clicking on the "View" button.
                        </Typography>
                        <br />
                        <OrdersTable site_id={id} isSubmit={isSubmit} setIsSubmit={setIsSubmit} isHistory={true} />
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end">
          <ButtonLink
            name="Back"
            onClick={() => {
              history.push({
                pathname: "/inventory"
              });
            }}
          />
        </Box>
            </MainPage>
        </>
    );
};

export default Reorder;
