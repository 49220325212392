import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ButtonLink from './ButtonLink';

const DialogComponent = (props) => {
    const { open, handleClose, handleSubmit, dialogText, title, dialog, children} = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            style={{ maxHeight: '700px', top: '10%' }}
        >
            <DialogTitle className={`${dialogText}`}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText className={`${dialog}`}>
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonLink onClick={handleClose} color='gray' name='cancel' />
                <ButtonLink onClick={handleSubmit} color="pink" name='Submit' autoFocus />
            </DialogActions>
        </Dialog>
    )
}

export default DialogComponent
