const PackageData = {
    columns: [
        { id: 'order', label: 'Cycle Order', minWidth: 57 },
        { id: 'cycleName', label: 'Cycle Name', minWidth: 270 },
        { id: 'product', label: 'Product', minWidth: 300 },
        { id: 'threshold', label: 'Threshold', minWidth: 200 },
        { id: 'delete', label: 'Delete', minWidth: 200 }
    ],

}
// function createData(id, name, washType) {
//     return { id, name, washType };
// }


export default PackageData