import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MobileLayout from '../Layout/MobileLayout';
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableContainer, Typography } from '@material-ui/core';
import axios from 'axios'
import SiteContext from '../../shared/context/site/site-context';
import { useHistory, useLocation } from 'react-router';
import { fetchSiteControllerBySiteId, fetchSiteControllerLiveData } from '../../shared/api';
import ButtonLink from "../Util/ButtonLink";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
    table: {
        minWidth: 250,
        transition: '0.5s ease',
    },
    tableHead: {
        padding: 20

    },
    tableRow: {
        transition: '0.3s ease',
        "&:hover": {
            backgroundColor: "#b8eaff !important"
        },
        maxHeight: 300,
        overflow: 'auto'
    },
    liveIndicator: {
        color: 'white',
        display: 'inline',
        padding: '3px 10px',
        borderRadius: '6px',
    }
});
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.disabled,
        },
    },
}))(TableRow);

const TestSensor = (props) => {
    //const { site } = useContext(SiteContext);
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    //const carWashName = "Test Site"
    const [Inputs, setInputs] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [MacAddress, setMacAddress] = useState("");
    

    let siteId, carWashName  = "";

    if (!location.state?.siteId) {
        siteId = localStorage.getItem("siteId");
        carWashName = localStorage.getItem("carWashName");
    } else {
        siteId = location.state?.siteId;
        carWashName = location.state?.siteName;
        localStorage.setItem("siteId", siteId);
        localStorage.setItem("carWashName", carWashName);
    }

    const title = "Real-time Data in " + carWashName;

    useEffect(() => {
        const fetchData = async () => {
      
            const controller = await fetchSiteControllerBySiteId(siteId);
            if (controller?.length <= 0) return;

            const { controllerMacAddress } = controller[0];

            // const macAdd = 'c4f3129adab1';
            // const result = await fetchSiteControllerLiveData(macAdd);

            const result = await fetchSiteControllerLiveData(controllerMacAddress);

            var dataItem = [];
            var dataList = [];
            var inputItem = [];
            var inputList = [];
            for (var i = 1; i < 25; i++) {
                dataItem = [];
                dataItem.push(i);
                if (result.sensors[i] !== -1) { dataItem.push(result.sensors[i]); }
                else { dataItem.push("No Signal"); }

                dataList.push(dataItem);
            }
            for (var j = 1; j < 33; j++) {
                inputItem = [];
                inputItem.push(j);
                if (result.inputs[j] == 1) inputItem.push("ON");
                else inputItem.push("OFF");

                inputList.push(inputItem);
            }
            setSensors(dataList)
            setInputs(inputList);
            setMacAddress(result?.mac);
        }

        const interval = setInterval(() => {
            fetchData();
        }, 1000)

        const timeoutInterval = setInterval(() => {
            history.push({
                pathname: "/controllers/"
            });
        }, 300000)

        return () => {
            clearInterval(timeoutInterval)
            clearInterval(interval)
        }
    }, [])

    // function TurnOn(){
    //     setMessage("Turn On Site Setup Model Sucessfully");
    // }

    // function TurnOff(){
    //     setMessage("Turn Off Site Setup Model");
    // }


    return (
        <>
        <MobileLayout name={title} color='orange' className={classes.tableHead}>
        <ButtonLink color='pink' name='< Back to site'
            onClick={() => {
            history.push({
                pathname: "/controllers/"
            });
        }} />
            <Typography align='center' variant='h6' >Controller Mac: {MacAddress}</Typography>
            {/* <ButtonLink color='pink' name='Turn On Setup Model'
                onClick={TurnOn} />  */}
            {/* <ButtonLink color='blue' name='Refresh'
                onClick={Refresh} />  */}
            

            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={5}>
                    <Typography align='center' variant='h6' >Sensors Data</Typography>
                    <TableContainer component={Paper} style={{ maxHeight: 500, overflow: 'auto' }}>
                        <Table className={classes.table} size='small'>
                            <TableHead >
                                <TableRow>
                                    <StyledTableCell>
                                        Port Number
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Sensor Data
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sensors.map((row) =>
                                (
                                    <StyledTableRow className={classes.tableRow} style={{}}
                                    // key={row.id}
                                    >
                                        <TableCell component="th" scope="row">
                                            Port No. {row[0]}
                                        </TableCell>
                                        <TableCell  >
                                            {row[1]}
                                        </TableCell>
                                    </StyledTableRow>
                                )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6} md={5} style={{ marginLeft: 'auto' }}>
                    <Typography align='center' variant='h6' >Input Live Data</Typography>
                    <TableContainer component={Paper} style={{ maxHeight: 500, overflow: 'auto' }}>
                        <Table className={classes.table} size='small'>
                            <TableHead >
                                <TableRow>
                                    <StyledTableCell>
                                        Wash Input No.
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Input Status
                                    </StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Inputs.map((row) =>
                                (
                                    <StyledTableRow className={classes.tableRow}
                                    // key={row.id}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row[0]}
                                        </TableCell>
                                        <TableCell >
                                            <Typography className={classes.liveIndicator} style={row[1] === 'ON' ? {backgroundColor: 'green'} : {backgroundColor: 'red'}}>{row[1]}</Typography>
                                        </TableCell>
                                    </StyledTableRow>
                                )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </MobileLayout >
        </>
    )
}

export default TestSensor