import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import MainPage from "../../../Layout/MainPage";
import { Box } from "@material-ui/core";
//import { Dropdown, DropdownLink } from "@material-tailwind/react";
import { fetchAllSites, getSitesForUser } from "../../../../shared/api";
import { SiteContext } from "../../../../shared/context/site/site-context";
import { authContext } from "../../../../shared/context/auth/auth-context";
import Grid from "@material-ui/core/Grid";
import H4 from "@material-tailwind/react/Heading4";
import TanksLevel from "./TanksLevel";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { DropdownItem } from "semantic-ui-react";
import ReactSelect from 'react-select'

const Tanks = props => {
  const { site, updateSiteContext } = useContext(SiteContext);
  const { user } = useContext(authContext);
  const history = useHistory();
  const [locations, setLocations] = useState([]); // array of all the locations
//   const [isSelected, setIsSelected] = useState(site?._id);

  useEffect(async () => {
    if (user) {
      let sites = user?.role === 'admin' ? await fetchAllSites() : (await getSitesForUser(user.id)).sites
      if (sites) {
        sites = sites.filter(site => site)
        sites = sites.sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1);
        setLocations(sites);
      } else {
        setLocations([]);
      }
    }
  }, []);

  const styles = {
    container: (styles) => ({ ...styles,
        display: "inline-block",
        marginBottom: "20px",
        marginLeft: '10px',
        minWidth: '200px'
    }),
  }

  return (
    <MainPage name={"Tank Levels"} color="orange">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <ReactSelect
                name="Site"
                labelId="Site"
                id="Site"
                placeholder="Filter by Site"
                onChange={(location) => {
                    localStorage.removeItem("site_id");
                    localStorage.removeItem("site_name");
                    updateSiteContext(location._id);
                }}
                styles={styles}
                options={locations.map((site) => (
                    {
                        ...site,
                        value: site._id, 
                        label: site.siteName
                    }
                ))}
            ></ReactSelect>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {site?._id ? (
            <>
              <TanksLevel site={site} />
            </>
          ) : (
            <>
              <H4 style={{ margin: "20px" }} color="orange">
                Please Select a Site
              </H4>
            </>
          )}
        </Grid>
      </Grid>
    </MainPage>
  );
};

export default Tanks;
