import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MainPage from "../../Layout/MainPage";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import { TableContainer, Link, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import SiteData from "../../Sites/SiteData";
import ButtonLink from "../../Util/ButtonLink";
import { SiteContext } from "../../../shared/context/site/site-context";
import { authContext } from "../../../shared/context/auth/auth-context";
import Grid from "@material-ui/core/Grid";
import { getSitesForUser } from "../../../shared/api";
import {useGetUserSites} from "../../../hooks/mutations/useGetUserSites";
import {useGetUserSitesAddresses} from "../../../hooks/mutations/useGetUserSitesAddresses";
import {center,mapContainerStyle,options} from "../Dashboard";
import Map from "../../Map/Map.js";
import LinearProgress from '@mui/material/LinearProgress';
import DashboardAlerts from "../DashboardAlerts.js";

const useStyles = makeStyles({
  pageContent: {
    marginTop: 40
  },
  table: {
    minWidth: 650,

    transition: "0.5s ease"
  },
  searchBar: {
    marginBottom: "30px"
  },
  tableRow: {
    transition: "0.3s ease",
    "&:hover": {
      backgroundColor: "#b8eaff !important"
    }
  },
  link: {
    transition: "0.5s ease",
    "&:hover": {
      fontWeight: 500
    }
  }
});

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.disabled
    }
  }
}))(TableRow);


const Dashboard = props => {
  const history = useHistory();
  const classes = useStyles();
    //context APIs
    const { updateSiteContext } = useContext(SiteContext);
    const { user } = useContext(authContext);

  const {mutateAsync: getUserSites } = useGetUserSites();
  const {mutateAsync: getAddresses } = useGetUserSitesAddresses();
  const [coordinates, setCoordinates] = useState([]);
  const [sites, setSites] = useState([]);
  const [address, setAddress] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // search bar hooks and methods
  const [searched, setSearched] = useState("");
  const [rows, setRows] = useState(SiteData.originalRows);

  //display tanks alerts states
  const [isTanksAlert, setIsTanksAlert] = useState(false);
  const [tanksAlerts, setTanksAlerts] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredRows = rows.filter(row => {
    return row?.siteName?.toLowerCase().includes(searched.toLowerCase());
  });

useEffect(()=>{
(async()=>{
  if(user?.id){
    const sitesRes = await getUserSites({userId: user?.id});
    if(sitesRes) setSites(sitesRes);
    const adressesRes = await getAddresses({userId: user?.id});
    if(adressesRes) setAddress(adressesRes);
  }
})();
},[user]);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        let sites = await getSitesForUser(user.id);
        if (sites.success) {
          setRows(sites.sites);
        } else {
          setRows([]);
        }
      }
    }
    fetchData();
  }, [user]);

  useEffect(() => {
    if(address?.length > 0){
       const fetch = address.map((add => {
         return {id: add?.addressId,
           lat: parseFloat(add?.lat), lng:parseFloat(add?.lng) }
       }))
 
       setCoordinates(fetch);
    }
   }, [address]);

  return (
    <>
      <MainPage name={"Dashboard"} color="green">
        <DashboardAlerts />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            { user?.role === 'distributor' && 
            (
              sites.length > 0 && address.length > 0 ?
                (
                  <Map
                  key={Math.random() * 100 + 'map'}
                  zoom={7}
                  mapContainerStyle={mapContainerStyle}
                  center={center}
                  points={coordinates}
                  options={options}
                  site={sites}
                  address={address}
                />
                ): (
                  <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
                )
            )
              }
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {SiteData.columnsOperatorDashboard.map(column => {

                      if (user.role === 'distributor' && column.id === "reorders"){
                        return null;
                      }

                      return (<StyledTableCell
                                key={column.id + 'columnsOperatorDashboard' }
                                align={column.align}
                                style={{ minWidth: column.minWidth, paddingLeft: '2.2%' }}
                                >
                                  {column.label}
                              </StyledTableCell>);                 
                     }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <StyledTableRow
                        className={classes.tableRow}
                            key={row._id + 'classes.tableRow'}
                      >
                        <TableCell component="th" scope="row">
                          <TableCell component="th" scope="row">
                            <Link
                              style={{ cursor: "pointer" }}

                              href={row.id}
                              onClick={e => {
                                e.preventDefault();
                                history.push({
                                  pathname: "/site/",
                                  search: row._id,
                                  state: {
                                    _id: row._id,
                                    siteId: row.siteId,
                                    siteName: row.siteName,
                                    siteOwner: row.siteOwner
                                  }
                                });
                              }}
                              color="inherit"
                              underline="none"
                            >
                              {row.siteName}
                            </Link>
                          </TableCell>
                        </TableCell>
                        <TableCell>
                          <ButtonLink
                            name="View"
                            color="blue"
                            onClick={() => {
                              updateSiteContext(row._id);
                              history.push({
                                pathname: "/tanksLevel",
                                state: {
                                  site_id: row._id,
                                  siteName: row.siteName
                                }
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <ButtonLink
                            name="View"
                            color="blue"
                            onClick={() => {
                              updateSiteContext(row._id);
                              history.push({
                                pathname: "/inventories",
                                state: {
                                  site_id: row._id,
                                  siteName: row.siteName
                                }
                              });
                            }}
                          />
                        </TableCell>
                        {/* <TableCell>
                          <ButtonLink
                            name="View"
                            color="blue"
                            onClick={() => {
                              //console.log(row)
                              updateSiteContext(row._id);
                              history.push({
                                pathname: "/yesterday",
                                state: {
                                  _id: row._id,
                                  site_id: row.siteId,
                                  siteName: row.siteName
                                }
                              });
                            }}
                          />
                        </TableCell> */}
                    { (user.role === 'owner' || user.role === 'operator') &&
                          <TableCell>
                          <ButtonLink
                            name="View"
                            color="blue"
                            onClick={() => {
                              updateSiteContext(row._id);
                              history.push({
                                pathname: "/reorders",
                                state: {
                                  _id: row._id,
                                  siteName: row.siteName
                                }
                              });
                            }}
                          />
                        </TableCell>
                    }
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <Card className={classes.pageContent} elevation={5}>
              <div style={{ width: "fit-content", padding: "10px" }}>
                <CardHeader color="yellow">
                  <H6 color="white">Latest Activity</H6>
                </CardHeader>
              </div>
              {alerts.map(row => (
                <Alert severity={row.severity}>
                  <AlertTitle>{row.severity}</AlertTitle>
                  Date: {row.dt} � <strong>{row.alert}</strong>
                </Alert>
              ))}
            </Card>
          </Grid> */}
        </Grid>
      </MainPage>
    </>
  );
};

export default Dashboard;
