import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import Typography from '@mui/material/Typography';

const MainPage = (props) => {
    const useStyles = makeStyles((theme) => ({
        pageContent: {
            padding: theme.spacing(2),
            marginTop: '20px',
            background: 'linear-gradient(to right, #abbfcd, #cccccc);'
        },
        card: {
            width: 'fit-content',
            padding: '10px'
        },
        header: {
            padding: '10px',
            height: '50px'
        },
        head: {

            margin: 0,
            height: '20px'
        },
    }));
    const classes = useStyles();


    return (
    <Grid style={{ marginTop: '3vh' }} container spacing={3}>
            <Grid item xs={12} >
                <Card className={classes.pageContent} elevation={24}>
                    <div className={classes.card}>
                        <CardHeader className={classes.header} color={props.color}>
                            <Typography className={classes.head} >{props.name}</Typography>
                        </CardHeader>
                    </div>
                    {props.children}
                </Card>
            </Grid>
        </Grid>
    )
}

export default MainPage
