import { Face, Fingerprint, TrafficRounded } from "@material-ui/icons";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Grid, Box, Typography, TextField } from "@material-ui/core";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import Button from "@material-tailwind/react/Button";
import { makeStyles } from "@material-ui/core/styles";
import "@material-tailwind/react/tailwind.css";
import Checkbox from "@material-tailwind/react/Checkbox";
import { Link } from "react-router-dom";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Logo from "../Util/Logo";
import authContext from "../../shared/context/auth/auth-context";
import jwtDecode from 'jwt-decode'
import ReCAPTCHA from "react-google-recaptcha";
const Login = ({ history }) => { //function component//
  const useStyles = makeStyles(theme => ({
    padding: {
      padding: theme.spacing(5),
      width: "600px",
      height: "300px",
      minWidth: "200px"
    },
    box: {
      background: " linear-gradient(-45deg, #ee7752, #23a6d5, #23d5ab);",
      height: "100vh"
    },
    margin: {
      marginTop: "auto",
      flex: 0.8,
      alignItems: "center"
    },
    icon: {
      fontSize: "2rem",
      color: "grey"
    },
    card: {
      width: "600px"
    }
  }));
  const { updateAuthContext } = useContext(authContext);
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(""); 
  const [error, setError] = useState("");
  const [errors, setErrors] = useState([{email: ''}]); 
  const reRef = useRef()
  //console.log("error=" + errors);
  // useEffect(() => {
  //     if (localStorage.getItem("authToken")) {
  //         history.push("/");
  //     }
  // }, [history]);

  const validateEmail = email => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  };

  // const validatePassword = password => {
  //   return password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/);
  // };

  const loginValidation = () => 
  {
    let temp={};
    temp.email = validateEmail(email)?"":"Please enter valid email";
    //temp.password = validatePassword(password)?"":"Your password must be at least 8 characters.At least one number, a special character , upper case, and lowercase letter is required.";
     setErrors({
       ...temp
     })
     
      return Object.values(temp).every(x => x === "");
  }

  const loginHandler = async e => {
    e.preventDefault();

   const config = {
    header: {
      "Content-Type": "application/json"
    }
  };

   let result =  loginValidation();
   const reToken = await reRef.current.getValue() 
   
  //  console.log("Recaptcha Token",reToken)
  //  console.log("Result",result)
  if(result && reToken)
  {
     
    
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_BACKEND_LINK + "api/auth/login",
      { email, password, reToken },
      config
    );
    


    localStorage.setItem("authToken", data.token);

    const decoded = jwtDecode(data.token);

    //console.log(decoded)
    
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: decoded.id, comment: "logged in", category: "Login" }
      )
    }
    catch (error) {
      console.log(error)
    }
      updateAuthContext(data.token);

      if (password == process.env.REACT_APP_DEFAULT_PASSWORD) {
  
          const { data } = await axios.post(process.env.REACT_APP_BACKEND_LINK +
              `api/auth/forgotPassword/`,
              {
                  email
              }
          );

          if (data?.success) {
              history.push(`/passwordReset/${data?.data.resetToken}`);

          }
      }
      else
      {
        reRef.current.reset()
          history.push("/");
          
      }
      

  } catch (error) {
    setError(error?.response?.data?.error);
    setTimeout(() => {
      setError("");
    }, 5000);
  }

    
   }
   else if(!reToken){
     alert("Please check ReCaptcha")
   }
   
  };

  return (
    <form onSubmit={loginHandler}>
      <Box
        className={classes.box}
        display="flex"
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        p={2}
      >
        <Card className={classes.card}>
          <CardHeader color="blueGray" size="lg">
            <Logo />
          </CardHeader>

          <CardBody>
            <Box mt={4}>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <Face className={classes.icon} />
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    id="username"
                    label="Email"
                    type="text"
                    fullWidth
                    onChange={e => setEmail(e.target.value.trim())} 
                    value={email} 
                    error={errors.email}
                    helperText= {errors.email}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <Fingerprint className={classes.icon} />
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    id="password"
                    label="Password"
                    type="password"
                    fullWidth
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    // error={errors.password}
                    // helperText= {errors.password}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardBody>
        
          <div className="flex justify-center">
          <ReCAPTCHA sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY} ref = {reRef} size="normal" type="image"  />
            </div>
          <Box mt={3} ml={3}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Checkbox color="teal" text="Remember me" id="checkbox" />

              <Link to="/forgotPassword">Forgot Password?</Link>
            </Grid>
          </Box>
          <CardFooter>
            <div className="flex justify-center">
              <Button color="teal" size="lg" ripple="dark" type="submit">
                Login
              </Button>
            </div>
          </CardFooter>

          {error && <Alert severity="error">{error}</Alert>}
        </Card>
      </Box>
    </form>
  );
};

export default Login;
