
import React ,{useContext, useState}from 'react';
import { TextField, InputLabel, FormControl, Select, MenuItem, Grid, Paper, Typography, Button, Box, IconButton } from '@material-ui/core';
import ReactSelect, { components } from 'react-select';
import DropdownIndicator, { selectStyles } from "../../Util/DropdownIndicator";
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import { useHistory, useLocation } from 'react-router';
import MainPage from '../../../components/Layout/MainPage';
import { authContext } from "../../../shared/context/auth/auth-context";
import ButtonLink from '../../Util/ButtonLink';
import { v4 } from 'uuid';
import axios from 'axios'
import { FormHelperText } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ColorPicker } from 'material-ui-color';
import { set } from 'lodash';
import { SketchPicker } from 'react-color';
import ColorLensIcon from '@material-ui/icons/ColorLens';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '33ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: '35ch',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }, button: {
    margin: theme.spacing(2),
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2)
  },
}));

const UpdateProduct = function FormPropsTextFields() {

  const classes = useStyles();
  const { user } = useContext(authContext);
  const history = useHistory();
  const location = useLocation();
  const ProductId = location.state.productId;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [OtherSize, setOtherSize] = React.useState('');
  const [OtherClass, setOtherClass] = React.useState('');
  const [SizeSelect, setSizeSelect] = React.useState('');
  const [ProductDescription, setProductDescription] = React.useState('');
  const [ProductPrice, setProductPrice] = React.useState('');
  const [ProductSKU, setProductSKU] = React.useState('');
  const [ProductWeight, setProductWeight] = React.useState('');
  const [ProductClass, setProductClass] = React.useState('');
  const [ProductColor, setProductColor] = React.useState('');
  const [ProductUsage, setProductUsage] = React.useState('');
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState({
    productsku:'',
    productclass:'',
    containersize:'',
    productcolor:'',
    othersize:'',
    productweight:'',
    productprice:'',
    productusage:''
    }); 
  
    const productClassOptions = [
        { value: "FOAM DETERGENT", label: "FOAM DETERGENT" },
        { value: "SEALANT/PROTECT", label: "SEALANT/PROTECT" },
        { value: "DRYING AGENT", label: "DRYING AGENT" },
        { value: "FIRE AND ICE", label: "FIRE AND ICE" },
        { value: "SPECIALTY", label: "SPECIALTY" },
        { value: "WHEEL/TIRE", label: "WHEEL/TIRE" },
        { value: "TRI COLOR WAX", label: "TRI COLOR WAX" },
        { value: "TRI COLOR SOAP", label: "TRI COLOR SOAP" },
        { value: "LOW PH PRESOAK", label: "LOW PH PRESOAK" },
        { value: "HI PH PRESOAK", label: "HI PH PRESOAK" },
        { value: "other", label: "Other, input aside" },
    ]

  React.useEffect(() => {

    const fetchData = async () => {
      const readURL1 = process.env.REACT_APP_BACKEND_LINK + "api/product/readone/" + ProductId;
      const result = await axios.get(readURL1);
      setProductDescription(result.data.productDescription);
      setProductClass(result.data.productClass);
      setProductPrice(result.data.productPrice);
      //setProductClass(result.data.productClass);
      setProductWeight(result.data.productWeight);
      setProductSKU(result.data.productSKU)
      setProductColor(result.data.productColor);
      setProductUsage(result.data.productUsage);

      if (result.data.productSize === 2.5 || result.data.productSize === 5) {
        setSizeSelect(result.data.productSize)
      }
      else {
        setSizeSelect("other");
        setOtherSize(result.data.productSize);
      }
      if((result.data.productClass==="FOAM DETERGENT")||(result.data.productClass==="SEALANT/PROTECT")||(result.data.productClass==="DRYING AGENT")||(result.data.productClass==="FIRE AND ICE")||(result.data.productClass==="SPECIALTY")||(result.data.productClass==="WHEEL/TIRE")||(result.data.productClass==="TRI COLOR WAX")||(result.data.productClass==="TRI COLOR SOAP")||(result.data.productClass==="LOW PH PRESOAK")||(result.data.productClass==="HI PH PRESOAK")){
        setProductClass(result.data.productClass)

      }
      else{
        setProductClass("other")
        setOtherClass(result.data.productClass)
      }
      
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    if (event.target.name === "ProductClass") {
      setProductClass(event.target.value)
      //console.log(event.target.value);
    }
    if (event.target.name === "SizeSelect") {
      setSizeSelect(event.target.value);
    }

  };

  
  const productValidation = async e => 
  {
    let temp={};
    temp.productsku = ProductSKU?"":"Please enter the Product SKU";
    temp.desc = ProductDescription?"":"Please enter description";
    temp.productclass = ProductClass?"":"Please enter the Product Class";
    temp.containersize = SizeSelect?"":"Please select the Container Size";
    temp.productcolor = ProductColor?"":"Please select the Product Color";
    //temp.othersize = !OtherSize && SizeSelect === 'other'?"Please enter positive input size":"";
    // if(!temp.othersize)
    // temp.othersize = OtherSize?OtherSize>0?"":"Please enter positive product input container size":"Please enter container size";
    temp.productweight = ProductWeight?ProductWeight>0?"":"Please enter positive product weight":"Please enter product weight";
    temp.productprice = ProductPrice?ProductPrice>0?"":"Please enter positive product price":"Please enter product price";
    temp.productusage = ProductUsage?ProductUsage>=0?"":"Please enter positive product usage":"Please enter product usage";
  
    // if(ProductClass =="other"){
    //   temp.otherclass = OtherClass ?"":"Please enter product class";
    // }
    
    setErrors({
       ...temp
     })
     
      return Object.values(temp).every(x => x === "");
  }

  async function handleSubmit() {
    let Size = SizeSelect;
    if (SizeSelect === "other") {
      Size = OtherSize;
    }
    let productClasss = ProductClass
    if(ProductClass ==="other"){
      productClasss = OtherClass
    }

    let body = {
      productId: ProductId,
      productDescription: ProductDescription,
      productClass: productClasss,
      productColor: ProductColor,
      productSize: Size,
      productPrice: ProductPrice,
      productWeight: ProductWeight,
      productSKU: ProductSKU,
      productUsage: ProductUsage
    };

    productValidation().then(function(result) {
      if(result)
      {
        //console.log(result)
      let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/product/update", {
          method: "POST",
          body: JSON.stringify(body),
          mode: "cors",
          headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem("authToken")}`
          },
        }).then(response => response.json(),)

      response.then(function(result)
      {
      if(result.errors && result.errors[0].msg.includes(""))
      {
        setError(result.errors[0].msg);
        setTimeout(()=> { setError("") }, 5000);
      }
      else
      {
      history.push({
          pathname: '/products/',
          state: {
          }
        });
      }
      });
      }
      });
      try {
        await axios.post(
          process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Product ${ProductSKU} Updated`, category: "Update Product" }
        )
      }
      catch (error) {
        console.log(error)
      }
  };

  function handleCancel() {
    history.push({
      pathname: '/products/',
      state: {
      }
    });
  };

  return (
    <MainPage name={'Update Product'}>
      <form className={classes.root} noValidate autoComplete="off">
        <div id="ProductSize">
          {error && <Alert severity="error">{error}</Alert>}
          <Typography align='center' variant='h5' >Product Info.</Typography>
          <Grid 
            container 
            spacing={2}
            justifyContent="flex-start"
          >
            <Grid 
              item xs={12} sm={6} md={4} lg={3}
            >
            <TextField id="ProductSKU" required
              style={{ margin: '20px' }}
              label="Display SKU"
              value={ProductSKU}
              InputLabelProps={{ shrink: true }}
              onInput={e => setProductSKU(e.target.value)}
              error={errors.productsku}
              helperText= {errors.productsku}
              />
            </Grid>
            <Grid 
              item xs={12} sm={6} md={4} lg={3}
            >
            <TextField id="ProductDescription" 
              InputLabelProps={{ shrink: true }}
              style={{ margin: '20px' }}
              label="Description"
              value={ProductDescription}
              onInput={e => setProductDescription(e.target.value)} 
              />
            </Grid>
            <Grid 
              item xs={12} sm={6} md={4} lg={3}
            >
            <FormControl className={classes.formControl} required
              style={{ margin: '20px' }}>
              <InputLabel id="SizeSelect"
                InputLabelProps={{ shrink: true }} error = {errors.containersize}>Container Size</InputLabel>
              <Select
                name="SizeSelect"
                labelId="Product Size"
                id="SizeSelect"
                value={SizeSelect}
                onChange={handleChange}
                error = {errors.containersize}
              >
                <MenuItem value={"2.5"}>2.5 Galon</MenuItem>
                <MenuItem value={"5"}>5.0 Galon</MenuItem>
                <MenuItem value={"other"}>other, input aside</MenuItem>
              </Select>
              {(errors.containersize) && <FormHelperText htmlFor='render-select' error>{errors.containersize}</FormHelperText>}
            </FormControl>
            </Grid>
            {SizeSelect === "other" &&
              <Grid 
                item xs={12} sm={6} md={4} lg={3}
              >
                <TextField id="otherSize"
                  style={{ margin: '20px' }}
                  type = "number"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: 1 } }}
                  label="Input Size"
                  value={OtherSize}
                  onInput={e => setOtherSize(e.target.value)} 
                  error={errors.othersize}
                  helperText= {errors.othersize}
                  />
              </Grid>
            }
            <Grid 
              item xs={12} sm={6} md={4} lg={3}
            >
              <TextField id="ProductPrice"
                style={{ margin: '20px' }}
                type = "number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 1 } }}
                label="MSRP Price (CAD)"
                value={ProductPrice}
                onInput={e => setProductPrice(e.target.value)} 
                error={errors.productprice}
                helperText= {errors.productprice}
                />
            </Grid>
            <Grid 
              item xs={12} sm={6} md={4} lg={3}
            >
              <TextField id="ProductWeight"
                type = "number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 1 } }}
                style={{ margin: '20px' }}
                label="Specific Gravity - G/ML"
                value={ProductWeight}
                onInput={e => setProductWeight(e.target.value)}
                error={errors.productweight}
                helperText= {errors.productweight}
                />
            </Grid>
            <Grid 
              item xs={12} sm={6} md={4} lg={3}
            >
              <FormControl
                    style={{ margin: '20px' }} className={classes.formControl} required
                >
                    <InputLabel id="ProductClass" 
                    error={errors.productClass}
                    InputLabelProps={{ shrink: true }} style={ProductClass ? {
                        transform: 'translate(0, 1.5px) scale(0.75)',
                        transformOrigin: 'top left',
                    } : {}}>Product Class *</InputLabel>
                    <ReactSelect
                    name="ProductClass"
                    labelId="ProductClass"
                    id="ProductClass"
                    placeholder="Product Class *"
                    styles={selectStyles}
                    error={errors.productClass}
                    onChange={(val) => {handleChange({ target: { name: "ProductClass", value: val.value}})}}
                    components={{ DropdownIndicator }}
                    value={ProductClass ? {
                        value: ProductClass,
                        label: productClassOptions.find(row => row?.value == ProductClass)?.label
                      } : null}
                    options={productClassOptions}
                    ></ReactSelect>
                    {(errors.productclass) && <FormHelperText htmlFor='render-select' error>{errors.productclass}</FormHelperText>}
                </FormControl>
            </Grid>
            {ProductClass === "other" &&
              <Grid 
                item xs={12} sm={6} md={4} lg={3}
              >
                <TextField id="otherClass"
                  style={{ margin: '20px' }}
                  label="Input Class"
                  type = "text"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: 1 } }}
                  value={OtherClass}
                  onInput={e => setOtherClass(e.target.value)} 
                  error = {errors.otherclass}
                  helperText = {errors.otherclass}
                  />
              </Grid>
            }
            <Grid 
              item xs={12} sm={6} md={4} lg={3}
            >
              <TextField id="productUsage"
                type = "number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 1 } }}
                style={{ margin: '20px' }}
                label="mLs per Use"
                value={ProductUsage}
                onInput={e => setProductUsage(e.target.value)} 
                error = {errors.productusage}
                helperText = {errors.productusage}
                />
              </Grid>
              <Grid 
              item xs={12} sm={6} md={4} lg={3}
            >
              <TextField id="productColor" required
                style={{ margin: '20px' }}
                label="Product Color"
                value={ProductColor}
                onInput={e => setProductColor(e.target.value)}
                error = {errors.productcolor}
                helperText = {errors.productcolor}
                />
            </Grid>
            <Grid item xs={12}>
              <Grid 
                container 
                spacing={2}
                justifyContent="flex-start"
              >  
                <IconButton 
                  aria-label="colorPicker"
                  color="secondary"
                  onClick={()=> setDisplayColorPicker(state => !state)}
                  style={{padding:0, marginRight:"10px"}}
                >
                  <ColorLensIcon style={{width:"40px", height:"40px"}}/>
                </IconButton>
                <Typography variant='button'>Color Picker</Typography>
              </Grid>
              {displayColorPicker &&             
                <SketchPicker
                  color={ProductColor}
                  onChangeComplete={(color)=> setProductColor(color.hex)}
                />
              }
            </Grid>
            <Grid item xs={3}>
              <Box bgcolor={ProductColor} color="background.paper" p={0.7} style={{ margin: '20px', marginBottom: '40px' }}>
                Display Color
              </Box>
            </Grid>
          </Grid>
        </div>
      </form>
      <Box display="flex" justifyContent="flex-end">
        <ButtonLink
          onClick={handleCancel}
          color='blue'
          name='cancel' />
        <ButtonLink
          onClick={handleSubmit}
          color='pink'
          name='submit' />
        </Box>
    </MainPage>
  )
}


export default UpdateProduct
