import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import {AlacartTables} from './AlacarteTables';
import {
    generateMultiDaysTanksChartData, 
    calculateDays, 
    generateMultiDaysPackagesReportData, 
    buildMultiDaysTanksChartData,
    bulidMultiDaysPackagesChart,
    buildInLinePackagesChartData,
    buildInLineAlacartePackagesChartData,
    calculateMultiReportBlendedCost
} from './helpers';


const useStyles = makeStyles( {
    heading1: {
        marginRight: 3,
        fontWeight: 500,
    },
    heading2: {
        marginRight: 40,
        fontWeight: 500,
    },
    heading3: {
        marginRight: 59,
        fontWeight: 500,
    },
    heading4: {
        fontSize: 30,
        fontWeight: 500,
    },
    headingTit: {
        fontSize: 33,
        fontWeight: 400,
        marginTop: 20
    },
} );



export const ChartGenerationAvg = ( props ) =>
{
    const {siteReport, days: dd, from, to } = props;

    const classes = useStyles();

    const displayAlacarte = isReportsHaveAlacarte(siteReport);
    const {fromDateTime, toDateTime, days} = calculateDays(dd, from, to);
    //tanks
    const tanksData = generateMultiDaysTanksChartData(siteReport);
    const {optionsLine, seriesLine} = buildMultiDaysTanksChartData(tanksData, toDateTime, fromDateTime);

    //packages
    const packagesData = generateMultiDaysPackagesReportData(siteReport, false);
    const {data} = bulidMultiDaysPackagesChart(packagesData);
    
    const inlinePackagesChartData = buildInLinePackagesChartData(siteReport, days, toDateTime, fromDateTime);
    const inlineAlacarteChartData = buildInLineAlacartePackagesChartData(siteReport, days, toDateTime, fromDateTime);
    const bcpc = calculateMultiReportBlendedCost(packagesData);

    return (
        <>
            <Typography>
                <span className={ classes.heading1 }>Site Name:</span>
                { props?.site?.siteName }
            </Typography>
            <Typography>
                <span className={ classes.heading2 }>From: </span>
                { moment( fromDateTime ).format( "YYYY/MM/DD" ) }
            </Typography>
            <Typography>
                <span className={ classes.heading3 }>To: </span>
                { moment( toDateTime ).format( "YYYY/MM/DD" ) }
            </Typography>
            <br />
            <Chart
                options={ optionsLine }
                series={ seriesLine }
                type="bar"
                height={ 250 }
            />
            <Chart
                options={ data.options }
                series={ data.series }
                type="donut"
                height={ 250 }
            />
            <Grid 
                container 
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} style={{ minWidth: 200, maxWidth: 255 }}>
                <Typography>
                {`Avg. Blended Cost Per Car: $${bcpc}`}
                </Typography>
                </Grid>
            </Grid>
            {displayAlacarte && <AlacartTables reports={siteReport}/>}
            {inlinePackagesChartData?.length > 0 && <center><p  className={ classes.headingTit }> Wash Packages</p></center>}
            { inlinePackagesChartData?.length > 0 &&

            inlinePackagesChartData.map( ( packagData, index ) =>
                {
                    return (
                        <>
                            <p key={ 'packagesChartData' + `${ packagData?._id }` + index } className={ classes.heading4 }> { packagData?.packageName }</p>
                            <ReactApexChart
                                key={ 'packages-ChartData' + `${ packagData?._id }` + 'packageseries' + index }
                                options={ packagData?.optionLine }
                                series={ packagData?.seriesPackage }
                                type="line"
                                height={ 350 }
                            />
                        </>
                    );
                } ) 
            }

            {displayAlacarte && 
                <>
                <center> <p  className={ classes.headingTit }> A La Carte Packages</p></center>
                    { ( inlineAlacarteChartData.length > 0) &&

                    inlineAlacarteChartData.map( ( packagData, index ) =>
                    {
                    return (
                        <>
                            <p key={ 'packagesChartData' + `${ packagData?._id }` + index } className={ classes.heading4 }> { packagData?.packageName }</p>
                            <ReactApexChart
                                key={ 'packages-ChartData' + `${ packagData?._id }` + 'packageseries' + index }
                                options={ {...packagData?.optionLine, colors: ['#5432a8', '#008ffb']} }
                                series={ packagData?.seriesPackage }
                                type="line"
                                height={ 350 }
                            />
                        </>
                    );
                    } )
                    }
                </>
            }
        </>
    );
};

const isReportsHaveAlacarte = (reports)=> {
    const isReportHasAlacarte = (report) => report?.aLacartePackages.length > 0;
    return reports.some(isReportHasAlacarte);
};
