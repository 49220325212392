
import { components } from 'react-select'


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props} style={{padding: '6px'}}>
          <svg class="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
        </components.DropdownIndicator>
  );
};

const selectStyles = {
    control: (styles) => ({ ...styles,
        marginTop: '16px',
        fontSize: '1rem',
        border: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
        borderRadius: '0',
        minHeight: '20px'
    }),
    indicatorContainer: (styles) => ({ ...styles,
        padding: '6px',
    }),
    indicatorSeparator: (styles) => ({ ...styles,
        display: 'none',
    }),
    valueContainer: (styles) => ({ ...styles,
        padding: '0'
    }),
}

export default DropdownIndicator;
export { selectStyles };