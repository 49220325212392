import { createContext } from "react";

export const authContext = createContext({
  token: null,
  user: {
    _id: null,
    name: null,
    email: null,
    password: null,
    role: null,
    phone: null
  },
  updateAuthContext: token => {}
});

export default authContext;
