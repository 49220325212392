import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Chart from "react-apexcharts";
import {AlacartTables} from './AlacarteTables';
import {
  generateTanksChartData, 
  generatePackagesReportData, 
  buildTanksChartData, 
  buildPackagesData, 
  calculateLastReportBlendedCost
} from './helpers';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
  heading1: {
    marginRight: 10,
    fontWeight: 500,
  },
  heading2: {
    marginRight: 10,
    fontWeight: 500,
  },
  heading3: {
    marginRight: 59,
    fontWeight: 500,
  },
});

export const ChartGeneration = (props) => {

  const { tanks, packages, toDateTime, fromDateTime, aLacartePackages } =
    props.siteReport[0];

    const classes = useStyles();
    const tanksData= generateTanksChartData(tanks);
    const {optionsLine, seriesLine} = buildTanksChartData(tanksData, toDateTime, fromDateTime);
    const packagesData = generatePackagesReportData(packages);
    const {data} = buildPackagesData(packagesData);
    const bcpc = calculateLastReportBlendedCost(packages);
  return (
    <>
      <Typography>
        <span className={classes.heading1}>Site Name:</span>
        {props?.site?.siteName}
      </Typography>
      <Typography>
        <span className={classes.heading2}>Report Date:</span>
        {moment(fromDateTime).format("YYYY/MM/DD")}
      </Typography>
      <Chart
        options={optionsLine}
        series={seriesLine}
        type="bar"
        height={250}
      />
      <Chart
        options={data.options}
        series={data.series}
        type="donut"
        height={250}
      />
      <Grid 
        container 
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} style={{ minWidth: 200, maxWidth: 255 }}>
          <Typography>
          {`Blended Cost Per Car: $${bcpc}`}
          </Typography>
        </Grid>
      </Grid>

      {aLacartePackages.length > 0 && <AlacartTables reports={props.siteReport}/>}
    </>
  );
};
