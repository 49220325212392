import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import TableRow from '@material-ui/core/TableRow';

const StyleTableRow = (props) => {


    const StyledTableRow = withStyles(theme => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#d7ebf7"
            }
        }
    }))(TableRow);
    return (
        <StyledTableRow >
            {props.children}
        </StyledTableRow>
    )
}

export default StyleTableRow
