import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from "react";
import SubPage from "../Layout/SubPage"
import { Box } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import ReactSelect from 'react-select'

const Logs = () => {

    const [logs, setLogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState([]);
    const [names, setNames] = useState([]);
    const [name, setName] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const fetchLogs = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/auth/logs")
            setLogs(fetchLogs.data);
            setCategories([...new Set(fetchLogs.data.map(log => log.category.trim()))].sort((a,b) => (a.toLowerCase().localeCompare(b.toLowerCase()))))
            setNames([...new Set(fetchLogs.data.map(log => log.name.trim()))].sort((a,b) => (a.toLowerCase().localeCompare(b.toLowerCase()))))
        };
        fetchData();
    }, []);


    const styles = {
        container: (styles) => ({ ...styles,
            display: "inline-block",
            marginBottom: "20px",
            marginLeft: '10px',
            minWidth: '200px'
        }),
    }

    return (
        <SubPage color='yellow' name='Activity Logs'  >
            <ReactSelect
                name="Category"
                labelId="Category"
                id="Category"
                placeholder="Filter by Category"
                isMulti
                onChange={setCategory}
                styles={styles}
                options={categories.map((cat) => (
                    {
                        value: cat, 
                        label: cat
                    }
                ))}
            ></ReactSelect>

            <ReactSelect
                name="Name"
                labelId="Name"
                id="Name"
                placeholder="Filter by User Name"
                isMulti
                onChange={setName}
                styles={styles}
                options={names.map((name) => (
                    {
                        value: name, 
                        label: name
                    }
                ))}
            ></ReactSelect>
            <Box marginTop={"30px"}>
                <div style={{ maxHeight: 300, overflow: 'auto' }}>
                    {logs.map((log) => {
                        return (category.length == 0 || category.some(cat => cat.value === log.category)) && 
                        (name.length == 0 || name.some(name => name.value === log.name)) && 
                        <Alert severity="info" key={log.creationDate} >
                            <strong>{log.name}</strong>: <i>{log.comment}</i> on {moment(log.creationDate).format("YYYY/MM/DD h:mm a")}
                        </Alert>
                    })}
                </div>
            </Box>
        </SubPage>
    )
}

export default Logs
