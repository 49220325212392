import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import H3 from "@material-tailwind/react/Heading6";


const MainPage = (props) => {
    const useStyles = makeStyles((theme) => ({
        pageContent: {
            padding: theme.spacing(2),
            marginTop: '20px',
        },
        header: {
             padding: '10px',
            [theme.breakpoints.up('sm')]: {
                height: '70px',
              },
              [theme.breakpoints.only('sm')]: {
                minheight: '40px',
              },
        }
    }));
    const classes = useStyles();


    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >

                <Card className={classes.pageContent} elevation={5}>
                    <div style={{ width: 'fit-content', padding: '10px' }}>
                        <CardHeader className={classes.header} color={props.color}>
                            <H3 color="white">{props.name}</H3>
                        </CardHeader>
                    </div>
                    {props.children}
                </Card>
            </Grid>
        </Grid>
    )
}

export default MainPage
