import React, { useEffect, useState } from "react";
import MainPage from "../Layout/MainPage";
import SitesDropdown from "../Util/SitesDropdown";
import axios from "axios";
import LeadText from "@material-tailwind/react/LeadText";
import { ChartGenerationAvg } from "./MultidayChart";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Box } from "@material-ui/core";
import ButtonLink from "../Util/ButtonLink";
import {useGetReportsBySite} from "../../hooks/queries/useGetReportsBySite";
import moment from "moment";

export const MultiDayReport = React.memo((props) => {
  const [selectedToDate, setSelectedToDate] = React.useState(null);
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [type, setType] = useState("");
  const [reportDays, setReportDays] = useState(0);
  const [title, setTitle] = useState("");

  const [siteSelected, setSiteSelected] = useState(null);

  const {data: report = [] } = useGetReportsBySite(
    siteSelected?._id, 
    !!type ? 
    {
        periodFrom: selectedFromDate,
        periodTo: selectedToDate
    }
      : null 
    );

    const handleDateFromChange = async (date) => {
        setSelectedFromDate(date.toLocaleDateString());
        setSiteSelected(null)
    };

    const handleDateToChange = async (date) => {
        setSelectedToDate(date.toLocaleDateString());
        setSiteSelected(null)
    };
  
  // fetching data from dropdown component
  const handleClick = (site) => {
    setSiteSelected(site);
  };

  return (
    <MainPage name="Multiday Report" color="red">
        <>
          <Box display="flex">
            <ButtonLink
              onClick={() => {
                setReportDays(7);
                setType("week");
                setTitle("Last 7 days Report")
                handleDateToChange(moment().subtract(1, 'd').toDate());
                handleDateFromChange(moment().subtract(7, 'd').toDate());
                setSiteSelected(null)
              }}
              color="pink"
              name="Last 7 days"
            />
            <ButtonLink
              onClick={() => {
                setType("month");
                setReportDays(30);
                setTitle("Last 30 days Report")
                handleDateToChange(moment().subtract(1, 'd').toDate());
                handleDateFromChange(moment().subtract(30, 'd').toDate());
                setSiteSelected(null)
              }}
              color="pink"
              name="Last 30 days"
            />
            <ButtonLink
              onClick={() => {
                setType("custom");
                setReportDays(0);
                setTitle("Please Select Dates: 'From' Date should be lesser than 'To' Date ")
                setSiteSelected(null)
              }}
              color="pink"
              name="Custom Dates"
            />
          </Box>
          {type === "custom" ? (
            <>
              <Box display="flex" justifyContent="flex-end">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ marginTop: 0, paddingRight: "10px" }}
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    id="date-picker-dialog"
                    label="From "
                    format="yyyy-MM-dd"
                    autoOk={true}
                    maxDate={new Date()}
                    value={selectedFromDate}
                    onChange={handleDateFromChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ marginTop: 0, paddingRight: "10px" }}
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    id="date-picker-dialog"
                    label="To "
                    format="yyyy-MM-dd"
                    autoOk={true}
                    value={selectedToDate}
                    maxDate={new Date()}
                    onChange={handleDateToChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </>
          ) : (
            <></>
          )}
        </>
      {type ? (
        <>
        <LeadText color="red"> {title}</LeadText>
        <SitesDropdown onShowClick={handleClick} />
          {siteSelected ? (
            <>
              {report.length > 0 ? (
                 <>
                <ChartGenerationAvg site={siteSelected} siteReport={report} days={reportDays} to={selectedToDate} from={selectedFromDate}/>
                </>
              
              ) : (
                <LeadText color="teal">No Data</LeadText>
              )}
            </>
          ) : (
            <>
              <LeadText color="teal">Select a Site</LeadText>
            </>
          )}
        </>
      ) : (
        <>
        <LeadText color="teal">Select any button</LeadText>
        </>
      )}
    </MainPage>
  );
});
