import Card from '@material-tailwind/react/Card'
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MobileLayout from '../Layout/MobileLayout';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Small from "@material-tailwind/react/Small";
import Avatar from '@material-ui/core/Avatar';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import Notifications from './Notifications';
const InstallerDashboard = () => {

    const [installerId, setInstallerId] = useState();
    const [installer, setInstaller] = useState([]);
    const [count, setCount] = useState(0);
    const [countConfig, setCountConfig] = useState(0);

    const history = useHistory();
    const useStyles = makeStyles((theme) => ({
        pageContent: {
            padding: theme.spacing(2),
            margin: '5px',
            width: '97%',
            height: 65,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        icon: {
            color: 'black'

        },

        box: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        paper: {
            borderRadius: '10px',
            height: '250px',
            overflow: 'auto',
            margin: '5px',
        },
        avatar: {
            marginRight: '10px'
        },
        text: {
            marginLeft: '15px',
            width: '220px',
        }
    }));



    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            const decoded = jwtDecode(token);
            setInstallerId(decoded.id);
        }
        const fetchData = async () => {
            const installerData = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/auth/installer");
            setInstaller(installerData.data);

        }
        fetchData();
    }, []);

    useEffect(() => {
        for (let i = 0; i < installer.length; i++) {
            if ((installer[i].installer1 === installerId || installer[i].installer2 === installerId) && installer[i].installationStatus !== "Completed") {
                setCount(prevState => prevState + 1);
            }
        }
        for (let i = 0; i < installer.length; i++) {
            if ((installer[i].installer1 === installerId || installer[i].installer2 === installerId) && installer[i].installationStatus === "Completed") {
                setCountConfig(prevState => prevState +1)
            }
        }
    }, [installerId, installer])

    const classes = useStyles();
    return (
        <>
            <MobileLayout color='cyan' name='Overview'>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Card className={classes.pageContent} elevation={24}>
                            <Avatar >
                                <AssignmentIcon className={classes.icon} />
                            </Avatar>
                            <Box className={classes.box}>
                                <Avatar className={classes.avatar}>
                                    <Typography className={classes.number} variant='h5'>
                                        {count}
                                    </Typography>
                                </Avatar>
                                <Box className={classes.text}>
                                    <Typography>
                                        You have new sites assigned
                                    </Typography>
                                    <Small color='blue'
                                        onClick={() => history.push('/sitesAssigned')}>
                                        click here to see the sites
                                    </Small>
                                </Box>
                            </Box>
                        </Card>
                        <Card className={classes.pageContent} elevation={24}>
                            <Avatar>
                                <BeenhereIcon className={classes.icon} />
                            </Avatar>
                            <Box className={classes.box}>
                                <Avatar className={classes.avatar}>
                                    <Typography className={classes.number} variant='h5'>
                                        {countConfig}
                                    </Typography>
                                </Avatar>

                                <Box className={classes.text}>
                                    <Typography>
                                        You have configured sites
                                 </Typography>
                                    <Small color='blue' onClick={() => history.push('/configuredSite')}>
                                        click here to see the sites
                                    </Small>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </MobileLayout>
            <Notifications />
        </>
    )
}

export default InstallerDashboard