import React, { useState, useEffect, useContext } from "react";
import TableCell from "@material-ui/core/TableCell";
import StyleTableRow from "../Util/StyleTableRow";
import axios from 'axios';
import {
  deleteUser,
  fetchAllUsers,
  fetchUserbyId,
  updateUserbyId,
} from "../../shared/api";
import ButtonLink from "../Util/ButtonLink";
import DialogComponent from "../Util/DialogComponent";
import { makeStyles } from "@material-ui/core/styles";
import SearchBar from "material-ui-search-bar";
import ReactLoading from "react-loading";
import swal from "sweetalert";
import jwtDecode from 'jwt-decode';
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Paper,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
//import swal from '@sweetalert/with-react'
import Swal2 from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  fields: {
    width: 220,
  },
  button: {
    marginRight: 20,
  },
}));


 
  const DeleteUserRow = (props) => {
    const { user, isSubmited, setIsSubmited, classes } = props;
    const token = localStorage.getItem('authToken');
    const decoded = jwtDecode(token);
    const [open, setOpen] = useState(false);
      const [ name, setName ] = useState("");
      const [ email, setEmail ] = useState("");
    const [phone, setPhone] = useState(null);
      const [ role, setRole ] = useState("");
    const [owner, setOwner] = useState("");
      const [ owners, setOwners ] = useState("");
    const [errors, setErrors] = React.useState({
      name: '', 
      email: '',
      phone: '',
      role: '',
      owner:''
      }); 
      const validatePhone = phone =>
      {
          return phone.toString().match(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/);
    }
  
    const validateEmail = email => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
    const userValidation = async e => 
    {
      let temp={};
      temp.name = name?"":"Please enter the Full Name";
      temp.email = email?"":"Please enter the Email";
      temp.phone = phone?"":"Please enter the Phone";
      temp.owner = owner?"":"Please select the Owner";
  
      if(temp.email === "" && email)
        temp.email = validateEmail(email)?"":"Please enter valid email";
      if(temp.phone === "" && phone)
        temp.phone = validatePhone(phone)?"":"Please enter valid phone";
      if(role !== "operator")
          temp.owner = "";
  
      setErrors({
         ...temp
       })
       
        return Object.values(temp).every(x => x === "");
    }

    const handleClose = () =>
    {
        setName("");
        setEmail("");
        setPhone("");
        setRole("");
        setOwner("");

        setOpen(false);
    };

  useEffect(() => {
    const fetchData = async () => {
      let fetchedUsers = await fetchAllUsers();
      const users = fetchedUsers.filter(
        (user) => user.role.toLowerCase() === "owner"
      );
      //console.log(users);
      setOwners(users);
    };
    fetchData();
  }, [ open, isSubmited ]);


  const handleSubmit = async () => {
    let result = await userValidation()
      //console.log('result' , result);
      if(result){
        //console.log(name, email, phone, owner);
        let body = {
          name: name,
          email: email,
          phone: phone,
          owner: owner,
        };
        const res = await updateUserbyId(user._id, body);
        if (res) {
       
          swal({
            title: "Success!",
            text: "User Updated",
            icon: "success",
            button: "OK!",
          }).then((result) => {
            try {
              axios.post(
              process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: decoded.id, comment: `User ${name}(${role}) Edited`, category: "Edit User" }
              )
          }
          catch (error) {
              console.log(error)
          }
            // Reload the Page
            //window.location.reload();
            setOpen(false);
            setIsSubmited((state) => !state);
          });
        }
      }
  };

  const handleEdit = async () => {
    try {
      const userbyid = await fetchUserbyId(user._id);
      setName(userbyid?.name);
      setEmail(userbyid?.email);
      setPhone(userbyid?.phone);
      setRole(userbyid?.role);
      setOwner(userbyid?.owner);

      setOpen(true);
    } catch (e) {
      if (e.message?.includes("404")) {
        swal({
          title: "Error!",
          text: e.response.data.error,
          icon: "error",
          button: "OK!",
        });
      }
      if (e.message?.includes("Network"))
        swal({
          title: "Error!",
          text: e.message,
          icon: "error",
          button: "OK!",
        });
    }
    
  };

  const handleDelete = async () => {
    try {
      Swal2.fire({
        title: `Delete ${user?.name}?`,
        text: `You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            const res = await deleteUser(user?._id, {});

            if (res.success == true) {
              try {
                axios.post(
                process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: decoded.id, comment: `User ${user?.name}(${user?.role}) Deleted`, category: "Delete User" }
                )
            }
            catch (error) {
                console.log(error)
            }
              swal({
                title: "Success!",
                text: res.msg,
                icon: "success",
                button: "OK!",
              }).then((result) => {
                setIsSubmited((state) => !state);
              });
            } else {
              swal({
                title: "Error!",
                text: res.msg,
                icon: "error",
                button: "OK!",
              });
            }
          })()
        }
      })

    } catch (e) {
      if (e.message?.includes("404")) {
        swal({
          title: "Error!",
          text: e.response.data.error,
          icon: "error",
          button: "OK!",
        });
      }
      if (e.message?.includes("Network"))
        swal({
          title: "Error!",
          text: e.message,
          icon: "error",
          button: "OK!",
        });
    }
  };

  return (
    <>
      <DialogComponent
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title="Edit User"
      >
        <TextField
          className={classes.fields}
          type="text"
          style={{ marginBottom: '20px' }}
          InputLabelProps={{ shrink: true }}
          label="Full Name"
          name="name"
          value={name}
          onInput={(e) => setName(e.target.value)}
          // onChange={handleInputChange}
          error = {errors.name}
          helperText = {errors.name}
        ></TextField>
        <br />
        <TextField
          className={classes.fields}
          type="text"
          style={{ marginBottom: '20px' }}
          InputLabelProps={{ shrink: true }}
          label="Email"
          name="email"
          value={email}
          onInput={(e) => setEmail(e.target.value)}
          // onInput={e => setBayNum(e.target.value)}
          // onChange={handleInputChange}
           error = {errors.email}
           helperText = {errors.email}
        ></TextField>
        <br />
        <TextField
          className={classes.fields}
          type="text"
          style={{ marginBottom: '20px' }}
          InputLabelProps={{ shrink: true }}
          label="Phone"
          name="Phone"
          value={phone}
          onInput={(e) => setPhone(e.target.value)}
          //  onChange={handleInputChange}
          // onInput={e => setBayNum(e.target.value)}
           error = {errors.phone}
           helperText = {errors.phone}
        ></TextField>
        <br />
        {role === "operator" && (
          <FormControl>
            <InputLabel id="owner" InputLabelProps={{ shrink: true }}>
              Company
            </InputLabel>
            <Select
              name="owner"
              defaultValue={user?.owner}
              className={classes.fields}
              label="Company"
              onChange={(e) => setOwner(e.target.value)}
            >
              {owners.map((user, i) => (
                <MenuItem key={i + "ursss" + user?._id} value={user?._id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogComponent>

      <StyleTableRow>
        <TableCell>
          {user?.name}
          <br />
          <span
            style={{ color: "red", fontStyle: "italic", fontSize: "0.8em" }}
          >
            {user?.role == "owner" ? "company" : user?.role}
          </span>
        </TableCell>
        <TableCell>{user?.email} </TableCell>
        <TableCell>
          <ButtonLink onClick={handleEdit} name="edit" color="pink" />
        </TableCell>
        <TableCell>
          <ButtonLink onClick={handleDelete} name="delete" color="pink" />
        </TableCell>
      </StyleTableRow>
    </>
  );
};

export default DeleteUserRow;
