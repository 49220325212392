import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

//import { Dropdown, DropdownLink } from "@material-tailwind/react";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import SearchBar from "material-ui-search-bar";
import jwtDecode from "jwt-decode";
import { Box } from "@material-ui/core";
import {useGetUserById} from "../../hooks/queries/useGetUserById";
import {useGetSites} from "../../hooks/queries/useGetSites";
import { authContext } from "../../shared/context/auth/auth-context";
import {useGetUserSites} from '../../hooks/mutations/useGetUserSites';
import ReactSelect from 'react-select'

const SitesDropdown = React.memo((props) => {
  const { user: loggedInUser } = useContext(authContext);
  const {mutateAsync: getUserSites} = useGetUserSites();
  const [locations, setLocations] = useState([]);
  const [userId, setUserId] = useState('');
  const [searched, setSearched] = useState("");

  const {data: user = null } = useGetUserById(userId);
  const {data: sites = [] } = useGetSites();

  const cancelSearch = () => {
    setSearched("");
  };

  const filtered = locations.filter((row) => {
    return row?.siteName?.toLowerCase().includes(searched.toLowerCase());
  });

  useEffect(() => {
    if(!loggedInUser){
      const token = localStorage.getItem("authToken");
      const decoded = jwtDecode(token);
      if (decoded) setUserId(decoded?.id);
    } else {
      setUserId(loggedInUser?.id);
    }
  }, []);

  useEffect(() => {
    if(user){
        if (user.role === "admin") {
          setLocations(sites.sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1));
        } else {
          (async ()=>{
            const fetchedSites = await getUserSites({userId: user?._id});
            setLocations(fetchedSites.sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1));
          })()
        }
    }
  }, [user, loggedInUser, sites]);


  const styles = {
    container: (styles) => ({ ...styles,
        display: "inline-block",
        marginBottom: "20px",
        marginLeft: '10px',
        minWidth: '200px'
    }),
}

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
        <ReactSelect
            name="Site"
            labelId="Site"
            id="Site"
            placeholder="Filter by Site"
            onChange={(location) => {
                props.onShowClick(location);
                }}
            styles={styles}
            options={locations.map((site) => (
                {
                    ...site,
                    value: site._id, 
                    label: site.siteName
                }
            ))}
        ></ReactSelect>
    </Box>
  );
});

export default SitesDropdown;

