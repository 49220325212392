const ProductData = {
    columns: [
        { id: 'productSKU', label: 'Product SKU', minWidth: 80 },
        { id: 'productDescription', label: 'Description', minWidth: 230 },
        { id: 'productColor', label: 'Color', minWidth: 100 },
        { id: 'productClass', label: 'Item Class', minWidth: 180 },
        { id: 'productSize', label: 'Container Size', minWidth: 80 },
        { id: 'productPrice', label: 'MSRP Price', minWidth: 80 },
        { id: 'edit', label: 'Edit', minWidth: 80 },
        { id: 'delete', label: 'Delete', minWidth: 80 }
    ],


    originalRows: [
        // createData('111', 'Mark ', '19, Stanley st, London, ON', '31231412312'),
        // createData('222', 'Bassim ', '293, Adelaide st, Toronto, ON', '31231412312'),
        // createData('333', 'Raman ', 'IT', '31231412312'),
        // createData('444', 'Shuwen 1', 'US', '31231412312'),
        // createData('11', 'Mason 1', '19, Stanley st, London, ON', '31231412312'),
        // createData('22', 'Customer 1', '293, Adelaide st, Toronto, ON', '31231412312'),
        // createData('33', 'Customer 1', 'IT', '31231412312'),
        // createData('44', 'Customer 1', 'US', '31231412312'),
    ]
}
// function createData(siteName, siteOwner, email) {
//     return { siteName, siteOwner, email };
// }

export default ProductData