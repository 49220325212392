import { useState, memo } from "react";
import { SiteContext } from "./site-context";
import {
  fetchOneSite,
  fetchAllCycles,
  fetchAllPackages,
  fetchAllTanks,
  fetchAllBays,
  fetchAllControllers
} from "../../api";

export const SiteContextStates = memo(props => {
  //site related objects to pass via context provider
  const [site, setSite] = useState(null);
  const [packages, setPackages] = useState([]);
  const [cycles, setCycles] = useState([]);
  const [tanks, setTanks] = useState([]);
  const [bays, setBays] = useState([]);
  const [controllers, setControllers] = useState([]);

  // update context objects from the children components
  async function updateSiteContext(id) {
    try {
      //console.log("update site id called.....");
      const site = await fetchOneSite(id);
      if (site) {
        setSite(site);
      }
      const sitePackage = await fetchAllPackages(id);
      if (sitePackage) {
        setPackages(sitePackage);
      }
      const siteCycle = await fetchAllCycles(id);
      if (siteCycle) {
        setCycles(siteCycle);
      }
      const siteTank = await fetchAllTanks(id);
      if (siteTank) {
        setTanks(siteTank);
      }
      const siteBay = await fetchAllBays(id);
      if (siteBay) {
        setBays(siteBay);
      }
      const siteController = await fetchAllControllers(id);
      if (siteController) {
        setControllers(siteController);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <SiteContext.Provider
        value={{
          site,
          packages,
          cycles,
          tanks,
          bays,
          controllers,
          updateSiteContext
        }}
      >
        {props.children}
      </SiteContext.Provider>
    </>
  );
});
