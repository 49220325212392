import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { TableContainer, TablePagination } from "@material-ui/core";
import SiteData from "../../Sites/SiteData";
import { readAllOrders } from "../../../shared/api";
import OrderRow from "./OrderRow";

const useStyles = makeStyles({
    pageContent: {
        marginTop: 40
    },
    table: {
        minWidth: 650,

        transition: "0.5s ease"
    },
    searchBar: {
        marginBottom: "30px"
    },
    tableRow: {
        transition: "0.3s ease",
        "&:hover": {
            backgroundColor: "#b8eaff !important"
        }
    },
 
});

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.disabled
        }
    }
}))(TableRow);


const OrdersTable = (props) => {
     const { site_id, isHistory, isSubmit, setIsSubmit } = props;
    const classes = useStyles();
    const [orders, setOrders] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = useState([]);

    

    useEffect(async () => {
        //fetch orders
        const ordersReply = await readAllOrders(site_id);
        if (ordersReply?.success) {

                if (!isHistory) {
                    const ordersList = ordersReply?.orders.filter(order => (order.status != 5));
                    setOrders(ordersList);
                    setRows(ordersList);
                    const tableHeaders = SiteData.columnsAdminRequestOrdersList;
                    setHeaders(tableHeaders);
                } else {
                    const ordersList = ordersReply?.orders.filter(order => (order.status === 5)).reverse();
                    setOrders(ordersList);
                    setRows(ordersList);
                    const tableHeaders = SiteData.columnsOrdersHistoryList;
                    setHeaders(tableHeaders);
                }
        }

    }, [isSubmit]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <>
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                            {headers.map(column => (
                            <StyledTableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                            >
                                {column.label}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                    <TableBody>
                        {orders
                         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(order => (
                                <OrderRow order={order} isHistory = {isHistory} isSubmit = {isSubmit} setIsSubmit = {setIsSubmit}/>
                            ))}
                    </TableBody>
            </Table>
        </TableContainer>
            {!(orders?.length > 0) ? (<h6>No order created</h6>) : (<></>)}
            <TablePagination
                    rowsPerPageOptions={[5, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
        );
};

export default OrdersTable;