import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle } from "@material-ui/lab";
import axios from "axios";
import SubPage from '../../Layout/SubPage'
import H6 from "@material-tailwind/react/Heading6";
import moment from 'moment';
import { Box } from '@material-ui/core';//TextField
import ReactSelect from 'react-select'

export const Alerts = () => {

    const [sites, setsites] = useState([]);
    const [alerts, setalerts] = useState([]);
    const [numberOfAlerts, setNumberOfAlerts] = useState("All");
    const [site, setSite] = useState([]);
    const categories =  ['10', '20', '50', 'All'];

    useEffect(() => {
        const fetchData = async () => {
            const fetchSites = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/site/read");
            fetchSites.data = fetchSites.data.sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1);
            setsites(fetchSites.data)
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const fetchAlerts = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/alert/alerts/0");
            setalerts(fetchAlerts.data);
        };
        fetchData();
    }, []);

    //console.log(alerts)
    useEffect(() => {
        alerts.forEach((element) => {
            if (element.ack === true || element.severity === '3') {
                element.severity = "success";
            } else {
                // if still unclear, mark level 0 as error, level 1 as warning and level 2 as info
                if (element.severity === "1" || element.statusCode === "1") {
                    element.severity = "warning";
                }
                if (element.severity === "0" || element.statusCode === "0") {
                    element.severity = "error";
                }
                if (element.severity === "2" || element.statusCode === "2") {
                    element.severity = "info";
                }
            }
        })

    }, [alerts])

    const styles = {
        container: (styles) => ({ ...styles,
            display: "inline-block",
            marginBottom: "20px",
            marginLeft: '10px',
            minWidth: '200px'
        }),
    }

    return (
        <SubPage name='Latest Alerts' color='lightGreen'>
            <ReactSelect
                name="Category"
                labelId="Category"
                id="Category"
                placeholder="Select Alerts Count"
                onChange={(cat) => {setNumberOfAlerts(cat.value)}}
                styles={styles}
                defaultValue={{
                    value: "All",
                    label: "Show All Alerts"
                }}
                options={categories.map((cat) => (
                    {
                        value: cat, 
                        label: `Show ${cat} Alerts`
                    }
                ))}
            ></ReactSelect>

            <ReactSelect
                name="Site"
                labelId="Site"
                id="Site"
                placeholder="Filter by Site"
                isMulti
                onChange={setSite}
                styles={styles}
                options={sites.map((site) => (
                    {
                        value: site._id, 
                        label: site.siteName
                    }
                ))}
            ></ReactSelect>
            <br />
            <Box marginTop={"30px"}>
            {/* <TextField id="nofalerts" required
                placeholder='Number Of Alerts'
                placement="bottom-start"
                size="regular"
                        ripple="dark"
                        style={{
                            display: "inline",
                            float: "right",
                            marginBottom: "20px",
                            marginLeft: '30px'
                        }}
                        onInput={e => alertsCount(e.target.value)}
                /> */}
                <div style={{ maxHeight: 300, overflow: 'auto' }}>
                    {alerts ? (
                        alerts
                        .filter((row) => site.length === 0 || site.some(site => site.value === row.site_id))
                        .slice(0, numberOfAlerts === 'All' ? undefined : parseInt(numberOfAlerts))
                        .map((row, i) => (
                            <Alert key={i + 'al'} severity={row.statusCode == 1 ? 'warning' : row.statusCode == 2 ? 'error' : 'info'}>
                                <AlertTitle key={i + 'alertTitle1' + row._id}>{row.statusCode == 1 ? 'Controller offline!' : row.statusCode == 2 ? 'Sensor error!' : 'Update'}</AlertTitle>
                                <strong >Site:</strong>
                                {sites.map((site, i) => {
                                    return row.site_id === site._id && <i key={'ai' + i} > {site.siteName} </i>
                                })}
                                <strong>Date:</strong> <i> {moment(row.dt).format("YYYY/MM/DD h:mm a")}</i> 
                            </Alert>
                    ))) :
                        <H6 style={{ textAlign: 'center' }} color="teal">No Alerts</H6>
                    }
                </div>
            </Box>
        </SubPage>
    )
}
