import { Route, Switch, HashRouter } from "react-router-dom";
import "@material-tailwind/react/tailwind.css";
import CreateSite from "../Sites/CreateSite/CreateSite";
import UpdateSite from "../Sites/UpdateSite/UpdateSite";
import TestSensor from "../TestSensor/TestSensor";
import Site from "../Sites/Site/Site";
import Bays from "../Sites/Bays/Bays";
import Packages from "../Sites/Packages/Packages";
import UpdateInput from "../Sites/Packages/UpdateInput";
import Sites from "../Sites/Sites";
import Products from "../Products/Products";
import Distributors from "../Distributors/Distributors";
import CreateBay from "../Sites/Bays/CreateBay/CreateBay";
import UpdateBay from "../Sites/Bays/UpdateBay/UpdateBay";
import Tanks from "../Sites/Tanks/Tanks";
import TanksLevel from "../Sites/Tanks/operator/Tanks";
import CreateTank from "../Sites/Tanks/CreateTank/CreateTank";
import UpdateTank from "../Sites/Tanks/UpdateTank/UpdateTank";
import Controllers from "../Sites/Controllers/Controllers";
import CreateController from "../Sites/Controllers/CreateController/CreateController";
import UpdateController from "../Sites/Controllers/UpdateController/UpdateController";
import CreateProduct from "../Products/CreateProduct/CreateProduct";
import UpdateProduct from "../Products/UpdateProduct/UpdateProduct";
import CreateDistributor from "../Distributors/CreateDistributor/CreateDistributor";
import UpdateDistributor from "../Distributors/UpdateDistributor/UpdateDistributor";
import CreatePackage from "../Sites/Packages/CreatePackage";
import CreateCycle from "../Sites/Packages/CreateCycle";
import UpdateCycle from "../Sites/Packages/UpdateCycle";
import SideNavigation from "../Navigation/SideNavigation";
import { makeStyles } from "@material-ui/core/styles";
import Dashboard from "../Dashboard/Dashboard";
import Alerts from "../Alerts/Alerts";
import React from "react";
import {MultiDayReport} from "../Reports/MultidayReport";
import {LatestReport} from '../Reports/LatestReport'
import { ProductUsage } from "../Reports/ProductUsageReport";
import Inventory from "../Inventory/coordinator/Inventory";
import Inventories from "../Inventory/coordinator/Inventories";
import Reorder from "../Inventory/coordinator/Reorder";
import Management from "../Management/Management";
import AlertPreferences from "../Alerts/AlertPreferences";

const useStyles = makeStyles({
  container: {
    display: "flex"
  },
  content: {
    margin: "auto",
    width: "78%"
  }
});

function Coordinator({ logoutHandler }) {
  const classes = useStyles();

  return (
    <>
      <HashRouter>
        <div className={classes.container}>
          <SideNavigation logoutHandler={logoutHandler} />
          <div className={classes.content}>
            <Switch>
              <Route path="/management">
                <Management screen={'admin'}/>
              </Route>
              <Route path="/createSite">
                <CreateSite />
              </Route>
              <Route path="/updateSite">
                <UpdateSite />
              </Route>
              <Route path="/testSensor">
                <TestSensor />
              </Route>
              <Route path="/sites">
                <Sites />
              </Route>
              <Route path="/products">
                <Products />
              </Route>
              <Route path="/createProduct">
                <CreateProduct />
              </Route>
              <Route path="/updateProduct">
                <UpdateProduct />
              </Route>
              <Route path="/distributors">
                <Distributors />
              </Route>
              <Route path="/createDistributor">
                <CreateDistributor />
              </Route>
              <Route path="/updateDistributor">
                <UpdateDistributor />
              </Route>
              <Route path="/controllers">
                <Controllers />
              </Route>
              <Route path="/createController">
                <CreateController />
              </Route>
              <Route path="/updateController">
                <UpdateController />
              </Route>
              <Route path="/site">
                <Site />
              </Route>
              <Route path="/bays">
                <Bays />
              </Route>
              <Route path="/createBay">
                <CreateBay />
              </Route>
              <Route path="/updateBay">
                <UpdateBay />
              </Route>
              <Route path="/tanks">
                <Tanks />
              </Route>
              <Route path="/tanksLevel">
                <TanksLevel />
              </Route>
              <Route path="/updateTank">
                <UpdateTank />
              </Route>
              <Route path="/createTank">
                <CreateTank />
              </Route>
              <Route path="/createPackage">
                <CreatePackage />
              </Route>
              <Route path="/packages">
                <Packages />
              </Route>
              <Route path="/updateInput">
                <UpdateInput />
              </Route>
              <Route path="/createCycle">
                <CreateCycle />
              </Route>
              <Route exact path="/updateCycle">
                <UpdateCycle />
              </Route>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route path="/alerts">
                <Alerts />
              </Route>
              <Route path="/alertPreferences">
                <AlertPreferences />
              </Route>
              <Route path="/inventory">
                <Inventory />
              </Route>
              <Route path="/inventories">
                <Inventories />
              </Route>
              <Route path="/reorders">
                <Reorder />
              </Route>
              <Route path="/latestReport">
                <LatestReport />
              </Route>
              <Route path="/productUsage">
                <ProductUsage />
              </Route>
              <Route path="/multidayReport">
                <MultiDayReport />
              </Route>
            </Switch>
          </div>
        </div>
      </HashRouter>
    </>
  );
}

export default Coordinator;
