import React, { useEffect, useContext } from "react";
import { Link, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import SiteLayout from "./SiteLayout";
import Button from "@material-tailwind/react/Button";
import ButtonLink from "../../Util/ButtonLink";
import swal from "sweetalert";
import { makeStyles,useTheme } from '@material-ui/core/styles';
import ConfirmDialog from "../../Util/ConfirmDialog/ConfirmDialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import { SiteContext } from "../../../shared/context/site/site-context";
import {
  fetchOneSite
} from "../../../shared/api";
import axios from "axios";
import { authContext } from "../../../shared/context/auth/auth-context";


const SiteDetails = function SetSite(props) {
  const { site } = useContext(SiteContext);
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    emptyPackages: {
      minHeight: '80px',
  },
}));
const classes = useStyles();
const { user } = useContext(authContext);
const theme = useTheme();

  let _id = props.site_id,
    siteId = props.siteId,
    CarWashName = props.siteName,
    CarWashOwner = props.siteOwner,
    fixedGridCols = props.fixedGridCols;

  const SiteDetails = [
  {label:"Bays", path:"/bays/"}, 
  {label:"Tanks", path:"/tanks/"},
  {label:"Controllers", path:"/controllers/"}
  ];

  const maxRowElements={xs:1, sm:3, md:4, lg:6, xl:7};
  const spacing = {
  mobile: fixedGridCols/maxRowElements.xs, 
  ipad: fixedGridCols/maxRowElements.sm,
  nesthub: fixedGridCols/maxRowElements.md,
  laptop: fixedGridCols/maxRowElements.lg,
  desktop: fixedGridCols/maxRowElements.xl,
  }

  const ButtonDetails = {
    update: {label:"Update Site", color:"orange", path:"/updateSite"}, 
    delete: {label:"Delete Site", color:"red"},
    back: {label:"Back To All Sites", color:"blue", path:"/Sites/"}
  };

  const numberOfButtons = Object.keys(ButtonDetails).length;
  const buttonStartSpacing = {
    mobile: maxRowElements.xs - numberOfButtons, 
    ipad: maxRowElements.sm - numberOfButtons,
    nesthub: maxRowElements.md - numberOfButtons,
    laptop: maxRowElements.lg - numberOfButtons,
    desktop: maxRowElements.xl - numberOfButtons
  }

  const matches = useMediaQuery(theme.breakpoints.only('xs'));

  const [AddressId, setAddressId] = React.useState(site?.addressId);
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {

      try {
        const site = await fetchOneSite(_id);
        if (site) {
          setAddressId(site.addressId);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);

  async function handleDelete() {
    let body = {
      siteId: _id
    };
    
    let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/site/delete", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem("authToken")}`
      },
      mode: "cors"
    }).then((response) => response.json());

    response.then(function (result) {
        swal({
          title: "Success!",
          text: result?.message,
          icon: "success",
          button: "OK!",
          width: '100px'
      }).then(function() {
         history.push({
          pathname: "/Sites/"
        });
      });
      });
      
      try {
            await axios.post(
              process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Site ${CarWashName} Delete`, category: "Delete Site" }
            )
          }
          catch (error) {
            console.log(error)
          }
  }

  return (
    <>
      <SiteLayout name='Bays | Tanks | Controllers' color='cyan'>
        {/* two types of layout: containers and items. */}
        <Grid container className = {classes.emptyPackages} spacing={2}>
        {SiteDetails.map(row => (
          // Item width are sized relative to their parent element., internally sized as percentages
          // five grid breakpoints: xs, sm, md, lg, and xl
          <Grid item 
            xs={spacing.mobile} 
            sm={spacing.ipad} 
            md={spacing.nesthub} 
            lg={spacing.laptop} 
            xl={spacing.desktop}>
            <Button color="purple" buttonType="link" ripple="dark">
              <Link
                style={{ cursor: "pointer" }}
                href={_id}
                onClick={e => {
                  e.preventDefault();
                  history.push({
                    pathname: row.path,
                    state: {
                    _id: _id,
                    siteId: siteId,
                    siteName: CarWashName,
                    siteOwner: CarWashOwner
                    }
                  });
                }}
                color="inherit"
                underline="none"
              >
                {row.label.length <= (matches?11:15) ? row.label: row.label.substr(0, (matches?11:15)) + "..."}
              </Link>
            </Button>
          </Grid>
          ))}
        </Grid>  

              <Grid container spacing={2} justifyContent="flex-end" >
      
              {
                user?.role === 'admin' &&
                <>
                    <Grid item
                  >
                      <ButtonLink
                          color={ButtonDetails.update?.color}
                          name={ButtonDetails.update?.label}
                          onClick={() => {
                              history.push({
                                  pathname: ButtonDetails.update?.path,
                                  state: {
                                      _id: _id,
                                      siteId: siteId,
                                      siteName: CarWashName,
                                      siteOwner: CarWashOwner,
                                      addressId: AddressId
                                  }
                              });
                          }}
                      />
                  </Grid>


                  <Grid item

                  >
                      <ButtonLink
                          name={ButtonDetails.delete?.label}
                          color={ButtonDetails.delete?.color}
                          onClick={() => {
                              setConfirmOpen(true);
                          }}
                      />
                  </Grid>
                </>
              }

                  <Grid item

                  >
                      <ButtonLink
                          color={ButtonDetails.back?.color}
                          name={ButtonDetails.back?.label}
                          onClick={() => {
                              history.push({
                                  pathname: ButtonDetails.back?.path
                              });
                          }}
                      />
                  </Grid>


        <ConfirmDialog
          title={ButtonDetails.delete?.label}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleDelete}
        >
          Are you sure you want to delete this Site completely?
        </ConfirmDialog>
         
          </Grid>       
      </SiteLayout>
    </>
  );
};

export default SiteDetails;
