import React, { useState, useEffect } from 'react'
import MobileLayout from '../Layout/MobileLayout'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, TextField, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios'
import jwtDecode from 'jwt-decode';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ButtonLink from '../Util/ButtonLink';
import moment from 'moment';
import swal from 'sweetalert';
import InstallerClosedRequests from './InstallerClosedRequests';
import Alert from "@material-ui/lab/Alert";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({

    icon: {
        color: 'black'

    },

    paper: {
        borderRadius: '10px',
        height: '250px',
        overflow: 'auto',
        margin: '5px',
    },
    request: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '500',
        color: 'rgb(7,36, 51 , 60%)'
    },
    details: {
        alignItems: 'left',
        display: 'flex',
        justifyContent: 'space-between'
    },

    helper: {
        borderLeft: `2px solid cyan`,
        padding: theme.spacing(1, 2),
        width: '100%',

    },

    description: {
        fontWeight: 500,
    },
    info: {
        display: 'flex'

    },
    para: {
        marginLeft: "5px",
        color: '#2e6b99',
    },
    box: {
        marginBottom: 10,
        marginRight: 20
    },
    details: {
        alignItems: 'left',
        display: 'flex',
        justifyContent: 'flex-start'
    },
}));


const Notifications = () => {
    const initialValues = {
        comment: ""
    }
    const [user, setUser] = useState([]);
    const [request, setRequest] = useState([]);
    const [open, setOpen] = useState(false);
    const [sites, setSites] = useState([]);
    const [values, setValues] = useState(initialValues);
    const [address, setAddress] = useState([]);
    const [selectedRequest, setSelectedRequest] = useState([]);
    const [list, setList] = React.useState(false);
    const [error, setError] = useState("");
    const [expanded, setExpanded] = useState(false);
    let oldsite;
    const classes = useStyles();
    const handleClickOpen = (data) => {
        setOpen(true);
        setSelectedRequest(data);


    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    useEffect(() => {
        const fetchData = async () => {
            const request = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/request/requests");
            const sites = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/site/read")
            const address = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/auth/address")
            setAddress(address.data)
            setRequest(request.data);
            setSites(sites.data)
        }
        fetchData();

        let authToken = localStorage.getItem("authToken") || null;

        let user = null;
        if (authToken) {
            user = jwtDecode(authToken);
            setUser(user.id);
        }
    }, [list])

    useEffect(() => {
        request.map((req, i) => {
            if((req.installerId === user) && !req.isClosed)
                setExpanded(`panel${i + 1}`)
        });
    }, [request])

    const handleSubmit = async () => {
        let body = {
            _id: selectedRequest._id,
            comment: values.comment
        }


        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK + "api/request/resolve", body);
            swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: "OK!",
                width: '100px'
            }).then(function(result)
            {
                setOpen(false);
                setList(!list);
            });
        }
        catch (e) {
            if(e.response.data.errors && e.response.data.errors[0].msg.includes(""))
            {
                setError(e.response.data.errors[0].msg);
                setTimeout(() => {
                setError("");
                }, 5000);
            } else if (e.message.includes("status")) {
                swal({
                    title: "Error!",
                    text: "User Aleardy Exist",
                    icon: "error",
                    button: "OK!",
                })
            } else if (e.message.includes("Network"))
                swal({
                    title: "Error!",
                    text: e.message,
                    icon: "error",
                    button: "OK!",
                    width: '100px'
                })
        }

    }

 

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

let check = false;
    return (
        < div style={{ marginTop: '-10vh' }}>
            <MobileLayout name='Maintenence' >
                <Paper className={classes.paper}>
                    <List className={classes.root}>
                    {
                        request.map((req, i) => {
                            return(
                            (req.installerId === user) && oldsite != req.siteId && 
                            !req.isClosed ?
                            
                                <div key={i + "req"}>
                                        <Accordion expanded={expanded === `panel${i + 1}`
                                         } onChange={handleChange(`panel${i + 1}`)}>

                                        <AccordionSummary  expandIcon={<ExpandMoreIcon/>} aria-controls="panel1c-content" id="panel1c-header">
                                            <ListItem key={"req" + i} >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <ApartmentIcon className={classes.icon} />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                {   check = (req.installerId === user || check), oldsite = req.siteId ,sites.map((site) => {
                                                    return (site._id === req.siteId) && address.map((add, i) => {
                                                        return (
                                                            (add.addressId === site.addressId) && 
                                                            <>
                                                            <ListItemText key={"ad" + i} primary={site.siteName} secondary={`${add.addressLine}, ${add.city}, ${add.province}, ${add.postalCode} `} />
                                                           
                                                            </>
                                                            )
                                                    })
                                                })}
                                            </ListItem>
                                            </AccordionSummary>
                
                {request.map((req, i) => {
                return (
                    !req.isClosed && req.siteId == oldsite &&
                          <AccordionDetails key={"rd" + i} className={classes.details} >
                                    
                                    <div className={classes.info} />
                                    <div className={clsx(classes.helper)}>

                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Requested By: 
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.requestedBy}
                                            </Typography>
                                        </div>
                          
                 
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Request:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.request_msg}
                                            </Typography>
                                        </div>
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Request Date:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {moment(req.requestDate).format('YYYY/MM/DD h:mm a')}
                                            </Typography>
                                        </div>
                                   
                                        {req.scheduledAt && <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Scheduled On:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.scheduledAt && moment(req.scheduledAt).format('YYYY/MM/DD')}
                                            </Typography>
                                        </div>
                                        }

                                 
                                    </div>
                                    <Box className={classes.box} display='flex' alignItems='center' justifyContent='flex-end'>
                                        <ButtonLink color='red' name='Resolve' onClick={() => handleClickOpen(req)} />
                                    </Box>
                                  
    
                                </AccordionDetails>
                           )   
                        })
                    }
                                        </Accordion>
                                </div>
                                : "" 
                            )
                        }) 
                        }
                    </List>
                    {!check &&  <Typography className={classes.request}>No Maintenence Requests</Typography>}
                </Paper>
            </MobileLayout >
            <InstallerClosedRequests list={list}/>
            
            <Dialog
                open={open}
                onClose={handleClose}

            >
                <DialogTitle id="alert-dialog-title">
                    {"Maintenence Information"}
                </DialogTitle>
                <DialogContent>

                    <DialogContentText id="alert-dialog-description">
                        Request: {selectedRequest.request_msg}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Scheduled On: {moment(selectedRequest.scheduledAt).format('YYYY/MM/DD')}
                    </DialogContentText>
                    <TextField
                        className={classes.textfield}
                        multiline
                        required
                        rows={2}
                        rowsMax={2}
                        value={values.comment}
                        onChange={handleInputChange}
                        name='comment'
                        autoFocus
                        margin="dense"
                        id="commemnt"
                        label="Report"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                {error && <Alert severity="error">{error}</Alert>}
                </DialogContent>
                <DialogActions>
                    <ButtonLink name='Resolve' onClick={handleSubmit} autoFocus></ButtonLink>

                </DialogActions>
            </Dialog>

        </div >
    )
}

export default Notifications


