import {useMutation, useQuery} from 'react-query';
import axios from "axios";

const handleFetchSites = async () => {
    const sitesData = await axios.get(
        process.env.REACT_APP_BACKEND_LINK +
          "api/site/read/"
      );
      
      if (sitesData?.data) return sitesData?.data;

      return [];
};

export const useGetSites = () => {
  return useQuery(
    ['sites-*'],
    () => handleFetchSites(),
    { 
        staleTime: 1000*60*60*24
    }
  );
};