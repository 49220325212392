import { Typography } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { makeStyles } from "@material-ui/core/styles";
import {
    generatePackagesReportData, 
    generateMultiDaysPackagesReportData
} from './helpers'
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles( {
    heading1: {
        marginRight: 3,
        fontWeight: 500,
    },
    heading2: {
        marginRight: 40,

        fontWeight: 500,
    },
    heading3: {
        marginRight: 59,
        fontWeight: 500,
    },
    heading4: {
        fontSize: 30,
        fontWeight: 500,
        marginTop: 20,
        marginBottom: 20
    },
} );


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export const AlacartTables = (props) => {
  const { reports } = props;
  const classes = useStyles();
  const [numberOfReports, setNumberOfReports] = useState(reports?.length);
  const [packageData, setPackageData] = useState({});

  useEffect(()=>{

    if(numberOfReports === 1) setPackageData(generatePackagesReportData(reports[0].aLacartePackages));  
    if(numberOfReports > 1) setPackageData(generateMultiDaysPackagesReportData(reports, true));

  },[]);


 const generateAlacarteTables = (packageData, i) =>{
    return ( 
        <Grid item key={i + 'al-la-carte'} xs={4} style={{ minWidth: 250, maxWidth: 255 }}>
            <TableContainer component={Paper} >
                <Table  aria-label="customized table">
                    <TableHead>
                    <TableRow>
                        <StyledTableCell>{packageData?.packagesNames[i]}</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">Package Count</StyledTableCell>
                            <StyledTableCell align="right">{packageData?.packagesCount[i]}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">Avg. Cost Per Car</StyledTableCell>
                            <StyledTableCell align="right">{`$${packageData?.packagesCostPerCAr[i]}`}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">Total Package Cost</StyledTableCell>
                            <StyledTableCell align="right">{`$${packageData?.packagesCost[i]}`}</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>);
    };

  return (
    <>
    <p  className={ classes.heading4 }> A La Carte Packages</p>
        <Grid container spacing={2}  style={{marginLeft: '0px', marginBottom: '20px'}}>
        { Object.keys(packageData).length > 0 && 
            packageData?.packagesIds?.map((pId, i) => generateAlacarteTables(packageData, i))
        }
        </Grid>
    </>
  );
};




