import React, { useState, useEffect, useContext } from "react";
import { authContext } from "../../../shared/context/auth/auth-context";
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Paper,
  Typography,
  FormHelperText,
  Button,
  Box
} from "@material-ui/core";
import ReactSelect, { components } from 'react-select';
import DropdownIndicator, { selectStyles } from "../../Util/DropdownIndicator";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import { useHistory, useLocation } from "react-router";
import MainPage from "../../../components/Layout/MainPage";
import { v4 } from "uuid";
import ButtonLink from "../../Util/ButtonLink";
import axios from "axios";
import { SiteContext } from "../../../shared/context/site/site-context";
import { func } from "prop-types";
import Alert from "@material-ui/lab/Alert";


const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "35ch"
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "35ch"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2)
  }
}));

const CreateCycle = function FormPropsTextFields() {
  const { site, packages, tanks, updateSiteContext } = useContext(SiteContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(authContext);

  const CycleId = v4().slice(-12);

  let Site_Id,
    CarWashName,
    CarWashOwner,
    packageId,
    packageName = "";
  if (!location.state?._id && !site?._id) {
    Site_Id = localStorage.getItem("site_id");
    CarWashName = localStorage.getItem("CarWashName");
    CarWashOwner = localStorage.getItem("CarWashOwner");
    packageId = localStorage.getItem("packageId");
    packageName = localStorage.getItem("packageName");
  } else {
    Site_Id = location.state?._id || site._id;
    CarWashName = location.state?.siteName || site?.siteName;
    CarWashOwner = location.state?.siteOwner || site?.siteOwner;
    packageId = location.state?.packageId || packages[0]?.packageId;
    packageName = location.state?.packageName || packages[0]?.packageName;
    localStorage.setItem("site_id", Site_Id);
    localStorage.setItem("CarWashName", CarWashName);
    localStorage.setItem("CarWashOwner", CarWashOwner);
    localStorage.setItem("packageId", packageId);
    localStorage.setItem("packageName", packageName);
  }

  const [CycleName, setCycleName] = React.useState("");
  const [Product, setProduct] = React.useState("");
  const [ProductClass, setProductClass] = React.useState("");
  const [ProductAli, setProductAli] = React.useState("");
  const [Tank, setTank] = React.useState("");
  const [STank, setSTank] = React.useState("");
  const [Amount, setAmount] = React.useState(0);
  const [DefaultUsage, setDefaultUsage] = React.useState("");
  const [Threshold, setThreshold] = React.useState(0);
  const [Unit, setUnit] = React.useState("ml");
  const [Products, setProducts] = React.useState([]);

  const [UsageOz, setUsageOz] = React.useState("");
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState({
    cyclename:'',
    tank:'',
    threshold:'',
    amount:''
  });
  const defaultText =
    "The default Threshold is " + DefaultUsage + "ml," + " (" + UsageOz + "oz)";
  const tankText = "The chosen Tank contain  Product: " + ProductAli;

  useEffect(() => {
    (async ()=>{
      const _id = location.state?._id ? location.state?._id : 
      site?._id ? site?._id : localStorage.getItem("site_id");
      await updateSiteContext(_id);
    })();

    return () => {
      (async ()=>{
        const _id = location.state?._id ? location.state?._id : 
        site?._id ? site?._id : localStorage.getItem("site_id");
        await updateSiteContext(_id);
      })();
    };
  }, []);

  useEffect(()=>{
    setThreshold((Amount*0.1).toFixed(1))
  },[Amount])

  React.useEffect(() => {
    const fetchData = async () => {
      const readURL = process.env.REACT_APP_BACKEND_LINK + "api/product/read";
      const result = await axios.get(readURL);
      setProducts(result.data.sort((a,b) => (a.productSKU?.toLowerCase() > b.productSKU?.toLowerCase()) ? 1 : -1));
    };
    fetchData();
  }, [site]);

  const handleChange = event => {

    if (event.target.name === "Unit") {
      setUnit(event.target.value);
    }
  };


  const handleTankItemClick = (event, tank) => {
    var temp = "";
    temp+= tank.product;
    // temp+="  ";
    // temp+=tank.productAlias;
    setSTank(temp);   
    setProductAli(tank.productAlias);
    setTank(tank._id);
    var productId = "";
    tanks.forEach(element => {
      if (element._id === tank._id) productId = element.productId;
    });
    const prd = Products.find(p => p?.productId == tank?.productId);

      setProductClass(prd?.productClass);
      setProduct(prd?.productSKU);
      setDefaultUsage(prd?.productUsage);
      setAmount(prd?.productUsage)
      if (!isNaN(prd?.productUsage))
        var mlUsage = parseFloat(prd?.productUsage ? prd?.productUsage : 0);
      var usageOz = mlUsage * 0.0338;
      usageOz = Math.round(usageOz * 1000) / 1000;
      setUsageOz(usageOz);
  };

//console.log(STank);

const cycleValidation = async e => 
{
  let temp={};
  temp.cyclename = CycleName?"":"Please enter the cycle name";
  temp.tank = Tank?"":"Please select the tank";
  temp.threshold = Threshold?Threshold>=0?"":"Please enter valid positive threshhold value":"Please enter threshold";
  temp.amount = Amount?Amount>=0?"":"Please enter the positive site estimated usage":"Please enter site estimated usage";

  setErrors({
     ...temp
   })
   
    return Object.values(temp).every(x => x === "");
}


  async function handleSubmit() {
    let body = {
      cycleId: CycleId,
      cycleName: CycleName,
      tank_id: Tank,
      amount: Amount,
      threshold: Threshold,
      unit: Unit,
      product: Product,
      siteId: Site_Id
    };

    //console.log(body);
    

    //console.log(body);
    cycleValidation().then(function(result)
    {
    if(result)
    {

    let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/cycle/create", {
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem("authToken")}`
      }
    }).then(response => response.json(),)

    response.then(function(result)
    {
    if(result.errors && result.errors[0].msg.includes(""))
    {
        setError(result.errors[0].msg);
        setTimeout(()=> { setError("") }, 5000);
    } 
    else {
    history.push({
      pathname: "/Site/",
      state: {
        _id: Site_Id,
        siteName: CarWashName,
        siteOwner: CarWashOwner,

        packageId,
        packageName
      }
    });
    }
    });
    }
  });
  try {
    await axios.post(
      process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Cycle ${CycleName} Created at ${CarWashName}`, category: "Create Cycle" }
    )
  }
  catch (error) {
    console.log(error)
  }
  }

  function handleCancel() {
    history.push({
      pathname: "/Site/",
      state: {
        _id: Site_Id,
        siteName: CarWashName,
        siteOwner: CarWashOwner,
        packageId,
        packageName
      }
    });
  }

  return (
    <MainPage name={"Create Cycle"}>
      <form className={classes.root} noValidate autoComplete="off">
        <div id="cycleInfo">
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            required
            id="CycleName"
            style={{ margin: "20px" }}
            label="Cycle Name"
            value={CycleName}
            onInput={e => setCycleName(e.target.value)}
            error = {errors.cyclename}
            helperText = {errors.cyclename}
          />
          
          
          <FormControl
            className={classes.formControl}
          >
            <InputLabel id="Tank" 
             error={errors.tank}
             InputLabelProps={{ shrink: true }} style={Tank ? {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
            } : {}}>Tank *</InputLabel>
            <ReactSelect
              name="Tank"
              labelId="Tank"
              id="Tank"
              placeholder="Tank *"
              styles={selectStyles}
              error={errors.tank}
              onChange={(val) => {handleTankItemClick(null, tanks.find(row => row?._id == val.value))}}
              components={{ DropdownIndicator }}
              value={Tank ? {
                value: Tank._id,
                label: `${Product}-${ProductClass}-${ProductAli}`
              } : null}
              options={tanks.map((row) => {
                const prd = Products.find(p => p?.productId == row?.productId);
                return(
                {
                    value: `${row?._id}`, 
                    label: `${row.product}-${prd?.productClass}-${row?.productAlias}`
                }
                )
            })}
            ></ReactSelect>
            {errors.tank && (
              <FormHelperText htmlFor="render-select" error>
                {errors.tank}
              </FormHelperText>
            )}
          </FormControl>

          <TextField
            id="Amount"
            style={{ margin: "20px" }}
            label="Site Estimated Usage (ml)"
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            InputLabelProps={{ shrink: true }}
            value={Amount}
            onInput={e => setAmount(e.target.value)}
            error = {errors.amount}
            helperText={errors.amount}  
          />
          
           { (errors.threshold) &&
          <TextField
            required
            id="Threshold"
            style={{ margin: "20px" }}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            InputLabelProps={{ shrink: true }}
            label="Threshold (ml)"
            value={Threshold}
            onInput={e => setThreshold(e.target.value)}
            error = {errors.threshold}
            helperText={errors.threshold}  
          /> 
          
          ||

          <TextField
          required
          id="Threshold"
          style={{ margin: "20px" }}
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          InputLabelProps={{ shrink: true }}
          label="Threshold (ml)"
          value={Threshold}
          onInput={e => setThreshold(e.target.value)}
          helperText={errors.defaultText}  
        />
           }
        </div>
      </form>
      <Box display="flex" justifyContent="flex-end">
        <ButtonLink onClick={handleCancel} color="blue" name="cancel" />
        <ButtonLink onClick={handleSubmit} color="pink" name="submit" />
      </Box>
    </MainPage>
  );
};

export default CreateCycle;
