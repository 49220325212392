import React, { useContext } from "react";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import Tab from "@material-tailwind/react/Tab";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { useHistory, useLocation } from "react-router";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { Box, useMediaQuery, Paper } from "@material-ui/core";
import "@material-tailwind/react/tailwind.css";

import { Button, Collapse } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuItems from "./MenuItems";
import { Welcome } from "./Welcome";
import MapDrawer from "../Util/MapDrawer";
import ButtonLink from "../Util/ButtonLink";
import Logo from "../Util/Logo";
import authContext from "../../shared/context/auth/auth-context";

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("lg")]: {
      height: "50px"
    },
    zIndex: theme.zIndex.drawer + 1,
    minHeight: "70px",
    flex: 1,
    background: "linear-gradient(to right, #141e30, #243b55)"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    fontSize: 25,
    [theme.breakpoints.up("lg")]: {
      display: "none"
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },

  active: {
    background: "linear-gradient(to right, #0288d1, #03a9f4)",
    borderRadius: 10,
    marginTop: 15,
    color: "white",
    boxShadow: "0 7px 6px -6px #777",
    padding: "7px 15px"
  },
  list: {
    marginTop: "20px"
  },
  acount: {
    marginLeft: "10px",
    background: "#b82363",
    color: "white",
    "&:hover": {
      background: "purple",
      color: "white"
    }
  },

  content: {
    margin: "auto",
    width: "80%"
  },
  welcome: {
    flex: 1,
    alignContent: "flex-end"
  },
  map: {
    padding: theme.spacing(1)
  },
  listItem: {
    marginTop: 15,
    borderRadius: 10,
    padding: "7px 15px",
    transition: "0.3s",
    minWidth: "30px",
    "&:hover": {
      background: "#F5F5F5",
      transition: "0.3s",
      color: "#0288d1"
    }
  },
  iconActive: {
    color: "white",
    minWidth: "30px"
  },
  icon: {
    minWidth: "30px"
  }
}));

function SideNavigation(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = React.useState({});
  //auth context
  const { user } = useContext(authContext);

  const handleClick = (item) => {
    setOpen(prevOpen => ({
        ...prevOpen,
        [item.text]: !prevOpen[item.text]
    }))
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // decide wich menu to display
  let role = user.role;
  let menu = "";
  switch (role) {
    case "installer":
      menu = MenuItems.InstallerMenu;
      break;
    case "admin":
      menu = MenuItems.MainMenu;
      break;
    case "distributor":
      menu = MenuItems.DistributorMenu;
      break;
    case "owner":
      menu = MenuItems.SiteOwnerMenu;
      break;
    case "operator":
      menu = MenuItems.operatorMenu;
      break;
  }
  //// drawer items
  const drawerItems = menu.map((item, i) => {
    if (item.SubMenu) {
      //create array of sub menu items
      const subMenuItems = item.SubMenu.map((subItem, j) => {
        return (
          <ListItem
            key={"Ss" + j + '64654654'}
            button
            className={
              location.pathname === subItem.path
                ? classes.active
                : classes.listItem
            }
            onClick={() => {
              history.push(subItem.path);
            }}
          >
            <ListItemText
              key={"Sc" + j + '98796546546'}
              style={{ textAlign: "left" }}
              primary={subItem.text}
            />
          </ListItem>
        );
      });

      // create the sub menu
      const subMenu = (
          <Collapse key={"S4545" + i + '98796-546546'} in={open[item.text]} timeout="auto" unmountOnExit>
          <List key={"S4545" + i + '98++796-546546'} component="div" disablePadding>
            {subMenuItems.map(x => x)}
          </List>
        </Collapse>
      );

      return (
        <>
          {/* No Error */}
          <ListItem
            key={"Ms6+54654" + i + '4654654poipopo'}
            className={classes.listItem}
            button
            onClick={() => handleClick(item)}
          >
            <ListItemIcon key={"Mc454" + i + "654654lkjlkj"} className={classes.icon}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              key={"Mk654654" + i + '6354654lkjlkj'}
              className={classes.text}
              primary={item.text}
            />
                  {open[item.text] ? <ExpandLess key={"asdfad654645" + i + '635465sdfsdf'} /> : <ExpandMore key={"sfds6354654" + i + 'sdfs564654'}/>}
          </ListItem>
          {subMenu}
        </>
      );
      // No Error
    } else {
      return (
        <ListItem
          button
              key={"asdfadasdasd" + i + '635654adsasd'}
          onClick={() => {
            history.push(item.path);
          }}
          className={
            location.pathname === item.path ? classes.active : classes.listItem
          }
        >
          <ListItemIcon
                  key={"asdfsdsdadasdasd" + i + '6356sdf5sdf4adfsdfsasd'}
            className={
              location.pathname === item.path
                ? classes.iconActive
                : classes.icon
            }
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            key={"Mkasdd" + i + '654adasd'}
            className={classes.text}
            primary={item.text}
          />
        </ListItem>
      );
    }
  });

  ////
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      {!isMatch ? <Welcome color="#595959" /> : null}
      <Divider />
      <Tab className={classes.list}>
        {drawerItems}
        {!isMatch ? (
          <ButtonLink
            Style={{ margin: 20 }}
            color="pink"
            name="Logout"
            onClick={props.logoutHandler}
          />
        ) : null}
      </Tab>
      <Paper className={classes.map}>
        <MapDrawer />
      </Paper>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar elevation={3} position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon className={classes.menuButton} />
          </IconButton>
          <Logo />

          {isMatch ? (
            <Box
              display="flex"
              justifyContent="flex-end"
              flexGrow={1}
              m={2}
              p={2}
              alignItems="center"
            >
              <Welcome className={classes.welcome} />
              <Button
                className={classes.acount}
                name="Logout"
                onClick={props.logoutHandler}
              >
                Logout
              </Button>
            </Box>
          ) : null}
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

export default SideNavigation;
