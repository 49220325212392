import React, { useState, useEffect} from 'react'
import AssignSite from './AssignSite'
import Users from './Users/Users'
import DeleteUser from './DeleteUser'

const Management = (props) => {
    const { screen } = props;

    const [isSubmited, setIsSubmited] = useState(false);
    useEffect(() => {
        //console.log('Management useEffect...');
    }, [isSubmited]);

    return (
        <>
            <Users screen={screen} isSubmited={isSubmited} setIsSubmited={setIsSubmited}/>
            <DeleteUser screen={screen} isSubmited={isSubmited} setIsSubmited={setIsSubmited}/>
            <AssignSite screen={screen} isSubmited={isSubmited} setIsSubmited={setIsSubmited}/>
        </>
    )
}

export default Management
