import {useMutation, useQuery} from 'react-query';
import axios from "axios";

const handleFetchCycle = async (tankId) => {
    const tankscycledata = await axios.get(
        process.env.REACT_APP_BACKEND_LINK +
          "api/cycle/readonebytankid/" +
          tankId
      );

      if (tankscycledata?.data?.length > 0) return tankscycledata?.data;

      return [];
};

export const useGetCycleByTank = (tankId, doFetch) => {
  return useQuery(
    ['cycle--', tankId],
    () => handleFetchCycle(tankId),
    { 
        staleTime: 1000*60*60*3,
        enabled: Boolean(!!tankId && doFetch)
    }
  );
};