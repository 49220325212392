import React, { useState, useEffect, useContext } from "react";
import { authContext } from "../../../shared/context/auth/auth-context";
import {
  TextField,
  FormControl,
  Box,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Paper,
  Typography,
  Button,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import { useHistory, useLocation } from "react-router";
import MainPage from "../../Layout/MainPage";
import ButtonLink from "../../Util/ButtonLink";
import { SiteContext } from "../../../shared/context/site/site-context";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "35ch"
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "50ch"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2)
  }
}));

const UpdateInput = function FormPropsTextFields() {
  const { site,packages,updateSiteContext } = useContext(SiteContext);
  const { user } = useContext(authContext);
  const location = useLocation();
  const SiteId = location.state?._id || site?._id;
  const CarWashName = location.state?.siteName || site?.siteName;
  const CarWashOwner = location.state?.siteOwner || site?.siteOwner;
  let packageId = '';
  if (location.state?.packageId) {
    packageId = location.state?.packageId;
    localStorage.setItem('packageId-u', packageId);
  } else {
    packageId = localStorage.getItem('packageId-u');
  }
  
  const classes = useStyles();
  const history = useHistory();
  const [PackageName, setPackageName] = React.useState("");
  const [isAlacarte, setIsAlacarte] = useState(false);
  const [isInclusive, setIsInclusive] = useState(false);
  const [Input, setInput] = React.useState("");
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState({
    packagename: '', 
    carwashinput: ''
  }); 

  useEffect(() => {
    (async ()=>{
      const _id = location.state?._id ? location.state?._id : 
      site?._id ? site?._id : localStorage.getItem("site_id");
      await updateSiteContext(_id);
    })();

    return () => {
      (async ()=>{
        const _id = location.state?._id ? location.state?._id : 
        site?._id ? site?._id : localStorage.getItem("site_id");
        await updateSiteContext(_id);
      })();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const readURL1 =
        process.env.REACT_APP_BACKEND_LINK 
        + "api/package/readone/" + packageId;
      const result = await axios.get(readURL1);

      if(result?.data) {
        setPackageName(result?.data?.packageName);
        setInput(result?.data?.input);
        setIsAlacarte(result?.data?.isAlacarte);
        setIsInclusive(result?.data?.isInclusive);
      }
    };
    fetchData();
  }, []);

  const packageValidation = async e => 
  {
    let temp={};
    temp.packagename = PackageName?"":"Please enter the package name";
    temp.carwashinput = Input && Input>0?"":"Please enter the valid car wash input";
    
    setErrors({
       ...temp
     })
     
      return Object.values(temp).every(x => x === "");
  }

  async function handleSubmit() {
    let body = {
      packageId: packageId,
      packageName: PackageName,
      input:Input,
      isAlacarte,
      isInclusive
    };

    //console.log(body);

    // fetch("http://localhost:5000/api/package/updateInput", {
    packageValidation().then(function(result)
    {
    if(result)
    {
    let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/package/updateInput", {
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem("authToken")}`
      }
    }).then(response => response.json(),)

    response.then(function(result)
    {
     //console.log('result=', result);
    if(result.errors && result.errors[0].msg.includes(""))
    {
      setError(result.errors[0].msg);
      setTimeout(()=> {setError("")}, 5000);
    }
    else
    {
    history.push({
      pathname: "/site/",
      state: {
        _id: SiteId,
        siteName: CarWashName,
        siteOwner: CarWashOwner
      }
    });
    }
  });
  }
  });
  try {
    await axios.post(
      process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Package ${PackageName} Updated at ${CarWashName}`, category: "Update Package" }
    )
  }
  catch (error) {
    console.log(error)
  }
  }

  function handleCancel() {
    history.push({
      pathname: "/packages",
      state: {
        _id: SiteId,
        siteName: CarWashName,
        siteOwner: CarWashOwner,
        packageId: packageId,
        packageName: PackageName
      }
    });
  }

  const handleAlacarteChange = (event) => {
    setIsAlacarte(event?.target?.checked);
  };

  const handleInclusiveChange = (event) => {
    setIsInclusive(event?.target?.checked);
  };

  return (
    <MainPage name={"Update Package"}>
      <form className={classes.root} noValidate autoComplete="off">
        <div id="packageInfo">
          {error && <Alert severity="error">{error}</Alert>}
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
                required
                id="PackageName"
                style={{ margin: "20px" }}
                label="Package Name"
                value={PackageName}
                onInput={e => setPackageName(e.target.value)}
                InputLabelProps={{ shrink: true }}
                error = {errors.packagename}
                helperText = {errors.packagename}
              />
              <TextField
                required
                id="Input"
                type = "number"
                style={{ margin: "20px" }}
                label="Carwash Input"
                value={Input}
                onInput={e => setInput(e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 1 } }}
                error = {errors.carwashinput}
                helperText = {errors.carwashinput}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel 
              control={
              <Checkbox 
              checked={isAlacarte}
              onChange={handleAlacarteChange}
              />} 
              label="A Lacarte" 
              />
              <FormControlLabel 
              control={
              <Checkbox  
              checked={isInclusive}
              onChange={handleInclusiveChange}
              />} 
              label="Inclusive" 
              />
            </Grid>
          </Grid>
        </div>
      </form>
      <Box display="flex" justifyContent="flex-end">
        <ButtonLink onClick={handleCancel} color="blue" name="cancel" />
        <ButtonLink onClick={handleSubmit} color="pink" name="submit" />
      </Box>
    </MainPage>
  );
};

export default UpdateInput;
