import React, { useState, useEffect,useContext } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography'
import Map from '../Map/Map.js'
import mapStyles from '../Map/mapStyles2';
import { authContext } from "../../shared/context/auth/auth-context";
import { getSitesForUser } from "../../shared/api";
import jwtDecode from 'jwt-decode'
// import Geocode from "react-geocode";
import axios from "axios";
const mapContainerStyle = {
    width: '200px',
    height: '200px'
}

const drawerMapContainerStyle = {
    width: '580px',
    height: '500px'
}
const center = {
    lat: 43.407510,
    lng: -80.306350,
}


const options = {

    styles: mapStyles,
    disableDefaultUI: true,

}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography align='center' variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiDialogContent);


export default function CustomizedDialogs() {
    // Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_APIS_KEY);

    // // // set response language. Defaults to english.
    // Geocode.setLanguage("en");
  
    // // // set response region. Its optional.
    // // // A Geocoding request with region=es (Spain) will return the Spanish city.
    // Geocode.setRegion("es");
    // Geocode.setLocationType("ROOFTOP");
  
    // // Enable or disable logs. Its optional.
    // // Geocode.enableDebug();
    const [locations, setLocations] = useState([]); // array of all the locations
    const [coordinates, setCoordinates] = useState([]);
    const [sites, setSites] = useState([])
    const [point,] = useState([])
    const [address, setAddress] = useState([])
  
    const [isLoaded, setIsLoaded] = useState(false);
    const { user } = useContext(authContext);
    


    // useEffect(() => {
    //   let authToken = localStorage.getItem("authToken") || null;
    //   let user = null;
    //   if (authToken) {
    //     user = jwtDecode(authToken);
    //     console.log("This is user",user)
    //     setUser(user);
    //   }
    // }, []);
    //console.log(user,"In map drwaer")
    // useEffect(async () => {
    //     if (user) {
    //       let sites = await getSitesForUser(user.id);
    //       if (sites.success) {
    //           console.log(sites)
    //        console.log("This is sites from map", sites)
    //       } else {
    //         console.log("This is no site", user.role)
    //       }
    //     }
    //   }, []);
  
      useEffect(() => {
        //console.log(user.id)
      const fetchData = async () => {
          let fetchAddress
          let fetchAllSites
          let fetchSites
          if(user.role ==='admin'){
            fetchAddress = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/auth/address");
            fetchSites = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/site/read")
            //console.log("This is admin add",fetchAddress)
            const fetch = fetchAddress.data.map((add => {
          return  {id: add.addressId,
            lat: parseFloat(add?.lat), lng:parseFloat(add?.lng) }
        }))
        setAddress(fetchAddress.data)
        setSites(fetchSites.data)
        setCoordinates(fetch)
          }
        else {
            let fetchAddress = []
            let i = 0
            fetchSites = await getSitesForUser(user.id);
            
        if(fetchSites?.sites){
            for(let site of fetchSites?.sites){
                let result = await axios.get(process.env.REACT_APP_BACKEND_LINK + `api/site/readAddress/${site?.addressId}`);
                //console.log(result)
                fetchAddress[i] = result.data
                i++
            }
        }
            //fetchSites = await axios.get(process.env.REACT_APP_BACKEND_LINK + "api/site/read")
            //console.log("This is owner address",fetchAddress)
            const fetch = fetchAddress.map((add => {
                return  {id: add.addressId,
                  lat: parseFloat(add.lat), lng:parseFloat(add.lng) }   }))
              //console.log(fetchSites)
              setAddress(fetchAddress)
              setSites(fetchSites.sites)
              setCoordinates(fetch)
        }
       //const fetchSites = await getSitesForUser(user.id);
        
        // const fetch = fetchAddress.data.map((add => {
        //   return {id: add.addressId,
        //     address: `${add.addressLine}, ${add.city},${add.province}, ${add.provinceCode}`}
        // }))
        // setAddress(fetchAddress.data)
        // setLocations(fetch)
        //setSites(fetchSites.data)
      };
      fetchData();
    }, []);
  
  
    // useEffect(() => {
  
    //   const fetchData = () => {
  
    //     locations.forEach((value, i) => {
  
    //       Geocode.fromAddress(value.address).then((res) => {
    //         const { lat, lng } = res.results[0].geometry.location;
    //         const id = value.id
    //         setCoordinates(prev => [...prev, {id, lat, lng }])
    //         if (i === locations.length - 1) {
    //           setIsLoaded(true);
    //         }
    //       })
  
    //     })
  
    //   }
    //   fetchData();
    // }, [locations])
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button color="primary" onClick={handleClickOpen}>
                <Map zoom={10}
                    key={coordinates.id}
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    points={coordinates}
                    options={options}
                    site={sites}
                    address={address}></Map>
            </Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Location
                </DialogTitle>
                <DialogContent dividers>
                    {<Map zoom={7}
                        mapContainerStyle={drawerMapContainerStyle}
                        center={center}
                        points={coordinates}
                        options={options}
                        site={sites}
                        address={address}></Map>}
                </DialogContent>
            </Dialog>
        </div >
    );
}