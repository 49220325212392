import {
  MdDashboard,
  MdSettings,
  MdStorage,
  MdAddAlert,
  MdMemory,
  MdSupportAgent
} from "react-icons/md";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { BrightnessMediumRounded, MailRounded, MicOutlined, MissedVideoCallOutlined, MobileFriendly, MobileFriendlyOutlined, MobileFriendlyRounded, MobileOffOutlined, Mood } from "@material-ui/icons";
import { ModalDescription } from "semantic-ui-react";

const MenuItems = {
  MainMenu: [
    {
      text: "Dashboard",
      icon: <MdDashboard style={{ fontSize: "1.5rem" }} />,
      path: "/",
      SubMenu: null
    },
   
    {
      text: "Sites",
      icon: <MdStorage style={{ fontSize: "1.5rem" }} />,
      path: "/sites",
      SubMenu: null
    },
    {
      text: "Alert Preferences",
      icon: <MdAddAlert style={{ fontSize: "1.5rem" }} />,
      path: "/alertPreferences",
      SubMenu: null
    },
    {
      text: "Tanks",
      icon: <MdStorage style={{ fontSize: "1.5rem" }} />,
      path: "/tanksLevel",
      SubMenu: null
    },
    {
      text: "Inventory & Orders",
      icon: <MdMemory style={{ fontSize: "1.5rem" }} />,
      path: "/inventory",
      SubMenu: null
    },
    {
      text: "Management",
      icon: <AccountBoxIcon style={{ fontSize: "1.5rem" }} />,
      path: null,
      SubMenu: [
        {
            text: "User Management",
            path: "/management"
        }
      ]
    },
    {
      text: "Reports",
      icon: <InboxIcon style={{ fontSize: "1.5rem" }} />,
      path: null,
      SubMenu: [
        {
          text: "Latest Period",
          path: "/latestReport"
        },
        {
          text: "Multiday Period",
          path: "/multidayReport"
        },
        {
          text: "Product Usage Analysis",
          path: "/productUsage"
        }
      ]
    },
    {
      text: "Distributor Settings",
      icon: <MobileFriendlyRounded style={{ fontSize: "1.5rem" }} />,
      path: "/distributors",
      SubMenu: null
    },
    {
      text: "Product Settings",
      icon: <MdSettings style={{ fontSize: "1.5rem" }} />,
      path: "/products",
      SubMenu: null
    }
  ],
  DistributorMenu: [
    {
      text: "Dashboard",
      icon: <MdDashboard style={{ fontSize: "1.5rem" }} />,
      path: "/",
      SubMenu: null
    },
    {
      text: "Sites",
      icon: <MdStorage style={{ fontSize: "1.5rem" }} />,
      path: "/sites",
      SubMenu: null
    },
    {
      text: "Alert Preferences",
      icon: <MdAddAlert style={{ fontSize: "1.5rem" }} />,
      path: "/alertPreferences",
      SubMenu: null
    },
    {
      text: "Tanks",
      icon: <MdStorage style={{ fontSize: "1.5rem" }} />,
      path: "/tanksLevel",
      SubMenu: null
    },
    {
      text: "Inventory & Orders",
      icon: <MdMemory style={{ fontSize: "1.5rem" }} />,
      path: "/inventory",
      SubMenu: null
    },
    {
      text: "Reports",
      icon: <InboxIcon style={{ fontSize: "1.5rem" }} />,
      path: null,
      SubMenu: [
        {
          text: "Latest Period",
          path: "/latestReport"
        },
        {
          text: "Multiday Period",
          path: "/multidayReport"
        },
        {
          text: "Product Usage Analysis",
          path: "/productUsage"
        }
      ]
    },
    {
      text: "Support",
      icon: <MdSupportAgent style={{ fontSize: "1.5rem" }} />,
      path: "/support",
      SubMenu: null
    }
  ],
  SiteOwnerMenu: [
    {
      text: "Dashboard",
      icon: <MdDashboard style={{ fontSize: "1.5rem" }} />,
      path: "/",
      SubMenu: null
    },
    {
      text: "Tanks",
      icon: <MdStorage style={{ fontSize: "1.5rem" }} />,
      path: "/tanksLevel",
      SubMenu: null
    },
    {
      text: "Sites",
      icon: <MdStorage style={{ fontSize: "1.5rem" }} />,
      path: "/sites",
      SubMenu: null
    },
    {
      text: "Alert Preferences",
      icon: <MdAddAlert style={{ fontSize: "1.5rem" }} />,
      path: "/alertPreferences",
      SubMenu: null
    },
    {
      text: "Inventory",
      icon: <MdMemory style={{ fontSize: "1.5rem" }} />,
      path: "/inventory",
      SubMenu: null
    },
    {
      text: "Reports",
      icon: <InboxIcon style={{ fontSize: "1.5rem" }} />,
      path: null,
      SubMenu: [
        {
          text: "Latest Period",
          path: "/latestReport"
        },
        {
          text: "Multiday Period",
          path: "/multidayReport"
        },
        {
          text: "Product Usage Analysis",
          path: "/productUsage"
        }
      ]
    },
    {
      text: "Support",
      icon: <MdSupportAgent style={{ fontSize: "1.5rem" }} />,
      path: "/support",
      SubMenu: null
    }
  ],
  InstallerMenu: [
    {
      text: "Dashboard",
      icon: <MdDashboard style={{ fontSize: "1.5rem" }} />,
      path: "/"
    },
    {
      text: "Sites Assigned",
      icon: <MdStorage style={{ fontSize: "1.5rem" }} />,
      path: "/sitesAssigned"
    },
    {
      text: "Configured Sites",
      icon: <MdMemory style={{ fontSize: "1.5rem" }} />,
      path: "/configuredSite"
    },
    // {
    //   text: "Management",
    //   icon: <MdSettings style={{ fontSize: "1.5rem" }} />,
    //   path: "/management"
    // }
  ],
  operatorMenu: [
    {
      text: "Dashboard",
      icon: <MdDashboard style={{ fontSize: "1.5rem" }} />,
      path: "/",
      SubMenu: null
    },
    {
      text: "Tanks",
      icon: <MdStorage style={{ fontSize: "1.5rem" }} />,
      path: "/tanksLevel",
      SubMenu: null
    },
    {
      text: "Sites",
      icon: <MdStorage style={{ fontSize: "1.5rem" }} />,
      path: "/sites",
      SubMenu: null
    },
    {
      text: "Alert Preferences",
      icon: <MdAddAlert style={{ fontSize: "1.5rem" }} />,
      path: "/alertPreferences",
      SubMenu: null
    },
    {
      text: "Inventory",
      icon: <MdMemory style={{ fontSize: "1.5rem" }} />,
      path: "/inventory",
      SubMenu: null
    },
    {
      text: "Reports",
      icon: <InboxIcon style={{ fontSize: "1.5rem" }} />,
      path: null,
      SubMenu: [
        {
          text: "Latest Period",
          path: "/latestReport"
        },
        {
          text: "Multiday Period",
          path: "/multidayReport"
        },
        {
          text: "Product Usage Analysis",
          path: "/productUsage"
        }
      ]
    },
    ,
    {
      text: "Support",
      icon: <MdSupportAgent style={{ fontSize: "1.5rem" }} />,
      path: "/support",
      SubMenu: null
    }
  ]
};

export default MenuItems;
