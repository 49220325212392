import {useMutation, useQuery} from 'react-query';
import axios from "axios";

const handleFetchReports = async (siteId, body) => {
    const data = await axios.post(
        process.env.REACT_APP_BACKEND_LINK + "api/report/" + siteId,
        body
      );
 
      if(data?.data?.success === true) return data?.data?.reports;

      return [];
};

export const useGetReportsBySite = (siteId, body) => {

  return useQuery(
    ['reports-', siteId, JSON.stringify(body)],
    () => handleFetchReports(siteId, body),
    { 
        staleTime: 1000*60*60*3,
        enabled: Boolean(!!siteId && !!body)
    }
  );
};

