import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "@material-tailwind/react/tailwind.css";
import { authContext } from "../../shared/context/auth/auth-context";

import { getSitesForUser, fetchAllSites, fetchUserbyId, fetchSiteControllerBySiteId, fetchAllTanks } from "../../shared/api";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableRow,  TableContainer, Paper, Typography, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
    alertDialog: {
      backgroundColor: "#fafafa",
      padding: 0,
    },
    alertDialogContent: {
      marginBottom: 0,
    },
    alertCard: {
      marginBottom: "5px",
      padding: "0px!important",
    },
    alertCardContent: {
      padding: "10px"
    },
    alertCardTitle: {
      color: "black",
      fontSize: "18px",
      fontWeight: "500"
    },
    alertCardTank: {
      color: "#FF5833",
      fontSize: "14px",
      fontWeight: "500"
    },
    alertCardDetail: {
      fontSize: "12px",
    },
    alertCardAlert: {
      padding: "0 0 0 5px",
      marginTop: "2px",
      marginBottom: "2px"
    },
    alertDialogTitle: {
      backgroundColor: "#33E3FF"
    }
});

const DashboardAlerts = () => {
  const { user } = useContext(authContext);
  const classes = useStyles();
  //display tanks alerts states
  const [isTanksAlert, setIsTanksAlert] = useState(false);
  const [tanksAlerts, setTanksAlerts] = useState([]);


  useEffect(() => {

    const fetchData = async () => {
      if (user) {
        let sites = user?.role === 'admin' ? await fetchAllSites() : (await getSitesForUser(user.id)).sites
        let userDetails = await fetchUserbyId(user.id);
        if (sites) {
          sites = sites.filter(site => site).sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1);
          //get sites and the thanks that has alerts - promeses array
          const siteWithTanks = sites.map(async site => {
            let alerts = userDetails?.alerts?.find(alert => alert.siteId === site._id);
            //get site controllers
            const siteControllers = await fetchSiteControllerBySiteId(site.siteId);
            const controllersWithAlerts = siteControllers.filter(
                controller => controller.controllerOffline
            )
            site.controllerOffline = controllersWithAlerts.length > 0 && alerts?.controllerPopup

            //get site tanks
            const siteTanks = await fetchAllTanks(site._id);
            //filter only tanks has low level or low stock
            const tanksWithAlerts = siteTanks.filter(
              tank => (tank.tankLevelMinAlert && alerts?.tankPopup) || 
              (tank.stockMinAlert && alerts?.inventoryPopup) || 
              (tank.tankUsageAboveMaxAlert && alerts?.usagePopup) || 
              (tank.tankUsageBelowMinAlert && alerts?.usagePopup)
            );

            if (tanksWithAlerts.length > 0) {
                return { site, alerts: alerts, tanks: tanksWithAlerts };
            } else {
                return null;
            }
          });
          //sites and tanks that has alerts
            let siteWithTanksAlerted = await Promise.all(siteWithTanks);
            siteWithTanksAlerted = siteWithTanksAlerted.filter(obj => obj);

          //if there are sites with alreted tanks update states
          if (siteWithTanksAlerted.length > 0) {
            //loop over the sites and tanks
            const tanksAlerts = siteWithTanksAlerted.map(siteWithTanks => {
              //loop over tanks and add alerts to it
              const tanksAndAlerts = siteWithTanks.tanks.map(siteTank => {
                let tankAlerts = [];
                if (siteTank.tankLevelMinAlert && siteWithTanks.alerts?.tankPopup) {
                  tankAlerts.push(`Tank ${siteTank.product} LOW LEVEL.`);
                }
                if (siteTank.stockMinAlert && siteWithTanks.alerts?.inventoryPopup) {
                  tankAlerts.push(`Product ${siteTank.product} LOW INVENTORY.`);
                }
                if (siteTank.tankUsageAboveMaxAlert && siteWithTanks.alerts?.usagePopup) {
                    tankAlerts.push(`Usage for ${siteTank.product} ABOVE MAX`)
                }
                if (siteTank.tankUsageBelowMinAlert && siteWithTanks.alerts?.usagePopup) {
                    tankAlerts.push(`Usage for ${siteTank.product} BELOW MIN`)
                }
                
                return { ...siteTank, alerts: tankAlerts };
              });

              return { site: siteWithTanks.site, tanks: tanksAndAlerts };
            });

            //set site and its tanks with its alerts
            setTanksAlerts(tanksAlerts);
            setIsTanksAlert(true);
          }
        }
      }
    }
    fetchData();

  }, [user]);

  return (
    <>
      {/*-- start -- alert user with low tank level and product low stock --*/}
      <Dialog
            open={isTanksAlert}
            onClose={() => setIsTanksAlert(false)}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle
            id="scroll-dialog-title"
            className={classes.alertDialogTitle}
            >
            Tank Level and Stock Reminders
            </DialogTitle>
            <DialogContent dividers={true} className={classes.alertDialog}>
            {/* <Scrollbars style={{ height: 500 }}> */}
                <DialogContentText id="scroll-dialog-description" tabIndex={-1} className={classes.alertDialogContent}>
                <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {tanksAlerts.map(siteAndTanks => {
                        return (
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <Typography
                                    variant="h5"
                                    component="h2"
                                    key={siteAndTanks?.site._id + "cardtop"}
                                    className={classes.alertCardTitle}
                                    >
                                    {`${siteAndTanks?.site.siteName}:`}
                                    </Typography>

                                    {siteAndTanks?.site.controllerOffline && 
                                        <Alert key={siteAndTanks?.site._id + "tankDiv"}
                                        severity="warning"
                                        className={classes.alertCardAlert}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            key={siteAndTanks?.site._id + "tankcardtop"}
                                            className={classes.alertCardTank}
                                        >
                                            Controller Offline
                                        </Typography>
                                        </Alert>
                                    }

                                    {siteAndTanks?.tanks.map(tank => {
                                    return (
                                        <Alert key={tank._id + "tankDiv"}
                                        severity="warning"
                                        className={classes.alertCardAlert}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            key={tank._id + "tankcardtop"}
                                            className={classes.alertCardTank}
                                        >
                                            {`Tank with product ${tank.product}:`}
                                        </Typography>
                                        {tank.alerts.map(alert => {
                                            return (
                                                <Typography
                                                key={alert?._id + "alertsDiv"}
                                                className={classes.alertCardDetail}
                                                >
                                                {alert}
                                            </Typography>
                                            );
                                        })}
                                        </Alert>
                                    );
                                    })}
                                </TableCell>
                            </TableRow>
                        );
                        })}
                        </TableBody></Table></TableContainer>
                </DialogContentText>
            {/* </Scrollbars> */}
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setIsTanksAlert(false)} color="primary">
                Close
            </Button>
            </DialogActions>
        </Dialog>
        {/* -- end -- alert user with low tank level and product low stock --*/}
    </>
  );
};

export default DashboardAlerts;
