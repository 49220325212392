import React, { useState, useEffect, useContext } from "react";
import { authContext } from "../../../shared/context/auth/auth-context";
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  FormHelperText
} from "@material-ui/core";
import ReactSelect, { components } from 'react-select';
import DropdownIndicator, { selectStyles } from "../../Util/DropdownIndicator";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import { useHistory, useLocation } from "react-router";
import MainPage from "../../../components/Layout/MainPage";
import ButtonLink from "../../Util/ButtonLink";
import axios from "axios";
import ConfirmDialog from "../../Util/ConfirmDialog/ConfirmDialog";
import { SiteContext } from "../../../shared/context/site/site-context";
import { parse } from "date-fns/esm";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "35ch"
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "35ch"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2)
  }
}));

const UpdateCycle = function FormPropsTextFields() {
  const { site, cycles, tanks, updateSiteContext } = useContext(SiteContext);

  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(authContext);

  let _id,
    SiteId,
    CarWashName,
    CarWashOwner,
    CycleId = "";
  if (!location.state?._id && !site?._id) {
    _id = localStorage.getItem("site_id");
    SiteId = localStorage.getItem("siteId");
    CarWashName = localStorage.getItem("CarWashName");
    CarWashOwner = localStorage.getItem("CarWashOwner");
    CycleId = localStorage.getItem("CycleId");
  } else {
    _id = location.state?._id || site._id;
    SiteId = location.state?.siteId || site?.siteId;
    CarWashName = location.state?.siteName || site?.siteName;
    CarWashOwner = location.state?.siteOwner || site?.siteOwner;
    CycleId = location.state?.cycleId || cycles[0]?.cycleId;
    localStorage.setItem("site_id", _id);
    localStorage.setItem("siteId", SiteId);
    localStorage.setItem("CarWashName", CarWashName);
    localStorage.setItem("CarWashOwner", CarWashOwner);
    localStorage.setItem("CycleId", CycleId);
  }

  // const SiteId = '60ca2d4833af271d103cab7b';

  const [CycleName, setCycleName] = React.useState("");
  const [Product, setProduct] = React.useState("");
  const [ProductId, setProductId] = React.useState("");
  const [ProductClass, setProductClass] = React.useState("");
  const [Port, setPort] = React.useState("");
  const [Amount, setAmount] = React.useState("");
  const [Threshold, setThreshold] = React.useState("");
  const [Unit, setUnit] = React.useState("");
  const [Site_Id, setSiteId] = React.useState("");
  const [DefaultUsage, setDefaultUsage] = React.useState("");
  const [UsageOz, setUsageOz] = React.useState("");
  const defaultText =
    "The default Threshold is " + DefaultUsage + "ml," + " (" + UsageOz + "oz)";
  const [confirmOpen, setConfirmOpen] = React.useState("");
  const [Products, setProducts] = React.useState([]);
  const [ProductAli, setProductAli] = React.useState("");
  const [Tank, setTank] = React.useState("");
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState({
    cyclename:'',
    tank:'',
    threshold:'',
    amount:''
  });
  

  useEffect(() => {
    (async ()=>{
      if (tanks?.length === 0) await updateSiteContext(_id);
    })();
    return () => {
      updateSiteContext(_id);
    };
  }, []);

  useEffect(()=>{
    setThreshold((Amount*0.1).toFixed(1))
  },[Amount])

  React.useEffect(() => {
    const fetchData = async () => {
      const readURL = process.env.REACT_APP_BACKEND_LINK + "api/product/read";
      const result = await axios.get(readURL);
      setProducts(result.data.sort((a,b) => (a.productSKU?.toLowerCase() > b.productSKU?.toLowerCase()) ? 1 : -1));
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const readURL1 =
        process.env.REACT_APP_BACKEND_LINK + "api/cycle/readonebyid/" + CycleId;
      const result = await axios.get(readURL1);
      setCycleName(result.data.cycleName);
      //setProduct(result.data?.tank_id[0]?.product);
      setProductAli(result.data?.tank_id[0]?.productAlias);
      setTank(result.data?.tank_id[0]?._id);
      setProductId(result.data?.tank_id[0]?.productId)
      setAmount(result.data.amount);
      setThreshold(result.data.threshold);
      setUnit(result.data.unit);
      setSiteId(result.data?.siteId[0]);
      setDefaultUsage(" ");
      setUsageOz(" ");
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if(ProductId && Products.length > 0) {
      const prd = Products.find(p => p?.productId == ProductId);
      setProduct(prd?.productSKU);
      setProductClass(prd?.productClass)
    }
  }, [ProductId, Products]);

  const handleChange = event => {
    // if (event.target.name === "Tank") {
    //   setProduct(event.target.value);
    // }
    if (event.target.name === "Unit") {
      setUnit(event.target.value);
    }

  };

  const handleTankItemClick = (event, tank) => {
    setTank(tank?._id);
    var productId = "";
    tanks.forEach(element => {
      if (element._id === tank?._id) productId = element.productId;
    });

    const prd = Products.find(p => p?.productId == tank?.productId);

    setProduct(tank?.product);
    setProductClass(prd?.productClass);
    setProductAli(tank?.productAlias);
    setDefaultUsage(prd?.productUsage);
    setAmount(prd?.productUsage)
    if (!isNaN(prd?.productUsage))
      var mlUsage = parseFloat(prd?.productUsage ? prd?.productUsage : 0);
    var usageOz = mlUsage * 0.0338;
    usageOz = Math.round(usageOz * 1000) / 1000;
    setUsageOz(usageOz);

  };

  const cycleValidation = async e => 
{
  let temp={};
  temp.cyclename = CycleName?"":"Please enter the cycle name";
  temp.tank = Tank?"":"Please select the tank";
  temp.threshold = Threshold?Threshold>=0?"":"Please enter valid positive threshhold value":"Please enter threshold";
  temp.amount = Amount?Amount>=0?"":"Please enter the positive site estimated usage":"Please enter site estimated usage";
 
  setErrors({
     ...temp
   })
   
    return Object.values(temp).every(x => x === "");
}

  async function handleSubmit() {
    let body = {
      cycleId: CycleId,
      cycleName: CycleName,
      tank_id: Tank,
      amount: Amount,
      threshold: Threshold,
      unit: Unit,
      product: Product,
      siteId: Site_Id
    };

  cycleValidation().then(function(result)
  {
    if(result)
    {
    let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/cycle/update", {
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem("authToken")}`
      }
    }).then(response => response.json(),)

  response.then(function(result)
  {
    if(result.errors && result.errors[0].msg.includes(""))
    {
        setError(result.errors[0].msg);
        setTimeout(() => {setError("")}, 5000);
    }
    else
    {
    history.push({
      pathname: "/site/",
      state: {
        _id: Site_Id,
        siteId: SiteId,
        siteName: CarWashName,
        siteOwner: CarWashOwner
      }
    });
  }
  });
  }
  });
  try {
    await axios.post(
      process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Cycle ${CycleName} Updated at ${CarWashName}`, category: "Update Cycle" }
    )
  }
  catch (error) {
    console.log(error)
  }
  }

  function handleCancel() {
    history.push({
      pathname: "/site/",
      state: {
        _id: Site_Id,
        siteId: SiteId,
        siteName: CarWashName,
        siteOwner: CarWashOwner
      }
    });
  }

  async function handleDelete() {
    let body = {
      cycleId: CycleId
    };
    fetch(process.env.REACT_APP_BACKEND_LINK + "api/cycle/delete", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem("authToken")}`
      },
      mode: "cors"
    });
    history.push({
      pathname: "/site/",
      search: _id,
      state: {
        _id: _id,
        siteId: SiteId,
        siteName: CarWashName,
        siteOwner: CarWashOwner
      }
    });
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Cycle ${CycleName} Deleted from ${CarWashName}`, category: "Delete" }
      )
    }
    catch (error) {
      console.log(error)
    }
  }

  return (
    <MainPage name={"Update Cycle"}>
      <form className={classes.root} noValidate autoComplete="off">
        <div id="cycleInfo">
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            required
            id="CycleName"
            style={{ margin: "20px" }}
            label="Cycle Name"
            value={CycleName}
            InputLabelProps={{ shrink: true }}
            onInput={e => setCycleName(e.target.value)}
            error = {errors.cyclename}
            helperText = {errors.cyclename}
          />

          <FormControl
            className={classes.formControl}
          >
            <InputLabel id="Tank" 
             error={errors.tank}
             InputLabelProps={{ shrink: true }} style={Tank ? {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
            } : {}}>Tank *</InputLabel>
            <ReactSelect
              name="Tank"
              labelId="Tank"
              id="Tank"
              placeholder="Tank *"
              styles={selectStyles}
              error={errors.tank}
              onChange={(val) => {handleTankItemClick(null, tanks.find(row => row?._id == val.value))}}
              components={{ DropdownIndicator }}
              value={Tank ? {
                value: Tank._id,
                label: `${Product}-${ProductClass}-${ProductAli}`
              } : null}
              options={tanks.map((row) => {
                const prd = Products.find(p => p?.productId == row?.productId);
                return(
                {
                    value: `${row?._id}`, 
                    label: `${row.product}-${prd?.productClass}-${row?.productAlias}`
                }
                )
            })}
            ></ReactSelect>
            {errors.tank && (
              <FormHelperText htmlFor="render-select" error>
                {errors.tank}
              </FormHelperText>
            )}
          </FormControl>

          <TextField
            id="Amount"
            style={{ margin: "20px" }}
            label="Site Estimated Usage (ml)"
            value={Amount}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            InputLabelProps={{ shrink: true }}
            onInput={e => setAmount(e.target.value)}
            error = {errors.amount}
            helperText={errors.amount}
          />
          { (errors.threshold) &&
          <TextField
            required
            id="Threshold"
            style={{ margin: "20px" }}
            label="Usage Threshold"
            value={Threshold}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            InputLabelProps={{ shrink: true }}
            error = {errors.threshold}
            helperText={errors.threshold}
            onInput={e => setThreshold(e.target.value)}
          /> ||
          <TextField
            required
            id="Threshold "
            style={{ margin: "20px" }}
            label="Usage Threshold (ml)"
            value={Threshold}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            InputLabelProps={{ shrink: true }}
            error = {errors.threshold}
            onInput={e => setThreshold(e.target.value)}
          />
          }
        </div>
      </form>
      <Box display="flex" justifyContent="flex-end">
        <ButtonLink onClick={handleCancel} color="blue" name="cancel" />
        <ButtonLink onClick={handleSubmit} color="pink" name="submit" />
        {/* <ButtonLink color='gray' name='Delete Cycle'
          onClick={handleDelete} /> */}
        <ButtonLink
          name="Delete Cycle"
          color="gray"
          onClick={() => {
            setConfirmOpen(true);
          }}
        />
        <ConfirmDialog
          title="Delete Cycle"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleDelete}
        >
          Are you sure you want to delete this cycle completely?
        </ConfirmDialog>
      </Box>
    </MainPage>
  );
};

export default UpdateCycle;
