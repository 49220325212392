import {useMutation, useQuery} from 'react-query';
import axios from "axios";

const handleFetchSitesAddresses = async (userId) => {
    const sites = await axios.get(
        process.env.REACT_APP_BACKEND_LINK +
          "api/user/sitesFor/addresses/" + userId
      );
      
      if (sites?.data?.success) return sites?.data?.addresses;

      return [];
};

export const useGetUserSitesAddresses = () => {
  return useMutation(
    ({userId}) => handleFetchSitesAddresses(userId),
    { 
        staleTime: 1000*60*60*24
    }
  );
};