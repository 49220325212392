import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { authContext } from "../../../shared/context/auth/auth-context";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  Button,
  TableContainer,
  Typography,
  TableSortLabel,
  Box
} from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import { useHistory, useLocation } from "react-router";
import MainPage from "../../../components/Layout/MainPage";
import ConfirmDialog from "../../Util/ConfirmDialog/ConfirmDialog";
import ButtonLink from "../../Util/ButtonLink";
import BayData from "./BayData";
import axios from "axios";
import { SiteContext } from "../../../shared/context/site/site-context";

const useStyles = makeStyles({
  table: {
    minWidth: 650,

    transition: "0.5s ease"
  },
  searchBar: {
    marginBottom: "30px"
  },
  tableRow: {
    transition: "0.3s ease",
    "&:hover": {
      backgroundColor: "#b8eaff !important"
    }
  }
});
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.disabled
    }
  }
}))(TableRow);
const Bays = props => {
  const [isSubmited, setIsSubmited] = useState(false);
  const { site, updateSiteContext } = useContext(SiteContext);
  const [BayName, setBayname] = useState("")
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const { user } = useContext(authContext);

  let _id,
    siteId,
    CarWashName,
    CarWashOwner = "";
  if (!location.state?._id && !site?._id) {
    _id = localStorage.getItem("site_id");
    siteId = localStorage.getItem("siteId");
    CarWashName = localStorage.getItem("CarWashName");
    CarWashOwner = localStorage.getItem("CarWashOwner");
  } else {
    _id = location.state?._id || site._id;
    siteId = location.state?.siteId || site?.siteId;
    CarWashName = location.state?.siteName || site?.siteName;
    CarWashOwner = location.state?.siteOwner || site?.siteOwner;
    localStorage.setItem("site_id", _id);
    localStorage.setItem("siteId", siteId);
    localStorage.setItem("CarWashName", CarWashName);
    localStorage.setItem("CarWashOwner", CarWashOwner);
  }

  const title = "Bays in " + CarWashName;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [DeleteRowId, setDeleteRowId] = useState("");
  const [confirmOpen, setConfirmOpen] = useState("");

  // search bar hooks and methods
  const [searched, setSearched] = useState("");

  //update list data by useEffect rather than hard code
  // const [rows, setRows] = useState(BayData.originalRows);
  const [rows, setItems] = useState([]);
  const [message, setMessage] = useState();

  useEffect(() => {
    return () => {
      updateSiteContext(_id);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const readURL1 =
        process.env.REACT_APP_BACKEND_LINK + "api/bay/read/" + _id;

      const result = await axios.get(readURL1);
      result.data = result.data.sort((a,b) => (a.bayName?.toLowerCase() > b.bayName?.toLowerCase()) ? 1 : -1);
      setItems(result.data);
      const readURL2 =
        process.env.REACT_APP_BACKEND_LINK + "api/site/readone/" + _id;
      const result2 = await axios.get(readURL2);
    };
    fetchData();
    return () => {};
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredRows = rows.filter(row => {
    return row.bayName.toLowerCase().includes(searched.toLowerCase());
  });
  const cancelSearch = () => {
    setSearched("");
  };

  async function DeleteBay() {
    //console.log(DeleteRowId);
    let body = {
      bayId: DeleteRowId
    };
    fetch(process.env.REACT_APP_BACKEND_LINK + "api/bay/delete", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem("authToken")}`
      },
      mode: "cors"
    })
   
  // window.location.reload(true)
  //   setMessage("Delete Successfully!");
  //  
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Bay ${BayName} Deleted from ${CarWashName}`, category: "Delete Bay" }
      )
    }
    catch (error) {
      console.log(error)
    }
    setIsSubmited((state) => !state);
  }
  useEffect(()=>{
    const fetchData = async () => {
          const readURL1 =
            process.env.REACT_APP_BACKEND_LINK + "api/bay/read/" + _id;
          const result = await axios.get(readURL1);
          result.data = result.data.sort((a,b) => (a.bayName?.toLowerCase() > b.bayName?.toLowerCase()) ? 1 : -1);
          setItems(result.data);
          
        };
        fetchData();
  },[isSubmited])

  return (
    <MainPage name={title}>
      <SearchBar
        className={classes.searchBar}
        onChange={searchVal => setSearched(searchVal)}
        onCancelSearch={() => cancelSearch()}
      />
      {/* Table */}
      {message && (
        <div className="message" style={{ color: "red" }}>
          {" "}
          {message}{" "}
        </div>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {BayData.columns.map(column => {
                if(!(user.role === 'admin') && (column.id === 'edit' || column.id === 'delete')) return null;
               return (
                  <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
               );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <StyledTableRow className={classes.tableRow} key={row.id}>
                 
                  <TableCell>{row.bayName}</TableCell>
                  <TableCell>{row.bayDescription}</TableCell>
                  <TableCell>{row.washType}</TableCell>
                 {user.role === 'admin' && 
                 <>
                  <TableCell>
                    <ButtonLink
                      name="Edit"
                      color="green"
                      onClick={() => {
                        history.push({
                          pathname: "/updateBay",
                          search: _id,
                          state: {
                            _id: _id,
                            siteId: siteId,
                            siteName: CarWashName,
                            siteOwner: CarWashOwner,
                            bayId: row.bayId
                          }
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <ButtonLink
                      name="Delete"
                      color="gray"
                      onClick={() => {
                        setConfirmOpen(true);
                        setDeleteRowId(row.bayId);
                        setBayname(row.bayName)
                        //console.log(row);
                      }}
                    />
                    <ConfirmDialog
                      title="Delete Bay"
                      open={confirmOpen}
                      setOpen={setConfirmOpen}
                      onConfirm={DeleteBay}
                    >
                      Are you sure you want to delete this bay?
                    </ConfirmDialog>
                  </TableCell>
                 </>
                 }
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {user?.role === 'admin' &&        
          <ButtonLink
            color="pink"
            name="Create New Bay"
            onClick={() => {
              history.push({
                pathname: "/createBay",
                state: {
                  _id: _id,
                  siteId: siteId,
                  siteName: CarWashName,
                  siteOwner: CarWashOwner
                }
              });
            }}
          />
        }
        <ButtonLink
          color="blue"
          name="Back to site"
          onClick={() => {
            history.push({
              pathname: "/site/",
              search: _id,
              state: {
                _id: _id,
                siteName: CarWashName,
                siteOwner: CarWashOwner
              }
            });
          }}
        />
      </Box>
    </MainPage>
  );
};

export default Bays;
