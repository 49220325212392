import { createContext } from "react";

export const SiteContext = createContext({
  site: null,
  Packages: null,
  Cycles: null,
  Tanks:null,
  Bays: null,
  Controllers: null,
  updateSiteContext: _id => {}
});

export default SiteContext;
