import React, { useEffect, useState } from 'react'
import Maintenence from './Maintenence'
import SitesOverview from './SitesOverview'
import jwtDecode from 'jwt-decode'
import SitesView from './SitesView'
const Sites = () => {

  const [user, setUser] = useState();


  useEffect(() => {
    let authToken = localStorage.getItem("authToken") || null;
    let user = null;
    if (authToken) {
      user = jwtDecode(authToken);
      setUser(user.role);
    }
  }, []);

  return (
    <>
      {(user === 'admin' || user === 'distributor') && <> <SitesOverview /><Maintenence /></>}
      {(user === 'owner' || user === 'operator') && <><SitesView /></>}
    </>
  )
}

export default Sites
