import React, { useEffect, useState, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { TableContainer, Link, Box } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import { useHistory } from "react-router";
import MainPage from "../Layout/MainPage";
import SiteData from "./SiteData";
import ButtonLink from "../Util/ButtonLink";
import axios from "axios";
import { authContext } from "../../shared/context/auth/auth-context";
import { SiteContext } from "../../shared/context/site/site-context";
import { getSitesForUser } from "../../shared/api";

const useStyles = makeStyles({
  table: {
    minWidth: 650,

    transition: "0.5s ease"
  },
  searchBar: {
    marginBottom: "30px"
  },
  tableRow: {
    transition: "0.3s ease",
    "&:hover": {
      backgroundColor: "#b8eaff !important"
    }
  },
  link: {
    transition: "0.5s ease",
    "&:hover": {
      fontWeight: 500
    }
  }
});
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.disabled
    }
  }
}))(TableRow);
const Sites = props => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useContext(authContext);
  const { updateSiteContext } = useContext(SiteContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // search bar hooks and methods
  const [searched, setSearched] = useState("");
  const [rows, setRows] = useState(SiteData.originalRows);
  const [users, setUsers] = useState([]);
  const [installers, setInstaller] = useState([]);
  let site_id = null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredRows = rows.filter(row => {
    return row?.siteName?.toLowerCase().includes(searched.toLowerCase());
  });
  const cancelSearch = () => {
    setSearched("");
  };

  useEffect(async () => {
    if (user) {
      let sites = await getSitesForUser(user.id);
      sites.sites = sites.sites.sort((a,b) => (a.siteName?.toLowerCase() > b.siteName?.toLowerCase()) ? 1 : -1);
      if (sites.success) {
        setRows(sites.sites);
      } else {
        setRows([]);
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const users = await axios.get(
        process.env.REACT_APP_BACKEND_LINK + "api/auth/users"
      );
      const installer = await axios.get(
        process.env.REACT_APP_BACKEND_LINK + "api/auth/installer"
      );
      setUsers(users.data);
      setInstaller(installer.data);
    };
    fetchData();
  }, []);

  return (
    <>
      <MainPage name={"SITES"} color="teal">
        <SearchBar
          className={classes.searchBar}
          onChange={searchVal => setSearched(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        {/* Table */}
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {SiteData.columns.map((column, i) => (
                  <StyledTableCell
                    key={"cc" + i}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                  <StyledTableRow className={classes.tableRow} key={row._id}>
                    <TableCell component="th" scope="row">
                      <Link
                        style={{ cursor: "pointer" }}
                        className={classes.link}
                        href={row.id}
                        onClick={e => {
                        
                          updateSiteContext(row._id);
                          e.preventDefault();
                          history.push({
                            pathname: "/site/",
                            search: row._id,
                            state: {
                              _id: row._id,
                              siteId: row.siteId,
                              siteName: row.siteName,
                              siteOwner: row.siteOwner
                            }
                          });
                        }}
                        color="inherit"
                        underline="none"
                      >
                        {row.siteName}
                      </Link>
                    </TableCell>

                    <TableCell>{row.siteOwner}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      {installers.map((installer, i) => {
                        return (
                          installer.siteId === row.siteId && (
                            <>
                              <div key={i + "in"}>
                                {users.map((user, i) => {
                                  return (
                                    user._id === installer.installer1 && (
                                      <div key={i}> {user.name}</div>
                                    )
                                  );
                                })}
                              </div>

                              <div>
                                {users.map((user, i) => {
                                  return (
                                    user._id === installer.installer2 && (
                                      <div key={i + "um"}> {user.name}</div>
                                    )
                                  );
                                })}
                              </div>
                            </>
                          )
                        );
                      })}
                    </TableCell>
                    <TableCell>No</TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </MainPage>
    </>
  );
};

export default Sites;
