const BayData = {
    columns: [
        
        { id: 'name', label: 'Name', minWidth: 200 },
        { id: 'description', label: 'Description', minWidth: 200 },
        { id: 'washType', label: 'Wash Type', minWidth: 200 },
        { id: 'edit', label: 'Edit', minWidth: 200 },
        { id: 'delete', label: 'Delete', minWidth: 200 }
    ],


    originalRows: [
        createData('111','North','Tunnel'),
        createData('222','East','Tunnel'),
        createData('333','West','Auto'),
        createData('444','Big','Auto'),
        createData('555','Small','Auto'),
        //  createBayList()
    ]


}
function createData(id, name, washType) {
    return { id, name, washType };
}

function GetDatalist () {
    //console.log("get_datalist...");
    fetch(process.env.REACT_APP_BACKEND_LINK +'api/bay/read'
    ,{
    method: "GET",
    headers : {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("authToken")}`
    },
    mode: "cors"
    }
    )
    .then (response =>{
        const ret  = response.json();
        //console.log(ret);
        return ret;
    });

    }
async function createBayList() {
    const response = await fetch(process.env.REACT_APP_BACKEND_LINK +'api/bay/read'
    ,{
    method: "GET",
    headers : {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("authToken")}`
    },
    mode: "cors"
    }
    );
    const data = await response.json();
    const bays = [];

    //console.log(data);

    for(var i=0 ; i< 5;i++){
        let bayId = data[i].bayId;
        let bayName = data[i].bayName;
        let washType = data[i].washType;

        const bayInfo = createData(bayId, bayName, washType)
        //console.log(bayInfo)

        bays.push(bayInfo);
    }

    //console.log(bays)

    return bays;
}

export default BayData