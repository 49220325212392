import axios from "axios";

export const fetchInventoryById = async id => {
  const readURL =
    process.env.REACT_APP_BACKEND_LINK + "api/inventory/readone/" + id;
  const inventory = await axios.get(readURL);
  return inventory.data;
};

export const fetchInventoriesBySiteId = async id => {
  const readURL =
    process.env.REACT_APP_BACKEND_LINK + "api/inventory/readBySite/" + id;
  const inventories = await axios.get(readURL);
  return inventories.data;
};

export const updateInventoryStock = async (id, newInventories) => {
  const readURL =
    process.env.REACT_APP_BACKEND_LINK + "api/inventory/update/" + id;
  let data = JSON.stringify({
    updatedInventoris: newInventories
  });

  let config = {
    method: "put",
    url: readURL,
    headers: {
      "Content-Type": "application/json"
    },
    data: data
  };
  const inventories = await axios(config);
  return inventories.data;
};
