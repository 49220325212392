import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router";
import MainPage from "../../Layout/MainPage";
import ButtonLink from "../../Util/ButtonLink";
import jwtDecode from "jwt-decode";
import { SiteContext } from "../../../shared/context/site/site-context";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import swal from "sweetalert";
import axios from "axios";
import {fetchAllUsers } from '../../../shared/api/index'
import RestrictedSiteView  from "./RestrictedSiteView";
import TextField from "@material-ui/core/TextField";
import Maintenence from "./Owner-OperatorClosedRequests";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: "100%"
  },
textField: {
  marginLeft: theme.spacing.unit,
  marginRight: theme.spacing.unit,
  width: 500,
  color: "black",
  fontSize: 30,
  opacity: 1,
  borderBottom: 0,
  "&:before": {
    borderBottom: 0
  }
},
}));

const Site = function SetSite() {
  const { site } = useContext(SiteContext);
  const [userName, setUserName] = useState("");
  const [user, setUsers] = useState([]);
  const location = useLocation();
  const classes = useStyles();

  let _id,
    siteId,
    CarWashName,
    CarWashOwner = "";
  if (!location.state?._id && !site?._id) {
    _id = localStorage.getItem("site_id");
    siteId = localStorage.getItem("siteId");
    CarWashName = localStorage.getItem("CarWashName");
    CarWashOwner = localStorage.getItem("CarWashOwner");
  } else {
    _id = location.state?._id || site._id;
    siteId = location.state?.siteId || site?.siteId;
    CarWashName = location.state?.siteName || site?.siteName;
    CarWashOwner = location.state?.siteOwner || site?.siteOwner;
    localStorage.setItem("site_id", _id);
    localStorage.setItem("siteId", siteId);
    localStorage.setItem("CarWashName", CarWashName);
    localStorage.setItem("CarWashOwner", CarWashOwner);
  }


 
  const [req, setReq] = useState("");
  const [open, setOpen] = React.useState(false);
  const [requests, setRequests] = useState([]);
  const [list, setList] = React.useState(false);
  const [error, setError] = useState("");
  const history = useHistory()

  const handleInputChange = e => {
   setReq(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await axios.get(
        process.env.REACT_APP_BACKEND_LINK + "api/request/requests/" + _id
      );
  
      setRequests(data.data);

      let fetchedUsers =  await fetchAllUsers();
      setUsers(fetchedUsers)
     
    };
    fetchData();
   
  }, [_id, list]);


  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const decoded = jwtDecode(token);
      const name = decoded.name;
      setUserName(name);
    }
  }, []);

  const handleSubmit = async () => {
    let body = {
      siteId: _id,
      request: req,
      requestedBy: userName
    };

    try {
      let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/request/create", {
        method: "POST",
        body: JSON.stringify(body),
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("authToken")}`
        }
      }).then(
        response => response.json(), 
      )
      
      response.then(function(result) {
        if(result.errors && result.errors[0].msg.includes(""))
        {
        setError(result.errors[0].msg);
        setTimeout(() => {
          setError("");
          }, 5000);
        } else {
           swal({
            title: "Success!",
            text: "Request Submitted",
            icon: "success",
            button: "OK!",
          }).then(function() {
            toggleList();
            setOpen(false);
            setReq("");
          });
        }
      });
    

    } catch (e) {
      if (e.message.includes("status")) {
        setError(e.response.data.error);
        setTimeout(() => {
        setError("");
        }, 5000);
      }
      if (e.message.includes("Network"))
        swal({
          title: "Error!",
          text: e.message,
          icon: "error",
          button: "OK!",
        });
    }
  };

 const toggleList = () => {
  setList(!list);
 }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MainPage name={CarWashName} color="deepPurple">
     
        <Box display="flex" justifyContent="flex-end">
          <ButtonLink name="Request Maintenance" color="orange" onClick={handleClickOpen} />
          <ButtonLink
            name="Back to Site"
            onClick={() => {
              history.push({
                pathname: "/Sites/"
              });
            }}
          />
        </Box>
        
        {requests?.map((req, i) => {
          return  !req.isClosed &&  
          <RestrictedSiteView 
          user = {user}
          req = {req}
          toggleList = {toggleList}
          />
        
       })}
      </MainPage>
       <Dialog
        fullWidth
         maxWidth="sm"
         open={open}
         onClose={handleClose}
         className={classes.dialog}
          >
         <DialogContent>
          <TextField
            className={classes.textfield}
            multiline
            required
            rows={4}
            rowsMax={4}
            onChange={handleInputChange}
            name="request"
            autoFocus
            margin="dense"
            id="request"
            label="Request"
            type="text"
            fullWidth
            variant="standard"
          />
          {error && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <ButtonLink name="Confirm" onClick={handleSubmit} autoFocus />
          <ButtonLink color="pink" onClick={handleClose} name="Cancel" />
        </DialogActions>
      </Dialog>
       <Maintenence list = {list} />
    </>
  );
};

export default Site;
