const SiteData = {
  columns: [
    { id: "siteName", label: "Site Name", minWidth: 200 },
    { id: "siteOwner", label: "Company", minWidth: 200 },
    { id: "email", label: "Email", minWidth: 200 },
    { id: "installer", label: "Installer", minWidth: 200 },
    { id: "configured", label: "Site Configured", minWidth: 100 }
  ],
  columnsOperatorDashboard: [
    { id: "siteName", label: "Site Name", minWidth: 200 },
    { id: "tanksLevel", label: "Tank Levels", minWidth: 200 },
    { id: "inventory", label: "Inventory", minWidth: 200 },
    //{ id: "reports", label: "Reports", minWidth: 200 },
    { id: "reorders", label: "Reordering", minWidth: 200 }
  ],

  columnsAdminInventory: [
    { id: "siteName", label: "Site Name",  minWidth:200,maxWidth:250 },
    { id: "inventory", label: "Inventory",  minWidth: 200,maxWidth:250 },
    { id: "reorder", label: "Reordering", minWidth: 200,maxWidth:250 }

    ],
    columnsReordering: [
        { id: "Product SKU", label: "Product SKU", minWidth: 50,maxWidth:100 },
        { id: "stock", label: "Current Stock", minWidth: 50, maxWidth: 100  },
        { id: "order", label: "Order Amount", minWidth: 50, maxWidth: 100 },
        { id: "autoFill", label: "Auto Fill Amount", minWidth: 50, maxWidth: 100  },
        { id: "minStock", label: "Stock Trigger", minWidth: 50, maxWidth: 100  },
    ],
    columnsOrdersShoppingList: [
       { id: "Order-No", label: "Request No", minWidth: 50, maxWidth: 100 },
        { id: "Creation-Date", label: "Date Created", minWidth: 50, maxWidth: 100 },
        { id: "Created-by", label: "Created by", minWidth: 50, maxWidth: 100 },
        { id: "products", label: "SKUs ordered", minWidth: 50, maxWidth: 100 },
        { id: "total-container", label: "Total Containers", minWidth: 50, maxWidth: 100 },
        { id: "view", label: "View", minWidth: 50, maxWidth: 100 },
        { id: "Remove", label: "Remove", minWidth: 50, maxWidth: 100 },
        { id: "Send", label: "Submit Request", minWidth: 50, maxWidth: 100 },
    ],
    columnsOrdersHistoryList: [
       { id: "Order-No", label: "Request No", minWidth: 50, maxWidth: 100 },
        { id: "Submission-Date", label: "Submission Date", minWidth: 50, maxWidth: 100 },
        { id: "Created-by", label: "Created by", minWidth: 50, maxWidth: 100 },
        { id: "products", label: "SKUs ordered", minWidth: 50, maxWidth: 100 },
        { id: "total-container", label: "Total Containers", minWidth: 50, maxWidth: 100 },
        { id: "view", label: "View The Pdf", minWidth: 50, maxWidth: 100 },
        { id: "status", label: "Status", minWidth: 50, maxWidth: 100 },
    ],
    columnsAdminRequestOrdersList: [
      { id: "Order-No", label: "Request No", minWidth: 50, maxWidth: 100 },
       { id: "Submission-Date", label: "Submission Date", minWidth: 50, maxWidth: 100 },
       { id: "Created-by", label: "Created by", minWidth: 50, maxWidth: 100 },
       { id: "products", label: "SKUs ordered", minWidth: 50, maxWidth: 100 },
       { id: "total-container", label: "Total Containers", minWidth: 50, maxWidth: 100 },
       { id: "status-update", label: "Status Update", minWidth: 50, maxWidth: 100 },
       { id: "status", label: "Status", minWidth: 50, maxWidth: 100 },
   ],
  originalRows: [
    // createData('111', 'Mark ', '19, Stanley st, London, ON', '31231412312'),
    // createData('222', 'Bassim ', '293, Adelaide st, Toronto, ON', '31231412312'),
    // createData('333', 'Raman ', 'IT', '31231412312'),
    // createData('444', 'Shuwen 1', 'US', '31231412312'),
    // createData('11', 'Mason 1', '19, Stanley st, London, ON', '31231412312'),
    // createData('22', 'Customer 1', '293, Adelaide st, Toronto, ON', '31231412312'),
    // createData('33', 'Customer 1', 'IT', '31231412312'),
    // createData('44', 'Customer 1', 'US', '31231412312'),
  ]
};
// function createData(siteName, siteOwner, email, configured) {
//     return { siteName, siteOwner, email, configured };
// }

export default SiteData;
