import { Typography } from "@material-ui/core"
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";


const FetchName = (props) => {
    const [name, setName] = useState("");
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            const decoded = jwtDecode(token);
            let firstName = decoded.name.split(' ');
            firstName = " " + firstName[0];
            setName(firstName)
        }
    }, [])
    return (
        <Typography>  {name}</Typography >
    )
}

export default FetchName