import React, { useState, useEffect, useContext } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ButtonLink from "../../Util/ButtonLink";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import clsx from 'clsx';
import moment from 'moment';
import swal from "sweetalert";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
////////////////////////////////////////////////////

import Edit from "@material-ui/icons/Edit";
import SaveIcon from '@material-ui/icons/Save';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "25ch"
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2)
  },
  dialog: {
    width: "100%"
  },
  request: {
    textAlign: "center",
    fontSize: "25px",
    fontWeight: "500",
    color: "rgb(7,36, 51 , 60%)"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
},
icon: {
  verticalAlign: 'bottom',
  height: 20,
  width: 20,
},
details: {
  alignItems: 'left',
  display: 'flex',
  justifyContent: 'space-between'
},

helper: {
  borderLeft: `2px solid cyan`,
  padding: theme.spacing(1, 2),
  width: '100%',

},

description: {
  fontWeight: 500,
},
info: {
  display: 'flex',

},
para: {
  marginLeft: "5px",
  color: '#2e6b99',
},
///////////////////////////////////
container: {
  display: "flex",
  flexWrap: "wrap",
  padding: 10
},
textField: {
  marginLeft: theme.spacing.unit,
  marginRight: theme.spacing.unit,
  width: 500,
  color: "black",
  fontSize: 30,
  opacity: 1,
  borderBottom: 0,
  "&:before": {
    borderBottom: 0
  }
},
disabled: {
  color: "black",
  borderBottom: 0,
  "&:before": {
    borderBottom: 0
  }
},
btnIcons: {
  marginLeft: 10
}///////////////////////////
}));

const RestrictedSiteView = function SetRestrictedSiteView(props) {
  const classes = useStyles();

  const [requestDataId, setRequestDataId] = useState('');
  const [requestMessage, setRequestMessage] = useState("");
  const [requestedBy, setRequestedBy] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [scheduledAt, setScheduledAt] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [mouseEnter, setMouseEnter] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { user, req, toggleList } = props;
  const [error, setError] = useState("");
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    setRequestDataId(req._id);
    setRequestMessage(req.request_msg);
    setRequestedBy(req.requestedBy);
    setRequestDate(req.requestDate);
    setScheduledAt(req.scheduledAt);
    setExpanded(`panel${req._id}`);
  },[req]);

  const handleCancelSubmit = async () => {
    handleClose();
    let body = {
      _id:requestDataId
    }

    try {
        const data = await axios.post(process.env.REACT_APP_BACKEND_LINK + "api/request/close", body);
        swal({
            title: "Success!",
            text: data.data.message,
            icon: "success",
            button: "OK!",
            width: '100px'
        }).then(function() {
            toggleList();
        });
    }
    catch (e) {

        if (e.message.includes("status")) {
            swal({
                title: "Error!",
                text: e.message,
                icon: "error",
                button: "OK!",
            })
        }
        if (e.message.includes("Network"))
            swal({
                title: "Error!",
                text: "Network Error",
                icon: "error",
                button: "OK!",
                width: '100px'
            })
    }
}

  const handleUpdateRequest =  () => {
    // if(requestMessage)
    // {
    let body = {
      _id:requestDataId,
      request: requestMessage
    }

    try {
      let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/request/update", {
          method: "POST",
          body: JSON.stringify(body),
          mode: "cors",
          headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem("authToken")}`
          },
        }).then(
          response => response.json(), 
        )

        response.then(function(result) {
          if(result.errors && result.errors[0].msg.includes(""))
          {
          setError(result.errors[0].msg);
          setTimeout(() => {
            setError("");
            }, 5000);
          } else {    
            swal({
            title: "Success!",
            text: "Request Updated",
            icon: "success",
            button: "OK!",
          }).then(function() {
            toggleList();
          });
        }
        });
      } catch (e) {
      if (e.message.includes("status")) {
        swal({
          title: "Error!",
          text: e.message,
          icon: "error",
          button: "OK!",
        });
      }
      if (e.message.includes("Network"))
        swal({
          title: "Error!",
          text: e.message,
          icon: "error",
          button: "OK!",
        });
    }
   // }
  }
  /////////////////////////////////////////////////////////////////
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  //////////////////////////////////////////////////////////////////
const handleMouseEnter = event => {
    if (!mouseEnter) {
    setMouseEnter(true);
  }
};

const handleMouseLeave = event => {
  if (mouseEnter) {
    setMouseEnter(false);
  }
};

const handleEditClick = (id) => {
  setEditMode(true);
  setMouseEnter(false);
  setRequestDataId(id);
};

const handleRequestChange = (event) => {
    setRequestMessage(event.target.value);
};


const handleChange = (panel) => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : false);
};
 //////////////////////////////////////////////////////////////////

  return (
    <>
    <Accordion expanded={expanded === `panel${requestDataId}` } onChange={handleChange(`panel${requestDataId}`)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              
              <div className={clsx(classes.helper)}>
              {/* {
                                        //this is showing
              typeof req.installerId === 'undefined'
              &&  */}
              <Box className={classes.box} display='flex' alignItems='center' justifyContent='flex-end'>
               <ButtonLink color='red' name='Cancel Request' onClick={e => {handleClickOpen();}} />
               </Box>
               {/* } */}

               <div className={classes.info}>
                <Typography className={classes.description}>
                    Status:
                </Typography>
                
                <Typography className={classes.para}>
                    
                { 
               "In Progress"
                }

                </Typography>
            </div>
            <div className={classes.info}>
                <Typography className={classes.description}>
                    Requested By:
                </Typography>
                <Typography className={classes.para}>
                    {requestedBy}
                </Typography>
            </div>
            <div className={classes.info}>
                <Typography className={classes.description}>
                    Requested Date:
                </Typography>
                <Typography className={classes.para}>
                    {requestDate && moment(requestDate).format("YYYY/MM/DD h:mm a")}
                </Typography>
            </div>

            {req.installerId && <div className={classes.info}>
              <Typography className={classes.description}>
              Installer:
              </Typography>
              {user.map((inst, i) => {
                  return inst._id === req.installerId && (<Typography className={classes.para}>
                    {inst.name}
                    </Typography>)
                      })}

                    </div>
                    }
                    {scheduledAt && <div className={classes.info}>
                    <Typography className={classes.description}>
                    Scheduled At:
                  </Typography>
                  <Typography className={classes.para}>
                  {moment(scheduledAt).format("YYYY/MM/DD")}
                  </Typography>
                  </div>
                  }                                                                                     
            </div>
                      
              </AccordionSummary>
          <AccordionDetails>
          <Typography>
     
         <TextField
          defaultValue={requestMessage}
          value = {requestMessage}
          onChange = {handleRequestChange}
          className={classes.textField}
          disabled={!editMode} 
          multiline
          fullWidth
          rows={4}
          rowsMax={4}
          onMouseEnter = {handleMouseEnter}
          onMouseLeave = {handleMouseLeave}
          InputProps={{
              classes: {
                  disabled: classes.disabled
              },
              endAdornment:
                mouseEnter ?
                <InputAdornment position="end">
                  <IconButton onClick={e => handleEditClick(req._id)}><Edit /></IconButton>
                  <IconButton  onClick={ e => {handleUpdateRequest(); setEditMode(false)}}><SaveIcon /></IconButton>
                  </InputAdornment>
                    :
                  ""
          }}
          />
        
          </Typography>
          <Typography className={classes.para}>
          {error && <Alert severity="error">{error}</Alert>}
          </Typography>
          </AccordionDetails>
          </Accordion>

          <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>
                    {"Confirm Cancel?"}
                </DialogTitle>
                <DialogActions>
                    <ButtonLink name='Confirm' onClick={handleCancelSubmit} autoFocus>

                    </ButtonLink>
                    <ButtonLink color='pink' onClick={handleClose} name='Cancel'></ButtonLink>

                </DialogActions>
            </Dialog>
    </>
  );
};

export default RestrictedSiteView;
