const ControllerData = {
    columns: [
        // { id: 'controllerId', label: 'Controller Id', minWidth: 80 },
        { id: 'controllerMacAddress', label: 'Mac Address', minWidth: 150 },
        { id: 'controllerModel', label: 'Controller Model', minWidth: 100 },
        { id: 'oemModel', label: 'OEM Model', minWidth: 100 },
        { id: 'portPerController', label: 'Port Per Controller', minWidth: 50 },
        { id: 'test', label: 'Controller Test', minWidth: 100 },
        { id: 'edit', label: 'Edit', minWidth: 100 },
        { id: 'delete', label: 'Delete', minWidth: 100 }
    ],

 
    originalRows: [
        createData('111','HP-113','25'),
        createData('222','HP-114','25'),
        createData('333','HP-115','10'),
        createData('444','HP-116','25'),
        createData('555','HP-110','25'),
        createData('666','HP-116','25'),
    ]


}
function createData(id, product, size) {
    return { id, product, size };
}

export default ControllerData