import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "@material-tailwind/react/tailwind.css";
import React, { useEffect, useState } from "react";
import Login from "./components/Screens/Login";
import Coordinator from "./components/Routes/Coordinator";
import ForgotPassword from "./components/Screens/ForgotPassword";
import PrivateRoute from "./components/Routes/PrivateRoute";
import PrivateScreen from "./components/Screens/PrivateScreen";
import 'bootstrap/dist/css/bootstrap.min.css';


import ResetPassword from "./components/Screens/ResetPassword";
// import PrivateRoute from './components/Routes/PrivateRoute'
// import PrivateScreen from './components/Screens/PrivateScreen';
import authContext from "./shared/context/auth/auth-context";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { QueryClient, QueryClientProvider } from 'react-query'

function App() {
  const queryClient = new QueryClient();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const updateAuthContext = token => {
    if (token) {
      setToken(token);
      let user = jwt_decode(token);
      setUser(user);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("authToken") || null;
    let user = null;
    if (token) {
        setToken(token);
        user = jwt_decode(token);
        setUser(user);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);
  // const classes = useStyles();

  return (
    <QueryClientProvider client={queryClient}>
      <authContext.Provider value={{ user, token, updateAuthContext }}>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/" exact component={PrivateScreen} />
            {/* <Route path="/" component={Coordinator} /> */}
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/passwordReset/:resetToken" component={ResetPassword} />
          </Switch>
        </Router>
      </authContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
