import { Typography } from "@material-ui/core"
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import H6 from "@material-tailwind/react/Heading6";
import LeadText from "@material-tailwind/react/LeadText";
export const Welcome = (props) => {
    const [name, setName] = useState("");
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            const decoded = jwtDecode(token);
            const firstName = decoded.name.split(' ');
            setName(firstName[0])
        }
    }, [])
    return (
        <Typography style={{ marginLeft: '20px', marginBottom: 0 }} variant='h6' > Welcome, {name}</Typography >
    )
}