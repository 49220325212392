import React, { ReactDOM, useState, useEffect, useContext } from "react";
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  FormHelperText,
  Typography,
  Button,
  Box,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import ReactSelect, { components } from 'react-select';
import DropdownIndicator, { selectStyles } from "../../../Util/DropdownIndicator";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import { useHistory, useLocation } from "react-router";
import MainPage from "../../../../components/Layout/MainPage";
import { authContext } from "../../../../shared/context/auth/auth-context";
import ButtonLink from "../../../Util/ButtonLink";
import axios from "axios";
import { SiteContext } from "../../../../shared/context/site/site-context";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      marginLeft: theme.spacing(4),
      width: "35ch",
    },
  },
  formControl: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    width: "35ch",
  },
}));

const UpdateTank = function FormPropsTextFields() {
  const { site, tanks, controllers, updateSiteContext } =
    useContext(SiteContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  let SiteId,
    siteId,
    CarWashName,
    CarWashOwner,
    TankId = "";
  if (!location.state?._id && !site?._id) {
    SiteId = localStorage.getItem("site_id");
    siteId = localStorage.getItem("siteId");
    CarWashName = localStorage.getItem("CarWashName");
    CarWashOwner = localStorage.getItem("CarWashOwner");
    TankId = localStorage.getItem("TankObjectId");
  } else {
    SiteId = location.state?._id || site?._id;
    siteId = location.state?.siteId || site?.siteId;
    CarWashName = location.state?.siteName || site?.siteName;
    CarWashOwner = location.state?.siteOwner || site?.siteOwner;
    TankId = location.state?.tankId;
    localStorage.setItem("site_id", SiteId);
    localStorage.setItem("siteId", siteId);
    localStorage.setItem("CarWashName", CarWashName);
    localStorage.setItem("CarWashOwner", CarWashOwner);
    localStorage.setItem("TankObjectId", TankId);
  }
  const { user } = useContext(authContext);
  const [OtherSize, setOtherSize] = React.useState("");
  const [SizeSelect, setSizeSelect] = React.useState("");
  const [ProductAlias, setProductAlias] = React.useState("");
  const [NonMonitored, setNonMonitored] = React.useState(false);
  const [Stinger, setStinger] = React.useState("");
  const [PortNum, setPortNum] = React.useState("");
  const [MinStockAlert, setMinStockAlert] = React.useState("");
  const [DistanceToController, setDistanceToController] = React.useState("");
  const [Product, setProduct] = React.useState("");
  const [ProductClass, setProductClass] = React.useState({});
  const [ProductColor, setProductColor] = React.useState("");
  const [Controller, setController] = React.useState("");
  const [MacController, setMacController] = React.useState("");
  const [SensorType, setSensorType] = React.useState("");
  const [ProductId, setProductId] = React.useState("");
  const [CurrentTankId, setCurrentTankId] = React.useState("");
  const [discount, setDiscount] = React.useState(0);
  ///////////////////Selected////////////////////////
  const [CurrentTank, setCurrentTank] = React.useState({});
  //////////////////////////////////////////////////

  ///////////////Options////////////////////////////////
  const [Controllers, setControllers] = React.useState([]);
  const [Products, setProducts] = React.useState([]);
  const [Tanks, setTanks] = React.useState([]);
  const [AvailablePortList, setAvailablePortList] = React.useState([]);
  const [error, setError] = React.useState("");
  //////////////////////////////////////////////////////

  const [errors, setErrors] = React.useState({
    tanksize: "",
    inputsize: "",
    stinger: "",
    minstockalert: "",
    distancetocontroller: "",
  });

  React.useEffect(() => {
    const fetchData = async () => {
      //////////////////////Tanks///////////////////////////
      let readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/tank/read/" + SiteId;
      let result = await axios.get(readURL); //waiting
      setTanks(result.data);

      ////////////////////Controllers//////////////////////////
      readURL =
        process.env.REACT_APP_BACKEND_LINK + "api/controller/read/" + siteId;
      result = await axios.get(readURL);
      setControllers(result.data);
      //////////////////////////////////////////////////////////

      ////////////////////Products/////////////////////////////
      readURL = process.env.REACT_APP_BACKEND_LINK + "api/product/read";
      result = await axios.get(readURL);
      setProducts(result.data.sort((a,b) => (a.productSKU?.toLowerCase() > b.productSKU?.toLowerCase()) ? 1 : -1));
      ///////////////////////////////////////////////////////////
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    Tanks.filter((e) => e._id == TankId).forEach((e) => {
      setCurrentTank(e);
    });
  }, [Tanks]);

  React.useEffect(() => {
    const controller = Controllers.filter(
      (e) => e._id == CurrentTank.controllerId
    );

    let currentcontroller = {};
    currentcontroller = controller[0];

    if (currentcontroller) {
      setController(CurrentTank.controllerId);
      setMacController(currentcontroller.controllerMacAddress);
      
      let availablePortList = Array.from({length: currentcontroller?.portPerController}, (_, i) => i + 1);

      Tanks.filter((e) =>
      e._id !== CurrentTank._id
    ).forEach((e) => {
      var removePort = parseInt(e.portNum);
      var removeIndex = availablePortList.indexOf(removePort);
      availablePortList.splice(removeIndex, 1);
    });

      setAvailablePortList(availablePortList);
    }
  }, [CurrentTank, Controllers, Tanks]);

  React.useEffect(() => {
    setPortNum(CurrentTank.portNum);
    setStinger(CurrentTank.stinger);
    setMinStockAlert(CurrentTank.minStockAlert);
    setDistanceToController(CurrentTank.distanceToController);
    setSensorType(CurrentTank.sensorType);
    setCurrentTankId(CurrentTank.tankId);

    if (CurrentTank.size === "20" || CurrentTank.size === 10) {
      setSizeSelect(CurrentTank.size);
    } else {
      setSizeSelect("other");
      setOtherSize(CurrentTank.size);
    }
    ///////////////////Product Info//////////////////////////
    const prod = Products.find(p => p?.productId == CurrentTank.productId)
    setProductAlias(CurrentTank.productAlias);
    setNonMonitored(CurrentTank.nonMonitored);
    setProduct(CurrentTank.product);
    setProductClass({
        ...prod, 
        value: `${prod?.productId}-update-tank`, 
        label: `${prod?.productSKU}-${prod?.productClass}`
    });
    setProductColor(CurrentTank.productColor);
    setProductId(CurrentTank.productId);
    setDiscount(CurrentTank.discount);
    ////////////////////////////////////////////////////////

    ///////////////////controller Info///////////////////
    setController(CurrentTank.controllerId);
    //////////////////////////////////////////////////////
  }, [CurrentTank, Products]);

  const handleContrItemClick = (e, controller) => {
    setMacController(controller.controllerMacAddress);
    setController(controller?._id);

    let availablePortList = Array.from({length: controller?.portPerController}, (_, i) => i + 1);

    Tanks.filter((e) =>
    e._id !== CurrentTank._id
  ).forEach((e) => {
    var removePort = parseInt(e.portNum);
    var removeIndex = availablePortList.indexOf(removePort);
    availablePortList.splice(removeIndex, 1);
  });

    setAvailablePortList(availablePortList);
  };

  const handleProdItemClick = (product) => {
    setProduct(product.productSKU);
    setProductClass(product);
    setProductColor(product.productColor);
    setProductId(product.productId);
  };

  const handleChange = (event) => {
    if (event.target.name === "SizeSelect") {
      setSizeSelect(event.target.value);
    }
    if (event.target.name === "SensorType") {
      setSensorType(event.target.value);
    }
    if (event.target.name === "Port") {
      setPortNum(event.target.value);
    }
  };

  const tankValidation = async (e) => {
    let temp = {};
    temp.tanksize = NonMonitored || SizeSelect ? "" : "Please select the Tank Size";
    temp.inputsize = NonMonitored || (OtherSize && OtherSize > 0) ? "": "Please enter valid positive input size";
    temp.stinger = Stinger
      ? Stinger > 0
        ? ""
        : "Please enter positive stinger quantity"
      : "";
    temp.minstockalert = MinStockAlert
      ? MinStockAlert > 0
        ? ""
        : "Please enter positive min stock alert"
      : "";
    temp.distancetocontroller = DistanceToController
      ? DistanceToController > 0
        ? ""
        : "Please enter positive distance to controller"
      : "";
    temp.discount =
       (discount >= 0 && discount <=100 ) ? "" : "Please enter positive value of discount";

    if (SizeSelect !== "other") temp.inputsize = "";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

 async  function handleSubmit() {
    let Size = SizeSelect;
    if (SizeSelect === "other") {
      Size = OtherSize;
    } else {
      Size = parseInt(SizeSelect);
    }
    console.log('Controller: ', Controller)
    let body = {
      id: TankId,
      tankId: CurrentTankId,
      productAlias: ProductAlias,
      product: Product,
      productColor: ProductColor,
      productId: ProductId,
      size: Size,
      siteId: SiteId,
      distanceToController: DistanceToController,
      stinger: Stinger,
      minStockAlert: MinStockAlert,
      portNum: PortNum,
      controllerId: Controller,
      sensorType: SensorType,
      nonMonitored: NonMonitored,
      discount,
    };

    tankValidation().then(function (result) {
      if (result) {
        let response = fetch(
          process.env.REACT_APP_BACKEND_LINK + "api/tank/update",
          {
            method: "POST",
            body: JSON.stringify(body),
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        ).then((response) => response.json());

        response.then(function (result) {
          if (result.errors && result.errors[0].msg.includes("")) {
            setError(result.errors[0].msg);
            setTimeout(() => {
              setError("");
            }, 5000);
          } else {
            history.push({
              pathname: "/tanks/",
              state: {
                _id: SiteId,
                siteId: siteId,
                siteName: CarWashName,
                siteOwner: CarWashOwner,
              },
            });
          }
        });
      }
    });
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Tank ${Product} Updated at ${CarWashName}`, category: "Update Tank" }
      )
    }
    catch (error) {
      console.log(error)
    }
  }

  function handleCancel() {
    history.push({
      pathname: "/tanks/",
      state: {
        _id: SiteId,
        siteId: siteId,
        siteName: CarWashName,
        siteOwner: CarWashOwner,
      },
    });
  }

  return (
    <MainPage name={"Update Tank"}>
      <form className={classes.root} noValidate autoComplete="off">
        <div id="TankInfo">
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="h5">
            Tank Info.
          </Typography>
          <div>
          <FormControl className={classes.formControl}>
          <FormControlLabel 
          control={
            <Checkbox 
                name="NonMonitored"
                checked={NonMonitored}
                onChange={
                    (e, checked) => setNonMonitored(checked)
                }
            />} 
           label="Non-monitored Product" 
           />
           </FormControl>
           </div>
          <FormControl className={classes.formControl} disabled={NonMonitored}>
            <InputLabel id="SensorType" InputLabelProps={{ shrink: true }}>
              Sensor Type
            </InputLabel>
            <Select
              name="SensorType"
              labelId="Sensor Type"
              id="SensorType"
              value={SensorType ? SensorType : ''}
              onChange={handleChange}
            >
              <MenuItem value={"UK1C-G2-0E"}>UK1C-G2-0E (New)</MenuItem>
              <MenuItem value={"UK1C-E2-0E"}>UK1C-E2-0E (Old)</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} disabled={NonMonitored}>
            <InputLabel id="Controller" InputLabelProps={{ shrink: true }}>
              Controller
            </InputLabel>
            <Select
              name="Controller"
              labelId="Controller"
              id="Controller"
              value={MacController}
              onChange={handleChange}
              helperText={MacController}
            >
              {Controllers.map((row) => (
                <MenuItem
                  key={`${row?._id}-654654`}
                  value={row.controllerMacAddress}
                  onClick={(event) => handleContrItemClick(event, row)}
                >
                  {row.controllerMacAddress}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl} disabled={NonMonitored} required={!NonMonitored}>
            <InputLabel
              id="SizeSelect"
              error={errors.tanksize}
              InputLabelProps={{ shrink: true }}
            >
              Tank Size
            </InputLabel>
            <Select
              name="SizeSelect"
              labelId="Tank Size"
              id="SizeSelect"
              value={SizeSelect}
              onChange={handleChange}
              error={errors.tanksize}
            >
              <MenuItem value={"20"}>20 Gal</MenuItem>
              <MenuItem value={"10"}>10 Gal</MenuItem>
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>

            {errors.tanksize && (
              <FormHelperText htmlFor="render-select" error>
                {errors.tanksize}
              </FormHelperText>
            )}
          </FormControl>
          <TextField
            className={classes.formControl}
            id="productDiscount"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 0 } }}
            label="Product Discount (%)"
            value={discount}
            onInput={(e) => setDiscount(e.target.value)}
            error={errors.discount}
            helperText={errors.discount}
            disabled={NonMonitored}
          />
          <TextField
            className={classes.formControl}
            id="ProductAlias"
            label="Product Alias"
            value={ProductAlias}
            onInput={(e) => setProductAlias(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          {SizeSelect === "other" && (
            <TextField
              id="otherSize"
              label="Input Size"
              value={OtherSize}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: 1 } }}
              onInput={(e) => setOtherSize(e.target.value)}
              error={errors.inputsize}
              helperText={errors.inputsize}
              required={NonMonitored}
              disabled={NonMonitored}
            />
          )}

          <TextField
            className={classes.TextControl}
            id="Stinger"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 1 } }}
            label="Stinger Quantity"
            value={Stinger}
            onInput={(e) => setStinger(e.target.value)}
            error={errors.stinger}
            helperText={errors.stinger}
            disabled={NonMonitored}
          />

          <FormControl className={classes.formControl} disabled={NonMonitored}>
            <InputLabel id="Port">Port</InputLabel>
            <Select
              name="Port"
              labelId="Port"
              id="Port"
              value={PortNum && AvailablePortList?.length > 0 ? PortNum : ''}
              onChange={handleChange}
            >
              {AvailablePortList?.length > 0 && AvailablePortList.map((e) => (
                <MenuItem key={`${e}-654654`} value={e}>{e}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="MinStockAlert"
            label="Min Stock (Alert)"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 1 } }}
            value={MinStockAlert}
            onInput={(e) => setMinStockAlert(e.target.value)}
            error={errors.minstockalert}
            helperText={errors.minstockalert ? errors.minstockalert : "Min Stock (Alert) must be set greater than '0' to enable automatic inventory management"}
          />
          <TextField
            id="DistanceToController"
            label="Distance To Controller (ft)"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 1 } }}
            value={DistanceToController}
            onInput={(e) => setDistanceToController(e.target.value)}
            error={errors.distancetocontroller}
            helperText={errors.distancetocontroller}
            disabled={NonMonitored}
          />

          <FormControl className={classes.formControl}>
            <InputLabel id="Product" InputLabelProps={{ shrink: true }} style={Product ? {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
            } : {}}>Product</InputLabel>
            <ReactSelect
              name="Product"
              labelId="Product"
              id="Product"
              placeholder="Product"
              styles={selectStyles}
              onChange={handleProdItemClick}
              components={{ DropdownIndicator }}
              value={ProductClass}
              options={Products.map((row) => (
                {
                    ...row, 
                    value: `${row?.productId}-update-tank`, 
                    label: `${row.productSKU}-${row.productClass}`
                }
              ))}
            ></ReactSelect>
          </FormControl>

          <Grid item xs={6} sm={4}>
            <Box
              bgcolor={ProductColor}
              color="background.paper"
              p={0.7}
              // style={{ marginLeft: "20px", marginBottom: "40px" }}
            >
              Tank Color
            </Box>
          </Grid>
        </div>
      </form>
      <Box display="flex" justifyContent="flex-end">
        <ButtonLink onClick={handleCancel} color="blue" name="cancel" />
        <ButtonLink onClick={handleSubmit} color="pink" name="submit" />
      </Box>
    </MainPage>
  );
};

export default UpdateTank;
