import React, { useContext } from "react";
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  FormHelperText,
  Typography,
  Button,
  Box
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import { useHistory, useLocation } from "react-router";
import MainPage from "../../../Layout/MainPage";
import ButtonLink from "../../../Util/ButtonLink";
import { SiteContext } from "../../../../shared/context/site/site-context";
import axios from "axios";
import { authContext } from "../../../../shared/context/auth/auth-context";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "33ch"
    }
  },
  formControl: {
    margin: theme.spacing(1),
    width: "35ch"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2)
  }
}));

const CreateController = function FormPropsTextFields() {
  const { site, updateSiteContext } = useContext(SiteContext);
  const { user } = useContext(authContext);
  const location = useLocation();
  const _id = location.state?._id || site?._id;
  const siteId = location.state?.siteId || site?.siteId;
  const CarWashName = location.state?.siteName || site?.siteName;
  const CarWashOwner = location.state?.siteOwner || site?.siteOwner;

  //console.log(siteId);
  const classes = useStyles();
  const history = useHistory();
  const [HardwareId, setHardwareId] = React.useState("");
  const [ControllerMacAddress, setControllerMacAddress] = React.useState("");
  const [ControllerModel, setControllerModel] = React.useState("");
  const [PortPerController, setPortPerController] = React.useState("");
  const [oemModle, setOemModle] = React.useState("");
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState({
    macAdd:'',
    port:'',
  })

  React.useEffect(() => {
    return () => {
      const _id = location.state?._id || site?._id;
      updateSiteContext(_id);
    };
  }, []);
  const controllerValidation = async e => 
  {
    let temp={};
    temp.macAdd = ControllerMacAddress?"":"Please enter the Controller Mac Address";
    temp.port = PortPerController?"":"Please enter Port Number";
    setErrors({
       ...temp
     })
     
      return Object.values(temp).every(x => x === "");
  }


  const handleChange = event => {
    if (event.target.name === "PortPerController")
      setPortPerController(event.target.value);
  };

  async function handleSubmit() {
    let body = {
      siteId: siteId,
      controllerMacAddress: ControllerMacAddress.toLowerCase(),
      controllerId: HardwareId,
      controllerModel: ControllerModel,
      oemModel: oemModle,
      portPerController: PortPerController
    };
    controllerValidation().then(function(result) {
      //console.log(result)
      if(result)
      {
        let response = fetch(process.env.REACT_APP_BACKEND_LINK + "api/controller/create", {
          method: "POST",
          body: JSON.stringify(body),
          mode: "cors",
          headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem("authToken")}`
          }
        }).then( response => response.json(),)
    
        response.then(function(result)
        {
        if(result.errors && result.errors[0].msg.includes(""))
        {
          //console.log(result)
          setError(result.errors[0].msg);
          setTimeout(() => {
            setError("");
          }, 5000);
        }
        else {
        history.push({
          pathname: "/controllers/",
          state: {
            _id: _id,
            siteId: siteId,
            siteName: CarWashName,
            siteOwner: CarWashOwner
          }
        });
        }
      });
      }
    })
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Controller MAC: ${ControllerMacAddress} Created at ${CarWashName}`, category: "Create Controller" }
      )
    }
    catch (error) {
      console.log(error)
    }
  }

  function handleCancel() {
    history.push({
      pathname: "/controllers/",
      state: {
        _id: _id,
        siteId: siteId,
        siteName: CarWashName,
        siteOwner: CarWashOwner
      }
    });
  }

  return (
    <MainPage name={"Create Controller"}>
      <form className={classes.root} noValidate autoComplete="off">
        <div id="ControllerSize">
          {error && <Alert severity="error">{error}</Alert>}
          <Typography align="center" variant="h5">
            Controller Info.
          </Typography>
          {/*<TextField*/}
          {/*  disabled*/}
          {/*  id="hardwareId"*/}
          {/*  label="Controller Id"*/}
          {/*  value={HardwareId}*/}
          {/*  onInput={e => setHardwareId(e.target.value)}*/}
          {/*/>*/}
          <TextField
            id="controllerMacAddress"
            label="Controller Mac Address"
            value={ControllerMacAddress}
            onInput={e => setControllerMacAddress(e.target.value)}
            error={errors.macAdd}
            helperText= {errors.macAdd}

          />
          <TextField
            id="controllerModel"
            label="Controller Model"
            value={ControllerModel}
            onInput={e => setControllerModel(e.target.value)}
          />
          <TextField
            id="oemModle"
            label="OEM Model"
            value={oemModle}
            onInput={e => setOemModle(e.target.value)}
          />
          <FormControl className={classes.formControl}>
            <InputLabel id="PortPerController">Port Amount</InputLabel>
            <Select
              name="PortPerController"
              labelId="PortPerController"
              id="PortPerController"
              value={PortPerController}
              onChange={handleChange}
              error={errors.port}
              helperText= {errors.port}
            >
              <MenuItem value={"10"}>10</MenuItem>
              <MenuItem value={"16"}>16</MenuItem>
              <MenuItem value={"24"}>24</MenuItem>
            </Select>
          </FormControl>
        </div>
      </form>
      <Box display="flex" justifyContent="flex-end">
        <ButtonLink onClick={handleCancel} color="blue" name="cancel" />
        <ButtonLink onClick={handleSubmit} color="pink" name="submit" />
      </Box>
    </MainPage>
  );
};

export default CreateController;
