import axios from "axios";

export const sendSuppotEmail = async (id, {name, subject, message}) => {
  const readURL = process.env.REACT_APP_BACKEND_LINK + "api/mailer/send/" + id;
  let data = JSON.stringify({
    name,
    subject,
    message
  });

  let config = {
    method: "post",
    url: readURL,
    headers: {
      "Content-Type": "application/json"
    },
    data: data
  };
  const emailRes = await axios(config);
  return emailRes.data;
};