import { useEffect, useState } from "react";
import Coordinator from "../Routes/Coordinator";
import Distributor from "../Routes/Distributor";
import Operator from "../Routes/Operator";
import SiteOwner from "../Routes/SiteOwner"
import jwtDecode from "jwt-decode";
import Installer from "../Routes/Installer";
import { SiteContextStates } from "../../shared/context/site/site-context-component";
import { Redirect, Route } from "react-router-dom";

const PrivateScreen = ({ history }) => {
  const [role, setRole] = useState(null);

    useEffect(() => {
        let token = localStorage.getItem("authToken") || null;
    if (token) {
      var decoded = jwtDecode(token);
      setRole(decoded?.role);
    }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("authToken");   
    localStorage.clear();
    history.push("/login");
    };

  //choose which UI to display based on the role
  let RoleComponent = null; // this value has to be changed to a default component in case role string is not recognize
    switch (role) {
    case "admin":
      RoleComponent = <Coordinator logoutHandler={logoutHandler} />;
      break;
    case "distributor":
      RoleComponent = <Distributor logoutHandler={logoutHandler} />;
      break;
    case "installer":
      RoleComponent = <Installer logoutHandler={logoutHandler} />;
      break;
    case "owner":
      RoleComponent = <SiteOwner logoutHandler={logoutHandler} />;
      break;
    case "operator":
      RoleComponent = <Operator logoutHandler={logoutHandler} />;
  }

  return (
    <>
      <SiteContextStates>{RoleComponent}</SiteContextStates>
    </>
  );
};

export default PrivateScreen;
