import React, { useState, useEffect } from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { fetchAllSites,fetchAllUsers } from "../../../shared/api/index";
import clsx from 'clsx';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import Subpage from "../../Layout/SubPage"
import TablePagination from "@material-ui/core/TablePagination";

const useStyles = makeStyles((theme) => ({
    fields: {
        width: 220,
    },
    request: {
        textAlign: 'center',
        fontSize: '25px',
        fontWeight: '500',
        color: 'rgb(7,36, 51 , 60%)'
    },
    box: {
        marginBottom: 10,
        marginRight: 20
    },
    site: {
        color: "#0c3670",
        fontWeight: 500
    },
    date: {
        marginLeft: 20
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
        textAlign: 'left'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'left',
        display: 'flex',
        justifyContent: 'space-between'
    },
    column: {
        flexBasis: '50%',
    },

    helper: {
        borderLeft: `2px solid rgba(25, 118, 210, 0.6)`,
        padding: theme.spacing(1, 2),
        width: '100%',

    },

    description: {
        fontWeight: 500,
    },
    info: {
        display: 'flex'

    },
    para: {
        marginLeft: "5px",
        color: '#2e6b99',
    },
    address: {
        color: '#2e9099',
        fontSize: '14px'
    }
}));


const Maintenence = (props) => {
    const ButtonDetails = {
        back: {label:"Back To Site", color:"blue", path:"/Sites/"}
      };
    
    const [requests, setRequests] = useState([]);
    const [sites, setSites] = useState([]);
    const [user, setUser] = useState([]);
    const [users, setUsers] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        let authToken = localStorage.getItem("authToken") || null;

        let user = null;
        if (authToken) {
            user = jwtDecode(authToken);
            setUser(user.id);
        }
    },[])

    useEffect(() => {

        const fetchData = async () => {
            const readurl = process.env.REACT_APP_BACKEND_LINK + "api/request/requestsofOwner/" + user;
            const data = await axios.get(readurl)
            let fetchedSites = await fetchAllSites();
            let fetchedUsers = await fetchAllUsers();
            setSites(fetchedSites)
            setRequests(data.data)
            setUsers(fetchedUsers)
        }
        fetchData();

    }, [user, props.list])

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setPage(0);
    }


    return (
        < div>
        <Subpage name='Closed Requests' color='blue'>
        {sites.filter(site => 
        requests.find(req => req.isClosed && req.siteId === site._id))
        .map((site) => 
        {
                return (
                    < Accordion expanded={expanded === site._id
                    } onChange={handleChange(site._id)}>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <div className={classes.column}>
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                <p className={classes.site}>{site.siteName}</p>
                                </Typography>
                            </div>

                        </AccordionSummary>
                        {requests.filter(req => req.isClosed && req.siteId == site._id)
                         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                         .map((req, i) => {
                            return (
                                < AccordionDetails key={"rd" + i} className={classes.details} >
                                    <div className={classes.info} />
                                    <div className={clsx(classes.helper)}>

                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Requested By:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.requestedBy}
                                            </Typography>
                                        </div>


                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Request Title:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.request_msg}
                                            </Typography>
                                        </div>
                                        <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Request Date:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {moment(req.requestDate).format("YYYY/MM/DD h:mm a")}
                                            </Typography>
                                        </div>
                                        {req.installerId && <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Installer:
                                            </Typography>
                                            {users.map((inst, i) => {
                                                return inst._id === req.installerId && (<Typography className={classes.para}>
                                                    {inst.name}
                                                </Typography>)
                                            })}
                                        </div>}
                                        
                                        {req.scheduledAt &&  <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Scheduled On:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.scheduledAt && moment(req.scheduledAt).format("YYYY/MM/DD")}
                                            </Typography>
                                        </div>}

                                        {req.installerReport && <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Installer Report:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.installerReport}
                                            </Typography>
                                        </div>}
                                        {req.adminNotes && <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Admin Notes:
                                            </Typography>
                                            <Typography className={classes.para}>
                                                {req.adminNotes}
                                            </Typography>
                                        </div>}
                                        {req.closedDate &&  <div className={classes.info}>
                                            <Typography className={classes.description}>
                                                Closed Date:
                                            </Typography>
                                            <Typography className={classes.para}>
                                            {req.closedDate && moment(req.closedDate).format("YYYY/MM/DD h:mm a")}
                                            </Typography>
                                        </div>}
                                    </div>
                                    

                                </AccordionDetails>
                            )
                        }
                        )}
                    </Accordion>
                )
            })
            }
            <TablePagination
                    rowsPerPageOptions={[5, 25, 100]}
                    component="div"
                    count={requests.filter(req => req.isClosed).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
 
</Subpage >

</div>

    )
}

export default Maintenence
