import axios from "axios";

export const fetchUsers = async () => {
    const readURL = process.env.REACT_APP_BACKEND_LINK + "api/auth/users";
    const user = await axios.get(readURL);
    return user.data;
};
export const fetchUserbyId = async (id) => {
    const readURL = process.env.REACT_APP_BACKEND_LINK + "api/auth/userbyId/" + id;
    const user = await axios.get(readURL);
    return user.data;
};

export const fetchUserSites = async (id) => {
    const readURL = process.env.REACT_APP_BACKEND_LINK + "api/user/sitesFor/" + id;
    const userData = await axios.get(readURL);
    return userData.data;
};

export const fetchUserRelatedSites = async (id) => {
    const readURL = process.env.REACT_APP_BACKEND_LINK + "api/user/sites/filter/" + id;
    const userData = await axios.get(readURL);
    return userData.data;
};

export const updateUserSites = async (id, body) => {
    const readURL = process.env.REACT_APP_BACKEND_LINK + "api/user/update/" + id;
    const userData = await axios.put(readURL, body);
    return userData.data;
};
export const updateUserbyId = async (id, body) => {
    const readURL = process.env.REACT_APP_BACKEND_LINK + "api/auth/updateUserbyId/" + id;
    const userData = await axios.put(readURL, body);
    return userData.data;
};
export const deleteUser = async (id, body) => {
    const readURL = process.env.REACT_APP_BACKEND_LINK + "api/user/delete/" + id;
    const userData = await axios.post(readURL, body);
    return userData.data;
};