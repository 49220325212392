import { fetchSite, fetchSites, fetchSitesForUser } from "./site";
import { fetchSitePackages } from "./package";
import { fetchSiteCycles } from "./cycle";
import { fetchSiteTanks } from "./tank";
import { fetchSiteBays } from "./bay";
import { fetchSiteControllers } from "./controller";
import {
  fetchInventoriesBySiteId,
  fetchInventoryById,
  updateInventoryStock
} from "./inventory";
import { fetchProductById, fetchReorderProductsBySite } from "./product";
import { fetchUsers } from "./users";
import { createAlert } from "./alert";


export const fetchOneSite = fetchSite;
export const fetchAllSites = fetchSites;
export const fetchAllPackages = fetchSitePackages;
export const fetchAllCycles = fetchSiteCycles;
export const fetchAllTanks = fetchSiteTanks;
export const fetchAllBays = fetchSiteBays;
export const fetchAllControllers = fetchSiteControllers;
export const getInventoriesBySiteId = fetchInventoriesBySiteId;
export const getchInventoryById = fetchInventoryById;
export const getProductById = fetchProductById;
export const fetchAllUsers = fetchUsers;
export const updateStock = updateInventoryStock;
export const createAlertForSite = createAlert;
export const getSitesForUser = fetchSitesForUser;
export const getReorderingProductsForSite = fetchReorderProductsBySite;
export * from "./order";
export * from "./users";
export * from "./controller";
export * from "./emailer";