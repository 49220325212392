import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import MainPage from '../../Layout/MainPage'
import ButtonLink from '../../Util/ButtonLink'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert'
import axios from 'axios';
import { fetchAllUsers } from '../../../shared/api/index'

import jwtDecode from 'jwt-decode';
import { FormHelperText } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert"
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    fields: {
        width: 220,
    },
    button: {
        marginRight: 20,
    },
}));

const Users = (props) => {
    const { isSubmited, setIsSubmited, screen } = props;

    const classes = useStyles();

    const initialValues = {
        name: '',
        email: '',
        phone: '',
        role: '',
        owner: ''
    }
    const [values, setValues] = useState(initialValues);
    const [open, setOpen] = React.useState(false);
    const [users, setUsers] = useState([]);
    const token = localStorage.getItem('authToken');
    const [error, setError] = useState("");
    const [errors, setErrors] = React.useState({
        name: '', 
        email: '',
        phone: '',
        role: '',
        owner:''
        }); 

    const handleInputChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            let fetchedUsers = await fetchAllUsers();
            const users = fetchedUsers.filter(user => {
                if(!user?.role && !user?.email ) return false;
                return user?.role.toLowerCase() === "owner";
            })
            setUsers(users);
        }
        fetchData();

    }, [ open, isSubmited])

    function handleCancel(e) {

        setValues({
            name: "",
            email: "",
            phone: "",
            role: "",
            owner: ''

        })
    }

    const validatePhone = phone => {
        return phone.match(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/);
      }
    
      const validateEmail = email => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    
      const userValidation = async e => 
      {
        let temp={};
        temp.name = values.name?"":"Please enter the Full Name";
        temp.email = values.email?"":"Please enter the Email";
        temp.phone = values.phone?"":"Please enter the Phone";
        temp.role = values.role?"":"Please select the Role";
        temp.owner = values.owner?"":"Please select the Owner";

        if(temp.email === "" && values.email)
          temp.email = validateEmail(values.email)?"":"Please enter valid email";
        if(temp.phone === "" && values.phone)
          temp.phone = validatePhone(values.phone)?"":"Please enter valid phone";
        if(values.role !== "operator")
            temp.owner = "";

        setErrors({
           ...temp
         })
         
          return Object.values(temp).every(x => x === "");
      }

    const handleSubmit = async () => {


        let body = {
            name: values.name,
            email: values.email,
            phone: values.phone,
            // password: values.name.slice(0, 4) + (values.phone).toString().slice((values.phone).toString().length - 4, (values.phone).toString().length),
            password: process.env.REACT_APP_DEFAULT_PASSWORD,
            role: values.role,
            owner: values.owner
        }
        //console.log(body)

            try {
                 await axios.post(process.env.REACT_APP_BACKEND_LINK + "api/auth/register", body);
                swal({
                    title: "Success!",
                    text: "New User Added",
                    icon: "success",
                    button: "OK!"
                }).then((result) => {
                    // Reload the Page
                    //window.location.reload();
                    handleCancel();
                    setIsSubmited(state => !state);
                    
                  });

                const decoded = jwtDecode(token);

                try {
                    await axios.post(
                    process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: decoded.id, comment: `Registered ${values.name}(${values.role}) `, category: "Register User" }
                    )
                }
                catch (error) {
                    console.log(error)
                }
                setOpen(false);
            }
            catch (e) {
                if(e.response && e.response.data && e.response.data.errors && e.response.data.errors[0].msg.includes(""))
                {
                    setError(e.response.data.errors[0].msg);
                    setTimeout(() => {
                    setError("");
                    }, 5000);
                } else if (e.message.includes("status")) {
                    swal({
                        title: "Error!",
                        text: "User Aleardy Exist",
                        icon: "error",
                        button: "OK!"
                    })
                } else if (e.message.includes("Network"))
                    swal({
                        title: "Error!",
                        text: e.message,
                        icon: "error",
                        button: "OK!"
                    })
            }

    }


    const handleClickOpen = () => {
        userValidation().then(function(result) {
            //console.log('result' , result);
            setOpen(result);
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MainPage name='User Management' color='blueGray'>
            <Typography variant='subtitle1'>Default password : {process.env.REACT_APP_DEFAULT_PASSWORD}</Typography>
            <form style={{ marginBottom: '30px' }}>
                <Grid container>
                {error && <Alert severity="error">{error}</Alert>}
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className={classes.fields}
                            required
                            label="Full Name"
                            name='name'
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                            helperText= {errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>

                        <TextField
                            className={classes.fields}

                            required
                            name='email'
                            label="Email"
                            value={values.email}
                            onChange={handleInputChange}
                            error={errors.email}
                            helperText= {errors.email}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>

                        <TextField
                            className={classes.fields}

                            required
                            name='phone'
                            label="Phone"
                            value={values.phone}
                            onChange={handleInputChange}
                            error={errors.phone}
                            helperText= {errors.phone}

                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>


                        <FormControl >
                            <InputLabel id="role" error = {errors.role} required>Role</InputLabel>
                            <Select
                                name='role'
                                required
                                className={classes.fields}

                                value={values.role}
                                label='Role'
                                onChange={handleInputChange}
                                error = {errors.role}
                            >
                                {screen == 'admin' && <MenuItem value={'admin'}>Admin</MenuItem>}
                                <MenuItem value={'distributor'}>Distributor</MenuItem>
                                <MenuItem value={'owner'}>Company</MenuItem>
                                <MenuItem value={'operator'}>Site Operator</MenuItem>
                            </Select>
                            {(errors.role) && <FormHelperText htmlFor='render-select' error>{errors.role}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    {values.role === 'operator' && 
                    <Grid item xs={12}>
                        <FormControl >
                            <InputLabel id="owner" error = {errors.owner} required>Company</InputLabel>
                            <Select
                                name='owner'
                                required
                                className={classes.fields}

                                value={values.owner}
                                label='Owner'
                                onChange={handleInputChange}
                                error = {errors.owner}
                            >
                                {users.map((user, i) => (
                                    <MenuItem key={i + 'ursss' + user?._id} value={user._id}>{user.name}</MenuItem>
                                ))}
                            </Select>
                            {(errors.owner) && <FormHelperText htmlFor='render-select' error>{errors.owner}</FormHelperText>}
                        </FormControl>
                    </Grid>}

                </Grid>
            </form>

            <Box display="flex" justifyContent="flex-end">
                <ButtonLink onClick={handleCancel} color="blue" name="Clear" />
                <ButtonLink onClick={handleClickOpen} color="pink" name="Save" />
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Please Confirm the User information"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Name: {values.name}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Email: {values.email}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Phone: {values.phone}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Role: {values.role}
                    </DialogContentText>
                    {users.map((own, i) => {
                        return values?.owner === own?._id &&
                            < DialogContentText key={i + 'ursssValue' + own?._id} id="alert-dialog-description" >
                                Company: {own.name}
                            </DialogContentText>
                    })}
                </DialogContent>
                <DialogActions>
                    <ButtonLink name='Confirm' onClick={handleSubmit} autoFocus>

                    </ButtonLink>
                    <ButtonLink color='pink' onClick={handleClose} name='Cancel'></ButtonLink>

                </DialogActions>
            </Dialog>
        </MainPage >
    )
}

export default Users
