import {useMutation, useQuery} from 'react-query';
import axios from "axios";

const handleFetchUsers = async (usersRole) => {
    const usersData = await axios.post(
        process.env.REACT_APP_BACKEND_LINK +
          "api/auth/userbyRole/",
         {
            headers: {
                "Content-Type": "application/json"
            },
            data : {role: usersRole}
         }
      );

      if (usersData?.data?.success) return usersData?.data?.users;

      return [];
};

export const useGetUsersByRole = () => {
  return useMutation(
    ({usersRole}) => handleFetchUsers(usersRole),
    { 
        staleTime: 1000*60*60*24
    }
  );
};