const DistributorData = {
    columns: [
        { id: 'distributorName', label: 'Name', minWidth: 80 },
        { id: 'distributorPhone', label: 'Phone', minWidth: 230 },
        { id: 'distributorContact', label: 'Contact Person', minWidth: 100 },
        { id: 'distributorEmail', label: 'Email', minWidth: 180 },
        { id: 'edit', label: 'Edit', minWidth: 80 },
        { id: 'delete', label: 'Delete', minWidth: 80 }
    ]
}

export default DistributorData