import React, { useState, useEffect, useContext } from "react";
import { authContext } from "../../../shared/context/auth/auth-context";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  Button,
  TableContainer,
  Typography,
  TableSortLabel,
  Box,
  Link,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
import ReactSelect, { components } from 'react-select';
import DropdownIndicator, { selectStyles } from "../../Util/DropdownIndicator";
import SearchBar from "material-ui-search-bar";
import { useHistory, useLocation } from "react-router";
import MainPage from "../../../components/Layout/MainPage";
import ConfirmDialog from "../../Util/ConfirmDialog/ConfirmDialog";
import PackageData from "./PackageData";
import ButtonLink from "../../Util/ButtonLink";
import axios from "axios";
import { SiteContext } from "../../../shared/context/site/site-context";
import Swal from "sweetalert2";
import { SettingsInputHdmi } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    "& .MuiTextField-root": {
      width: "35ch"
    }
  },
  formControl: {
    minWidth: "30ch",
    marginTop: "20px"
  },
  table: {
    minWidth: 650,
    transition: "0.5s ease"
  },
  searchBar: {
    marginBottom: "30px"
  },
  tableRow: {
    transition: "0.3s ease",
    "&:hover": {
      backgroundColor: "#b8eaff !important"
    }
  }
});
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.disabled
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "133ch"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    minWidth: "133ch"
  },
  button: {
    margin: theme.spacing(2)
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2)
  }
}))(TableRow);

const Packages = props => {
  const { site, packages, updateSiteContext } = useContext(SiteContext);
  const { user } = useContext(authContext);
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  let _id,
    siteId,
    CarWashName,
    CarWashOwner,
    packageId,
    packageName = "";
  if (!location.state?._id && !site?._id) {
    _id = localStorage.getItem("site_id");
    siteId = localStorage.getItem("siteId");
    CarWashName = localStorage.getItem("CarWashName");
    CarWashOwner = localStorage.getItem("CarWashOwner");
    packageId = localStorage.getItem("packageId");
    packageName = localStorage.getItem("packageName");
  } else {
    _id = location.state?._id || site._id;
    siteId = location.state?.siteId || site?.siteId;
    CarWashName = location.state?.siteName || site?.siteName;
    CarWashOwner = location.state?.siteOwner || site?.siteOwner;
    packageId = location.state?.packageId || packages[0]?.packageId;
    packageName = location.state?.packageName || packages[0]?.packageName;
    localStorage.setItem("site_id", _id);
    localStorage.setItem("siteId", siteId);
    localStorage.setItem("CarWashName", CarWashName);
    localStorage.setItem("CarWashOwner", CarWashOwner);
    localStorage.setItem("packageId", packageId);
    localStorage.setItem("packageName", packageName);
  }

  const title = packageName + " in " + CarWashName;

  //update list data by useEffect rather than hard code
  const [Cycles, setCycles] = useState([]);
  const [PackageCycles, setPackageCycles] = useState([]);
  const [Order, setOrder] = useState("");
  const [CycleSelect, setCycleSelect] = useState("");
  const [CycleSelectId, setCycleSelectId] = useState("");
  const [rerender, setRerender] = useState(false);

  useEffect(
    () => {
      const fetchData = async () => {
        //fetch cycles of this site
        let cyclesReadURL =
          process.env.REACT_APP_BACKEND_LINK + "api/cycle/read/" + _id;
        let cyclesResult = await axios.get(cyclesReadURL);

        //fetch package
        let packReadOneURL =
          process.env.REACT_APP_BACKEND_LINK +
          "api/package/readone/" +
          packageId;
        let packagResult = await axios.get(packReadOneURL);

        //update cycles state
        if (cyclesResult?.data) {
          cyclesResult = cyclesResult.data.map(cyc =>{
            return {...cyc, product: cyc?.tank_id[0]?.product}
          })
          setCycles(cyclesResult);
        }
        //update packge cycles state
        if (packagResult?.data && cyclesResult) {
          let packagCycles = [];
          packagResult.data.packageCycles.forEach(packCycle => {
            cyclesResult.forEach(cycl => {
              if (packCycle.cycleId == cycl._id) {
                packagCycles.push({
                  cycleOrder: packCycle.cycleOrder,
                  cycle: cycl
                });
              }
            });
          });

          setPackageCycles(
            packagCycles.sort(function(a, b) {
              return a.cycleOrder - b.cycleOrder;
            })
          );
        }
      };
      fetchData();

      return () => {
        updateSiteContext(_id);
      };
    },
    [rerender]
  );
  //table states and functions
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //save button
  async function saveCyclToPackage() {
    let newPackageCycles = [];
    let err = false;
    //check if input empty
    if (CycleSelectId == "" && Order == "") {
      Swal.fire({
        icon: "error",
        text: "cycle input is empty"
      });
      err = true;
      return;
    }

    //check if cycle empty
    if (CycleSelectId == "" && Order != "") {
      Swal.fire({
        icon: "error",
        text: "cycle is empty"
      });
      err = true;
      return;
    }

    //check if order empty
    if (CycleSelectId != "" && Order == "") {
      Swal.fire({
        icon: "error",
        text: "cycle order is empty"
      });
      err = true;
      return;
    }

    //input correct
    if (CycleSelectId != "" && Order != "") {
      //verify if cycle order is assigned to a diffrent cycle
      PackageCycles.every(packCycle => {
        if (packCycle?.cycleOrder === Order) {
          Swal.fire({
            icon: "error",
            text: "cycle with chosen order already exist!"
          });
          err = true;
          setOrder("");
          setCycleSelect("");
          setCycleSelectId("");

          return false;
        } else {
          newPackageCycles.push({
            cycleId: packCycle?.cycle._id,
            cycleOrder: packCycle?.cycleOrder
          });
          return true;
        }
      });
    }

    //update package in database
    if (err == false) {
      //new packge cycles
      let data = JSON.stringify({
        packageId: packageId,
        packageCycles: [
          ...newPackageCycles,
          { cycleId: CycleSelectId, cycleOrder: Order }
        ]
      });
      //axios config
      let config = {
        method: "put",
        url: process.env.REACT_APP_BACKEND_LINK + "api/package/update",
        headers: {
          "Content-Type": "application/json"
        },
        data: data
      };
      //axios call
      let updatedPackage = await axios(config);

      if (updatedPackage?.data.success) {
        try {
          await axios.post(
            process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Package ${packageName} Updated at ${CarWashName}`, category: "Update Package" }
          )
        }
        catch (error) {
          console.log(error)
        }
        
        Swal.fire({
          icon: "success",
          text: "cycle added successfuly"
        });
      }
      if (!updatedPackage?.data.success) {
        Swal.fire({
          icon: "error",
          text: "something went wrong"
        });
      }
      setOrder("");
      setCycleSelect("");
      setCycleSelectId("");
      rerender ? setRerender(false) : setRerender(true);
    }
  }

  //remove cycle from package
  async function removeCycleFromPackage(cycle) {
    let newPackageCycles = [];
    if (cycle) {
      //newPackageCycles = PackageCycles.filter(
      //  pCycle => pCycle.cycleOrder != cycle.cycleOrder
      //);
      //remove cycle and format the new package cycle
      newPackageCycles = PackageCycles.map(pCycle => {
        if (pCycle.cycleOrder === cycle.cycleOrder) {
          return null;
        } else {
          return { cycleOrder: pCycle.cycleOrder, cycleId: pCycle.cycle._id };
        }
      });

      //remove null from array
      newPackageCycles = newPackageCycles.filter(cycle => cycle != null);

      //new packge cycles
      let data = JSON.stringify({
        packageId: packageId,
        packageCycles: newPackageCycles
      });
      //axios config
      let config = {
        method: "put",
        url: process.env.REACT_APP_BACKEND_LINK + "api/package/update",
        headers: {
          "Content-Type": "application/json"
        },
        data: data
      };
      //axios call
      let updatedPackage = await axios(config);

      if (updatedPackage?.data.success) {
        try {
          await axios.post(
            process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Package ${packageName} Updated at ${CarWashName}`, category: "Update Package" }
          )
        }
        catch (error) {
          console.log(error)
        }
        Swal.fire({
          icon: "success",
          text: "cycle removed successfuly"
        });
      }
      if (!updatedPackage?.data.success) {
        Swal.fire({
          icon: "error",
          text: "something went wrong"
        });
      }
      setOrder("");
      setCycleSelect("");
      setCycleSelectId("");
      rerender ? setRerender(false) : setRerender(true);
    }
  }

  //delete package
  async function deletePackage() {
    //console.log("package deleted");
    //console.log(packageId);
    let data = JSON.stringify({
      packageId: packageId
    });

    let config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_LINK + "api/package/delete",
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };

    let deletPackageResult = await axios(config);

    history.push({
      pathname: "/site/",
      search: _id,
      state: {
        _id: _id,
        siteId: siteId,
        siteName: CarWashName,
        siteOwner: CarWashOwner
      }
    });
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_LINK + "api/auth/logs", { userId: user.id, comment: `Package ${packageName} Deleted from ${CarWashName}`, category: "Delete Package"  }
      )
    }
    catch (error) {
      console.log(error)
    }
  }
  //add cycle order like an array of numbers so they can only accept from the next numbers
  return (
    <MainPage name={title}>
      {user?.role == 'admin' &&
      <>
      <FormControl
        className={classes.formControl}
        style={{ marginLeft: "20px" }}
        >
            <InputLabel id="CycleSelect" 
             InputLabelProps={{ shrink: true }} style={CycleSelect ? {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
            } : {}}>Add Cycle</InputLabel>
            <ReactSelect
              name="CycleSelect"
              labelId="CycleSelect"
              id="CycleSelect"
              placeholder="Add Cycle"
              styles={selectStyles}
              onChange={e => {
                const cycle = Cycles.find(p => p?._id == e?.value);
                setCycleSelectId(e?.value);
                setCycleSelect(cycle);
              }}
              components={{ DropdownIndicator }}
              value={CycleSelect ? {
                value: CycleSelect._id,
                label: CycleSelect.cycleName
              } : null}
              options={Cycles.map((row) => {
                return(
                {
                    value: row?._id, 
                    label: row.cycleName
                }
                )
            })}
            ></ReactSelect>
          </FormControl>

      <TextField
        type="number"
        id="Order"
        style={{ margin: "20px" }}
        label="Cycle Order"
        value={Order}
        onInput={e => setOrder(e.target.value)}
      />
      <Box display="flex" justifyContent="flex-end" marginBottom="10px">
        <ButtonLink color="green" name="Save" onClick={saveCyclToPackage} />
      </Box>
      </>
      }
      <TableContainer component={Paper}>
        <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {PackageData.columns.map(column => {
                if(!(user.role === 'admin') && (column.id === 'edit' || column.id === 'delete')) return null;
                return (
                    <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {PackageCycles.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ).map((row, index) => {
            return (
              <TableRow
                hover
                className={classes.tableRow}
                key={row?.cycle.cycleId + index}
              >
                <TableCell>{row.cycleOrder}</TableCell>
                <TableCell>{row.cycle.cycleName}</TableCell>
                <TableCell>{row.cycle.product}</TableCell>
                <TableCell>{row.cycle.threshold}</TableCell>
                {
                  user?.role === 'admin' &&
                  <TableCell>
                  <Button
                    color="warning"
                    onClick={() => {
                      removeCycleFromPackage(row);
                    }}
                  >
                    Remove
                  </Button>
                </TableCell>
                }
              </TableRow>
            );
          })}
        </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[8, 25, 100]}
        component="div"
        count={PackageCycles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Box display="flex" justifyContent="flex-end">
     
        {
          user?.role === 'admin' &&
          <>
                  <ButtonLink
            color="green"
            name="Update Package"
            height="20px"
            onClick={() => {
              history.push({
                pathname: "/updateInput",
                state: {
                  _id: _id,
                  siteName: CarWashName,
                  siteOwner: CarWashOwner,
                  packageId: packageId
                }
              });
            }}
          />

        <ButtonLink
            color="red"
            name="Delete Package"
            height="20px"
            variant="contained"
           onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!"
            }).then(result => {
              if (result.isConfirmed) {
                deletePackage();
                Swal.fire("Deleted!", "Package has been deleted.", "success");
              }
            });
          }}
          />
          </>
        }
            <ButtonLink
            color="blue"
            name="Back to site"
            onClick={() => {
              history.push({
                pathname: "/Site/",
               // search: _id,
                state: {
                  _id: _id,
                  siteId: siteId,
                  siteName: CarWashName,
                  siteOwner: CarWashOwner
                }
              });
            }}
          />
      </Box>
    </MainPage>
  );
};

export default Packages;
