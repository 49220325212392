import React, { useState, useEffect, useContext } from "react";
import MainPage from "../Layout/MainPage";
import Map from "../Map/Map.js";
import mapStyles from "../Map/mapStyles";
import { makeStyles } from "@material-ui/core/styles";
import "@material-tailwind/react/tailwind.css";
import { Alerts } from '../Alerts/admin/Alerts'
import Logs from "../Logs/Logs";
import { authContext } from "../../shared/context/auth/auth-context";
import {useGetSites} from "../../hooks/queries/useGetSites";
import {useGetSitesAddresses} from "../../hooks/queries/useGetSitesAddresses";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import DashboardAlerts from "./DashboardAlerts.js";

export const mapContainerStyle = {
  width: "auto",
  height: "50vh"
};
export const center = {
  lat: 43.40751,
  lng: -80.30635
};


export const options = {
  zoomControl: true,
  styles: mapStyles,
  disableDefaultUI: true
};

const Dashboard = () => {

  const { user } = useContext(authContext);
  const {data: sites = [] } = useGetSites();
  const {data: address = [] } = useGetSitesAddresses();
  const [coordinates, setCoordinates] = useState([]);

  useEffect(() => {
   if(address?.length > 0){
      const fetch = address.map((add => {
        return {id: add?.addressId,
          lat: parseFloat(add?.lat), lng:parseFloat(add?.lng) }
      }))

      setCoordinates(fetch);
   }
  }, [address]);

  return (
    <>
      <MainPage name={"Dashboard"} color="teal"> 
        { sites.length > 0 && address.length > 0 ? 
          (
            <Map
              key={Math.random() * 100 + 'map'}
              zoom={7}
              mapContainerStyle={mapContainerStyle}
              center={center}
              points={coordinates}
              options={options}
              site={sites}
              address={address}
            />
          ): (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
          )  
      }
      </MainPage>
      <DashboardAlerts />

      {user?.role === 'admin' && <Alerts />}
      {user?.role === 'admin' && <Logs />}
      
    </>
  );
};

export default Dashboard;
