import { ErrorSharp, Face, Fingerprint } from "@material-ui/icons";
import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import Button from "@material-tailwind/react/Button";
import { makeStyles } from "@material-ui/core/styles";
import "@material-tailwind/react/tailwind.css";
import Input from "@material-tailwind/react/Input";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import ButtonLink from "../Util/ButtonLink";
import axios from "axios";

export default function ForgotPassword() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  function forgotPasswordValidation() {
    let temp = validateEmail(email) ? "" : "Please enter valid email";
    setErrors(temp);
    return temp === "";
  }

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();
    let result = forgotPasswordValidation();
    if (result) {
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(
          process.env.REACT_APP_BACKEND_LINK + "api/auth/forgotPassword",
          { email },
          config
        );
        //console.log("Succes",data)
        if (data?.success== true) {
          setSuccess(data?.success);
          swal({
            title: "Email Sent",
            text: `You will recieve reset password link to this email address: ${email} if you are registered with us`,
            icon: "success",
            button: "OK!",
          });
        }
      } catch (error) {
        //console.log('in catch=', error.response.data.error);
        setError(error.response.data.error);
        swal({
          title: "Email Sent",
          text: `You will recieve reset password link to this email address: ${email} if you are registered with us`,
          icon: "success",
          button: "OK!",
        });
        //setEmail("");
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
  };

  const useStyles = makeStyles((theme) => ({
    padding: {
      padding: theme.spacing(5),
      width: "600px",
      height: "300px",
      minWidth: "200px",
    },
    box: {
      background: " linear-gradient(-45deg, #ee7752, #23a6d5, #23d5ab);",
      height: "100vh",
    },
    margin: {
      marginTop: "auto",
      flex: 0.8,
      alignItems: "center",
    },
    icon: {
      fontSize: "2rem",
      color: "grey",
    },
    card: {
      width: "600px",
    },
  }));

  const classes = useStyles();
  return (
    <form onSubmit={forgotPasswordHandler}>
      <Box
        className={classes.box}
        display="flex"
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        p={2}
      >
        <Card className={classes.card}>
          <CardHeader color="blueGray" size="lg">
            <Typography variant="h5">Forgot Password</Typography>
          </CardHeader>
          {error && <span className="error-message">{error}</span>}
          {success && <span className="success-message">{success}</span>}
          <CardBody>
            <p className="forgotpassword-screen__subtext">
              Please enter the email address you registered your account with.
              We will send you reset password confirmation to this email
            </p>

            <Box mt={4}>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <Face className={classes.icon} />
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <Input
                    type="email"
                    color="teal"
                    placeholder="Email Address"
                    onChange={(e) => setEmail(e.target.value.trim())}
                    error={errors}
                    helperText={errors}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardBody>

          <CardFooter>
            <div className="flex justify-center">
              <Button color="teal" size="lg" ripple="dark" type="submit">
                Send Email
              </Button>
              <Box display="flex" justifyContent="flex justify-center">
              <ButtonLink
                name="Back to Login"
                color="teal"
                onClick={() => {
                  history.push({
                    pathname: "/login",
                  });
                }}
              />
            </Box>
            </div>
         
          </CardFooter>
        </Card>
      </Box>
    </form>
  );
}
