import React, { useEffect, useContext } from "react";
import { Link, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import SiteLayout from "./SiteLayout";
import Button from "@material-tailwind/react/Button";
import ButtonLink from "../../../Util/ButtonLink";
import { makeStyles,useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SiteContext } from "../../../../shared/context/site/site-context";
import {
    fetchOneSite
  } from "../../../../shared/api";

const SiteDetails = function SetSite(props) {
  const { site } = useContext(SiteContext);
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    emptyPackages: {
      minHeight: '80px',
  },
}));
const classes = useStyles();
const theme = useTheme();

  let _id = props.site_id,
    siteId = props.siteId,
    CarWashName = props.siteName,
    CarWashOwner = props.siteOwner,
    fixedGridCols = props.fixedGridCols;

  const SiteDetails = [
  {label:"Bays", path:"/bays/"}, 
  {label:"Tanks", path:"/tanks/"},
  {label:"Controllers", path:"/controllers/"}
  ];

  const maxRowElements={xs:1, sm:3, md:4, lg:6, xl:7};
  const spacing = {
  mobile: fixedGridCols/maxRowElements.xs, 
  ipad: fixedGridCols/maxRowElements.sm,
  nesthub: fixedGridCols/maxRowElements.md,
  laptop: fixedGridCols/maxRowElements.lg,
  desktop: fixedGridCols/maxRowElements.xl,
  }

  const ButtonDetails = {
    update: {label:"Update Site", color:"orange", path:"/updateSite"}, 
    back: {label:"Back To Site", color:"blue", path:"/sitesAssigned/"}
  };

  const matches = useMediaQuery(theme.breakpoints.only('xs'));
  const [AddressId, setAddressId] = React.useState(site?.addressId);

  useEffect(() => {
    const fetchData = async () => {

      try {
        const site = await fetchOneSite(_id);
        if (site) {
          setAddressId(site.addressId);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <SiteLayout name='Bays | Tanks | Controllers' color='cyan'>
        {/* two types of layout: containers and items. */}
        <Grid container className = {classes.emptyPackages} spacing={2}>
        {SiteDetails.map(row => (
          // Item width are sized relative to their parent element., internally sized as percentages
          // five grid breakpoints: xs, sm, md, lg, and xl
          <Grid item 
            xs={spacing.mobile} 
            sm={spacing.ipad} 
            md={spacing.nesthub} 
            lg={spacing.laptop} 
            xl={spacing.desktop}>
            <Button color="purple" buttonType="link" ripple="dark">
              <Link
                style={{ cursor: "pointer" }}
                href={_id}
                onClick={e => {
                  e.preventDefault();
                  history.push({
                    pathname: row.path,
                    state: {
                    _id: _id,
                    siteId: siteId,
                    siteName: CarWashName,
                    siteOwner: CarWashOwner
                    }
                  });
                }}
                color="inherit"
                underline="none"
              >
                {row.label.length <= (matches?11:15) ? row.label: row.label.substr(0, (matches?11:15)) + "..."}
              </Link>
            </Button>
          </Grid>
          ))}
        </Grid>  

              <Grid container spacing={2} justifyContent="flex-end" >

              <Grid item
                  >
                      <ButtonLink
                          color={ButtonDetails.update?.color}
                          name={ButtonDetails.update?.label}
                          onClick={() => {
                              history.push({
                                  pathname: ButtonDetails.update?.path,
                                  state: {
                                      _id: _id,
                                      siteId: siteId,
                                      siteName: CarWashName,
                                      siteOwner: CarWashOwner,
                                      addressId: AddressId
                                  }
                              });
                          }}
                      />
                  </Grid>

                  <Grid item

                  >
                      <ButtonLink
                          color={ButtonDetails.back?.color}
                          name={ButtonDetails.back?.label}
                          onClick={() => {
                              history.push({
                                  pathname: ButtonDetails.back?.path
                              });
                          }}
                      />
                  </Grid>
          </Grid>       
      </SiteLayout>
    </>
  );
};

export default SiteDetails;
